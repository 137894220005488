import { makeStyles } from "@material-ui/styles";

// TODO: We could factorize this for common styles among pages
export const useWhitelistStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  titleLayout: {
    textAlign: "left",
    marginTop: "0",
    width: "200",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  tabs: {
    marginBottom: 20,
  },
  actions: {
    height: 40,
    display: "flex",
    justifyContent: "flex-end",
    flexFlow: "row nowrap",
    gap: "8px",
  },
  row: {
    display: "flex",
    width: "70%",
    alignItems: "center",
    flex: 1,
  },
  columnName: {
    flex: 1,
  },
  columnFormat: {
    width: 100,
  },
  columnUpdate: {
    width: 150,
  },
  columnActions: {
    width: 45,
    textAlign: "center",
  },
  buttonAction: {
    padding: 0,
  },
  loaderWrapperLayout: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  whitelistLabel: {
    verticalAlign: "text-bottom",
  },
  lockedWhitelist: {
    marginLeft: "10px",
    position: "relative",
    top: 3,
  },
});

export default useWhitelistStyles;

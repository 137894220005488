import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "../pages/home";
import GeographicalReferential from "../pages/referentials/geographical";
import StationReferential from "../pages/referentials/station";
import UserTable from "../pages/user/user-table";
import Configurations from "../pages/configurations";
import Palette from "../palette";
import Deployments from "../pages/deployments";
import Whitelists from "../pages/whitelists";
import useRights from "../services/ducks/rights";
import ForbiddenAccess from "../pages/home/forbidden-access";
import PageNotFound from "../pages/page-not-found";
import { getConfig } from "services/config";
import { TabKey } from "pages/whitelists/hooks/use-tabs";

function RouteManager() {
  const { hasRights, hasSomeRights } = useRights();
  const config = getConfig();

  const routes = [
    <Route
      path="/"
      key="homePage"
      exact={true}
      component={hasRights("HOME_TABS") ? Home : ForbiddenAccess}
    />,
  ];

  if (!config.LIGHT_MODE) {
    routes.push(
      <Route path="/misc" key="palettePage" exact={true} component={Palette} />
    );
  }

  if (hasRights("USER_READ")) {
    routes.push(
      <Route path="/users" key="usersPage" exact={true} component={UserTable} />
    );
  }

  if (
    !config.LIGHT_MODE &&
    hasSomeRights(["REF_GEO_GARE_READ", "REF_GEO_GROUP_READ"])
  ) {
    routes.push(
      <Route
        path="/referential/geo"
        key="geographicalReferentialPage"
        component={GeographicalReferential}
      />
    );
  }

  if (!config.LIGHT_MODE && hasRights("SUPERVISION_REF_GARE_READ")) {
    routes.push(
      <Route
        path="/referential/stations/:stationId?"
        key="stationReferentialPage"
        component={StationReferential}
      />
    );
  }

  if (
    !config.LIGHT_MODE &&
    hasSomeRights(["CONF_TECH_READ", "CONF_TRANSV_READ", "CONF_TRANSP_READ"])
  ) {
    routes.push(
      <Route
        path="/configurations/:type?/:transporter?"
        key="configurationPage"
        component={Configurations}
      />
    );
  } else if (hasSomeRights(["CONF_TRANSP_READ"])) {
    routes.push(
      <Route
        path="/configurations/:type(transporters)/:transporter?"
        key="configurationPage"
        component={Configurations}
      />
    );
  }

  if (
    !config.LIGHT_MODE &&
    hasSomeRights(["DEPLOY_SOFT_READ", "DEPLOY_CONF_READ"])
  ) {
    routes.push(
      <Route path="/deploy" key="deploymentPage" component={Deployments} />
    );
  }

  if (
    (!config.LIGHT_MODE &&
      hasSomeRights(["WL_UG_CONFIG_READ", "WL_TRANSPORTER_CONFIG_READ"])) ||
    (config.LIGHT_MODE && hasRights("WL_TRANSPORTER_CONFIG_READ"))
  ) {
    // exclude UG from light
    const authorizedTypes = config.LIGHT_MODE
      ? Object.values(TabKey)
          .filter((k) => k !== TabKey.UG)
          .join("|")
      : Object.values(TabKey).join("|");
    routes.push(
      <Route
        path={"/whitelists/:type(" + authorizedTypes + ")/:transporter?"}
        key="whitelistsPage"
        component={Whitelists}
      />
    );
  }

  routes.push(<Route key="pageNotFoundPage" component={PageNotFound} />);

  return <Switch>{routes}</Switch>;
}

export default RouteManager;

import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Theme,
} from "@material-ui/core";
import FormCheckboxList, {
  updateArray,
} from "../../../../components/form/checkbox-list";
import {
  checkFields,
  FieldValidator,
} from "../../../../validators/form-validator";
import { isEmpty, max } from "../../../../validators/form-validators";
import createStyles from "@material-ui/core/styles/createStyles";
import isEqual = require("lodash/isEqual");
import cloneDeep = require("lodash/cloneDeep");
import FormControl from "@material-ui/core/FormControl";
import classNames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { LaneFormState } from "../lane-tab";
import {
  Dock,
  Gate,
  Lane,
  Track,
  TrackLane,
} from "../../../../services/interfaces/referential";
import FormSelect from "../../../../components/form/select";
import TrackLaneTable, {
  TrackLaneErrors,
  TrackLaneValidatorResult,
} from "../common/track-lane-table";
import { UseCrud } from "../../../../commons/use-crud";

interface LaneFormDialogProperties {
  form: LaneFormState;
  setForm: (status: LaneFormState) => void;
  stationId: string;
  gates: Gate[];
  docks: Dock[];
  tracksState: UseCrud<Track>;
  lanesState: UseCrud<Lane>;
}

const laneInitialValue: Lane = {
  id: "",
  name: "",
  description: "",
  gateIds: [],
  dockId: "",
  stationId: "",
  trackLaneDTOList: [],
};

interface Errors {
  name: string;
  description: string;
}

const validators: FieldValidator<Lane, Errors> = {
  name: [
    { error: "Veuillez renseigner un nom", validator: isEmpty },
    { error: "Longueur max: 64", validator: (name: string) => max(name, 64) },
  ],
  description: [
    {
      error: "Longueur max: 100",
      validator: (description: string) => max(description, 100),
    },
  ],
};

const defaultErrors: Errors = {
  name: "",
  description: "",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginTop: {
      marginTop: theme.spacing(2),
    },
    dialogPaper: {
      overflowY: "visible",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      flexWrap: "nowrap",
      minWidth: "40vw",
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
    buttonsGroupLayout: {
      display: "flex",
      justifyContent: "flex-end",
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(2),
    },
  })
);

function LaneFormDialog({
  form,
  setForm,
  stationId,
  gates,
  docks,
  tracksState,
  lanesState,
}: LaneFormDialogProperties) {
  const classes = useStyles();
  const [data, setData] = useState<Lane>({ ...laneInitialValue, stationId });
  const [errors, setErrors] = useState<Errors>(defaultErrors);
  const [trackLanesErrors, setTrackLanesErrors] = useState<TrackLaneErrors[]>(
    []
  );
  const trackLanesErrorsReference = useRef<TrackLaneValidatorResult>({
    errors: [],
    isValid: true,
  });

  useEffect(() => {
    if (form.open) {
      setErrors(defaultErrors);
      if (form.data === undefined) {
        setData({
          ...laneInitialValue,
          stationId,
        });
      } else {
        setData({ ...form.data });
      }
    }
  }, [form.data, form.open, stationId]);

  function close() {
    setForm({
      ...form,
      open: false,
    });
  }

  function save() {
    if (validateData()) {
      if (form.open && form.submit !== undefined) {
        form.submit({ ...data });
      }
      close();
    }
  }

  function handleGateIdsChange(event: React.MouseEvent<HTMLDivElement>) {
    const gateIds: string[] = updateArray(
      event.currentTarget.dataset["value"] as string,
      data.gateIds
    );
    setData({ ...data, gateIds });
  }

  const validateData = (): boolean => {
    const errors = checkFields<Lane, Errors>(validators, defaultErrors, data);
    setErrors(errors);
    setTrackLanesErrors(trackLanesErrorsReference.current.errors);
    return [
      isEqual(errors, defaultErrors),
      trackLanesErrorsReference.current.isValid,
    ].every(Boolean);
  };

  function onTrackLanesValidateData(result: TrackLaneValidatorResult) {
    trackLanesErrorsReference.current = result;
  }

  function onTrackLanesChange(trackLanes: TrackLane[]) {
    if (
      !(
        trackLanes.length === data.trackLaneDTOList.length &&
        data.trackLaneDTOList.every((a) =>
          trackLanes.some(
            (b) =>
              a.laneId === b.laneId &&
              a.trackId === b.trackId &&
              a.name === b.name &&
              a.dockId === b.dockId &&
              a.position === b.position &&
              a.type === b.type
          )
        )
      )
    ) {
      setData({ ...data, trackLaneDTOList: cloneDeep(trackLanes) });
    }
  }

  const dock = useCallback(
    () => docks.find((d) => d.id === data.dockId) as Dock,
    [data.dockId, docks]
  );

  return (
    <Dialog
      open={form.open}
      onBackdropClick={close}
      onEscapeKeyDown={close}
      color={"primary"}
      aria-labelledby="form-dialog-title"
      PaperProps={{ className: classNames(classes.dialogPaper) }}
    >
      <DialogTitle id="form-dialog-title">
        {form.action === "add" ? "Créer une ligne" : "Modifier une ligne"}
      </DialogTitle>
      <DialogContent>
        <FormControl variant="standard" fullWidth margin="dense">
          <TextField
            id="name"
            label="Nom"
            type="text"
            value={data.name || ""}
            onChange={(event) => setData({ ...data, name: event.target.value })}
            error={errors.name !== ""}
            helperText={errors.name}
          />
        </FormControl>
        <FormControl
          variant="standard"
          fullWidth
          margin="dense"
          className={classes.marginBottom}
        >
          <TextField
            id="description"
            label="Description"
            type="text"
            value={data.description || ""}
            onChange={(event) =>
              setData({ ...data, description: event.target.value })
            }
            error={errors.description !== ""}
            helperText={errors.description}
          />
        </FormControl>
        {gates.some((g) => !g.laneId || g.laneId === data.id) && (
          <div className={classes.marginBottom}>
            <FormCheckboxList
              label="Portes associées"
              handleOnChange={handleGateIdsChange}
              availableOptions={gates
                .filter((g) => !g.laneId || g.laneId === data.id)
                .map((g) => ({ id: g.id, name: g.name }))}
              checkedElements={data.gateIds}
              noFixedHeight
              height="175px"
            />
          </div>
        )}
        <FormControl variant="standard" fullWidth margin="dense">
          <FormSelect
            handleOnChange={(value) => {
              setData({ ...data, dockId: value });
            }}
            availableOptions={docks}
            value={data.dockId}
            label="Associer à une zone étanche"
          />
        </FormControl>
        {dock() && (
          <div className={classes.marginTop}>
            <TrackLaneTable
              dockId={dock().id}
              trackIds={dock().trackIds}
              laneIds={[data.id]}
              trackLaneDTOList={data.trackLaneDTOList}
              tracksState={tracksState}
              lanesState={lanesState}
              onChange={onTrackLanesChange}
              onValidateData={onTrackLanesValidateData}
              trackLanesErrors={trackLanesErrors}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions className={classes.buttonsGroupLayout}>
        <Button onClick={close} variant="contained">
          Annuler
        </Button>
        <Button onClick={save} color="secondary" autoFocus variant="contained">
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default LaneFormDialog;

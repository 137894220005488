import React, { useContext, useState } from "react";
import {
  addGate,
  deleteGate,
  deleteGates,
  updateGate,
  updateGateActive,
  getLatestLogs,
  getAllLogs,
} from "../../../services/api/gates";
import {
  Dock,
  Gate,
  Lane,
  LedColor,
} from "../../../services/interfaces/referential";
import { createStyles, Fab, Paper, Theme } from "@material-ui/core";
import OptionsButton from "../../../components/options-button";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/styles";
import { AppContext } from "../../../app-provider";
import BooleanRenderer from "../../../components/virtualized-table/renderers/boolean-renderer";
import Tooltip from "@material-ui/core/Tooltip";
import GateFormDialog from "./dialog/gate-form-dialog";
import { fetchLanes } from "../../../services/api/lanes";
import { UseCrud } from "../../../commons/use-crud";
import { MATERIAL_ORANGE_100 } from "../../../theme";
import StatusChip from "../../../components/virtualized-table/renderers/user-status";
import { useSnackbar } from "notistack";
import SortableAndSearchableVirtualizedTable from "../../../components/virtualized-table/sortable-and-searchable-virtualized-table";
import useRights from "../../../services/ducks/rights";
import GateVersionsDialog from "./dialog/gate-versions-dialog";

interface GateTabProperties {
  stationId: string;
  gatesState: UseCrud<Gate>;
  lanesState: UseCrud<Lane>;
  docksState: UseCrud<Dock>;
  dataInFetching: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
    },
    fab: {
      float: "right",
      margin: theme.spacing(1),
    },
    orphanRow: {
      background: MATERIAL_ORANGE_100,
    },
  })
);

export interface GateFormState {
  open: boolean;
  action?: "add" | "edit";
  data?: Gate;
  submit?: (gate: Gate) => void | Promise<void>;
}

export interface GateVersionsState {
  open: boolean;
  id: string;
  name: string;
}

export const ledColorOptions = [
  {
    name: "LED Eteint/Bleues",
    shortName: "E/B",
    id: LedColor.OFF + "_" + LedColor.BLUE,
  },
  {
    name: "LED Rouges/Vertes",
    shortName: "R/V",
    id: LedColor.RED + "_" + LedColor.GREEN,
  },
  {
    name: "LED Rouges/Bleues",
    shortName: "R/B",
    id: LedColor.RED + "_" + LedColor.BLUE,
  },
];

/**
 * GateTab Component
 */
function GateTab({
  stationId,
  gatesState,
  lanesState,
  docksState,
  dataInFetching,
}: GateTabProperties) {
  const classes = useStyles();
  const { hasRights } = useRights();

  const gates = gatesState.datas;
  const lanes = lanesState.datas;
  const [selected, setSelected] = useState<Set<string>>(new Set([]));
  const [versionsDialog, setVersionsDialog] = useState<GateVersionsState>({
    open: false,
    id: "",
    name: "",
  });
  const [, confirmDialogDispatch] =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useContext(AppContext).reducers.confirmDialog!;
  const [formDialog, setFormDialog] = useState<GateFormState>({ open: false });
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  // ----------- ReactVirtualizedTable
  const handleSelected = (gate: Gate, checked: boolean) => {
    const updateSelected = new Set(selected);
    checked ? updateSelected.add(gate.id) : updateSelected.delete(gate.id);
    setSelected(updateSelected);
  };

  const isSelected = (gate: Gate): boolean => {
    return selected.has(gate.id);
  };

  // ----------- api actions

  async function deleteById(rowIndex: number) {
    handleSelected(gates[rowIndex], false);
    try {
      await deleteGate(gates[rowIndex].id);
      enqueueSnackbar(`Porte "${gates[rowIndex].name}" supprimée avec succès`, {
        variant: "success",
      });
      gatesState.remove(gates[rowIndex].id);
      if (lanes.some((l) => l.gateIds.includes(gates[rowIndex].id))) {
        lanesState.set(await fetchLanes(stationId));
      }
    } catch {
      enqueueSnackbar(`Erreur lors de la suppression de la porte`, {
        variant: "error",
      });
    }
  }

  async function deleteBySelection() {
    try {
      await deleteGates(selected);
      enqueueSnackbar(
        selected.size > 0
          ? `Portes supprimées avec succès`
          : `Porte "${selected.values().next().value}" supprimée avec succès`,
        { variant: "success" }
      );
      gatesState.removeAll(selected);
      if (lanes.some((l) => [...selected].some((r) => l.gateIds.includes(r)))) {
        lanesState.set(await fetchLanes(stationId));
      }
      setSelected(new Set([]));
    } catch {
      enqueueSnackbar(
        selected.size > 0
          ? `Erreur lors de la suppression des portes`
          : `Erreur lors de la suppression de la porte`,
        {
          variant: "error",
        }
      );
    }
  }

  async function add(gate: Gate) {
    try {
      gatesState.add(await addGate(gate));
      enqueueSnackbar(`Porte créée avec succès`, { variant: "success" });
      if (gate.laneId) {
        lanesState.set(await fetchLanes(stationId));
      }
    } catch {
      enqueueSnackbar(`Erreur lors de la création de la porte`, {
        variant: "error",
      });
    }
  }

  async function edit(gate: Gate) {
    try {
      const oldGate = gates.find((g) => g.id === gate.id);
      gatesState.update(await updateGate(gate));
      enqueueSnackbar(`Porte modifiée avec succès`, {
        variant: "success",
      });
      if (oldGate && oldGate.laneId !== gate.laneId) {
        lanesState.set(await fetchLanes(stationId));
      }
    } catch {
      enqueueSnackbar(`Erreur lors de la modification de la porte`, {
        variant: "error",
      });
    }
  }

  async function toggleStatus(rowIndex: number) {
    try {
      const gate = [...gates][rowIndex];
      gate.active = !gate.active;
      gatesState.update(await updateGateActive(gate));
      enqueueSnackbar(`Status de la porte modifié avec succès`, {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(`Erreur lors de la modification du status de la porte`, {
        variant: "error",
      });
    }
  }

  async function handleFetchLogs(
    gate: Gate,
    getLogs: (id: string, name: string) => Promise<void>
  ) {
    const snackbar = enqueueSnackbar("Téléchargement des logs", {
      variant: "info",
      persist: true,
    });
    try {
      await getLogs(gate.id, gate.name);
    } catch {
      enqueueSnackbar("Erreur lors du téléchargement des logs", {
        variant: "error",
      });
    } finally {
      if (snackbar !== null) {
        closeSnackbar(snackbar);
      }
    }
  }

  function handleVersionsDialogOpen(rowIndex: number) {
    setVersionsDialog({
      open: true,
      id: gates[rowIndex].id,
      name: gates[rowIndex].name,
    });
  }

  function handleGetVersionsDialogClose() {
    setVersionsDialog((versionsDialog) => ({
      ...versionsDialog,
      open: false,
    }));
  }

  // ----------- renders

  const renderOptionsButton = (rowIndex: number) => {
    const buttonActions = hasRights("REF_GARE_WRITE")
      ? [
          {
            label: gates[rowIndex].active ? "Rendre Inactif" : "Rendre Actif",
            handler: () => toggleStatus(rowIndex),
          },
          {
            label: "Modifier les informations",
            handler: () => {
              setFormDialog({
                open: true,
                action: "edit",
                data: { ...gates[rowIndex] },
                submit: (gate: Gate) => edit(gate),
              });
            },
          },
          {
            label: "Supprimer la porte",
            handler: () =>
              confirmDialogDispatch({
                type: "open",
                title: "Suppression de ligne",
                msg:
                  'Êtes-vous sûr de vouloir supprimer la porte "' +
                  gates[rowIndex].name +
                  '" ?',
                submit: () => deleteById(rowIndex),
              }),
          },
        ]
      : [];

    if (hasRights("REF_GARE_LOGS")) {
      buttonActions.push(
        {
          label: "Télécharger les derniers logs",
          handler: () => handleFetchLogs(gates[rowIndex], getLatestLogs),
        },
        {
          label: "Télécharger tous les logs",
          handler: () => handleFetchLogs(gates[rowIndex], getAllLogs),
        }
      );
    }

    if (hasRights("REF_GARE_VERSIONS")) {
      buttonActions.push({
        label: "Consulter les versions",
        handler: () => handleVersionsDialogOpen(rowIndex),
      });
    }

    return <OptionsButton buttonActions={buttonActions} />;
  };

  const headerLabelGate = [
    { label: "Nom", dataKey: "name", sortable: true, searchable: true },
    {
      label: "PMR",
      dataKey: "pmr",
      width: 100,
      sortable: true,
      renderer: (gate: Gate) => <BooleanRenderer value={gate.pmr} />,
    },
    {
      label: "LAF écran bas",
      dataKey: "lafBottomScreenEnabled",
      width: 150,
      sortable: true,
      renderer: (gate: Gate) => (
        <BooleanRenderer value={gate.lafBottomScreenEnabled} />
      ),
    },
    {
      label: "Couleurs LED",
      dataKey: "baseColor",
      width: 120,
      sortable: true,
      renderer: (gate: Gate) => {
        const option = ledColorOptions.find(
          (o) => o.id === gate.baseColor + "_" + gate.customColor
        );
        return option ? <span>{option.shortName}</span> : undefined;
      },
    },
    {
      label: "Contrôle Sortie TN",
      dataKey: "controlTnEnabled",
      width: 170,
      sortable: true,
      renderer: (gate: Gate) => (
        <BooleanRenderer value={gate.controlTnEnabled} />
      ),
    },
    {
      label: "URL",
      dataKey: "gateUrlAddress",
      sortable: true,
      searchable: true,
    },
    {
      label: "Double LAF - IP seconde porte",
      dataKey: "lafAdditionalGateAddress",
      sortable: true,
      searchable: true,
    },
    {
      label: "Ligne",
      dataKey: "laneId",
      sortable: true,
      searchable: true,
    },
    {
      label: "Zone étanche",
      dataKey: "dockId",
      sortable: true,
      searchable: true,
    },
    {
      label: "Status",
      dataKey: "active",
      width: 170,
      sortable: true,
      renderer: (gate: Gate) => <StatusChip active={gate.active} />,
      renderButtonActions: renderOptionsButton,
    },
  ];

  return (
    <>
      <GateVersionsDialog
        open={versionsDialog.open}
        gateId={versionsDialog.id}
        gateName={versionsDialog.name}
        handleClose={handleGetVersionsDialogClose}
      />
      <GateFormDialog
        form={formDialog}
        setForm={setFormDialog}
        stationId={stationId}
        lanes={lanes}
      />
      <Paper className={classes.root}>
        <SortableAndSearchableVirtualizedTable
          rows={gates.map((gate) => ({
            ...gate,
            laneId: lanesState.getName(gate.laneId),
            dockId: docksState.getName(gate.dockId),
          }))}
          rowStyle={(row: Gate) => {
            if (!row.laneId) {
              return classes.orphanRow;
            }
          }}
          rowCount={gates.length}
          updateRows={gatesState.set}
          columns={headerLabelGate}
          selected={selected}
          setSelected={setSelected}
          handleSelected={handleSelected}
          isSelected={isSelected}
          dataInFetching={dataInFetching}
          renderSelectionCheckboxes={hasRights("REF_GARE_WRITE")}
        />
      </Paper>
      {hasRights("REF_GARE_WRITE") && (
        <div>
          <Tooltip title="Ajouter" aria-label="Ajouter" className={classes.fab}>
            <Fab
              color="secondary"
              onClick={() =>
                setFormDialog({
                  open: true,
                  action: "add",
                  submit: add,
                })
              }
            >
              <AddIcon />
            </Fab>
          </Tooltip>
          <Tooltip
            title="Supprimer"
            aria-label="Supprimer"
            className={classes.fab}
          >
            <div>
              <Fab
                disabled={selected.size === 0}
                onClick={() =>
                  confirmDialogDispatch({
                    type: "open",
                    title: "Suppression de porte",
                    msg: "Êtes-vous sûr de vouloir supprimer cette sélection ?",
                    submit: deleteBySelection,
                  })
                }
              >
                <DeleteIcon />
              </Fab>
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
}

export default GateTab;

import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Theme,
} from "@material-ui/core";
import {
  checkFields,
  FieldValidator,
} from "../../../../validators/form-validator";
import { isEmpty, max } from "../../../../validators/form-validators";
import createStyles from "@material-ui/core/styles/createStyles";
import isEqual = require("lodash/isEqual");
import FormControl from "@material-ui/core/FormControl";
import classNames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { StationFormState } from "../index";
import {
  BoardingModes,
  BoardingModesTransporter,
  StationUpdate,
} from "../../../../services/interfaces/referential";
import AccordionCheckBoxList from "../../../../components/form/accordion-checkbox-list";

interface StationFormDialogProperties {
  form: StationFormState;
  setForm: (status: StationFormState) => void;
}

interface Errors {
  updateUrl: string;
}

const validators: FieldValidator<StationUpdate, Errors> = {
  updateUrl: [
    { error: "Veuillez renseigner une url de mise à jour", validator: isEmpty },
    { error: "Longueur max: 160", validator: (name: string) => max(name, 160) },
  ],
};

const availableOptions: {
  [key: string]: {
    [key in keyof BoardingModesTransporter]: boolean;
  };
} = {
  VOYAGES: {
    mission: true,
    ttl: true,
    unboarding: false,
    boarding: false,
  },
  OUIGO: {
    mission: true,
    ttl: true,
    unboarding: false,
    boarding: false,
  },
  INTERCITES: {
    mission: true,
    ttl: true,
    unboarding: false,
    boarding: false,
  },
  TER: {
    mission: true,
    ttl: true,
    unboarding: true,
    boarding: true,
  },
  TRENITALIA: {
    mission: true,
    ttl: true,
    unboarding: false,
    boarding: true,
  },
};

const availableTransporters: string[] = Object.keys(availableOptions);

const defaultBoardingModesTransporter: BoardingModesTransporter = {
  mission: false,
  ttl: false,
  unboarding: false,
  boarding: false,
};

const stationInitialValue: StationUpdate = {
  updateUrl: "",
  boardingModesTransporters: availableTransporters.map((transporterName) => ({
    transporterName,
    ...defaultBoardingModesTransporter,
  })),
};

const defaultErrors: Errors = {
  updateUrl: "",
};

function areAllTransportersPresent(
  boardingModesTransporters: BoardingModesTransporter[]
): boolean {
  return availableTransporters.every((t) =>
    boardingModesTransporters.map((bmt) => bmt.transporterName).includes(t)
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginTopField: {
      marginTop: theme.spacing(2),
    },
    dialogPaper: {
      overflowY: "visible",
    },
    buttonsGroupLayout: {
      display: "flex",
      justifyContent: "flex-end",
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(2),
    },
  })
);

function StationFormDialog({ form, setForm }: StationFormDialogProperties) {
  const classes = useStyles();
  const [data, setData] = useState<StationUpdate>({
    ...stationInitialValue,
  });
  const [errors, setErrors] = useState<Errors>(defaultErrors);

  useEffect(() => {
    if (form.open) {
      setErrors(defaultErrors);
      if (
        form.data &&
        form.data.boardingModesTransporters !== undefined &&
        areAllTransportersPresent(form.data.boardingModesTransporters)
      ) {
        setData({ ...form.data });
      } else if (
        form.data &&
        form.data.boardingModesTransporters !== undefined &&
        !areAllTransportersPresent(form.data.boardingModesTransporters)
      ) {
        for (const transporterName of availableTransporters) {
          if (
            !form.data?.boardingModesTransporters
              .map((bmt) => bmt.transporterName)
              .includes(transporterName)
          ) {
            form.data.boardingModesTransporters.push({
              transporterName,
              ...defaultBoardingModesTransporter,
            });
          }
        }

        setData({
          ...form.data,
          boardingModesTransporters: form.data.boardingModesTransporters,
        });
      } else {
        setData({ ...stationInitialValue });
      }
    }
  }, [form.data, form.open]);

  function close() {
    setForm({
      ...form,
      open: false,
    });
  }

  function save() {
    if (validateData()) {
      if (form.open && form.submit !== undefined) {
        form.submit({ ...data });
      }
      close();
    }
  }

  const validateData = (): boolean => {
    const errors = checkFields<StationUpdate, Errors>(
      validators,
      defaultErrors,
      data
    );
    setErrors(errors);
    return isEqual(errors, defaultErrors);
  };

  const handleTransporterChange = (
    event: React.MouseEvent<HTMLDivElement>,
    parameters: string
  ) => {
    const boardingModesTransporters: BoardingModesTransporter[] = [];
    for (const bmt of data.boardingModesTransporters)
      boardingModesTransporters.push(Object.assign({}, bmt));

    const value: string | undefined = event.currentTarget.dataset["value"];
    const transporterName: string = parameters;

    if (value !== undefined) {
      const indexToUpdate = boardingModesTransporters.findIndex(
        (boardingModesTransporter) =>
          boardingModesTransporter.transporterName === transporterName
      );

      if (
        indexToUpdate !== -1 &&
        (value == "mission" ||
          value == "ttl" ||
          value == "unboarding" ||
          value == "boarding")
      ) {
        boardingModesTransporters[indexToUpdate][value] =
          !boardingModesTransporters[indexToUpdate][value];
      }
    }
    setData({ ...data, boardingModesTransporters });
  };

  return (
    <Dialog
      open={form.open}
      onBackdropClick={close}
      onEscapeKeyDown={close}
      color={"primary"}
      aria-labelledby="form-dialog-title"
      PaperProps={{ className: classNames(classes.dialogPaper) }}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Modifier les informations de la gare
      </DialogTitle>
      <DialogContent>
        <FormControl variant="standard" fullWidth margin="dense">
          <TextField
            id="updateUrl"
            label="Url de mise à jour"
            type="text"
            value={data.updateUrl || ""}
            onChange={(event) =>
              setData({ ...data, updateUrl: event.target.value })
            }
            error={errors.updateUrl !== ""}
            helperText={errors.updateUrl}
          />
          <AccordionCheckBoxList
            handleOnChange={handleTransporterChange}
            availableOptions={availableOptions}
            optionsLabelMapping={BoardingModes}
            label={"Modes autorisés par transporteur"}
            checkedElements={data.boardingModesTransporters}
          />
        </FormControl>
      </DialogContent>
      <DialogActions className={classes.buttonsGroupLayout}>
        <Button onClick={close} variant="contained">
          Annuler
        </Button>
        <Button onClick={save} color="secondary" autoFocus variant="contained">
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default StationFormDialog;

import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import useRights from "services/ducks/rights";
import { RouteProperties } from ".";
import Tabs, { Tab } from "../../components/tabs-component";
import { TabKey } from "./hooks/use-tabs";

export const transporterTabs = [
  {
    key: "EUROPE",
    label: "Europe",
  },
  {
    key: "JC",
    label: "J&C",
  },
  {
    key: "VOYAGES",
    label: "Voyages",
  },
  {
    key: "TER",
    label: "TER",
  },
  {
    key: "EURAIL",
    label: "Eurail",
  },
  {
    key: "OUIGO",
    label: "Ouigo",
  },
  {
    key: "TRENITALIA",
    label: "TrenItalia",
  },
];

interface TransporterTabsProperties
  extends RouteComponentProps<RouteProperties> {
  className?: string;
  onTabChange: (tab: Tab) => void;
}

function useTabs(): Tab[] {
  const tabs: Tab[] = [];
  const { getUserInfo, hasActivity, hasSomeRights } = useRights();

  const userInfo = getUserInfo();
  const authorizedTransportersTabs = transporterTabs.filter(
    (t) =>
      (hasActivity("GC") && hasSomeRights(["WL_TRANSPORTER_CONFIG_READ"])) ||
      (hasActivity("TRANSPORTER") && userInfo.transporters.includes(t.key))
  );

  tabs.push(...authorizedTransportersTabs);

  return tabs;
}

export const TransporterTabs: React.FC<TransporterTabsProperties> = (
  properties
) => {
  const { history, match, className, onTabChange } = properties;
  const tabs = useTabs();
  const [transporter, setTransporter] = useState(match.params.transporter);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const onChangeIndexTab = (index: number) => {
    setTransporter(tabs[index].key);
  };

  useEffect(() => {
    // select default tab
    if (match.params.transporter === undefined && tabs.length > 0) {
      setTransporter(tabs[0].key);
    } else {
      setTransporter(match.params.transporter);
    }
  }, [match.params.transporter]);

  useEffect(() => {
    const tabIndex = tabs.findIndex((t) => t.key == transporter);

    if (tabIndex > -1) {
      const tab = tabs[tabIndex];
      setSelectedTabIndex(tabIndex);
      history.push(`/whitelists/${TabKey.TRANSPORTER}/${transporter}`);
      onTabChange(tab);
    }
  }, [transporter]);

  return (
    <Tabs
      tabs={useTabs()}
      indexTab={selectedTabIndex}
      onChangeIndexTab={onChangeIndexTab}
      secondary={true}
      additionalClassName={className}
    />
  );
};

export default withRouter(TransporterTabs);

import React, { useState } from "react";
import { CloudDownload, MoreHoriz } from "@material-ui/icons";
import { makeStyles, IconButton, Menu, MenuItem } from "@material-ui/core";

const useStyles = makeStyles({
  fileRoot: {
    display: "flex",
    flex: "1 1 auto",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
    padding: "4px",
    alignItems: "center",
    border: "1px solid rgba(0, 0, 0, .125)",
    backgroundColor: "rgba(0, 0, 0, .03)",
    boxShadow: "none",
    "&:not(:last-of-type)": {
      borderBottom: 0,
    },
    "&:first-of-type": {
      borderRadius: "3px 3px 0px 0px",
    },
    "&:last-of-type": {
      borderRadius: "0px 0px 3px 3px",
    },
  },
  fileName: {
    flex: "0 1 90%",
    paddingLeft: "8px",
    overflowWrap: "anywhere",
  },
  fileIcons: {
    display: "flex",
    flex: "0 0 10%",
    flexFlow: "row nowrap",
    justifyContent: "space-between",
  },
  horizIcon: {
    float: "right",
    paddingTop: "10%",
  },
});

interface HelpFileProperties {
  file: string;
  onDelete: (file: string) => void;
  onDownload: (file: string) => void;
  canDelete?: boolean;
}

export function HelpFile({
  file,
  onDelete,
  onDownload,
  canDelete = false,
}: HelpFileProperties) {
  const [anchorElement, setAnchorElement] = useState<HTMLElement>();
  const open = Boolean(anchorElement);
  const classes = useStyles();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElement(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorElement(undefined);
  };

  const handleDownload = () => {
    handleMenuClose();
    onDownload(file);
  };

  const handleDelete = () => {
    handleMenuClose();
    onDelete(file);
  };

  return (
    <div className={classes.fileRoot}>
      <div className={classes.fileName}>{file}</div>
      <div className={classes.fileIcons}>
        {canDelete && (
          <div>
            <IconButton
              aria-label={"More"}
              aria-haspopup={"menu"}
              onClick={handleMenuClick}
              size="small"
            >
              <MoreHoriz className={classes.horizIcon} />
            </IconButton>
            <Menu
              open={open}
              onClose={handleMenuClose}
              anchorEl={anchorElement}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem onClick={handleDownload}>Télécharger</MenuItem>
              <MenuItem onClick={handleDelete}>Supprimer</MenuItem>
            </Menu>
          </div>
        )}
        {!canDelete && (
          <IconButton
            aria-label={"Download"}
            aria-haspopup={"menu"}
            onClick={handleDownload}
            size="small"
          >
            <CloudDownload color="primary" />
          </IconButton>
        )}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import {
  BundleAvailableVersions,
  VersionInfo,
} from "../../services/api/deployments";
import FormSelect from "../../components/form/select";
import ConfigOption from "./config-option";

const mappingNameToTitle: { [key: string]: string } = {
  // Configuration
  "gate-config-technical": "Configuration Technique",
  "gate-config-business": "Configuration Transverse",
  "gate-config-transporter-eurail": "Configuration Transporteur Eurail",
  "gate-config-transporter-ouigo": "Configuration Transporteur Ouigo",
  "gate-config-transporter-voyages": "Configuration Transporteur Voyages",
  "gate-config-transporter-tnu": "Configuration Transporteur TNU",
  "gate-config-transporter-jc": "Configuration Transporteur Junior & Compagnie",
  "gate-config-transporter-ter": "Configuration Transporteur TER",
  "gate-config-transporter-europe": "Configuration Transporteur Europe",
  "gate-config-transporter-trenitalia": "Configuration Transporteur TrenItalia",
  // Software
  rfidvalidator: "Version billettique",
  gate: "Version porte",
  "gate-daemon": "Script déploiement",
  "plugin-ticketprocessor-ter": "Traitement titres TER",
  "plugin-ticketprocessor-ryo": "Traitement titres TER ARO",
  "plugin-ticketprocessor-tnu": "Traitement titres TNU",
  "plugin-ticketprocessor-resarail": "Traitement titres Voyages",
  "plugin-ticketprocessor-europe": "Traitement titres Europe",
  "plugin-ticketprocessor-ouigo": "Traitement titres Ouigo",
  "plugin-ticketprocessor-jc": "Traitement titres Junior & Compagnie",
  "plugin-ticketprocessor-eurail-interrail": "Traitement titres Eurail",
  "plugin-ticketprocessor-trenitalia": "Traitement titres TrenItalia",
  "plugin-ticketprocessor-trenitalia-mission":
    "Traitement titres TrenItalia Mission",
  "plugin-decoder-eurail-interrail": "Décodeur Eurail",
  "plugin-decoder-ter": "Décodeur TER",
  "plugin-decoder-ryo": "Décodeur RYO",
  "plugin-decoder-cards": "Décodeur Cartes & Abonnements",
  "plugin-decoder-ouigo": "Décodeur Ouigo",
  "plugin-decoder-jc": "Décodeur Junior & Compagnie",
  "plugin-decoder-resarail": "Décodeur Voyages",
  "plugin-decoder-europe": "Décodeur Europe",
  "plugin-decoder-trenitalia": "Décodeur TrenItalia",
};

interface VersionSelectListElementProperties {
  reference: React.MutableRefObject<null>;
  selectedVersions: { [key: string]: string | undefined };
  setSelectedVersions: (selectedVersions: {
    [key: string]: string | undefined;
  }) => void;
  v: BundleAvailableVersions;
  setPreview: (preview: VersionInfo | undefined) => void;
}

function VersionSelectListElement({
  reference,
  selectedVersions,
  setSelectedVersions,
  v,
  setPreview,
}: VersionSelectListElementProperties) {
  const [isListReady, setIsListReady] = useState(false);

  useEffect(() => {
    return () => setPreview(undefined);
  }, []);

  return (
    <FormSelect
      ref={reference}
      setIsListReady={setIsListReady}
      handleOnChange={(value: string) => {
        setSelectedVersions({
          ...selectedVersions,
          [v.info.fullName]: value === "" ? undefined : value,
        });
      }}
      handleCloseSelect={() => {
        setPreview(undefined);
      }}
      availableOptions={[
        { id: "", name: <span>-</span> },
        ...v.versions.map((c) => ({
          name: (
            <ConfigOption
              versionInfo={c}
              handleOpenConsult={(versionInfo: VersionInfo) => {
                isListReady && setPreview(versionInfo);
              }}
              handleCloseConsult={() => {
                setPreview(undefined);
              }}
            />
          ),
          id: c.version,
          searchableElement: c.name || c.version,
        })),
      ]}
      value={selectedVersions[v.info.fullName] || ""}
      label={mappingNameToTitle[v.info.fullName] || v.info.name}
      key={mappingNameToTitle[v.info.fullName] || v.info.name}
      searchable
    />
  );
}

export default VersionSelectListElement;

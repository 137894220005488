import React, { useState } from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { IconButton, Menu, MenuItem } from "@material-ui/core";

export interface ButtonActions {
  label: string;
  props?: object;
  handler: (event: React.ChangeEvent<unknown>) => void;
}

interface OptionsButtonProperties {
  buttonActions: ButtonActions[];
  buttonClassName?: string;
  renderButton?: (
    onClick: (event: React.MouseEvent<HTMLElement>) => void
  ) => JSX.Element;
}

function OptionsButton(properties: OptionsButtonProperties) {
  const { buttonActions, buttonClassName, renderButton } = properties;
  const [anchor, setAnchor] = useState<HTMLElement>();

  const handleOption = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(undefined);
  };

  return (
    <div onClick={(event) => event.stopPropagation()}>
      {renderButton ? (
        renderButton(handleOption)
      ) : (
        <IconButton
          aria-label={"More"}
          aria-haspopup={true}
          onClick={handleOption}
          className={buttonClassName}
        >
          <MoreHorizIcon style={{ float: "right", paddingTop: "10%" }} />
        </IconButton>
      )}
      <Menu
        open={Boolean(anchor)}
        anchorEl={anchor}
        onClose={handleClose}
        getContentAnchorEl={undefined}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {buttonActions.map((b, index) => (
          <MenuItem
            key={index}
            {...b.props}
            onClick={(event: React.ChangeEvent<unknown>) => {
              handleClose();
              b.handler(event);
            }}
          >
            {b.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default OptionsButton;

import axios from "axios";
import { Zone } from "../interfaces/referential";

export async function fetchZones(stationId: string): Promise<Zone[]> {
  const response = await axios.get(
    "/referential/stations/" + stationId + "/zones"
  );
  return response.data;
}

export async function addZone(zone: Zone) {
  const response = await axios.post("/referential/zones", {
    name: zone.name,
    description: zone.description,
    laneIds: zone.laneIds,
    stationId: zone.stationId,
  });
  return response.data;
}

export async function updateZone(zone: Zone) {
  const response = await axios.put("referential/zones/" + zone.id, {
    id: zone.id,
    name: zone.name,
    description: zone.description,
    laneIds: zone.laneIds,
    stationId: zone.stationId,
  });
  return response.data;
}

export async function deleteZones(zoneIds: Set<string>) {
  await axios.delete("/referential/zones/" + [...zoneIds].join(","), {
    responseType: "text",
  });
}

export async function deleteZone(zoneId: string) {
  await deleteZones(new Set([zoneId]));
}

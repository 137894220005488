import axios from "axios";
import { Track } from "../interfaces/referential";

export async function fetchTracks(stationId: string) {
  const response = await axios.get(
    "/referential/stations/" + stationId + "/tracks"
  );
  return response.data;
}

export async function addTrack(track: Track) {
  const response = await axios.post("/referential/tracks", {
    name: track.name,
    description: track.description,
    dockId: track.dockId,
    stationId: track.stationId,
    trackLaneDTOList: track.trackLaneDTOList,
  });
  return response.data;
}

export async function updateTrack(track: Track) {
  const response = await axios.put("/referential/tracks/" + track.id, {
    id: track.id,
    name: track.name,
    description: track.description,
    dockId: track.dockId,
    stationId: track.stationId,
    trackLaneDTOList: track.trackLaneDTOList,
  });
  return response.data;
}

export async function deleteTracks(trackIds: Set<string>) {
  await axios.delete("/referential/tracks/" + [...trackIds].join(","), {
    responseType: "text",
  });
}

export async function deleteTrack(trackId: string) {
  await deleteTracks(new Set([trackId]));
}

import React, { useState } from "react";
import { HelpFilesModal } from "components/help-files-modal/help-files-modal";
import useRights from "services/ducks/rights";
import { Button } from "@material-ui/core";
import { getConfig } from "services/config";

export function WhitelistHelpButton() {
  const [openHelp, setOpenHelp] = useState<boolean>(false);
  const { hasRights } = useRights();
  const config = getConfig();

  return (
    <div>
      <Button
        color="secondary"
        variant="contained"
        onClick={() => setOpenHelp(true)}
      >
        Aide
      </Button>
      <HelpFilesModal
        open={openHelp}
        helpType="whitelist"
        onClose={() => setOpenHelp(false)}
        canDelete={!config.LIGHT_MODE && hasRights("WL_HELP_DELETE")}
        canUpload={!config.LIGHT_MODE && hasRights("WL_HELP_CREATE")}
      />
    </div>
  );
}

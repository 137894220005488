import React, { useContext, useEffect, useState, useMemo } from "react";
import Tabs from "../../components/tabs-component";
import ExpandableTable from "../../components/expandable-table";
import { PanelContent } from "../../components/expandable-table/panel-content";
import { TopLevelPanel } from "../../components/expandable-table/top-level-panel";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  ConfigurationType,
  ConfigurationValueType,
  deleteConfiguration,
  deleteConfigurationValue,
  downloadConfiguration,
  downloadConfigurationFile,
  fetchConfiguration,
  fetchConfigurations,
  fetchConfigurationValues,
  fetchTransporters,
  TicketProcessor,
  updateConfiguration,
} from "../../services/api/configurations";
import DateFormatter from "../../components/date-formatter";
import {
  ExpandableColumn,
  TreeNode,
  ValueRow,
} from "../../components/expandable-table/types";
import { makeStyles } from "@material-ui/styles";
import OptionsButton from "../../components/options-button";
import { AppContext } from "../../app-provider";
import { useSnackbar } from "notistack";
import EditConfigurationDialog from "./edit-configuration-dialog";
import EditConfigurationValueDialog from "./edit-configuration-value-dialog";
import CreateConfigurationDialog from "./create-configuration-dialog";
import Button from "@material-ui/core/Button";
import ExpandableIcon from "../../components/expandable-table/expandable-icon";
import { RouteComponentProps, withRouter } from "react-router";
import useRights from "../../services/ducks/rights";
import { AxiosError } from "axios";
import { getConfig } from "services/config";
import { RequestError } from "services/interfaces/common";
import ConsultConfigurationDialogContent from "./consult-configuration-dialog-content";
import { DialogContentText } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  titleLayout: {
    textAlign: "left",
    marginTop: "0",
    width: "200",
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  tabs: {
    marginBottom: 20,
  },
  actions: {
    height: 40,
    display: "flex",
    justifyContent: "flex-end",
  },
  row: {
    display: "flex",
    width: "70%",
    alignItems: "center",
    flex: 1,
  },
  nameColumn: {
    flex: 3,
  },
  smallColumn: {
    flex: 1,
    // min-width allows empty div to occupy space
    minWidth: 1,
  },
  columnActions: {
    width: 45,
    textAlign: "center",
  },
  buttonAction: {
    padding: 0,
  },
  loaderWrapperLayout: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

interface ConfigurationValue {
  id: string;
  name: string;
  lastUpdateDatetime?: string;
  type: ConfigurationValueType;
  path: string;
  rowType: "value";
}

export interface Configuration {
  id: string;
  name: string;
  version: string;
  description: string;
  author?: string;
  creationDatetime: string;
  frozen: boolean;
  testVersion: boolean;
  standalone?: boolean;
  rows?: ValueRow<ConfigurationValue>[];
}

const fetchConfigurationValuesAndBuildTree = async (
  configurationType: ConfigurationType,
  transporter: string | undefined,
  config: Configuration
): Promise<ValueRow<ConfigurationValue>[]> => {
  const values = await fetchConfigurationValues(
    configurationType,
    transporter,
    config.id
  );
  const treeValues: ValueRow<ConfigurationValue>[] = [];

  for (const v of values) {
    const tValue: ConfigurationValue = {
      rowType: "value",
      id: v.id,
      name: v.name,
      lastUpdateDatetime: v.lastUpdateDatetime,
      type: v.type,
      path: v.path,
    };

    if (!v.path || v.path === "") {
      treeValues.push(tValue);
      continue;
    }

    // Vérification dans les lignes si le level existe déjà
    const existingPath = treeValues.find(
      (t) => t.rowType === "expandable" && t.label === v.path
    );

    if (existingPath && existingPath.rowType === "expandable") {
      existingPath.values.push(tValue);
      continue;
    }

    // Construction de la ligne intermédiaire pour le path
    treeValues.push({
      rowType: "expandable",
      label: v.path,
      values: [tValue],
    });
  }

  // Trie des dossiers
  for (const t of treeValues) {
    if (t.rowType === "expandable") {
      t.values.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  return treeValues.sort((a, b) => {
    if (a.rowType === "expandable") {
      if (b.rowType === "expandable") return a.label.localeCompare(b.label);

      return -1;
    }

    if (b.rowType === "expandable") return 1;

    return a.name.localeCompare(b.name);
  });
};

type ConfigurationFilters = Partial<Configuration>;

function filterConfiguration(
  configuration: Configuration,
  filters: ConfigurationFilters | undefined
) {
  if (!filters) return true;

  if (filters.name) {
    return (
      configuration.name.toLowerCase().includes(filters.name.toLowerCase()) ||
      `v${configuration.version.toLowerCase()}`.includes(
        filters.name.toLowerCase()
      )
    );
  }

  return true;
}

interface RouteProperties {
  type?: string;
  transporter?: string;
}

function Configurations({
  history,
  match,
}: RouteComponentProps<RouteProperties>) {
  const [addValue, setAddValue] = useState<boolean>(false);
  const [addConfiguration, setAddConfiguration] = useState<boolean>(false);
  const [transporters, setTransporters] = useState<TicketProcessor[]>([]);
  const [transportersLoading, setTransportersLoading] =
    useState<boolean>(false);
  const [editingValue, setEditingValue] = useState<{
    conf: string | undefined;
    value: string | undefined;
  }>({ conf: undefined, value: undefined });
  const [editingConfigurationId, setEditingConfigurationId] =
    useState<string>();
  const [configurations, setConfigurations] = useState<Configuration[]>();
  const [_, confirmDialogDispatch] =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useContext(AppContext).reducers.confirmDialog!;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { hasRights } = useRights();
  const config = getConfig();

  const tabs: { key: string; label: string; value: ConfigurationType }[] = [];

  const isTechniques = match.params.type === "techniques";
  const isTransverses = match.params.type === "transverses";
  const isTransporter = match.params.type === "transporters";

  // Has write access for each tab
  const hasWriteRights =
    (isTechniques && hasRights("CONF_TECH_WRITE")) ||
    (isTransverses && hasRights("CONF_TRANSV_WRITE")) ||
    (isTransporter && hasRights("CONF_TRANSP_WRITE"));

  // Has download right for current tab
  const hasDownloadRights =
    (isTechniques && hasRights("CONF_TECH_DOWNLOAD")) ||
    (isTransverses && hasRights("CONF_TRANSV_DOWNLOAD")) ||
    (isTransporter && hasRights("CONF_TRANSP_DOWNLOAD"));

  if (!config.LIGHT_MODE && hasRights("CONF_TECH_READ")) {
    tabs.push({ key: "techniques", label: "Techniques", value: "TECHNICAL" });
  }
  if (!config.LIGHT_MODE && hasRights("CONF_TRANSV_READ")) {
    tabs.push({ key: "transverses", label: "Transverses", value: "BUSINESS" });
  }
  if (hasRights("CONF_TRANSP_READ")) {
    tabs.push({
      key: "transporters",
      label: "Transporteurs",
      value: "TRANSPORTER",
    });
  }

  // Find the selected tab
  const selectedTab = tabs.find((t) => t.key === match.params.type) || tabs[0];

  function getCurrentType() {
    return selectedTab.value;
  }

  function getCurrentTransporter() {
    return match.params.transporter;
  }

  useEffect(() => {
    async function loadTransporters() {
      const t = await fetchTransporters();
      setTransporters(t);
      setTransportersLoading(false);
    }

    setTransportersLoading(true);
    loadTransporters();
  }, []);

  useEffect(() => {
    if (match.params.type === "transporters") {
      triggerConfigurationsLoad();
    }
  }, [match.params.type, transporters]);

  async function triggerConfigurationsLoad() {
    const { type, transporter } = match.params;

    if (type === "transporters" && !transporter) {
      if (transporters.length > 0) {
        history.push(`/configurations/transporters/${transporters[0].key}`);
      }
      return;
    }

    setConfigurations(undefined);
    async function loadConfigurations() {
      if (!selectedTab) {
        return;
      }

      // Find the transporter key when transporter tab selected
      let transporterKey;
      if (type === "transporters") {
        const selectedTransporter = transporters.find(
          (t) => t.key === transporter
        );
        if (!selectedTransporter) {
          return;
        }

        transporterKey = selectedTransporter.key;
      }
      const dto = await fetchConfigurations(getCurrentType(), transporterKey);

      setConfigurations(
        dto.map((c) => ({
          id: c.id,
          name: c.name,
          version: c.version,
          frozen: c.frozen,
          author: c.author,
          description: c.description,
          creationDatetime: c.creationDatetime,
          standalone: c.standalone,
          testVersion: c.testVersion,
        }))
      );
    }

    loadConfigurations();
  }

  const getDefaultTab = () => {
    if (hasRights("CONF_TECH_READ")) {
      return "techniques";
    }
    if (hasRights("CONF_TRANSV_READ")) {
      return "transverses";
    }
    return "transporters";
  };

  useEffect(() => {
    const { type } = match.params;
    // Redirect for default
    if (!type) {
      history.push(`/configurations/${getDefaultTab()}`);
      return;
    }

    triggerConfigurationsLoad();
  }, [match.params.type, match.params.transporter, match.params, history]);

  const columns: ExpandableColumn<Configuration>[] = [
    {
      title: "Nom",
      className: classes.nameColumn,
      filterKey: "name",
    },
    {
      title: isTechniques ? "Mode standalone" : "",
      className: classes.smallColumn,
    },
    { title: "Version de test", className: classes.smallColumn },
    { title: "Format", className: classes.smallColumn },
    { title: "Dernière modification", className: classes.smallColumn },
    { title: "", className: classes.columnActions },
  ];

  function onChangeTab(index: number) {
    history.push(`/configurations/${tabs[index].key}`);
  }

  function onChangeTransporterTab(index: number) {
    history.push(`/configurations/transporters/${transporters[index].key}`);
  }

  function renderExpandableRow(
    configuration: Configuration,
    row: TreeNode<ConfigurationValue>,
    index: number,
    open: boolean
  ) {
    return (
      <>
        <ExpandableIcon open={open} />
        {row.label}
      </>
    );
  }

  async function deleteValueHandler(
    configuration: Configuration,
    configurationValue: ConfigurationValue
  ) {
    if (!configurations) return;

    try {
      await deleteConfigurationValue(
        getCurrentType(),
        getCurrentTransporter(),
        configuration.id,
        configurationValue.id
      );
      enqueueSnackbar("Valeur supprimée avec succès", { variant: "success" });
    } catch {
      // console.log(e);
      enqueueSnackbar(
        "Une erreur est survenue lors de la suppression de la valeur",
        { variant: "error" }
      );
      return;
    }

    setConfigurations(
      configurations.map((c) => {
        if (c.id === configuration.id && c.rows) {
          // Pour la configuration qui est éditée
          // Il faut trouver la valeur supprimée
          // Pour ca, il faut regarder dans la liste des valeurs
          // Sauf qu'une "valeur" ne peut être qu'une simple ligne qui contient les vrais valeurs
          return {
            ...c,
            rows: c.rows
              .map((v) => {
                if (v.rowType === "expandable") {
                  return {
                    ...v,
                    values: v.values.filter(
                      (va) => va.id !== configurationValue.id
                    ),
                  };
                }

                return v;
              })
              .filter(
                (v) =>
                  v.rowType === "expandable" || v.id !== configurationValue.id
              ),
          };
        }

        return c;
      })
    );
  }

  function useValue(
    configuration: Configuration,
    configurationValue: ConfigurationValue,
    _rowIndex: number
  ) {
    const classes = useStyles();

    const buttonActions = [];

    if (hasWriteRights) {
      buttonActions.push({
        label: "Modifier",
        props: {
          disabled: configuration.frozen,
        },
        handler: () => {
          setEditingValue({
            conf: configuration.id,
            value: configurationValue.id,
          });
        },
      });
    }

    if (hasDownloadRights) {
      buttonActions.push({
        label: "Télécharger",
        handler: () =>
          downloadConfigurationFile(
            getCurrentType(),
            getCurrentTransporter(),
            configuration.id,
            configurationValue.id,
            configurationValue.type,
            configurationValue.name +
              (configurationValue.type === "FILE" ? "" : ".json")
          ),
      });
    }

    if (hasWriteRights) {
      buttonActions.push({
        label: "Supprimer",
        props: {
          disabled: configuration.frozen,
        },
        handler: () =>
          confirmDialogDispatch({
            type: "open",
            title: "Confirmation de suppression",
            msg: `Êtes-vous sûr de vouloir supprimer la valeur "${configurationValue.name}" ?`,
            submit: () => deleteValueHandler(configuration, configurationValue),
          }),
      });
    }

    return (
      <>
        <div className={classes.nameColumn}>{configurationValue.name}</div>
        <div className={classes.smallColumn}></div>
        <div className={classes.smallColumn}></div>
        <div className={classes.smallColumn}>
          {configurationValue.type === "FILE" ? "Fichier" : "Editeur"}
        </div>
        <div className={classes.smallColumn}>
          <DateFormatter datetime={configurationValue.lastUpdateDatetime} />
        </div>
        {buttonActions.length > 0 && (
          <div className={classes.columnActions}>
            <OptionsButton buttonActions={buttonActions} />
          </div>
        )}
      </>
    );
  }

  async function fetchValues(row: Configuration) {
    if (!configurations) return;

    const config = await fetchConfiguration(
      getCurrentType(),
      getCurrentTransporter(),
      row.id
    );
    const rows = await fetchConfigurationValuesAndBuildTree(
      getCurrentType(),
      getCurrentTransporter(),
      row
    );

    setConfigurations(
      configurations.map((c) => {
        if (c.id === row.id) {
          return { ...c, rows: rows, standalone: config.standalone };
        }

        return c;
      })
    );
  }

  function renderContent(row: Configuration, index: number) {
    return (
      <PanelContent
        root={row}
        index={index}
        onLoadPanel={fetchValues}
        expandableRowRenderer={renderExpandableRow}
        valueRowRenderer={useValue}
      />
    );
  }

  async function deleteConfigurationHandler(configuration: Configuration) {
    if (!configurations) return;

    try {
      await deleteConfiguration(
        getCurrentType(),
        getCurrentTransporter(),
        configuration.id
      );
      enqueueSnackbar("Configuration supprimée avec succès", {
        variant: "success",
      });
    } catch {
      // console.log(e);
      enqueueSnackbar(
        "Une erreur est survenue lors de la suppression de la configuration",
        { variant: "error" }
      );
      return;
    }

    setConfigurations(configurations.filter((c) => c.id !== configuration.id));
  }

  async function freezeConfigurationHandler(configuration: Configuration) {
    if (!configurations) return;

    try {
      await updateConfiguration(
        getCurrentType(),
        getCurrentTransporter(),
        configuration.id,
        {
          name: configuration.name,
          version: configuration.version,
          description: configuration.description,
          testVersion: configuration.testVersion,
          frozen: true,
        }
      );
      enqueueSnackbar("Configuration publiée avec succès", {
        variant: "success",
      });
    } catch (error_) {
      let message =
        "Une erreur est survenue lors de la publication de la configuration";
      const error = error_ as AxiosError<RequestError>;
      if (
        error.response &&
        error.response.data &&
        error.response.data.reason === "Configuration frozen"
      ) {
        message = error.response.data.message || "";
      }

      enqueueSnackbar(message, { variant: "error" });
      return;
    }

    setConfigurations(
      configurations.map((c) => {
        if (c.id === configuration.id) {
          c.frozen = true;
        }
        return c;
      })
    );
  }

  function useConfigurationTitle(
    configuration: Configuration,
    _rowIndex: number
  ) {
    const classes = useStyles();

    const buttonActions = [];

    if (hasWriteRights) {
      buttonActions.push(
        {
          label: "Modifier",
          props: {
            disabled: configuration.frozen,
          },
          handler: () => {
            setEditingConfigurationId(configuration.id);
          },
        },
        {
          label: "Ajouter une valeur",
          props: {
            disabled: configuration.frozen,
          },
          handler: () => {
            setAddValue(true);
            setEditingValue({ conf: configuration.id, value: undefined });
          },
        },
        {
          label: "Publier",
          props: {
            disabled: configuration.frozen,
          },
          handler: () =>
            confirmDialogDispatch({
              type: "open",
              title: "Publier la configuration",
              msg: () => (
                <DialogContentText>
                  Attention, cette action est irréversible et ne permettra plus
                  la modification de cette version de configuration.
                  <br />
                  Êtes-vous sûr de vouloir publier la version{" "}
                  {configuration.version} ?
                </DialogContentText>
              ),
              submit: () => freezeConfigurationHandler(configuration),
            }),
        },
        {
          label: "Consulter",
          handler: () =>
            confirmDialogDispatch({
              type: "open",
              title: `${configuration.name} - v${configuration.version}`,
              msg: () => (
                <ConsultConfigurationDialogContent
                  author={configuration.author}
                  creationDatetime={configuration.creationDatetime}
                  standalone={configuration.standalone}
                  testVersion={configuration.testVersion}
                  description={configuration.description}
                />
              ),
              closeLabel: "Fermer",
              submit: undefined,
            }),
        }
      );
    }

    if (hasDownloadRights) {
      buttonActions.push({
        label: "Télécharger",
        handler: () =>
          downloadConfiguration(
            getCurrentType(),
            getCurrentTransporter(),
            configuration.name,
            configuration.version,
            configuration.id
          ),
      });
    }

    if (hasWriteRights) {
      buttonActions.push({
        label: "Supprimer",
        props: {
          disabled: configuration.frozen,
        },
        handler: () =>
          confirmDialogDispatch({
            type: "open",
            title: "Supression de la configuration",
            msg: `Êtes-vous sûr de vouloir supprimer la configuration "${configuration.name}" v${configuration.version} ?`,
            submit: () => deleteConfigurationHandler(configuration),
          }),
      });
    }

    return (
      <>
        <div className={classes.nameColumn}>
          {configuration.name} - v{configuration.version}
        </div>
        <div className={classes.smallColumn}>
          {configuration.standalone != undefined &&
            (configuration.standalone ? "OUI" : "NON")}
        </div>

        <div className={classes.smallColumn}>
          {configuration.testVersion ? "OUI" : "NON"}
        </div>
        <div className={classes.smallColumn}></div>
        <div className={classes.smallColumn}></div>
        {buttonActions.length > 0 && (
          <div className={classes.columnActions}>
            <OptionsButton
              buttonActions={buttonActions}
              buttonClassName={classes.buttonAction}
            />
          </div>
        )}
      </>
    );
  }

  function renderRow(configuration: Configuration, index: number) {
    return (
      <TopLevelPanel
        index={index}
        key={configuration.id}
        root={configuration}
        titleRenderer={useConfigurationTitle}
        contentRenderer={renderContent}
      />
    );
  }

  const indexTab = transporters.findIndex(
    (t) => t.key === match.params.transporter
  );

  // Si l'utilisateur n'a accès à aucun transporteur et aucune tab autre que transporteur
  const hasConfigurationAllowed = useMemo(() => {
    if (!transportersLoading) {
      return !(tabs[0].key === "transporters" && transporters.length === 0);
    }
    return true;
  }, [tabs, transporters, transportersLoading]);

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <h1 className={classes.titleLayout}>Configurations</h1>
        {hasWriteRights && (
          <div className={classes.actions}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setAddConfiguration(true)}
              size="small"
            >
              Créer une configuration
            </Button>
          </div>
        )}{" "}
      </div>
      {!hasConfigurationAllowed && (
        <div>Vous n&apos;avez pas accès aux configurations.</div>
      )}
      {tabs.length !== 1 && hasConfigurationAllowed && (
        <Tabs
          tabs={tabs}
          indexTab={tabs.indexOf(selectedTab)}
          onChangeIndexTab={onChangeTab}
          hasSecondaryTab={["transporters"]}
          additionalClassName={isTransporter ? undefined : classes.tabs}
        />
      )}
      {isTransporter && hasConfigurationAllowed && (
        <Tabs
          tabs={transporters}
          indexTab={indexTab === -1 ? 0 : indexTab}
          onChangeIndexTab={onChangeTransporterTab}
          secondary={tabs.length !== 1}
          additionalClassName={classes.tabs}
        />
      )}

      {!configurations && hasConfigurationAllowed && (
        <div className={classes.loaderWrapperLayout}>
          <CircularProgress />
        </div>
      )}
      {configurations && hasConfigurationAllowed && (
        <>
          <ExpandableTable
            roots={configurations}
            rowRenderer={renderRow}
            columns={columns}
            bottomHeightMargin={70}
            filterFunction={filterConfiguration}
          />
        </>
      )}
      {hasWriteRights && hasConfigurationAllowed && (
        <>
          <EditConfigurationDialog
            configurationId={editingConfigurationId}
            onClose={() => setEditingConfigurationId(undefined)}
            configurations={configurations}
            updateConfigurations={setConfigurations}
            configurationType={getCurrentType()}
            transporter={getCurrentTransporter()}
          />
          <EditConfigurationValueDialog
            configurationId={editingValue.conf}
            configurationValueId={editingValue.value}
            onClose={() => {
              setEditingValue({ conf: undefined, value: undefined });
              setAddValue(false);
            }}
            reloadConfiguration={fetchValues}
            addMode={addValue}
            isTransporter={isTransporter}
            configurationType={getCurrentType()}
            transporter={getCurrentTransporter()}
          />
          <CreateConfigurationDialog
            onClose={() => setAddConfiguration(false)}
            updateConfigurations={setConfigurations}
            open={addConfiguration}
            configurations={configurations}
            configurationType={getCurrentType()}
            transporter={getCurrentTransporter()}
          />
        </>
      )}
    </div>
  );
}

export default withRouter(Configurations);

import axios from "axios";
import {
  Station,
  StationEquippedOnly,
  StationUpdate,
  StationMapStatus,
  StationBoardingStatus,
} from "../interfaces/referential";
import FileDownload from "js-file-download";

export async function fetchStationsMapStatus(): Promise<StationMapStatus[]> {
  const response = await axios.get("/stations/map");
  return response.data;
}

export async function fetchStationsBoardingStatus(): Promise<
  StationBoardingStatus[]
> {
  const response = await axios.get("/stations/boarding");
  return response.data;
}

export async function fetchStations(
  equippedStationsFilter?: boolean
): Promise<Station[]> {
  const response = await axios.get("/referential/stations", {
    params: {
      equippedOnly: equippedStationsFilter,
    },
  });
  return response.data;
}

export async function fetchEquippedStations() {
  return fetchStations(true);
}

export async function importStations(file: File) {
  const formData = new FormData();
  formData.append("file", file);
  const response = await axios.post("/referential/stations/_import", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
  return response.data;
}

export function exportStations() {
  axios
    .get("/referential/stations/_export", { responseType: "blob" })
    .then((response) => {
      FileDownload(response.data, "stations.xlsx");
    });
}

export async function updateStationEquipped(station: Station) {
  const stationEquippedOnly: StationEquippedOnly = {
    patchEquipped: true,
    equipped: station.equipped,
  };
  const response = await axios.patch(
    "/referential/stations/" + station.id,
    stationEquippedOnly
  );
  return response.data;
}

export async function updateStation(station: Station) {
  const stationUpdateUrlOnly: StationUpdate = {
    patchUpdate: true,
    updateUrl: station.updateUrl,
    boardingModesTransporters: station.boardingModesTransporters,
  };
  const response = await axios.patch(
    "/referential/stations/" + station.id,
    stationUpdateUrlOnly
  );
  return response.data;
}

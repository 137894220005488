import React from "react";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface SortProperties {
  onSortChange: (sortDirection: SortDirectionType) => void;
  direction: SortDirectionType;
  priority: number;
}

const useStyles = makeStyles(() => ({
  icon: {
    opacity: 1,
    display: "inherit",
    cursor: "pointer",
  },
}));

export type SortDirectionType = boolean | "asc" | "desc";

export default function Sort({
  onSortChange,
  direction,
  priority,
}: SortProperties) {
  const classes = useStyles();

  function handleSortChange() {
    switch (direction) {
      case false: {
        onSortChange("desc");
        break;
      }
      case "desc": {
        onSortChange("asc");
        break;
      }
      case "asc": {
        onSortChange(false);
        break;
      }
    }
  }

  return (
    <div onClick={handleSortChange} className={classes.icon}>
      {direction === false && <SwapVertIcon />}
      {direction === "desc" && <ArrowDownwardIcon />}
      {direction === "asc" && <ArrowUpwardIcon />}
      {priority > 0 ? priority : ""}
    </div>
  );
}

export type FieldValidator<S, E> = {
  [K in Extract<keyof E, keyof S>]: {
    error: string;
    validator: (value: S[K], data?: S) => boolean;
  }[];
};

export const checkFields = <T, U>(
  errorFields: FieldValidator<T, U>,
  errors: U,
  data: T
): U => {
  let newErrors = { ...errors };
  for (const dataKey of Object.keys(errorFields) as (keyof FieldValidator<
    T,
    U
  >)[]) {
    for (const validator of errorFields[dataKey]) {
      if (validator.validator(data[dataKey], data)) {
        newErrors = { ...newErrors, [dataKey]: validator.error };
        break;
      }
    }
  }

  return newErrors;
};

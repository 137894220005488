import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
  Button,
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core";
import Loader from "components/loader";
import ItemElementsList from "components/list/item-element-list";
import { default as clsx } from "clsx";
import { getGateVersions } from "services/api/gates";
import useRights from "services/ducks/rights";
import { AxiosError } from "axios";
import { RequestError } from "services/interfaces/common";

const mappingSoftware: { [key: string]: string } = {
  gate: "Version porte",
  "gate-daemon": "Script déploiement",
  rfidvalidator: "Version billettique",
  "gate-config-technical": "Configuration Technique",
  "gate-config-business": "Configuration Transverse",
};

const mappingPlugins: {
  [key: string]: { title: string; transporters: string[] };
} = {
  "plugin-ticketprocessor-ter": {
    title: "Traitement titres TER",
    transporters: ["TER"],
  },
  "plugin-ticketprocessor-trenitalia": {
    title: "Traitement titres TrenItalia",
    transporters: ["TRENITALIA"],
  },
  "plugin-ticketprocessor-trenitalia-mission": {
    title: "Traitement titres TrenItalia Mission",
    transporters: ["TRENITALIA"],
  },
  "plugin-ticketprocessor-resarail": {
    title: "Traitement titres Voyages",
    transporters: ["VOYAGES"],
  },
  "plugin-ticketprocessor-europe": {
    title: "Traitement titres Europe",
    transporters: ["EUROPE"],
  },
  "plugin-ticketprocessor-ouigo": {
    title: "Traitement titres Ouigo",
    transporters: ["OUIGO"],
  },
  "plugin-ticketprocessor-jc": {
    title: "Traitement titres Junior & Compagnie",
    transporters: ["JC"],
  },
  "plugin-ticketprocessor-eurail-interrail": {
    title: "Traitement titres Eurail",
    transporters: ["EURAIL"],
  },
  "plugin-ticketprocessor-tnu": {
    title: "Traitement titres TNU",
    transporters: [],
  },
  "plugin-ticketprocessor-ryo": {
    title: "Traitement titres TER ARO",
    transporters: ["TER"],
  },
  "plugin-decoder-ter": {
    title: "Décodeur TER",
    transporters: ["TER"],
  },
  "plugin-decoder-trenitalia": {
    title: "Décodeur TrenItalia",
    transporters: ["TRENITALIA"],
  },
  "plugin-decoder-resarail": {
    title: "Décodeur Voyages",
    transporters: ["VOYAGES"],
  },
  "plugin-decoder-europe": {
    title: "Décodeur Europe",
    transporters: ["EUROPE"],
  },
  "plugin-decoder-ouigo": {
    title: "Décodeur Ouigo",
    transporters: ["OUIGO"],
  },
  "plugin-decoder-jc": {
    title: "Décodeur Junior & Compagnie",
    transporters: ["JC"],
  },
  "plugin-decoder-eurail-interrail": {
    title: "Décodeur Eurail",
    transporters: ["EURAIL"],
  },
  "plugin-decoder-cards": {
    title: "Décodeur Cartes & Abonnements",
    transporters: ["VOYAGES"],
  },
  "plugin-decoder-ryo": {
    title: "Décodeur RYO",
    transporters: ["TER"],
  },
  "gate-config-transporter-eurail": {
    title: "Configuration Transporteur Eurail",
    transporters: ["EURAIL"],
  },
  "gate-config-transporter-europe": {
    title: "Configuration Transporteur Europe",
    transporters: ["EUROPE"],
  },
  "gate-config-transporter-jc": {
    title: "Configuration Transporteur Junior & Compagnie",
    transporters: ["JC"],
  },
  "gate-config-transporter-voyages": {
    title: "Configuration Transporteur Voyages",
    transporters: ["VOYAGES"],
  },
  "gate-config-transporter-ouigo": {
    title: "Configuration Transporteur Ouigo",
    transporters: ["OUIGO"],
  },
  "gate-config-transporter-ter": {
    title: "Configuration Transporteur TER",
    transporters: ["TER"],
  },
  "gate-config-transporter-trenitalia": {
    title: "Configuration Transporteur TrenItalia",
    transporters: ["TRENITALIA"],
  },
  "gate-config-transporter-tnu": {
    title: "Configuration Transporteur TNU",
    transporters: [],
  },
};

interface GateVersionsDialogProperties {
  open: boolean;
  gateId: string;
  gateName: string;
  handleClose: () => void;
}

interface Version {
  current: boolean;
  date: string;
  lock: number;
  version: string;
}

interface GateVersion {
  name: string;
  versions: Version[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    horizontalBox: {
      "&>*": {
        flex: 1,
      },
    },
    smallBoxMargin: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    smallMarginBottom: {
      marginBottom: theme.spacing(2),
    },
  })
);

function GateVersionsDialog({
  open,
  gateId,
  handleClose,
}: GateVersionsDialogProperties) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fetchError, setFetchError] = useState<string>("");
  const [gateVersions, setGateVersion] = useState<GateVersion[]>();
  const classes = useStyles();
  const { hasTransporter, hasActivity } = useRights();

  const canDisplayVersion = (version: GateVersion) => {
    if (mappingPlugins[version.name] && hasActivity("TRANSPORTER")) {
      const tIndex = mappingPlugins[version.name].transporters.findIndex((t) =>
        hasTransporter(t)
      );
      if (tIndex === -1) {
        return false;
      }
    }
    return true;
  };

  const versionTitle = (version: GateVersion) => {
    if (mappingPlugins[version.name]) {
      return mappingPlugins[version.name].title;
    }
    return version.name;
  };

  useEffect(() => {
    if (open) {
      const fetchVersions = async () => {
        setFetchError("");
        setIsLoading(true);
        try {
          const fetchedVersions: GateVersion[] = await getGateVersions(gateId);
          setGateVersion(
            fetchedVersions.filter((version) => version.name !== "plugins-pack")
          );
          setIsLoading(false);
        } catch (error_: unknown) {
          const error = error_ as AxiosError<RequestError>;
          setIsLoading(false);
          setGateVersion(undefined);
          if (error.response?.data.message?.includes("I/O error")) {
            setFetchError("La porte n'a pas pu être contactée");
          } else {
            setFetchError("Erreur lors de la récupération des versions");
          }
        }
      };
      fetchVersions();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      aria-labelledby="form-create-dialog-title"
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      fullWidth={true}
      maxWidth={fetchError === "" ? "lg" : "sm"}
    >
      <DialogTitle id="form-create-dialog-title">
        Versions embarquées sur la porte
      </DialogTitle>
      <DialogContent>
        {(isLoading || (!gateVersions && fetchError === "")) && (
          <div style={{ textAlign: "center" }}>
            <Loader width={100} height={100} />
          </div>
        )}
        {!isLoading && fetchError !== "" && (
          <Box display="flex" justifyContent="center">
            <span style={{ fontSize: "24px", padding: "24px" }}>
              {fetchError}
            </span>
          </Box>
        )}
        {!isLoading && gateVersions && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-end"
            flexWrap="wrap"
            className={clsx(classes.horizontalBox, classes.smallMarginBottom)}
          >
            <Box
              display="flex"
              flexDirection="column"
              className={classes.smallBoxMargin}
            >
              <ItemElementsList
                label="Général"
                additionalClassName={classes.smallBoxMargin}
                rows={gateVersions
                  .filter(
                    (version) =>
                      !version.name.startsWith("plugin-decoder-") &&
                      !version.name.startsWith("plugin-ticketprocessor-") &&
                      !version.name.startsWith("gate-config-transporter-")
                  )
                  .map((version, index) => (
                    <Box
                      key={index}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <span>
                        {mappingSoftware[version.name] || version.name}
                      </span>
                      <span>
                        {
                          (
                            version.versions.find(
                              (version) => version.current === true
                            ) || { version: "-" }
                          ).version
                        }
                      </span>
                    </Box>
                  ))}
              />
              <ItemElementsList
                label="Décodeurs"
                additionalClassName={classes.smallBoxMargin}
                rows={gateVersions
                  .filter((version) =>
                    version.name.startsWith("plugin-decoder-")
                  )
                  .filter((version) => canDisplayVersion(version))
                  .map((version, index) => (
                    <Box
                      key={index}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <span>{versionTitle(version)}</span>
                      <span>
                        {
                          (
                            version.versions.find(
                              (version) => version.current === true
                            ) || { version: "-" }
                          ).version
                        }
                      </span>
                    </Box>
                  ))}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              className={classes.smallBoxMargin}
            >
              <ItemElementsList
                label="Traitements titres"
                additionalClassName={classes.smallBoxMargin}
                rows={gateVersions
                  .filter((version) =>
                    version.name.startsWith("plugin-ticketprocessor-")
                  )
                  .filter((version) => canDisplayVersion(version))
                  .map((version, index) => (
                    <Box
                      key={index}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <span>{versionTitle(version)}</span>
                      <span>
                        {
                          (
                            version.versions.find(
                              (version) => version.current === true
                            ) || { version: "-" }
                          ).version
                        }
                      </span>
                    </Box>
                  ))}
              />
              <ItemElementsList
                label="Configurations transporteurs"
                additionalClassName={classes.smallBoxMargin}
                rows={gateVersions
                  .filter((version) =>
                    version.name.startsWith("gate-config-transporter-")
                  )
                  .filter((version) => canDisplayVersion(version))
                  .map((version, index) => (
                    <Box
                      key={index}
                      display="flex"
                      justifyContent="space-between"
                    >
                      <span>{versionTitle(version)}</span>
                      <span>
                        {
                          (
                            version.versions.find(
                              (version) => version.current === true
                            ) || { version: "-" }
                          ).version
                        }
                      </span>
                    </Box>
                  ))}
              />
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => handleClose()}>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default GateVersionsDialog;

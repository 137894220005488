import React, { useCallback, useEffect, useState } from "react";
import {
  Dock,
  Gate,
  Lane,
  Station,
  StationUpdate,
  Track,
  Zone,
} from "../../../services/interfaces/referential";
import Tabs from "../../../components/tabs-component";
import {
  historyPush,
  retrieveIndexTab,
  tabsConfigs,
  tabsRoutes,
} from "./utils/tabs-utils";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import GateTab from "./gate-tab";
import LaneTab from "./lane-tab";
import { fetchGates } from "../../../services/api/gates";
import { fetchDocks } from "../../../services/api/docks";
import { fetchLanes } from "../../../services/api/lanes";
import useCrud from "../../../commons/use-crud";
import { fetchTracks } from "../../../services/api/tracks";
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import TrackTab from "./track-tab";
import ZoneTab from "./zone-tab";
import { fetchZones } from "../../../services/api/zones";
import DockTab from "./dock-tab";
import FormSelect from "../../../components/form/select";
import FormControl from "@material-ui/core/FormControl";
import { Theme } from "@material-ui/core";
import StationInfoDialog from "./dialog/station-info-dialog";
import StationFormDialog from "./dialog/station-form-dialog";
import {
  fetchEquippedStations,
  updateStation,
} from "../../../services/api/stations";
import { useSnackbar } from "notistack";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { SNCF_COMPL_GREEN_BLUE, SNCF_LIGHT_PURPLE } from "../../../theme";
import useRights from "services/ducks/rights";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    titleLayout: {
      textAlign: "left",
      marginTop: "0",
    },
    select: {
      flexDirection: "row",
      alignItems: "flex-end",
      "& .MuiInput-root": {
        minWidth: 200,
        marginRight: theme.spacing(2),
      },
      "& > span": {
        marginBottom: theme.spacing(0.5),
      },
    },
    infoButton: {
      width: "32px",
      height: "32px",
      marginBottom: theme.spacing(0.5),
      backgroundColor: SNCF_COMPL_GREEN_BLUE,
      borderColor: SNCF_COMPL_GREEN_BLUE,
      color: "white",
      "&:hover": {
        backgroundColor: theme.palette.secondary.light,
        borderColor: SNCF_LIGHT_PURPLE,
      },
    },
    tabs: {
      margin: "20px 0",
    },
  })
);

export interface StationInfoState {
  open: boolean;
  data?: Station;
  edit?: () => void;
}

export interface StationFormState {
  open: boolean;
  action?: "edit";
  data?: StationUpdate;
  submit?: (patch: StationUpdate) => Promise<void> | void;
}

interface RouteProperties {
  stationId?: string;
}

function StationReferential({
  history,
  match,
}: RouteComponentProps<RouteProperties>) {
  const classes = useStyles();

  const stationsState = useCrud<Station>("Station");
  const stations = stationsState.datas;
  const gatesState = useCrud<Gate>("Porte");
  const lanesState = useCrud<Lane>("Ligne");
  const tracksState = useCrud<Track>("Voie");
  const docksState = useCrud<Dock>("Zone étanche");
  const zonesState = useCrud<Zone>("Zone");
  const [stationSelected, setStationSelected] = useState<Station>();
  const [dataInFetching, setDataInFetching] = useState<boolean>(false);
  const [stationFormDialog, setStationFormDialog] = useState<StationFormState>({
    open: false,
  });
  const [stationInfoDialog, setStationInfoDialog] = useState<StationInfoState>({
    open: false,
  });
  const { hasRights } = useRights();
  const { enqueueSnackbar } = useSnackbar();

  // ----------- events

  function init() {
    if (!match.params.stationId) {
      setStationSelected(undefined);
    } else if (stations && stations.length > 0) {
      const station = stations.find((s) => s.id === match.params.stationId);
      if (station) {
        setStationSelected({ ...station });
      }
    }
  }

  // quand on arrive sur la page
  useEffect(() => {
    (async () => {
      stationsState.set(await fetchEquippedStations());
    })();
  }, []);

  // lorsque les stations sont chargées ou quand on change d'url
  useEffect(init, [history.location.pathname, stations]);

  // quand on change de stationId
  useEffect(() => {
    if (match.params.stationId) {
      setDataInFetching(true);
      Promise.all([
        fetchGates(match.params.stationId),
        fetchLanes(match.params.stationId),
        fetchTracks(match.params.stationId),
        fetchDocks(match.params.stationId),
        fetchZones(match.params.stationId),
      ]).then((values) => {
        gatesState.set(values[0]);
        lanesState.set(values[1]);
        tracksState.set(values[2]);
        docksState.set(values[3]);
        zonesState.set(values[4]);
        setDataInFetching(false);
      });
    }
  }, [match.params.stationId]);

  // ----------- routes

  function changeTab(index: number) {
    if (match.params.stationId) {
      historyPush(history, index, match.params.stationId);
    }
  }

  const indexTab = useCallback(() => {
    return match.params.stationId
      ? retrieveIndexTab(match.params.stationId, history.location.pathname)
      : 0;
  }, [history.location.pathname, match.params.stationId]);

  // ----------- renders

  function displayFormDialog() {
    setStationFormDialog({
      open: true,
      action: "edit",
      data: stationSelected ? { ...stationSelected } : undefined,
      submit: async (patch: StationUpdate) => {
        if (!stationSelected) return;
        stationsState.update(
          await updateStation({ ...stationSelected, ...patch })
        );
        enqueueSnackbar(`Informations de la gare modifiées avec succès`, {
          variant: "success",
        });
      },
    });
  }

  return (
    <div className={classes.root}>
      <StationFormDialog
        form={stationFormDialog}
        setForm={setStationFormDialog}
      />
      <StationInfoDialog
        dialog={stationInfoDialog}
        setDialog={setStationInfoDialog}
      />
      <h1 className={classes.titleLayout}>Référentiel de Gare</h1>
      <FormControl variant="standard" margin="dense" className={classes.select}>
        <FormSelect
          handleOnChange={(id) => {
            const station = stations.find((s) => s.id === id);
            if (station) {
              historyPush(history, 0, station.id);
            }
          }}
          availableOptions={stations}
          value={match.params.stationId || ""}
          label="Gare"
          searchable
        />
        {hasRights("REF_GARE_INFO") && match.params.stationId && (
          <Tooltip title="Informations" aria-label="Informations">
            <IconButton
              size="small"
              color="secondary"
              className={classes.infoButton}
              aria-label="Informations"
              onClick={() =>
                setStationInfoDialog({
                  open: true,
                  data: stationSelected ? { ...stationSelected } : undefined,
                  edit: displayFormDialog,
                })
              }
            >
              i
            </IconButton>
          </Tooltip>
        )}
      </FormControl>
      {match.params.stationId && (
        <>
          <Tabs
            tabs={tabsConfigs}
            indexTab={indexTab()}
            onChangeIndexTab={changeTab}
            additionalClassName={classes.tabs}
          />
          <Switch>
            <Route
              path={tabsRoutes.gates(":stationId")}
              exact={true}
              render={() => (
                <GateTab
                  stationId={match.params.stationId || ""}
                  lanesState={lanesState}
                  gatesState={gatesState}
                  docksState={docksState}
                  dataInFetching={dataInFetching}
                />
              )}
            />
            <Route
              path={tabsRoutes.lanes(":stationId")}
              exact={true}
              render={() => (
                <LaneTab
                  stationId={match.params.stationId || ""}
                  tracksState={tracksState}
                  lanesState={lanesState}
                  gatesState={gatesState}
                  docksState={docksState}
                  zonesState={zonesState}
                  dataInFetching={dataInFetching}
                />
              )}
            />
            <Route
              path={tabsRoutes.tracks(":stationId")}
              exact={true}
              render={() => (
                <TrackTab
                  stationId={match.params.stationId || ""}
                  tracksState={tracksState}
                  lanesState={lanesState}
                  docksState={docksState}
                  dataInFetching={dataInFetching}
                />
              )}
            />
            <Route
              path={tabsRoutes.docks(":stationId")}
              exact={true}
              render={() => (
                <DockTab
                  stationId={match.params.stationId || ""}
                  lanesState={lanesState}
                  tracksState={tracksState}
                  docksState={docksState}
                  dataInFetching={dataInFetching}
                />
              )}
            />
            <Route
              path={tabsRoutes.zones(":stationId")}
              exact={true}
              render={() => (
                <ZoneTab
                  stationId={match.params.stationId || ""}
                  lanesState={lanesState}
                  zonesState={zonesState}
                  dataInFetching={dataInFetching}
                />
              )}
            />
          </Switch>
        </>
      )}
    </div>
  );
}

export default withRouter(StationReferential);

export const confirmDialogInitialValues: ConfirmDialogState = {
  open: false,
  title: "Fenêtre d'alerte",
  msg: "Êtes-vous sûr ?",
  closeLabel: "NON",
  submitLabel: "OUI",
  submit: undefined,
};

export type ConfirmDialogAction =
  | { type: "init" }
  | {
      type: "open";
      title?: string;
      closeLabel?: string;
      submitLabel?: string;
      msg?: string | (() => JSX.Element);
      submit?: () => void;
    }
  | { type: "close" };

export interface ConfirmDialogState {
  open: boolean;
  title: string;
  closeLabel?: string;
  submitLabel?: string;
  msg: string | (() => JSX.Element);
  submit?: () => void;
}

export const confirmDialogReducer = (
  state: ConfirmDialogState,
  action: ConfirmDialogAction
) => {
  switch (action.type) {
    case "close": {
      return { ...state, open: false };
    }
    case "open": {
      return {
        open: true,
        title: action.title || state.title,
        msg: action.msg || state.msg,
        closeLabel: action.closeLabel || confirmDialogInitialValues.closeLabel,
        submitLabel:
          action.submitLabel || confirmDialogInitialValues.submitLabel,
        submit: action.submit || confirmDialogInitialValues.submit,
      };
    }
    default: {
      return state;
    }
  }
};

import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { StationAddDialog } from "./station-add-dialog";
import { Station } from "services/interfaces/referential";

interface StationListAddButtonProperties {
  availableStations: Station[];
  onAdd: (station: Station) => void;
}

export function StationListAddButton({
  availableStations,
  onAdd,
}: StationListAddButtonProperties) {
  const [open, setOpen] = useState<boolean>(false);

  const handleAddClick = () => {
    setOpen(true);
  };

  const handleAddConfirm = (station: Station) => {
    onAdd(station);
    setOpen(false);
  };

  const handleAddCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleAddClick}
        color="secondary"
        variant="contained"
        style={{ marginLeft: 8 }}
      >
        <Add />
      </Button>
      <StationAddDialog
        open={open}
        onChoice={handleAddConfirm}
        onCancel={handleAddCancel}
        choices={availableStations}
      />
    </>
  );
}

import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
} from "@material-ui/core";
import FormSelect from "components/form/select";
import { Station } from "services/interfaces/referential";

interface StationAddDialogProperties {
  open: boolean;
  choices: Station[];
  onChoice: (station: Station) => void;
  onCancel: () => void;
}

export function StationAddDialog({
  open,
  choices,
  onChoice,
  onCancel,
}: StationAddDialogProperties) {
  const [choice, setChoice] = useState<Station>();

  const handleChange = (value: string) => {
    const chosen = choices.find((c) => c.id === value);
    if (chosen) {
      setChoice(chosen);
    }
  };

  const handleCancel = () => {
    setChoice(undefined);
    onCancel();
  };

  const handleConfirm = () => {
    if (choice) {
      onChoice(choice);
      setChoice(undefined);
    }
  };

  return (
    <Dialog open={open} maxWidth="md">
      <DialogTitle>Ajouter une gare</DialogTitle>
      <DialogContent>
        <FormControl variant="standard" margin="dense" fullWidth>
          <FormSelect
            handleOnChange={handleChange}
            availableOptions={choices}
            value={choice ? choice.id : ""}
            label="Gare"
            searchable
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleCancel}>
          Annuler
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={!choice}
          onClick={handleConfirm}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

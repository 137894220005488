import { RootRow, TreeNode, TreeNodeValue } from "./types";
import React, { useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useRowStyles } from "./styles";
import { default as clsx } from "clsx";

interface ValueRowProperties<RootType, ValueType> {
  root: RootType;
  rowIndex: number;
  value: ValueType;
  leveled: boolean;
  valueRowRenderer: (
    root: RootType,
    value: ValueType,
    index: number
  ) => JSX.Element;
  selectable?: boolean;
}

function ValueRow<
  RootType extends RootRow<ValueType>,
  ValueType extends TreeNodeValue
>({
  root,
  rowIndex,
  value,
  leveled,
  valueRowRenderer,
  selectable,
}: ValueRowProperties<RootType, ValueType>) {
  const rowClasses = useRowStyles();
  return (
    <div
      className={clsx(rowClasses.row, rowClasses.value, {
        [rowClasses.leveledRow]: leveled,
        [rowClasses.selectable]: selectable,
      })}
    >
      {valueRowRenderer(root, value, rowIndex)}
    </div>
  );
}

interface ExpandableRowProperties<RootType, ValueType, T> {
  root: RootType;
  rowIndex: number;
  row: T;
  expandableRowRenderer: (
    root: RootType,
    value: T,
    index: number,
    open: boolean
  ) => JSX.Element;
  valueRowRenderer: (
    root: RootType,
    value: ValueType,
    index: number
  ) => JSX.Element;
  selectable?: boolean;
}

function ExpandableRow<
  RootType extends RootRow<ValueType>,
  ValueType extends TreeNodeValue,
  T extends TreeNode<ValueType>
>({
  root,
  rowIndex,
  row,
  expandableRowRenderer,
  valueRowRenderer,
  selectable,
}: ExpandableRowProperties<RootType, ValueType, T>) {
  const [open, setOpen] = useState<boolean>(false);
  const rowClasses = useRowStyles();

  const expand = () => setOpen(!open);

  return (
    <div>
      <div
        className={clsx(rowClasses.row, rowClasses.expandable, {
          [rowClasses.selectable]: selectable,
        })}
        onClick={expand}
      >
        {expandableRowRenderer(root, row, rowIndex, open)}
      </div>
      {open && (
        <div>
          {row.values &&
            row.values.map((value, index) => (
              <ValueRow
                key={index}
                root={root}
                rowIndex={rowIndex}
                value={value}
                leveled={true}
                valueRowRenderer={valueRowRenderer}
                selectable={selectable}
              />
            ))}
        </div>
      )}
    </div>
  );
}

interface PanelContentProperties<RootType, ValueType> {
  root: RootType;
  onLoadPanel: (row: RootType) => Promise<void>;
  expandableRowRenderer: (
    root: RootType,
    value: TreeNode<ValueType>,
    index: number,
    open: boolean
  ) => JSX.Element;
  valueRowRenderer: (
    root: RootType,
    value: ValueType,
    index: number
  ) => JSX.Element;
  index: number;
  selectable?: boolean;
}

export function PanelContent<
  RootType extends RootRow<ValueType>,
  ValueType extends TreeNodeValue
>({
  root,
  onLoadPanel,
  expandableRowRenderer,
  valueRowRenderer,
  index,
  selectable,
}: PanelContentProperties<RootType, ValueType>) {
  useEffect(() => {
    if (!root.rows) {
      onLoadPanel(root);
    }
  }, [onLoadPanel, root]);

  if (!root.rows)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <CircularProgress />
      </div>
    );

  return (
    <div style={{ width: "100%" }}>
      {root.rows.map((v, valueIndex) => {
        if (v.rowType === "expandable") {
          return (
            <ExpandableRow
              rowIndex={index}
              key={valueIndex}
              row={v}
              root={root}
              expandableRowRenderer={expandableRowRenderer}
              valueRowRenderer={valueRowRenderer}
              selectable={selectable}
            />
          );
        }

        return (
          <ValueRow
            rowIndex={index}
            key={valueIndex}
            root={root}
            value={v}
            leveled={false}
            valueRowRenderer={valueRowRenderer}
            selectable={selectable}
          />
        );
      })}
    </div>
  );
}

import axios from "axios";
import { Dock } from "../interfaces/referential";

export async function fetchDocks(stationId: string): Promise<Dock[]> {
  const response = await axios.get(
    "/referential/stations/" + stationId + "/docks"
  );
  return response.data;
}

export async function addDock(dock: Dock) {
  const response = await axios.post("/referential/docks", {
    name: dock.name,
    description: dock.description,
    stationId: dock.stationId,
    trackIds: dock.trackIds,
    laneIds: dock.laneIds,
    trackLaneDTOList: dock.trackLaneDTOList,
    tnBlacklistEnabled: dock.tnBlacklistEnabled,
  });
  return response.data;
}

export async function updateDock(dock: Dock) {
  const response = await axios.put("referential/docks/" + dock.id, {
    id: dock.id,
    name: dock.name,
    description: dock.description,
    stationId: dock.stationId,
    trackIds: dock.trackIds,
    laneIds: dock.laneIds,
    trackLaneDTOList: dock.trackLaneDTOList,
    tnBlacklistEnabled: dock.tnBlacklistEnabled,
  });
  return response.data;
}

export async function deleteDocks(dockIds: Set<string>) {
  await axios.delete("/referential/docks/" + [...dockIds].join(","), {
    responseType: "text",
  });
}

export async function deleteDock(dockId: string) {
  await deleteDocks(new Set([dockId]));
}

import { EUserType, User } from "../services/api/users";

export const isEmpty = (value: string | string[] | null | undefined): boolean =>
  !value || value.length === 0;

export const max = (
  string_: string | null | undefined,
  value: number
): boolean => !!string_ && string_.length > value;

export const isEmptyAndTransporterActivity = (
  value: string[],
  user?: User
): boolean => {
  return (
    user !== undefined && value.length === 0 && user.activity === "TRANSPORTER"
  );
};

export const isEmptyOrSncfUser = (value: string, user?: User): boolean => {
  return (
    user !== undefined &&
    value.length === 0 &&
    user.type === EUserType.LOCAL &&
    user.id === undefined
  );
};

export const isEmptyOrNationalPerimeter = (
  value: string[],
  user?: User
): boolean => {
  return (
    user !== undefined && value.length === 0 && user.perimeter !== "NATIONAL"
  );
};

export const isInvalidSncfUsername = (value: string, user?: User): boolean => {
  return (
    user !== undefined &&
    user.type === EUserType.SNCF &&
    user.lastName === "" &&
    user.username !== ""
  );
};

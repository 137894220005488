import axios from "axios";
import FileDownload from "js-file-download";

export enum EUserType {
  LOCAL = "LOCAL",
  SNCF = "SNCF",
}

export interface User {
  type: EUserType;
  active: boolean;
  id?: string;
  username: string;
  lastName: string;
  firstName: string;
  password: string;
  activity: string;
  profile: string;
  perimeter: string;
  transporters: string[];
  perimeterElements: string[];
}

/**
 * Fetches user data / provides a mock
 */
export const fetchUser = async (id: string): Promise<User> => {
  const response = await axios.get(`/users/${id}`);
  return response.data;
};

/**
 * Fetches all users data / provides a mock
 */
export const fetchAllUsers = async (): Promise<User[]> => {
  const response = await axios.get("/users");
  return response.data;
};

/**
 * Fetches user data / provides a mock
 */
export const postUser = async (user: User): Promise<User> => {
  const response = await axios.post("/users", user);
  return response.data;
};

/**
 * Updates a user
 */
export const updateUser = async (user: User): Promise<User> => {
  const response = await axios.put(`/users/${user.id}`, user);
  return response.data;
};

/**
 * Deletes users
 */
export const deleteUsers = async (users: User[]): Promise<boolean> => {
  await axios.delete(`/users/${users.map((user) => user.id)}`);
  return true;
};

/**
 * Exports users
 */
export const exportUsers = async () => {
  const response = await axios.get("/users/_export", { responseType: "blob" });
  FileDownload(response.data, "users.xlsx");
};

/**
 * Import users
 */
export const importUsers = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  const response = await axios.post("/users/_import", formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
  return response.data;
};

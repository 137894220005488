import React, { useState } from "react";
import {
  FormHelperText,
  FormLabel,
  List,
  Theme,
  Box,
  Input,
  InputAdornment,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import FormCheckbox from "./checkbox";
import ClearIcon from "@material-ui/icons/Clear";

export interface CheckboxListOption {
  name: string;
  id: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    smallMarginBottom: {
      marginBottom: theme.spacing(1),
    },
    listLayout: {
      display: "flex",
      flexDirection: "column",
      overflowX: "hidden",
      backgroundColor: "#f5f5f5",
      borderRadius: "4px",
      padding: 0,
      "&>div:nth-child(even)": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
      "&>div:nth-child(even):hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
      },
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
        backgroundColor: "#F9F9F9",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        backgroundColor: "#B7B7B7",
      },
      "&::-webkit-scrollbar-track": {
        borderRadius: "10px",
        backgroundColor: "#F9F9F9",
      },
    },
  })
);

interface Properties {
  handleOnChange: (event: React.MouseEvent<HTMLDivElement>) => void;
  availableOptions: CheckboxListOption[];
  hide?: boolean;
  label: string;
  checkedElements: string[];
  disabled?: boolean;
  error?: string;
  height?: string;
  noFixedHeight?: boolean;
}

export const updateArray = (value: string, array: string[]): string[] => {
  const array_ = [...array];
  if (array_.includes(value)) {
    array_.splice(array_.indexOf(value), 1);
  } else {
    array_.push(value);
  }
  return array_;
};

/**
 * Checkbox list component for form
 */
function FormCheckboxList({
  handleOnChange,
  availableOptions,
  hide = false,
  label,
  checkedElements,
  disabled,
  error,
  height = "215px",
  noFixedHeight,
}: Properties) {
  const [search, setSearch] = useState<string>("");
  const classes = useStyles();

  if (hide || !availableOptions || Object.keys(availableOptions).length === 0) {
    return <></>;
  }

  const handleSearchChange = (
    event: React.FormEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    setSearch(event.currentTarget.value);
  };

  const handleClearSearch = () => {
    setSearch("");
  };

  const listElements = availableOptions
    .filter(
      (option) =>
        option.name && option.name.toLowerCase().includes(search.toLowerCase())
    )
    .map((option) => (
      <FormCheckbox
        handleOnChange={handleOnChange}
        value={option.id}
        key={option.id}
        label={option.name}
        checked={checkedElements.includes(option.id)}
        disabled={disabled}
      />
    ));

  return (
    <>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        display="flex"
        alignItems="center"
        className={classes.smallMarginBottom}
      >
        <FormLabel error={error !== undefined && error !== ""}>
          {label}
        </FormLabel>
        <Input
          type="text"
          value={search}
          onChange={handleSearchChange}
          placeholder="Recherche"
          style={{ width: "40%", marginTop: 0 }}
          endAdornment={
            search !== "" && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Clear search"
                  onClick={handleClearSearch}
                  size="small"
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )
          }
        />
      </Box>
      <List
        className={classes.listLayout}
        style={{
          height: noFixedHeight ? "auto" : height,
          maxHeight: noFixedHeight ? height : "none",
        }}
      >
        {listElements.length > 0 ? (
          listElements
        ) : (
          <Typography
            style={{ margin: "auto", alignSelf: "center" }}
            variant="body1"
          >
            AUCUN RESULTAT
          </Typography>
        )}
      </List>
      {error !== undefined && error !== "" && (
        <FormHelperText error={error !== undefined && error !== ""}>
          {error}
        </FormHelperText>
      )}
    </>
  );
}

export default FormCheckboxList;

import React from "react";
import {
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { Station } from "services/interfaces/referential";

const useStyles = makeStyles(() =>
  createStyles({
    stations: {
      maxHeight: 300,
      overflowY: "auto",
      paddingBottom: 0,
      marginBottom: "8px",
    },
    subheader: {
      backgroundColor: "white",
    },
    importInput: {
      display: "none",
    },
  })
);

interface StationListSelectProperties {
  selectedId?: string;
  stationsList: Station[];
  onSelect: (station: string) => void;
}

export function StationListSelect({
  selectedId,
  stationsList,
  onSelect,
}: StationListSelectProperties) {
  const classes = useStyles();

  return (
    <List
      className={classes.stations}
      subheader={
        <ListSubheader className={classes.subheader}>Gares</ListSubheader>
      }
      dense
    >
      {stationsList.map((k) => (
        <ListItem
          button
          key={k.id}
          selected={k.id === selectedId}
          onClick={() => onSelect(k.id)}
        >
          <ListItemText primary={k.name} />
        </ListItem>
      ))}
    </List>
  );
}

import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Loader from "../../components/loader";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Configuration } from "./index";
import { makeStyles } from "@material-ui/styles";
import FormCheckbox from "../../components/form/checkbox";
import FormSelect from "../../components/form/select";
import {
  ConfigurationCreationDTO,
  ConfigurationDTO,
  ConfigurationType,
  createConfiguration,
} from "../../services/api/configurations";
import { useSnackbar } from "notistack";
import { Theme } from "@material-ui/core";
import useRights from "services/ducks/rights";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.spacing(2.5),
  },
  field: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
  textField: {
    flex: 1,
  },
  buttonsGroupLayout: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(2),
  },
  descriptionLabel: {
    color: "grey",
    marginBottom: "5px",
  },
  multiLineTextField: {
    width: "100%",
    "& .MuiInputBase-root": { padding: "10px", borderRadius: "3px" },
  },
}));

const initialConfigurationCreation = (): ConfigurationCreationDTO => ({
  name: "",
  version: "",
  duplicated: false,
  testVersion: false,
  author: "",
  description: "",
  sourceConfigurationId: "",
});

interface CreateConfigurationDialogProperties {
  onClose: () => void;
  updateConfigurations: Dispatch<SetStateAction<Configuration[] | undefined>>;
  open: boolean;
  configurations: Configuration[] | undefined;
  configurationType: ConfigurationType;
  transporter: string | undefined;
}

const emptyHelperText = " ";

function CreateConfigurationDialog({
  open,
  onClose,
  updateConfigurations,
  configurations,
  configurationType,
  transporter,
}: CreateConfigurationDialogProperties) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [creation, setCreation] = useState<ConfigurationCreationDTO>(
    initialConfigurationCreation()
  );
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { getUserInfo } = useRights();
  const userInfo = getUserInfo();

  useEffect(() => setIsLoading(false), [open]);
  useEffect(
    () => setCreation(initialConfigurationCreation()),
    [configurationType, transporter]
  );

  const onDialogClose = (_event: unknown, reason: string) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      handleClose();
    }
  };

  const handleClose = () => {
    onClose();
    setCreation(initialConfigurationCreation());
    setIsLoading(false);
  };

  const handleEditNom = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCreation({ ...creation, name: event.target.value });
  };

  const handleEditVersion = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCreation({ ...creation, version: event.target.value });
  };

  const handleSave = async () => {
    setIsLoading(true);

    let c: ConfigurationDTO;
    creation.author = userInfo.name;
    try {
      c = await createConfiguration(configurationType, transporter, creation);
      enqueueSnackbar("Configuration créée avec succès", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar(
        "Une erreur est survenue lors du figeage de la configuration",
        { variant: "error" }
      );
      handleClose();
      return;
    }

    updateConfigurations((confs) => [
      {
        id: c.id,
        name: c.name,
        version: c.version,
        author: c.author,
        standalone: c.standalone,
        testVersion: c.testVersion,
        description: c.description,
        creationDatetime: c.creationDatetime,
        frozen: c.frozen,
      },
      ...(confs || []),
    ]);

    handleClose();
  };

  const isVersionUnavailable =
    configurations !== null &&
    configurations !== undefined &&
    creation.version !== null &&
    configurations.some(
      (configuration) => creation.version === configuration.version
    );

  return (
    <Dialog
      open={open}
      onClose={onDialogClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="form-dialog-title">
        Création de la configuration
      </DialogTitle>
      <DialogContent>
        {isLoading && (
          <div style={{ textAlign: "center" }}>
            <Loader width={100} height={100} />
          </div>
        )}
        {!isLoading && (
          <>
            <div className={classes.row}>
              <div className={classes.textField}>
                <TextField
                  id="rename-text-field"
                  label="Nom"
                  value={creation.name}
                  onChange={handleEditNom}
                />
              </div>
              <div className={classes.textField}>
                <TextField
                  id="version-text-field"
                  label="Version"
                  value={creation.version}
                  onChange={handleEditVersion}
                  error={isVersionUnavailable}
                  helperText={
                    isVersionUnavailable
                      ? `Une version ${creation.version} existe déjà.`
                      : emptyHelperText
                  }
                />
              </div>
            </div>
            <div className={classes.row}>
              <FormCheckbox
                handleOnChange={() =>
                  setCreation({
                    ...creation,
                    duplicated: !creation.duplicated,
                  })
                }
                label={"Dupliquer une configuration"}
                checked={creation.duplicated}
                className={classes.field}
              />
              <div className={classes.field}>
                {creation.duplicated && (
                  <FormSelect
                    handleOnChange={(value: string) =>
                      setCreation({ ...creation, sourceConfigurationId: value })
                    }
                    availableOptions={(configurations || []).map((c) => ({
                      name: c.name + " - v" + c.version,
                      id: c.id,
                    }))}
                    value={creation.sourceConfigurationId}
                    label={""}
                  />
                )}
              </div>
            </div>
            <div className={classes.row}>
              <FormCheckbox
                handleOnChange={() =>
                  setCreation({
                    ...creation,
                    testVersion: !creation.testVersion,
                  })
                }
                label={"Version de test"}
                checked={creation.testVersion}
                className={classes.field}
              />
            </div>
            <div className={classes.row}>
              <div className={classes.textField}>
                <p className={classes.descriptionLabel}>Description</p>
                <TextField
                  value={creation.description}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setCreation({
                      ...creation,
                      description: event.target.value,
                    });
                  }}
                  multiline={true}
                  className={classes.multiLineTextField}
                  variant="filled"
                  margin="none"
                  minRows={5}
                  maxRows={5}
                  inputProps={{ maxLength: 500 }}
                  InputProps={{ disableUnderline: true }}
                />
              </div>
            </div>
          </>
        )}
      </DialogContent>
      {!isLoading && (
        <DialogActions className={classes.buttonsGroupLayout}>
          <Button variant="contained" onClick={handleClose}>
            Annuler
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSave}
            disabled={
              !creation.name || !creation.version || isVersionUnavailable
            }
          >
            Créer
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default CreateConfigurationDialog;

import React, { useState } from "react";
import { Grid, makeStyles, Paper } from "@material-ui/core";
import Map from "./map";
import Info from "./info";
import { SNCF_MAIN_DARK_VIOLET } from "../../theme";
import useRights from "../../services/ducks/rights";

const useStyle = makeStyles({
  titleLayout: {
    textAlign: "left",
    marginTop: "0",
  },
  separator: {
    borderTop: `1px solid ${SNCF_MAIN_DARK_VIOLET}`,
  },
  paperLayout: {
    padding: "12px",
  },
});

function Home() {
  const classes = useStyle();
  const [selectedStation, setSelectedStation] = useState<string | undefined>();
  const { hasRights } = useRights();

  return (
    <div>
      <h1 className={classes.titleLayout}>Accueil</h1>
      <Paper className={classes.paperLayout}>
        <Grid container spacing={3}>
          {hasRights("HOME_MAPS_READ") && (
            <Grid item xs={6}>
              <Map selected={selectedStation} onSelected={setSelectedStation} />
            </Grid>
          )}
          {hasRights("HOME_MAPS_BOARDINGS") && (
            <Grid item xs={6}>
              <Info
                selected={selectedStation}
                setSelectedStation={setSelectedStation}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
    </div>
  );
}

export default Home;

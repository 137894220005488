import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import { Tooltip } from "@material-ui/core";

function StateIcon({ ok }: { ok: boolean }) {
  return (
    <Tooltip
      placement="top"
      title={
        ok
          ? "Toutes les portes de cette gare possèdent les mêmes versions"
          : "Des portes sont déployées sur des versions différentes"
      }
    >
      {ok ? <CheckIcon color="secondary" /> : <ErrorIcon color="error" />}
    </Tooltip>
  );
}

export default StateIcon;

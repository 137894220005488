import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import { Theme } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import React, { ReactNode, useState, useEffect, useRef } from "react";
import Menu from "./menu";
import useTheme from "@material-ui/core/styles/useTheme";
import UserInfo from "../pages/login/user-info";
import { createStyles, makeStyles } from "@material-ui/styles";
import useRights from "../services/ducks/rights";
import { default as clsx } from "clsx";

interface Properties {
  children: ReactNode;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      minHeight: "100vh",
    },
    appBar: {
      backgroundColor: theme.palette.primary.dark,
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 13,
    },
    hide: {
      display: "none",
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    paper: {
      backgroundColor: theme.palette.primary.main,
      border: "none",
    },
    drawerOpen: {
      backgroundColor: theme.palette.primary.light,
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflow: "hidden",
    },
    drawerClose: {
      backgroundColor: theme.palette.primary.light,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflow: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
    },
    "@keyframes paddingLeftOpenAnimation": {
      from: { paddingLeft: "70px" },
      to: { paddingLeft: "48px" },
    },
    "@keyframes marginLeftCloseAnimation": {
      from: { marginLeft: "-48px" },
      to: { marginLeft: "0px" },
    },
    paddingLeft: {
      paddingLeft: "70px",
    },
    paddingLeftOpenAnimation: {
      animation: "$paddingLeftOpenAnimation 225ms 1",
      animationFillMode: "forwards",
    },
    marginLeftCloseAnimation: {
      animation: "$marginLeftCloseAnimation 195ms 1",
      animationFillMode: "forwards",
    },
  })
);

function Layout({ children }: Properties) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const hasBeenOpen = useRef(false);
  const { hasRights } = useRights();

  useEffect(() => {
    if (open) {
      hasBeenOpen.current = true;
    }
  }, [open]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar
          disableGutters
          className={clsx({
            [classes.paddingLeftOpenAnimation]: open,
            [classes.marginLeftCloseAnimation]: !open && hasBeenOpen.current,
          })}
        >
          {hasRights("HOME_TABS") && (
            <IconButton
              color="inherit"
              aria-label="Déplier le menu"
              onClick={() => setOpen(true)}
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
          )}

          <img
            src={"/logo_sncf.svg"}
            alt={"SNCF Logo"}
            width={122}
            style={{ marginRight: 20 }}
          />
          <Typography variant="h6" color="inherit" noWrap={true}>
            Supervision fixe
          </Typography>
          <UserInfo />
        </Toolbar>
      </AppBar>
      {hasRights("HOME_TABS") && (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx(classes.paper, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={() => setOpen(false)}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          <Divider />
          <Menu open={open} />
        </Drawer>
      )}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
}

export default Layout;

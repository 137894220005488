import React, { useContext, useState } from "react";
import { GateRow } from "./deployment-tab";
import Button from "@material-ui/core/Button";
import { AppContext } from "../../app-provider";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import OptionsButton from "../../components/options-button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { rebootGates } from "../../services/api/gates";
import { SNCF_MAIN_PRUNE, SNCF_MAIN_PRUNE_HOVER } from "../../theme";

interface RebootButtonProperties {
  gates: GateRow[];
}

const useStyles = makeStyles({
  wrapper: {
    position: "relative",
    marginLeft: 30,
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  button: {
    color: "white",
    backgroundColor: SNCF_MAIN_PRUNE,
    "&:hover": {
      backgroundColor: SNCF_MAIN_PRUNE_HOVER,
    },
  },
});

function RebootButton({ gates }: RebootButtonProperties) {
  const [loading, setLoading] = useState<boolean>(false);
  const [_, confirmDialogDispatch] =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useContext(AppContext).reducers.confirmDialog!;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  function handleReboot(type: string) {
    return async () => {
      setLoading(true);

      await rebootGates(
        gates.map((g) => g.id),
        type
      );

      try {
        enqueueSnackbar(
          `Portes redémarrées (option ${
            type === "SOFT" ? "logiciel" : "complet"
          }) avec succès`,
          {
            variant: "success",
          }
        );
      } catch {
        enqueueSnackbar(
          `Une erreur est survenue pendant le redémarrage des portes`,
          {
            variant: "error",
          }
        );
      }

      setLoading(false);
    };
  }

  const buttonActions = [
    {
      label: "Logiciel",
      handler: () =>
        confirmDialogDispatch({
          type: "open",
          title: "Redémarrage logiciel",
          msg: `Êtes-vous sûr de vouloir déclencher un redémarrage (logiciel) les portes sélectionnées (${gates.length}) ?`,
          submit: handleReboot("SOFT"),
        }),
    },
    {
      label: "Complet",
      handler: () =>
        confirmDialogDispatch({
          type: "open",
          title: "Redémarrage complet",
          msg: `Êtes-vous sûr de vouloir déclencher un redémarrage (complet) les portes sélectionnées (${gates.length}) ?`,
          submit: handleReboot("HARD"),
        }),
    },
  ];

  return (
    <OptionsButton
      buttonActions={buttonActions}
      renderButton={(onClick) => (
        <div className={classes.wrapper}>
          <Button
            variant="contained"
            color="inherit"
            className={classes.button}
            disabled={gates.length === 0 || loading}
            onClick={onClick}
          >
            Redémarrer
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      )}
    />
  );
}

export default RebootButton;

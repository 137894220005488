import React from "react";
import { Checkbox, ListItem, ListItemText } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  noPadding: {
    padding: "0",
  },
});

interface Properties {
  handleOnChange: (
    event: React.MouseEvent<HTMLDivElement>,
    parameters?: unknown
  ) => void;
  value?: string;
  label: string;
  checked: boolean | undefined;
  disabled?: boolean;
  className?: string;
}

/**
 * Checkbox component for form
 */
function FormCheckbox({
  handleOnChange,
  value,
  label,
  checked,
  disabled,
  className,
}: Properties) {
  const classes = useStyles();

  return (
    <ListItem
      role="listitem"
      button
      disabled={disabled}
      data-value={value}
      onClick={handleOnChange}
      className={className}
    >
      <Checkbox
        checked={checked}
        className={classes.noPadding}
        disabled={disabled}
      />
      <ListItemText primary={label} />
    </ListItem>
  );
}

export default FormCheckbox;

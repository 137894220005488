import React from "react";
import { JsonEditor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";

const trads = {
  fr: {
    array: "Liste",
    auto: "Automatique",
    appendText: "Ajouter",
    appendTitle:
      "Ajouter un nouveau champ de type 'auto' après celui-ci (Ctrl+Shift+Ins)",
    appendSubmenuTitle: "Sélectionner le type de champ à ajouter",
    appendTitleAuto: "Ajouter un nouveau champ de type 'auto' (Ctrl+Shift+Ins)",
    ascending: "Croissant",
    ascendingTitle: "Trier les enfants de ce ${type} par ordre croissant",
    actionsMenu: "Cliquer pour ouvrir le menu (Ctrl+M)",
    collapseAll: "Regrouper tous les champs",
    descending: "Décroissant",
    descendingTitle: "Trier les enfants de ce ${type} par ordre décoissant",
    drag: "Faire glisser pour déplacer ce champ (Alt+Shift+Flèche)",
    duplicateKey: "Clé dupliquée",
    duplicateText: "Dupliquer",
    duplicateTitle: "Dupliquer les champs sélectionnés (Ctrl+D)",
    duplicateField: "Dupliquer ce champ (Ctrl+D)",
    duplicateFieldError: "Nom du champ en doublon",
    cannotParseFieldError: "Impossible de parser le champs en JSON",
    cannotParseValueError: "Impossible de parsr la valur en JSON",
    empty: "vide",
    expandAll: "Déplier tous les champs",
    expandTitle:
      "Cliquer pour déplier/regrouper ce champ (Ctrl+E). \n" +
      "Ctrl+Clic pour déplier/regrouper en incluant tous les enfants.",
    insert: "Insérer",
    insertTitle:
      "Insérer un nouveau champ de type 'auto' avant celui-ci (Ctrl+Ins)",
    insertSub: "Sélectionner le type du champ à insérer",
    object: "Objet",
    ok: "Ok",
    redo: "Refaire (Ctrl+Shift+Z)",
    removeText: "Supprimer",
    removeTitle: "Supprimer les champs sélectionnés (Ctrl+Suppr)",
    removeField: "Supprimer ce champ (Ctrl+Suppr)",
    selectNode: "Sélectionner un noeud...",
    showAll: "montrer tout",
    showMore: "montrer plus",
    showMoreStatus: "${visibleChilds} parmi ${totalChilds} items affichés.",
    sort: "Trier",
    sortTitle: "Trier les enfants de ce ${type}",
    sortTitleShort: "Trier le contenu",
    sortFieldLabel: "Champ:",
    sortDirectionLabel: "Direction:",
    sortFieldTitle:
      "Sélectionner le champ par lequel la liste ou l'objet sera trié",
    sortAscending: "Croissant",
    sortAscendingTitle: "Trier le champ sélectionné par ordre croissant",
    sortDescending: "Décroissant",
    sortDescendingTitle: "Trier le champ sélectionné par ordre décroissant",
    string: "Chaîne de caractères",
    transform: "Convertir",
    transformTitle: "Filtrer, trier ou convertir les enfants de ce ${type}",
    transformTitleShort: "Filtrer, trier ou convertir le contenu",
    extract: "Extraire",
    extractTitle: "Extraire ce ${type}",
    transformQueryTitle: "Enrer une requête JMESPath",
    transformWizardLabel: "Wizard",
    transformWizardFilter: "Filtrer",
    transformWizardSortBy: "Trier par",
    transformWizardSelectFields: "Sélectionner les champs",
    transformQueryLabel: "Requête",
    transformPreviewLabel: "Prévisualisation",
    type: "Type",
    typeTitle: "Changer le type de ce champ",
    openUrl:
      "Ctrl+Clic or Ctrl+Entrée pour ouvrir l'url dans une nouvelle fenêtre",
    undo: "Annuler la dernière action (Ctrl+Z)",
    validationCannotMove: "Impossible de déplacer un champ dans lui-même",
    autoType:
      'Champ de type "auto". ' +
      "Le type de champ est automatiquement déterminé à partir de la valeur " +
      "et peut être une chaîne de caractères, un nombre, un booléen ou null.",
    objectType:
      'Champ de type "objet". ' +
      "Un object contient une liste non ordonnée de paires de clé/valeur.",
    arrayType:
      'Champ de type "liste". ' +
      "Une liste contient un ensemble ordonné de valeurs.",
    stringType:
      'Champ de type "chaîne de caractères". ' +
      "Le type du champ n'est pas déterminé à partir de la valeur, " +
      "mais est toujours une chaîne de caractère.",
    modeCodeText: "Code",
    modeCodeTitle: "Basculer en surlignage de code",
    modeFormText: "Formulaire",
    modeFormTitle: "Basculer vers l'éditeur de formulaire",
    modeTextText: "Texte",
    modeTextTitle: "Basculer vers l'éditeur de texte brut",
    modeTreeText: "Arbre",
    modeTreeTitle: "Basculer vers l'éditeur d'arbre",
    modeViewText: "Visualisation",
    modeViewTitle: "Basculer en mode visualisation de l'arbre",
    examples: "Exemples",
    default: "Defaut",
  },
};

interface CustomJsonEditorProperties {
  value: object;
  onChange: (value: object) => void;
}

function CustomJsonEditor({ value, onChange }: CustomJsonEditorProperties) {
  return (
    <JsonEditor
      value={value}
      onChange={onChange}
      navigationBar={false}
      statusBar={false}
      search={false}
      language={"fr"}
      languages={trads}
    />
  );
}

export default CustomJsonEditor;

import { TrackLane } from "../../../../services/interfaces/referential";

export function findTrackLanesWithMissing(
  trackIds: string[],
  laneIds: string[],
  trackLanes: TrackLane[],
  dockId: string
) {
  const oldTrackLanes = [...trackLanes];
  const temporary = {
    founded: [] as TrackLane[],
    missing: [] as TrackLane[],
  };
  for (const trackId of trackIds) {
    for (const laneId of laneIds) {
      const trackLane = oldTrackLanes.find(
        (tl) => tl.laneId === laneId && tl.trackId === trackId
      );
      if (trackLane) {
        temporary.founded.push(trackLane);
      } else {
        temporary.missing.push({
          name: "",
          trackId,
          laneId,
          dockId,
          type: "",
          position: "",
        });
      }
    }
  }
  return temporary;
}

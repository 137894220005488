import OptionsButton from "components/options-button";
import { Tab } from "components/tabs-component";
import fileDownload from "js-file-download";
import React, { useState } from "react";
import { downloadWhitelist, WhitelistDTO } from "services/api/whitelists";
import useRights from "services/ducks/rights";
import { RenderedWhitelist } from ".";
import { DeleteWhitelistConfirmationModal } from "./delete-whitelist-confirmation-modal";
import { TabKey } from "./hooks/use-tabs";
import { Lock } from "@material-ui/icons";
import { default as useWhitelistStyles } from "./hooks/use-whitelist-styles";

export interface WhitelistRowTitleProperties {
  whitelist: RenderedWhitelist;
  selectedTab: Tab;
  onDeletedWhitelist: (whitelist: WhitelistDTO) => void;
  onCreatingWhitelistElement: (whitelist: WhitelistDTO) => void;
}

export const WhitelistRowTitle: React.FC<WhitelistRowTitleProperties> = ({
  whitelist,
  selectedTab,
  onDeletedWhitelist,
  onCreatingWhitelistElement,
}) => {
  const { hasRights, hasLevel } = useRights();
  const classes = useWhitelistStyles();

  const [deletingWhitelist, setDeletingWhitelist] = useState<WhitelistDTO>();

  const actions = {
    add: {
      label: "Ajouter une valeur",
      props: {
        disabled: whitelist.locked,
      },
      handler: () => {
        onCreatingWhitelistElement(whitelist);
      },
    },
    download: {
      label: "Télécharger",
      handler: async () => {
        const blob = await downloadWhitelist({
          id: whitelist.id,
          whitelistType: TabKey[whitelist.type],
          transporter: whitelist.transporter || undefined,
        });
        fileDownload(blob, whitelist.name + ".zip");
      },
    },
    delete: {
      label: "Supprimer",
      props: {
        disabled: whitelist.locked,
      },
      handler: () => {
        setDeletingWhitelist(whitelist);
      },
    },
  };
  const authorizedActions = [];

  const onCloseDeleteWhitelistModal = (deleted: boolean) => {
    if (deleted && deletingWhitelist) {
      onDeletedWhitelist(whitelist);
    } else {
      setDeletingWhitelist(undefined);
    }
  };

  // filter contextual actions
  if (
    hasLevel("NATIONAL") &&
    ((hasRights("WL_UG_VALUE_CREATE") && TabKey.UG === selectedTab.key) ||
      (hasRights("WL_TRANSPORTER_VALUE_CREATE") &&
        TabKey.TRANSPORTER === selectedTab.key))
  )
    authorizedActions.push(actions.add);
  if (
    hasLevel("NATIONAL") &&
    ((hasRights("WL_UG_VALUE_DOWNLOAD") && TabKey.UG === selectedTab.key) ||
      (hasRights("WL_TRANSPORTER_VALUE_DOWNLOAD") &&
        TabKey.TRANSPORTER === selectedTab.key))
  )
    authorizedActions.push(actions.download);
  if (
    hasLevel("NATIONAL") &&
    ((hasRights("WL_UG_VALUE_DELETE") && TabKey.UG === selectedTab.key) ||
      (hasRights("WL_TRANSPORTER_VALUE_DELETE") &&
        TabKey.TRANSPORTER === selectedTab.key))
  )
    authorizedActions.push(actions.delete);

  return (
    <>
      <div className={classes.columnName}>
        <span className={classes.whitelistLabel}>{whitelist.name}</span>
        {whitelist.locked && (
          <span className={classes.lockedWhitelist}>
            <Lock fontSize="small" />
          </span>
        )}
      </div>
      {authorizedActions.length > 0 && (
        <div className={classes.columnActions}>
          <OptionsButton
            buttonActions={authorizedActions}
            buttonClassName={classes.buttonAction}
          />
        </div>
      )}

      {deletingWhitelist && (
        <DeleteWhitelistConfirmationModal
          whitelistId={deletingWhitelist.id}
          whitelistType={TabKey[deletingWhitelist.type]}
          whitelistTransporter={deletingWhitelist.transporter || undefined}
          onClose={onCloseDeleteWhitelistModal}
        />
      )}
    </>
  );
};

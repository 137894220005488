import React, { useState, useEffect } from "react";
import { Button, makeStyles } from "@material-ui/core";
import {
  SNCF_MAIN_DARK_VIOLET,
  SNCF_COMPL_BLUE,
  SNCF_COMPL_BLUE_DARK,
  SNCF_LIGHT_GRAY,
  SNCF_GRAY,
  SNCF_MAIN_PRUNE,
} from "../../../theme";
import { getConfig } from "../../../services/config";
import { default as clsx } from "clsx";
import DropDownMenu from "../../../components/drop-down-menu";
import {
  ItemName,
  StationBoardingStatus,
} from "../../../services/interfaces/referential";
import InfoSection, { SectionData } from "./info-section";
import useRights from "../../../services/ducks/rights";
import { fetchStationsBoardingStatus } from "services/api/stations";

const useStyle = makeStyles({
  oppeButton: {
    backgroundColor: SNCF_COMPL_BLUE,
    "&:hover": {
      backgroundColor: SNCF_COMPL_BLUE_DARK,
    },
  },
  pagecheckupButton: {
    backgroundColor: SNCF_LIGHT_GRAY,
    "&:hover": {
      backgroundColor: SNCF_GRAY,
    },
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flex: 1,
  },
  button: {
    margin: "10px",
    width: "30%",
  },
  container: {
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: "column",
  },
  infoSectionContainer: {
    margin: 10,
  },
  infoSectionBoardingHeader: {
    backgroundColor: SNCF_MAIN_DARK_VIOLET,
  },
  infoSectionPassengersHeader: {
    backgroundColor: SNCF_MAIN_PRUNE,
  },
});

const openOppe = () => {
  const config = getConfig();
  window.open(config.OPPE_URL);
};

const openPageCheckup = () => {
  const config = getConfig();
  window.open(config.PAGECHECKUP_URL);
};

interface InfoProperties {
  selected: string | undefined;
  setSelectedStation: (stationId: string | undefined) => void;
}

const computeBoardingData = (
  stations: StationBoardingStatus[]
): SectionData[] => [
  {
    value: String(
      stations.reduce(
        (accumulator, station) => accumulator + station.activeBoardingCount,
        0
      )
    ),
    root: true,
    label: "Embarquements en cours",
  },

  ...(stations.length > 1
    ? stations.map((station) => ({
        value: String(station.activeBoardingCount),
        root: false,
        label: station.name,
      }))
    : []),
  {
    value: String(
      stations.reduce(
        (accumulator, station) => accumulator + station.completedBoardings,
        0
      )
    ),
    root: true,
    label: "Embarquements réalisés",
  },
  {
    value: String(
      stations.reduce(
        (accumulator, station) => accumulator + station.liftedBoardings,
        0
      )
    ),
    root: true,
    label: "Embarquements levés",
  },
];

const computePassengerData = (
  stations: StationBoardingStatus[]
): SectionData[] => [
  {
    value: String(
      stations.reduce(
        (accumulator, station) => accumulator + station.passengersCount,
        0
      )
    ),
    root: true,
    label: "Passagers embarqués",
  },
  {
    value: String(
      stations.reduce(
        (accumulator, station) => accumulator + station.passengerFlow,
        0
      )
    ),
    root: true,
    label: "Passagers/min",
  },
];

export default function Info({ selected, setSelectedStation }: InfoProperties) {
  const [stations, setStations] = useState<StationBoardingStatus[]>([]);
  const [boardingData, setBoardingData] = useState<SectionData[]>([]);
  const [flowData, setFlowData] = useState<SectionData[]>([]);

  const classes = useStyle();
  const { hasRights } = useRights();
  const config = getConfig();

  useEffect(() => {
    let subscribed = true;

    const loadStations = async () => {
      const fetchedStations = await fetchStationsBoardingStatus();
      if (subscribed) {
        setStations(fetchedStations);
      }
    };

    loadStations();
    const interval = setInterval(
      loadStations,
      config.HOMEPAGE_REFRESH_INTERVAL * 1000
    );
    return () => {
      subscribed = false;
      if (interval) {
        clearInterval(interval);
      }
    };
  }, []);

  const selectStation = (station: ItemName | undefined) => {
    setSelectedStation(station && station.id);
  };

  useEffect(() => {
    if (stations.length > 0) {
      const selectedStations = stations.filter(
        (station) => !selected || station.id === selected
      );
      setBoardingData(computeBoardingData(selectedStations));
      setFlowData(computePassengerData(selectedStations));
    }
  }, [selected, stations]);

  return (
    <div className={clsx(classes.container)}>
      <div>
        <DropDownMenu
          elementList={stations}
          handleToUpdate={selectStation}
          defaultValueId={selected}
          noValueLabel={"Toutes les gares"}
        />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <InfoSection
          name={"Embarquements"}
          data={boardingData}
          classNames={{
            infoSectionContainer: [classes.infoSectionContainer],
            infoSectionHeader: [classes.infoSectionBoardingHeader],
          }}
        />
        {!config.LIGHT_MODE && (
          <InfoSection
            name={"Passagers"}
            data={flowData}
            classNames={{
              infoSectionContainer: [classes.infoSectionContainer],
              infoSectionHeader: [classes.infoSectionPassengersHeader],
            }}
          />
        )}
      </div>
      <div className={classes.buttonsContainer}>
        {hasRights("HOME_OPPE") && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => openOppe()}
            className={clsx(classes.oppeButton, classes.button)}
          >
            Accès à OPPE
          </Button>
        )}
        {!config.LIGHT_MODE && hasRights("HOME_CHECKUP") && (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            onClick={() => openPageCheckup()}
            className={clsx(classes.pagecheckupButton, classes.button)}
          >
            Page checkup
          </Button>
        )}
      </div>
    </div>
  );
}

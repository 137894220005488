import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import Tabs from "../../components/tabs-component";
import useStations from "../referentials/station/use-stations";
import DropDownMenu from "../../components/drop-down-menu";
import ExportButtonGroup from "../../components/export-button-group";
import DeploymentTab from "./deployment-tab";
import { ItemName } from "../../services/interfaces/referential";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router";
import useRights from "../../services/ducks/rights";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  titleLayout: {
    textAlign: "left",
    marginTop: "0",
  },
  tabsWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
    height: "48px",
  },
  tabs: {
    width: "50%",
  },
  selectWrapper: {
    marginLeft: 50,
  },
});

const tabs: { key: string; label: string; type: string }[] = [
  { key: "software", label: "Logiciels", type: "SOFTWARE" },
  { key: "configuration", label: "Configurations", type: "CONFIG" },
];

function Deployments({ history }: RouteComponentProps) {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [selectedStation, setSelectedStation] = useState<string>();
  const stations = useStations();
  const classes = useStyles();
  const { hasRights } = useRights();

  const hasExportRight =
    (tabIndex === 0 && hasRights("DEPLOY_SOFT_EXPORT")) ||
    (tabIndex === 1 && hasRights("DEPLOY_CONF_EXPORT"));

  function handleSelectStation(item?: ItemName) {
    setSelectedStation(item ? item.id : undefined);
  }

  function changeTab(index: number) {
    switch (index) {
      case 0: {
        setTabIndex(0);
        history.push("/deploy/software");
        break;
      }
      case 1: {
        setTabIndex(1);
        history.push("/deploy/configuration");
        break;
      }
    }
  }

  const filteredStations = selectedStation
    ? stations.filter((s) => s.id === selectedStation)
    : stations;
  return (
    <div className={classes.root}>
      <h1 className={classes.titleLayout}>Déploiements</h1>

      <div className={classes.tabsWrapper}>
        {hasRights(["DEPLOY_SOFT_READ", "DEPLOY_CONF_READ"]) && (
          <Tabs
            tabs={tabs}
            indexTab={tabIndex}
            onChangeIndexTab={changeTab}
            additionalClassName={classes.tabs}
          />
        )}
        <DropDownMenu
          elementList={stations}
          handleToUpdate={handleSelectStation}
          defaultValueId={undefined}
          additionalClassName={classes.selectWrapper}
          noValueLabel={"Toutes les gares"}
        />
        {hasExportRight && (
          <ExportButtonGroup type={tabIndex === 0 ? "SOFTWARE" : "CONFIG"} />
        )}
      </div>

      <Switch>
        <Redirect
          from="/deploy"
          exact={true}
          to={`/deploy/${
            hasRights("DEPLOY_SOFT_READ") ? "software" : "configuration"
          }`}
        />
        <Route
          path="/deploy/software"
          exact={true}
          render={() => {
            setTabIndex(0);
            // eslint-disable-next-line unicorn/no-null
            return null;
          }}
        />
        <Route
          path="/deploy/configuration"
          exact={true}
          render={() => {
            setTabIndex(1);
            // eslint-disable-next-line unicorn/no-null
            return null;
          }}
        />
      </Switch>

      <DeploymentTab stations={filteredStations} type={tabs[tabIndex].type} />
    </div>
  );
}

export default withRouter(Deployments);

import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  WhitelistLockDTO,
  WhitelistDTO,
  WhitelistElementDTO,
  unlockWhitelist,
  lockWhitelist,
  fetchWhitelistElement,
  fetchWhitelist,
  updateWhitelistElement,
} from "../../../services/api/whitelists";
import { useSnackbar } from "notistack";
import { RouteComponentProps, withRouter } from "react-router";
import { RouteProperties } from "..";
import { WhitelistElementDialog } from "./whitelist-element-dialog";

const snackbarAutoHideDelayMs = 10_000; // 10 secs
const lockRefreshIntervalMs = 5 * 60 * 1000; // 5 mins

type WhitelistElementModalProperties = {
  whitelistId: string;
  whitelistElementId: string;
  onClose: () => void;
  onSave: (wle: WhitelistElementDTO) => void;
  editMode: boolean;
} & RouteComponentProps<RouteProperties>;

function WhitelistElementModal({
  whitelistId,
  whitelistElementId,
  onClose,
  onSave,
  editMode: editModeProperty,
  match,
}: WhitelistElementModalProperties) {
  const [whitelist, setWhitelist] = useState<WhitelistDTO>();
  const [whitelistElement, setWhitelistElement] =
    useState<WhitelistElementDTO>();
  const [lock, setLock] = useState<WhitelistLockDTO>();
  const [editMode, setEditMode] = useState(editModeProperty);
  const [canEdit, setCanEdit] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const interval = useRef<ReturnType<typeof setInterval>>();

  const releaseLock = useCallback(() => {
    if (lock) {
      unlockWhitelist({
        whitelistType: match.params.type,
        transporter: match.params.transporter,
        id: whitelistId,
      }).finally(() => setLock(undefined));
    }
  }, [lock, match.params, whitelistId]);

  const acquireLock = useCallback(() => {
    const isSubscribed = true;

    const doAquireLock = async () => {
      try {
        const lock = await lockWhitelist({
          whitelistType: match.params.type,
          transporter: match.params.transporter,
          id: whitelistId,
        });
        if (isSubscribed) {
          setLock(lock);
          setEditMode(true);
        }
      } catch {
        if (canEdit) {
          enqueueSnackbar(
            "Echec de rafraichissement du verrou de la liste blanche",
            {
              variant: "error",
              autoHideDuration: snackbarAutoHideDelayMs,
            }
          );
        } else {
          enqueueSnackbar(
            "Un autre utilisateur modifie actuellement ce fichier.",
            {
              variant: "warning",
              autoHideDuration: snackbarAutoHideDelayMs,
            }
          );
        }
        setLock(undefined);
        setEditMode(false);
      }
    };

    doAquireLock();
  }, [canEdit, enqueueSnackbar, match.params, whitelistId]);

  const handleClose = useCallback(() => {
    releaseLock();
    onClose();
  }, [onClose, releaseLock]);

  const handleSave = (updatedWhitelistElement: WhitelistElementDTO) => {
    if (whitelist && whitelistElement) {
      const doElementSave = async () => {
        try {
          const update = {
            name: updatedWhitelistElement.name,
            content: updatedWhitelistElement.content,
          };
          const updated = await updateWhitelistElement(
            {
              whitelistType: match.params.type,
              transporter: match.params.transporter,
              id: whitelistElementId,
            },
            update as WhitelistElementDTO
          );
          enqueueSnackbar(
            `La liste blanche « ${whitelistElement.name} » a été modifiée avec succès`,
            {
              variant: "success",
              autoHideDuration: snackbarAutoHideDelayMs,
            }
          );
          onSave(updated);
        } catch {
          enqueueSnackbar(
            `Une erreur est survenue lors de la modification de la whitelist`,
            {
              variant: "error",
              autoHideDuration: snackbarAutoHideDelayMs,
            }
          );
        } finally {
          handleClose();
        }
      };

      doElementSave();
    }
  };

  useEffect(() => {
    if (lock === undefined) {
      setCanEdit(false);
      if (interval.current) {
        clearTimeout(interval.current);
        interval.current = undefined;
      }
    } else {
      setCanEdit(editMode);
      if (!interval.current) {
        interval.current = setInterval(acquireLock, lockRefreshIntervalMs);
      }
    }

    return () => {
      if (interval.current) {
        clearTimeout(interval.current);
        interval.current = undefined;
      }
    };
  }, [editMode, lock, acquireLock]);

  useEffect(() => {
    if (editMode) {
      return acquireLock();
    }
  }, [editMode, acquireLock]);

  useEffect(() => {
    let subscribed = true;
    const fetchWl = async () => {
      const wl = await fetchWhitelist({
        whitelistType: match.params.type,
        transporter: match.params.transporter,
        id: whitelistId,
      });
      if (subscribed) {
        setWhitelist(wl);
      }
    };
    const fetchWle = async () => {
      const wle = await fetchWhitelistElement({
        whitelistType: match.params.type,
        transporter: match.params.transporter,
        id: whitelistElementId,
      });
      if (subscribed) {
        setWhitelistElement(wle);
      }
    };

    Promise.all([fetchWl(), fetchWle()]).catch((error) =>
      enqueueSnackbar(error.toString(), { variant: "error" })
    );

    return () => {
      subscribed = false;
    };
  }, [whitelistId, whitelistElementId, enqueueSnackbar, match.params]);

  return (
    <WhitelistElementDialog
      whitelist={whitelist}
      whitelistElement={whitelistElement}
      canEdit={canEdit}
      onEdit={() => setEditMode(true)}
      onClose={handleClose}
      onSave={handleSave}
    />
  );
}

export default withRouter(WhitelistElementModal);

import React, { useState, useEffect } from "react";
import UserForm from "./user-form";
import { User, EUserType } from "../../services/api/users";
import { AvailableUserOptions } from "../../services/api/available-user-options";
import { CircularProgress, Theme, Dialog, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((_theme: Theme) => ({
  loaderWrapperLayout: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "400px",
    height: "200px",
  },
}));

export const defaultUser: User = {
  type: EUserType.SNCF,
  active: true,
  username: "",
  lastName: "",
  firstName: "",
  password: "",
  activity: "",
  profile: "",
  transporters: [],
  perimeter: "",
  perimeterElements: [],
};

interface UserEditProperties {
  open: boolean;
  availableUserOptions?: AvailableUserOptions;
  currentUser?: User;
  closeModal: () => void;
  handleUser: (user: User) => void;
}

/**
 * Create New User / Modify User Edit Component
 */
export function UserEdit({
  availableUserOptions,
  currentUser,
  open,
  closeModal,
  handleUser,
}: UserEditProperties) {
  const [user, setUser] = useState<User>(defaultUser);
  const [escapeKeyDown, setEscapeKeyDown] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    if (currentUser) {
      setUser(currentUser);
    }
  }, [user, open]);

  const handleEscapeKeyDown = () => {
    setEscapeKeyDown(true);
  };

  const closeAndResetModal = () => {
    setUser(defaultUser);
  };

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      onEscapeKeyDown={handleEscapeKeyDown}
      onExited={closeAndResetModal}
    >
      {availableUserOptions ? (
        <UserForm
          isNewUser={user.id === undefined}
          currentUser={user}
          availableUserOptions={availableUserOptions}
          closeModal={closeModal}
          escapeKeyDown={escapeKeyDown}
          setEscapeKeyDown={setEscapeKeyDown}
          handleUser={handleUser}
        />
      ) : (
        <div className={classes.loaderWrapperLayout}>
          <CircularProgress />
        </div>
      )}
    </Dialog>
  );
}

export default UserEdit;

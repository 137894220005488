import { orange } from "@material-ui/core/colors";

export const PRIMARY_COLOR = "#2d2e30";
export const PRIMARY_COLOR_LIGHTER = "#404042";
export const SECONDARY_COLOR = "#0089d1";
export const SECONDARY_LIGHT = "#d1efff";

export const MATERIAL_ORANGE_100 = orange[100];

export const SNCF_MAIN_MAGENTA = "#ef0044";
export const SNCF_MAIN_PRUNE = "#be006e";
export const SNCF_MAIN_PRUNE_HOVER = "#AD005D";
export const SNCF_MAIN_VIOLET = "#6c2c83";
export const SNCF_MAIN_DARK_VIOLET = "#541969";
export const SNCF_COMPL_LIGHT_GREEN = "#c7dd00";
export const SNCF_COMPL_YELLOW = "#eeab00";
export const SNCF_COMPL_ORANGE = "#ff5100";
export const SNCF_COMPL_BLUE = "#0089d1";
export const SNCF_COMPL_BLUE_DARK = "#0069a0";
export const SNCF_COMPL_GREEN_BLUE = "#019aa8";
export const SNCF_COMPL_GREEN = "#53b900";
export const SNCF_LIGHT_BROWN = "#9d9087";
export const SNCF_BROW = "#877970";
export const SNCF_DARK_BROWN = "#6a5c53";
export const SNCF_LIGHT_GRAY = "#9b9c9e";
export const SNCF_MODAL_GRAY = "#eeeeee";
export const SNCF_GRAY = "#73777a";
export const SNCF_DARK_GRAY = "#4a4d54";
export const SNCF_BCKGND_GRAY = "#eeeeee";
export const SNCF_GRAY_DELIMITER = "#d6d6d8";
export const SNCF_GRAY_1 = "#737778";
export const SNCF_GRAY_2 = "#757678";
export const SNCF_GRAY_3 = "#7f8384";
export const SNCF_BLUE_DARK = "#00062F";
export const SNCF_BLUE_LIGHT = "#323C69";
export const SNCF_WHITE = "#F0F0F0";
export const SNCF_GRAY_LIGHT = "#D2D4DE";
export const SNCF_BLUE_LIGHTER = "#5B6185";
export const SNCF_MAGENTA = "#BD0035";
export const SNCF_CYAN = "#00CAFF";
export const SNCF_CYAN_DARK = "#008CE6";
export const SNCF_GREEN_LIGHT = "#00E68C";
export const SNCF_RED_LIGHT = "#e80000";
export const SNCF_PURPLE = "#A00069";
export const SNCF_YELLOW = "rgb(254, 251, 0)";
export const SNCF_LIGHT_PURPLE = "rgb(178, 161, 199)";
export const SNCF_ORANGE = "#ec6625";
export const SNCF_BLACK_TXT = "2c2d2f";
export const SNCF_APP_BCKGND = "#2d2e30";
export const SNCF_RED = "#ed0044";

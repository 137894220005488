import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Theme } from "@material-ui/core/styles";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";
import { NavLink } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { default as clsx } from "clsx";
import { Tooltip } from "@material-ui/core";
import { SNCF_COMPL_BLUE_DARK } from "../../theme";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: 50,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: SNCF_COMPL_BLUE_DARK,
    },
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: "30px",
  },
  text: {
    color: theme.palette.common.white,
  },
}));

export interface MenuItemProperties {
  to: string;
  text: string;
  Icon: React.ComponentType<SvgIconProps>;
  exact?: boolean;
  tooltip?: string;
}

export default function MenuItem({
  to,
  text,
  Icon,
  exact,
  tooltip,
}: MenuItemProperties) {
  const classes = useStyles();

  return (
    <Tooltip
      title={tooltip || ""}
      enterDelay={500}
      disableFocusListener
      placement="right"
    >
      <ListItem
        className={clsx(classes.root)}
        button={true}
        // eslint-disable-next-line react/display-name
        component={React.forwardRef((properties, reference) => (
          <NavLink
            activeClassName={classes.active}
            exact={exact}
            to={to}
            {...properties}
            innerRef={reference as (node: HTMLAnchorElement | null) => void}
          />
        ))}
      >
        <ListItemIcon style={{ marginLeft: "5px" }}>
          <Icon className={classes.icon} />
        </ListItemIcon>
        <ListItemText primary={text} classes={{ primary: classes.text }} />
      </ListItem>
    </Tooltip>
  );
}

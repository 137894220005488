import React from "react";
import { BundleVersionDTO } from "../../services/api/deployments";
import InfoIcon from "@material-ui/icons/PermDeviceInformation";
import ArrowRight from "@material-ui/icons/ArrowForward";
import { makeStyles } from "@material-ui/styles";
import { SNCF_COMPL_BLUE, SNCF_COMPL_GREEN } from "../../theme";
import LightTooltip from "../../components/light-tooltip";
import isEmpty = require("lodash/isEmpty");

const useStyles = makeStyles({
  bundle: {
    display: "flex",
    alignItems: "center",
  },
  bundleName: {
    width: 80,
  },
  actual: {
    color: SNCF_COMPL_BLUE,
  },
  next: {
    color: SNCF_COMPL_GREEN,
  },
});

function Bundle({
  bundle,
  showName,
}: {
  bundle: BundleVersionDTO;
  showName?: boolean;
}) {
  const classes = useStyles();
  const displayBundleName = bundle.id.name.toUpperCase();
  return (
    <div className={classes.bundle}>
      {showName && (
        <div className={classes.bundleName}>{displayBundleName}</div>
      )}
      <div className={bundle.actual ? classes.actual : ""}>
        {bundle.actual || "-"}
      </div>
      {bundle.requested && <ArrowRight fontSize="small" />}
      <div className={bundle.requested ? classes.next : ""}>
        {bundle.requested || ""}
      </div>
    </div>
  );
}

function BundlesInfo({ bundles }: { bundles: BundleVersionDTO[] | null }) {
  if (!bundles || isEmpty(bundles)) return <>-</>;

  if (bundles.length === 1) {
    return <Bundle bundle={bundles[0]} />;
  }

  return (
    <LightTooltip
      placement={"left"}
      title={
        <div>
          {bundles.map((b) => (
            <Bundle bundle={b} showName={true} key={b.id.fullName} />
          ))}
        </div>
      }
    >
      <InfoIcon />
    </LightTooltip>
  );
}

export default BundlesInfo;

export default [
  {
    key: "stationGroups",
    label: "Groupes de gares",
  },
  {
    key: "stations",
    label: "Gares",
  },
];

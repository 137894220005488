import React, { useContext, useState } from "react";
import {
  addTrack,
  deleteTrack,
  deleteTracks,
  updateTrack,
} from "../../../services/api/tracks";
import { Dock, Lane, Track } from "../../../services/interfaces/referential";
import OptionsButton from "../../../components/options-button";
import { Paper, Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { AppContext } from "../../../app-provider";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import TrackFormDialog from "./dialog/track-form-dialog";
import { UseCrud } from "../../../commons/use-crud";
import { fetchDocks } from "../../../services/api/docks";
import { fetchLanes } from "../../../services/api/lanes";
import { useSnackbar } from "notistack";
import SortableAndSearchableVirtualizedTable from "../../../components/virtualized-table/sortable-and-searchable-virtualized-table";
import useRights from "../../../services/ducks/rights";

interface TrackTabProperties {
  stationId: string;
  tracksState: UseCrud<Track>;
  lanesState: UseCrud<Lane>;
  docksState: UseCrud<Dock>;
  dataInFetching: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
    },
    fab: {
      float: "right",
      margin: theme.spacing(1),
    },
  })
);

export interface TrackFormState {
  open: boolean;
  action?: "add" | "edit";
  data?: Track;
  submit?: (track: Track) => void | Promise<void>;
}

/**
 * TrackTab Component
 */
function TrackTab({
  stationId,
  tracksState,
  lanesState,
  docksState,
  dataInFetching,
}: TrackTabProperties) {
  const classes = useStyles();
  const { hasRights } = useRights();

  const tracks = tracksState.datas;
  const docks = docksState.datas;
  const [selected, setSelected] = useState<Set<string>>(new Set([]));
  const [, confirmDialogDispatch] =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useContext(AppContext).reducers.confirmDialog!;
  const [formDialog, setFormDialog] = useState<TrackFormState>({
    open: false,
  });
  const { enqueueSnackbar } = useSnackbar();

  // ----------- ReactVirtualizedTable

  const handleSelected = (track: Track, checked: boolean) => {
    const updateSelected = new Set(selected);
    checked ? updateSelected.add(track.id) : updateSelected.delete(track.id);
    setSelected(updateSelected);
  };

  const isSelected = (track: Track): boolean => {
    return selected.has(track.id);
  };

  // ----------- api actions

  async function deleteById(rowIndex: number) {
    handleSelected(tracks[rowIndex], false);
    try {
      await deleteTrack(tracks[rowIndex].id);
      enqueueSnackbar(`Voie "${tracks[rowIndex].name}" supprimée avec succès`, {
        variant: "success",
      });
      tracksState.remove(tracks[rowIndex].id);
      if (
        docks.some((d) =>
          d.trackLaneDTOList.some((t) => t.trackId === tracks[rowIndex].id)
        )
      ) {
        docksState.set(await fetchDocks(stationId));
      }
    } catch {
      enqueueSnackbar(`Erreur lors de la suppression de la voie`, {
        variant: "error",
      });
    }
  }

  async function deleteBySelection() {
    try {
      await deleteTracks(selected);
      enqueueSnackbar(
        selected.size > 0
          ? `Voies supprimées avec succès`
          : `Voie "${selected.values().next().value}" supprimée avec succès`,
        { variant: "success" }
      );
      tracksState.removeAll(selected);
      if (
        docks.some((d) =>
          [...selected].some((r) =>
            d.trackLaneDTOList.some((t) => t.trackId === r)
          )
        )
      ) {
        fetchDocks(stationId).then((d) => docksState.set(d));
      }
      setSelected(new Set([]));
    } catch {
      enqueueSnackbar(
        selected.size > 0
          ? `Erreur lors de la suppression des voies`
          : `Erreur lors de la suppression de la voie`,
        {
          variant: "error",
        }
      );
    }
  }

  async function add(track: Track) {
    try {
      tracksState.add(await addTrack(track));
      enqueueSnackbar(`Voie créée avec succès`, { variant: "success" });
      fetchDocks(stationId).then((d) => docksState.set(d));
      if (track.trackLaneDTOList.length > 0) {
        fetchLanes(stationId).then((l) => lanesState.set(l));
      }
    } catch {
      enqueueSnackbar(`Erreur lors de la création de la voie`, {
        variant: "error",
      });
    }
  }

  async function edit(track: Track) {
    try {
      tracksState.update(await updateTrack(track));
      enqueueSnackbar(`Voie modifiée avec succès`, {
        variant: "success",
      });
      fetchDocks(stationId).then((d) => docksState.set(d));
      const oldTrack = tracks.find((l) => l.id === track.id);
      if (
        // Si les deux listes de trackLanes sont différentes
        oldTrack &&
        (oldTrack.trackLaneDTOList.some(
          (x) => !track.trackLaneDTOList.includes(x)
        ) ||
          track.trackLaneDTOList.some(
            (x) => !oldTrack.trackLaneDTOList.includes(x)
          ))
      ) {
        fetchLanes(stationId).then((l) => lanesState.set(l));
      }
    } catch {
      enqueueSnackbar(`Erreur lors de la modification de la voie`, {
        variant: "error",
      });
    }
  }

  // ----------- renders

  const renderOptionsButton = (rowIndex: number) => {
    const buttonActions = [
      {
        label: "Modifier les informations",
        handler: () => {
          setFormDialog({
            open: true,
            action: "edit",
            data: { ...tracks[rowIndex] },
            submit: (track: Track) => edit(track),
          });
        },
      },
      {
        label: "Supprimer la voie",
        handler: () =>
          confirmDialogDispatch({
            type: "open",
            title: "Suppression de voie",
            msg:
              'Êtes-vous sûr de vouloir supprimer la voie "' +
              tracks[rowIndex].name +
              '" ?',
            submit: () => deleteById(rowIndex),
          }),
      },
    ];

    return <OptionsButton buttonActions={buttonActions} />;
  };

  const headerLabelTrack = [
    {
      label: "Nom",
      dataKey: "name",
      sortable: true,
      searchable: true,
      flexGrow: 1,
    },
    {
      label: "Description",
      dataKey: "description",
      sortable: true,
      searchable: true,
      flexGrow: 3,
    },
    {
      label: "Zone étanche",
      dataKey: "dockId",
      sortable: true,
      searchable: true,
      flexGrow: 3,
      renderButtonActions: hasRights("REF_GARE_WRITE")
        ? renderOptionsButton
        : undefined,
    },
  ];

  return (
    <>
      <TrackFormDialog
        form={formDialog}
        setForm={setFormDialog}
        stationId={stationId}
        docks={docks}
        tracksState={tracksState}
        lanesState={lanesState}
      />
      <Paper className={classes.root}>
        <SortableAndSearchableVirtualizedTable
          rows={tracks.map((track) => ({
            ...track,
            dockId: docksState.getName(track.dockId),
          }))}
          rowCount={tracks.length}
          updateRows={tracksState.set}
          columns={headerLabelTrack}
          selected={selected}
          setSelected={setSelected}
          handleSelected={handleSelected}
          isSelected={isSelected}
          dataInFetching={dataInFetching}
          renderSelectionCheckboxes={hasRights("REF_GARE_WRITE")}
        />
      </Paper>
      {hasRights("REF_GARE_WRITE") && (
        <div>
          <Tooltip title="Ajouter" aria-label="Ajouter" className={classes.fab}>
            <div>
              <Fab
                color="secondary"
                onClick={() =>
                  setFormDialog({
                    open: true,
                    action: "add",
                    submit: add,
                  })
                }
              >
                <AddIcon />
              </Fab>
            </div>
          </Tooltip>
          <Tooltip
            title="Supprimer"
            aria-label="Supprimer"
            className={classes.fab}
          >
            <Fab
              disabled={selected.size === 0}
              onClick={() =>
                confirmDialogDispatch({
                  type: "open",
                  title: "Suppression de voie",
                  msg: "Êtes-vous sûr de vouloir supprimer cette sélection ?",
                  submit: deleteBySelection,
                })
              }
            >
              <DeleteIcon />
            </Fab>
          </Tooltip>
        </div>
      )}
    </>
  );
}

export default TrackTab;

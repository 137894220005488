import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  FormControl,
  TextField,
  List,
  Button,
  FormGroup,
  CircularProgress,
  FormLabel,
  DialogTitle,
  DialogContent,
  DialogActions,
  Theme,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { default as clsx } from "clsx";
import {
  User,
  EUserType,
  postUser,
  updateUser,
} from "../../services/api/users";
import { AvailableUserOptions } from "../../services/api/available-user-options";
import FormCheckbox from "../../components/form/checkbox";
import FormSelect from "../../components/form/select";
import FormCheckboxList, {
  updateArray,
} from "../../components/form/checkbox-list";
import { fetchSncfUser, SncfUser } from "../../services/api/sncf-user";
import { FieldValidator, checkFields } from "../../validators/form-validator";
import isEqual = require("lodash/isEqual");
import {
  isEmpty,
  isEmptyAndTransporterActivity,
  isEmptyOrSncfUser,
  isEmptyOrNationalPerimeter,
  isInvalidSncfUsername,
} from "../../validators/form-validators";
import { AppContext } from "../../app-provider";
import { useSnackbar } from "notistack";
import PasswordInput from "../../components/form/password-input";
import useRights from "../../services/ducks/rights";
import { getConfig } from "../../services/config";
import { AxiosError } from "axios";
import { RequestError } from "services/interfaces/common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonsGroupLayout: {
      display: "flex",
      justifyContent: "flex-end",
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(2),
    },
    buttonLayout: {
      marginLeft: theme.spacing(1.5),
      width: "100px",
    },
    loaderWrapperLayout: {
      display: "flex",
      textAlign: "center",
      flexDirection: "column",
      marginTop: theme.spacing(1.25),
      alignItems: "center",
    },
    postLoaderLayout: {
      position: "absolute",
      top: "0",
      bottom: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 100,
    },
    marginBottom: {
      marginBottom: theme.spacing(4),
    },
    smallMarginBottom: {
      marginBottom: theme.spacing(1.25),
    },
    noMarginTop: {
      marginTop: "0",
    },
    noPadding: {
      padding: "0",
    },
    formLayout: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      minWidth: "80vw",
      margin: "auto",
      flexWrap: "wrap",
    },
    spaceBetween: {
      justifyContent: "space-between",
    },
    formColumnLayout: {
      flex: 1,
      minWidth: "250px",
      padding: theme.spacing(2),
      justifyContent: "flex-start",
      flexWrap: "nowrap",
    },
    mediumWidth: {
      width: "40%",
    },
    noOutline: {
      outline: "none",
    },
  })
);

interface UserFormProperties {
  currentUser: User;
  availableUserOptions: AvailableUserOptions;
  isNewUser: boolean;
  escapeKeyDown: boolean;
  closeModal: () => void;
  handleUser: (user: User) => void;
  setEscapeKeyDown: (bool: boolean) => void;
}

interface Errors {
  username: string;
  lastName: string;
  firstName: string;
  password: string;
  activity: string;
  profile: string;
  perimeter: string;
  transporters: string;
  perimeterElements: string;
}

const validators: FieldValidator<User, Errors> = {
  username: [
    { error: "Veuillez renseigner votre identifiant", validator: isEmpty },
    { error: "Identifiant inconnu", validator: isInvalidSncfUsername },
  ],
  lastName: [
    { error: "Veuillez renseigner votre nom de famille", validator: isEmpty },
  ],
  firstName: [
    { error: "Veuillez renseigner votre prénom", validator: isEmpty },
  ],
  password: [
    {
      error: "Veuillez renseigner un mot de passe",
      validator: isEmptyOrSncfUser,
    },
  ],
  activity: [{ error: "Veuillez choisir une activité", validator: isEmpty }],
  profile: [{ error: "Veuillez choisir un profil", validator: isEmpty }],
  perimeter: [{ error: "Veuillez choisir un périmètre", validator: isEmpty }],
  transporters: [
    {
      error: "Veuillez choisir au moins un transporteur",
      validator: isEmptyAndTransporterActivity,
    },
  ],
  perimeterElements: [
    {
      error: "Veuillez choisir au moins un élément",
      validator: isEmptyOrNationalPerimeter,
    },
  ],
};

const defaultErrors: Errors = {
  username: "",
  lastName: "",
  firstName: "",
  password: "",
  activity: "",
  profile: "",
  perimeter: "",
  transporters: "",
  perimeterElements: "",
};

/**
 * Create new user / modify user form component
 */
function UserForm({
  currentUser,
  availableUserOptions,
  isNewUser,
  closeModal,
  escapeKeyDown,
  setEscapeKeyDown,
  handleUser,
}: UserFormProperties) {
  const [user, setUser] = useState<User>({ ...currentUser });
  const [defaultUser, setDefaultUser] = useState<User>({ ...currentUser });
  const [errors, setErrors] = useState<Errors>(defaultErrors);
  const [isFetchingSncfUser, setIsFetchingSncfUser] = useState<boolean>(false);
  const [isPostingUser, setIsPostingUser] = useState<boolean>(false);
  const [, confirmDialogDispatch] =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useContext(AppContext).reducers.confirmDialog!;
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const {
    hasLevel,
    hasProfile,
    hasTransporter,
    hasStation,
    hasGroup,
    hasHigherLevel,
    hasRoleHierarchyWrite,
  } = useRights();
  const config = getConfig();

  useEffect(() => {
    if (isNewUser) {
      if (!config.LIGHT_MODE && hasProfile("gare_responsable")) {
        const updatedUser = (previousUser: User) => ({
          ...previousUser,
          activity: "GARE",
          perimeter: "STATION",
          profile: "gare_responsable",
          transporters: [],
          perimeterElements: [],
        });
        setUser(updatedUser);
        setDefaultUser(updatedUser);
      }
      if (hasProfile("transporter_responsable")) {
        const updatedUser = (previousUser: User) => ({
          ...previousUser,
          activity: "TRANSPORTER",
          perimeter: hasLevel("NATIONAL") ? "NATIONAL" : "STATION",
          profile: "transporter_responsable",
          transporters: [],
          perimeterElements: filteredPerimeterElements().map((v) => v.id),
        });
        setUser(updatedUser);
        setDefaultUser(updatedUser);
      }
      if (!config.LIGHT_MODE && hasProfile("gc_administrateur")) {
        const updatedUser = (previousUser: User) => ({
          ...previousUser,
          activity: "GC",
          perimeter: hasLevel("NATIONAL") ? "NATIONAL" : "STATION",
          profile: "gc_administrateur",
          transporters: [],
          perimeterElements: [],
        });
        setUser(updatedUser);
        setDefaultUser(updatedUser);
      }
    } else {
      setUser(currentUser);
      // Default user is used to check if any modification has been made
      // before cancelling the modal
      setDefaultUser(currentUser);
    }
  }, [currentUser]);

  const handleCancelClick = useCallback((): void => {
    if (isEqual(defaultUser, user)) {
      closeModal();
    } else {
      confirmDialogDispatch({
        type: "open",
        title: isNewUser
          ? "Nouvel utilisateur"
          : "Modification de l'utilisateur",
        msg: "Êtes-vous sûr de vouloir quitter cette page ? Vos modifications ne seront pas sauvegardées",
        submit: closeModal,
      });
    }
  }, [closeModal, confirmDialogDispatch, currentUser, isNewUser, user]);

  useEffect(() => {
    if (escapeKeyDown) {
      setEscapeKeyDown(false);
      handleCancelClick();
    }
  }, [escapeKeyDown, handleCancelClick, setEscapeKeyDown]);

  const loadSncfUser = useCallback(
    async (username: string): Promise<void> => {
      try {
        if (user.type === EUserType.SNCF && user.username) {
          setIsFetchingSncfUser(true);
          const sncfUserData: SncfUser = await fetchSncfUser(username);
          setUser({
            ...user,
            lastName: sncfUserData.sn,
            firstName: sncfUserData.givenName,
          });
          setErrors({
            ...errors,
            lastName: "",
            firstName: "",
            username: "",
          });
        }
      } catch {
        setErrors({ ...errors, username: "Identifiant inconnu" });
        setUser({
          ...user,
          lastName: "",
          firstName: "",
        });
      } finally {
        setIsFetchingSncfUser(false);
      }
    },
    [errors, user]
  );

  useEffect(() => {
    if (isNewUser && user.type === EUserType.SNCF) {
      setUser((previousUser) => ({
        ...previousUser,
        lastName: "",
        firstName: "",
        password: "",
      }));
      loadSncfUser(user.username);
    } else {
      setErrors({ ...errors, username: "" });
    }
  }, [user.type]);

  const perimeterItems: {
    [key: string]: {
      name: string;
      id: string;
    }[];
  } = {
    STATION_GROUP: availableUserOptions.stationGroups,
    STATION: availableUserOptions.stations,
    NATIONAL: [],
  };

  const handleSncfUserChange = (): void => {
    setErrors({ ...errors, lastName: "", firstName: "", password: "" });
    setUser({
      ...user,
      type: user.type === EUserType.SNCF ? EUserType.LOCAL : EUserType.SNCF,
    });
  };

  const handleActiveUserChange = (): void => {
    setUser({ ...user, active: !user.active });
  };

  const handleTextChange = (
    event: React.FormEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    setUser({
      ...user,
      [event.currentTarget.dataset.key as string]: event.currentTarget.value,
    });
  };

  const handleUsernameBlur = async (
    event: React.FormEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ): Promise<void> => {
    await loadSncfUser(event.currentTarget.value);
  };

  const handleActivityChange = (activity: string): void => {
    switch (activity) {
      case user.activity: {
        return;
      }
      case "GC": {
        setUser((previousUser) => ({
          ...previousUser,
          activity,
          perimeter: hasLevel("NATIONAL") ? "NATIONAL" : "STATION",
          profile: "gc_administrateur",
          transporters: [],
          perimeterElements: [],
        }));
        break;
      }
      case "GARE": {
        setUser((previousUser) => ({
          ...previousUser,
          activity,
          perimeter: "STATION",
          profile: "gare_responsable",
          transporters: [],
          perimeterElements: [],
        }));
        break;
      }
      case "TRANSPORTER": {
        setUser((previousUser) => ({
          ...previousUser,
          activity,
          perimeter: hasLevel("NATIONAL") ? "NATIONAL" : "STATION",
          profile: "transporter_responsable",
          transporters: [],
          perimeterElements: filteredPerimeterElements().map((v) => v.id),
        }));
        break;
      }
    }
    setErrors({ ...errors, transporters: "", perimeterElements: "" });
  };

  const handleProfileChange = (profile: string): void => {
    setUser({ ...user, profile: profile });
  };

  const handlePerimeterChange = (perimeter: string): void => {
    if (perimeter === user.perimeter) {
      return;
    }
    const perimeterElements: string[] = [];
    setUser({
      ...user,
      perimeter: perimeter,
      perimeterElements: perimeterElements,
    });
    setErrors({ ...errors, perimeterElements: "" });
  };

  const handleTransportersChange = (
    event: React.MouseEvent<HTMLDivElement>
  ): void => {
    const newTransporters: string[] = updateArray(
      event.currentTarget.dataset["value"] as string,
      user.transporters
    );
    setUser({ ...user, transporters: newTransporters });
  };

  const handlePerimeterElementsChange = (
    event: React.MouseEvent<HTMLDivElement>
  ): void => {
    const newPerimeterElements: string[] = updateArray(
      event.currentTarget.dataset["value"] as string,
      user.perimeterElements
    );
    setUser({ ...user, perimeterElements: newPerimeterElements });
  };

  const handleSaveClick = async (): Promise<void> => {
    try {
      if (validateUserForm()) {
        if (isEqual(user, defaultUser)) {
          closeModal();
        } else {
          setIsPostingUser(true);
          const result = isNewUser
            ? await postUser(user)
            : await updateUser(user);
          closeModal();
          enqueueSnackbar(
            `Utilisateur ${isNewUser ? "créé" : "modifié"} avec succès`,
            { variant: "success" }
          );
          handleUser(result);
          setIsPostingUser(false);
        }
      }
    } catch (error_: unknown) {
      const error = error_ as AxiosError<RequestError>;
      if (error.response?.data.reason === "UserAlreadyExists") {
        setErrors({
          ...defaultErrors,
          username: "Cet utilisateur existe déjà",
        });
        setIsPostingUser(false);
      } else {
        enqueueSnackbar(
          `Erreur lors de la ${
            isNewUser ? "création" : "modification"
          } de l'utilisateur`,
          { variant: "error" }
        );
        closeModal();
      }
    }
  };

  // eslint-disable-next-line unicorn/consistent-function-scoping
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const validateUserForm = (): boolean => {
    const errors = checkFields<User, Errors>(validators, defaultErrors, user);
    setErrors(errors);
    return isEqual(errors, defaultErrors);
  };

  const filteredPerimeterElements = () => {
    if (hasLevel("NATIONAL")) {
      return perimeterItems[user.perimeter] || [];
    }
    if (user.perimeter === "STATION") {
      return (perimeterItems[user.perimeter] || []).filter((perimeter) =>
        hasStation(perimeter.id)
      );
    }
    return (perimeterItems[user.perimeter] || []).filter((perimeter) =>
      hasGroup(perimeter.id)
    );
  };

  const filteredPerimeters = () => {
    if (
      (!hasHigherLevel(user.perimeter) && !isNewUser) ||
      hasLevel("NATIONAL")
    ) {
      return availableUserOptions.perimeters;
    }
    if (hasLevel("STATION_GROUP")) {
      return availableUserOptions.perimeters.filter(
        (perimeter) => perimeter.id !== "NATIONAL"
      );
    }
    return availableUserOptions.perimeters.filter(
      (perimeter) => perimeter.id === "STATION"
    );
  };

  return (
    <>
      <DialogTitle>
        {isNewUser ? "Nouvel utilisateur" : "Modification de l'utilisateur"}
      </DialogTitle>
      <DialogContent className={classes.noOutline}>
        <form
          className={classes.formLayout}
          autoComplete="off"
          onSubmit={handleFormSubmit}
        >
          {isPostingUser && (
            <div className={classes.postLoaderLayout}>
              <CircularProgress size={80} />
            </div>
          )}
          <FormGroup className={classes.formColumnLayout}>
            <FormGroup row className={classes.spaceBetween}>
              <FormControl className={classes.noPadding}>
                <List>
                  <FormCheckbox
                    handleOnChange={handleSncfUserChange}
                    label="Utilisateur SNCF"
                    checked={user.type === EUserType.SNCF}
                    disabled={
                      !isNewUser ||
                      isFetchingSncfUser ||
                      isPostingUser ||
                      config.LIGHT_MODE
                    }
                  />
                </List>
              </FormControl>
              <FormControl className={classes.marginBottom}>
                <List>
                  <FormCheckbox
                    handleOnChange={handleActiveUserChange}
                    label="Utilisateur Actif"
                    checked={user.active}
                    disabled={isFetchingSncfUser || isPostingUser}
                  />
                </List>
              </FormControl>
            </FormGroup>
            <FormControl className={classes.marginBottom}>
              <TextField
                inputProps={{ "data-key": "username" }}
                label="Identifiant"
                value={user.username}
                onChange={handleTextChange}
                onBlur={handleUsernameBlur}
                disabled={!isNewUser || isFetchingSncfUser || isPostingUser}
                error={errors.username !== ""}
                helperText={errors.username}
              />
              {isFetchingSncfUser && (
                <div className={classes.loaderWrapperLayout}>
                  <FormLabel className={classes.smallMarginBottom}>
                    Chargement du profil SNCF
                  </FormLabel>
                  <CircularProgress className={classes.smallMarginBottom} />
                </div>
              )}
            </FormControl>
            {!isFetchingSncfUser &&
              (user.type === EUserType.LOCAL || user.lastName !== "") && (
                <FormGroup
                  row
                  className={clsx(
                    classes.spaceBetween,
                    classes.marginBottom,
                    classes.noMarginTop
                  )}
                >
                  <FormControl className={classes.mediumWidth}>
                    <TextField
                      inputProps={{ "data-key": "lastName" }}
                      label="Nom"
                      value={user.lastName}
                      disabled={
                        user.type === EUserType.SNCF ||
                        isFetchingSncfUser ||
                        isPostingUser
                      }
                      onChange={handleTextChange}
                      error={errors.lastName !== ""}
                      helperText={errors.lastName}
                    />
                  </FormControl>
                  <FormControl className={classes.mediumWidth}>
                    <TextField
                      inputProps={{ "data-key": "firstName" }}
                      label="Prénom"
                      value={user.firstName}
                      disabled={
                        user.type === EUserType.SNCF ||
                        isFetchingSncfUser ||
                        isPostingUser
                      }
                      onChange={handleTextChange}
                      error={errors.firstName !== ""}
                      helperText={errors.firstName}
                    />
                  </FormControl>
                </FormGroup>
              )}
            {user.type === EUserType.LOCAL && (
              <FormControl className={classes.marginBottom}>
                <PasswordInput
                  onChange={handleTextChange}
                  inputProps={{ "data-key": "password" }}
                  value={user.password}
                  disabled={isFetchingSncfUser || isPostingUser}
                  error={errors.password}
                />
              </FormControl>
            )}
          </FormGroup>
          <FormGroup className={classes.formColumnLayout}>
            <FormGroup row className={classes.spaceBetween}>
              <FormControl
                className={clsx(classes.marginBottom, classes.mediumWidth)}
              >
                <FormSelect
                  handleOnChange={handleActivityChange}
                  availableOptions={availableUserOptions.activities}
                  value={user.activity}
                  disabled={
                    !hasProfile("gc_administrateur") ||
                    isFetchingSncfUser ||
                    isPostingUser
                  }
                  label="Activité"
                  error={errors.activity}
                />
              </FormControl>
              <FormControl
                className={clsx(classes.marginBottom, classes.mediumWidth)}
              >
                <FormSelect
                  handleOnChange={handleProfileChange}
                  availableOptions={availableUserOptions.profiles.filter(
                    (profile) =>
                      profile.activity === user.activity &&
                      !profile.name.startsWith("Application") &&
                      hasRoleHierarchyWrite(profile.id)
                  )}
                  value={user.profile}
                  disabled={
                    user.activity === "" || isFetchingSncfUser || isPostingUser
                  }
                  label="Profil"
                  error={errors.profile}
                />
              </FormControl>
            </FormGroup>
            <FormControl>
              {user.activity && user.activity === "TRANSPORTER" && (
                <FormCheckboxList
                  handleOnChange={handleTransportersChange}
                  availableOptions={
                    hasProfile("transporter_responsable")
                      ? availableUserOptions.transporters.filter(
                          (transporter) => hasTransporter(transporter.id)
                        )
                      : availableUserOptions.transporters
                  }
                  label="Transporteurs"
                  checkedElements={user.transporters}
                  disabled={isFetchingSncfUser || isPostingUser}
                  error={errors.transporters}
                  height="225px"
                />
              )}
            </FormControl>
          </FormGroup>
          <FormGroup className={classes.formColumnLayout}>
            <FormControl className={classes.marginBottom}>
              <FormSelect
                handleOnChange={handlePerimeterChange}
                availableOptions={filteredPerimeters()}
                value={user.perimeter}
                label="Périmètre géographique"
                disabled={
                  isFetchingSncfUser ||
                  isPostingUser ||
                  !hasHigherLevel(user.perimeter)
                }
                error={errors.perimeter}
              />
            </FormControl>
            <FormControl>
              <FormCheckboxList
                handleOnChange={handlePerimeterElementsChange}
                availableOptions={filteredPerimeterElements()}
                label="Sélection de périmètre"
                checkedElements={user.perimeterElements}
                disabled={isFetchingSncfUser || isPostingUser}
                error={errors.perimeterElements}
                height="225px"
              />
            </FormControl>
          </FormGroup>
        </form>
      </DialogContent>
      <DialogActions className={classes.buttonsGroupLayout}>
        <Button
          variant="contained"
          onClick={handleCancelClick}
          className={classes.buttonLayout}
          disabled={isFetchingSncfUser || isPostingUser}
        >
          Annuler
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSaveClick}
          className={classes.buttonLayout}
          disabled={isFetchingSncfUser || isPostingUser}
          autoFocus
        >
          Valider
        </Button>
      </DialogActions>
    </>
  );
}

export default UserForm;

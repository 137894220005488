import { Theme } from "@material-ui/core";
import React, { ReactNode } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
      minHeight: "100px",
      overflow: "hidden",
    },
    typography: {
      display: "flex",
      flexDirection: "column",
      width: theme.spacing(70),
      margin: theme.spacing(0.5),
    },
  })
);

interface SimplePopover {
  children: ReactNode;
  anchorElement: Element | ((element: Element) => Element) | null | undefined;
  preview?: object;
}

export default function SimplePopover({
  children,
  anchorElement,
  preview,
}: SimplePopover) {
  const classes = useStyles();

  return (
    <Popover
      id="simple-mouse-over-popover"
      className={classes.popover}
      classes={{ paper: classes.paper }}
      open={preview !== undefined && anchorElement !== null}
      anchorEl={anchorElement}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      disableRestoreFocus
      disableAutoFocus={true}
      disableEnforceFocus={true}
      transitionDuration={0}
    >
      <Typography className={classes.typography} component="div">
        {children}
      </Typography>
    </Popover>
  );
}

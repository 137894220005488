import React, { useState, ReactNode, useContext, useEffect } from "react";
import {
  Paper,
  FormControl,
  Button,
  TextField,
  useMediaQuery,
  FormLabel,
  Box,
} from "@material-ui/core";
import { default as clsx } from "clsx";
import { useLoginStyle } from "./styles";
import {
  redirectOpenAM,
  useAuthApi,
  setAxiosInterceptor,
  getAuthCodeFromRedirect,
} from "../../../services/ducks/auth";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { AppContext } from "../../../app-provider";
import Loader from "../../../components/loader";
import PasswordInput from "../../../components/form/password-input";
import useRights from "../../../services/ducks/rights";
import useVersions from "../../../services/ducks/versions";
import { fetchBotStatus } from "../../../services/api/versions";
import { getConfig } from "../../../services/config";

interface LoginManagerProperties {
  children: ReactNode;
}
const translateError = (error: string) => {
  const errorMapper = [
    {
      error: "Invalid login information",
      field: "password",
      text: "Informations de connexion incorrectes",
    },
    {
      error: "TokenExpiredException",
      field: "error",
      text: "Votre session a expiré",
    },
  ];

  return (
    errorMapper.find((entry) => error.includes(entry.error)) || {
      field: "error",
      text: error,
    }
  );
};

type IPropertiesWithRouter = LoginManagerProperties & RouteComponentProps;

function LoginManager({ children, history }: IPropertiesWithRouter) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [authContext] = useContext(AppContext).reducers.auth!;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ field: "", text: "" });
  const matches = useMediaQuery("(min-width:462px)");
  const loginStyle = useLoginStyle();
  const { getUserInfo } = useRights();
  const { setVersion } = useVersions();
  const config = getConfig();

  // api d'authent connectée au context + accès à l'history pour les redirects
  const authApi = useAuthApi(history);

  const currentUser = getUserInfo();
  useEffect(() => {
    const fetchAndSetBotVersion = async () => {
      try {
        const currentBotStatus = await fetchBotStatus();
        if (currentBotStatus && config) {
          setVersion("bot", currentBotStatus.version);
          setVersion("ihm", config.REACT_APP_VERSION);
        }
      } catch (error_) {
        console.log(error_);
      }
    };
    fetchAndSetBotVersion();
  }, []);

  // met à jour les callback de refresh token avec les token à jour
  useEffect(() => {
    if (authContext.tokenExpirationDate) {
      setAxiosInterceptor(authApi.refreshAuthToken, authApi.logout);
    }
  }, [
    authApi.logout,
    authApi.refreshAuthToken,
    authContext.credentials,
    authContext.tokenExpirationDate,
  ]);

  useEffect(() => {
    if (authContext.authError) {
      setError(translateError(authContext.authError));
    } else {
      setError({ field: "", text: "" });
    }
  }, [authContext]);

  const openAMAuthCode = getAuthCodeFromRedirect();

  useEffect(() => {
    if (!openAMAuthCode) {
      setLoading(true);
      authApi.tryLoadSavedUser().then(() => setLoading(false));
    }
  }, []);

  // si un utilisateur est connecté
  if (currentUser && currentUser.name) {
    return <>{children}</>;
  }

  // si authent en cours
  if (loading) {
    return (
      <div className={clsx(loginStyle.loader)}>
        <Loader />
      </div>
    );
  }

  // authentification après redirection vers l'idp openam
  if (openAMAuthCode) {
    setLoading(true);
    authApi.loginWithOpenAMCode(openAMAuthCode, () => setLoading(false));
  }

  const handleLoginWithCredentials = () => {
    if (username === "") {
      setError({
        field: "username",
        text: "Veuillez renseigner votre nom d'utilisateur",
      });
      return;
    }
    if (password === "") {
      setError({
        field: "password",
        text: "Veuillez renseigner votre mot de passe",
      });
      return;
    }
    authApi.loginWithCredentials(username, password, () => {
      setUsername("");
      setPassword("");
    });
  };

  const handleEnterPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      handleLoginWithCredentials();
    }
  };

  // sinon formulaire d'authentification
  return (
    <div className={loginStyle.rootContainer}>
      <Paper className={loginStyle.container}>
        <div className={loginStyle.title}>Authentification</div>
        {error && error.field === "error" && (
          <Box
            display="flex"
            justifyContent="center"
            style={{ marginBottom: "12px" }}
          >
            <FormLabel error={true}>{error.text}</FormLabel>
          </Box>
        )}
        <div className={loginStyle.rowContainer}>
          {!config.LIGHT_MODE && (
            <div
              className={loginStyle.columnContainer}
              onKeyPress={handleEnterPress}
            >
              <FormControl className={loginStyle.formInput}>
                <TextField
                  label="Utilisateur"
                  value={username}
                  onChange={(event) => setUsername(event.currentTarget.value)}
                  error={error && error.field === "username"}
                  helperText={
                    error && error.field === "username" ? error.text : ""
                  }
                />
              </FormControl>
              <FormControl className={loginStyle.formInput}>
                <PasswordInput
                  onChange={(event) => setPassword(event.currentTarget.value)}
                  value={password}
                  autoComplete="current-password"
                  error={error && error.field === "password" ? error.text : ""}
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth={true}
                variant="contained"
                color="primary"
                onClick={handleLoginWithCredentials}
                className={loginStyle.submit}
              >
                Connexion
              </Button>
            </div>
          )}
          {matches && !config.LIGHT_MODE && (
            <div className={loginStyle.verticalSeparator} />
          )}
          <div className={loginStyle.columnContainer}>
            {(matches || config.LIGHT_MODE) && (
              <div
                style={{ flex: 1, display: "flex", justifyContent: "center" }}
              >
                <img
                  src={"/logo_sncf.svg"}
                  alt={"SNCF Logo"}
                  width={220}
                  style={{ alignSelf: "center", marginBottom: "24px" }}
                />
              </div>
            )}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => redirectOpenAM()}
                className={
                  config.LIGHT_MODE
                    ? loginStyle.submitSncfSingle
                    : loginStyle.submitSncf
                }
              >
                Connexion Utilisateur SNCF
              </Button>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default withRouter(LoginManager);

import React, { useState } from "react";
import deburr from "lodash/deburr";
import Autosuggest, {
  SuggestionsFetchRequestedParams,
} from "react-autosuggest";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { Theme } from "@material-ui/core/styles";
import makeStyles from "@material-ui/core/styles/makeStyles";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function renderInputComponent(inputProperties: any): React.ReactNode {
  const { classes, inputRef, ref, ...other } = inputProperties;
  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: (node) => {
          ref(node);
          if (inputRef) {
            inputRef(node);
          }
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
}

function renderSuggestion(
  suggestion: string,
  { query, isHighlighted }: Autosuggest.RenderSuggestionParams
) {
  const matches = match(suggestion, query);
  const parts = parse(suggestion, matches);

  return (
    <MenuItem selected={isHighlighted}>
      <div>
        {parts.map((part) => (
          <span
            key={part.text}
            style={{ fontWeight: part.highlight ? 500 : 400 }}
          >
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
}

interface InputWithAutosuggestProperties {
  suggestions: Set<string>;
  id: string;
  label: string;
  error?: boolean;
  helperText?: React.ReactNode;
  value?: string;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    ce: Autosuggest.ChangeEvent
  ) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    position: "relative",
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
  divider: {
    height: theme.spacing(2),
  },
}));

function TextFieldWithSuggest({
  suggestions,
  id,
  label,
  error,
  helperText,
  value = "",
  onChange,
}: InputWithAutosuggestProperties) {
  const [stateSuggestions, setSuggestions] = useState<string[]>([]);
  const classes = useStyles();

  function getSuggestions(v: string) {
    const inputValue = deburr(v.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return [...suggestions].filter((suggestion) => {
      const keep =
        count < 5 &&
        suggestion.slice(0, inputLength).toLowerCase() === inputValue;
      if (keep) {
        count += 1;
      }
      return keep;
    });
  }

  const handleSuggestionsFetchRequested = (
    input: SuggestionsFetchRequestedParams
  ) => {
    setSuggestions(getSuggestions(input.value));
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const autosuggestProperties = {
    renderInputComponent,
    suggestions: stateSuggestions,
    onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
    onSuggestionsClearRequested: handleSuggestionsClearRequested,
    shouldRenderSuggestions: () => true,
    getSuggestionValue: (suggestion: string) => suggestion,
    renderSuggestion,
  };

  return (
    <div className={classes.root}>
      <Autosuggest
        {...autosuggestProperties}
        inputProps={{
          classes,
          id,
          label,
          value,
          error,
          helperText,
          onChange,
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
        renderSuggestionsContainer={(options) => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
      />
    </div>
  );
}

export default TextFieldWithSuggest;

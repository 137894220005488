import React from "react";
import { Grid, Box } from "@material-ui/core";
import { PassListValidation } from "./pass-list-validation";

interface PassListErrorsProperties {
  validation: PassListValidation;
}

export function PassListErrors({ validation }: PassListErrorsProperties) {
  return (
    <Grid item xs={4}>
      <Box display={"flex"} flexDirection={"column"} height={"100%"}>
        <Box flexBasis={"100%"} color={"error.main"}>
          {validation.hasErrors && <h4>Le format de la liste est incorrect</h4>}
          {validation.incorrect && validation.incorrect.length > 0 && (
            <p>
              {validation.incorrect.length} valeur
              {validation.incorrect.length > 1 && "s"} ne respecte
              {validation.incorrect.length > 1 && "nt"} pas le format :
              {validation.incorrect.join(" ; ")}
            </p>
          )}
          {validation.duplicates && validation.duplicates.length > 0 && (
            <p>
              {validation.duplicates.length} valeur
              {validation.duplicates.length > 1 && "s"}
              {validation.duplicates.length > 1 ? " sont " : " est "}
              dupliquée
              {validation.duplicates.length > 1 && "s"}
              {" : "}
              {validation.duplicates.join(" ; ")}
            </p>
          )}
        </Box>
      </Box>
    </Grid>
  );
}

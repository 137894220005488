import React, { useRef, useState } from "react";
import FormSelectList from "../../components/form/select-list";
import {
  BundleAvailableVersions,
  VersionInfo,
} from "../../services/api/deployments";
import ConsultConfigurationDialogContent from "pages/configurations/consult-configuration-dialog-content";
import { DialogTitle } from "@material-ui/core";
import SimplePopover from "../../components/popover/simple-popover";
import VersionSelectListElement from "./version-select-list-element";

interface VersionSelectListProperties {
  versions: BundleAvailableVersions[];
  selectedVersions: { [key: string]: string | undefined };
  setSelectedVersions: (selectedVersions: {
    [key: string]: string | undefined;
  }) => void;
  label: string;
  groups?: string | string[];
  additionalClassName?: string;
}

function VersionSelectList({
  versions,
  selectedVersions,
  setSelectedVersions,
  label,
  groups,
  additionalClassName,
}: VersionSelectListProperties) {
  let displayedVersions = versions;
  const anchorElementReference = useRef(null);

  const [preview, setPreview] = useState<VersionInfo | undefined>();
  if (groups) {
    displayedVersions = versions.filter((version) =>
      typeof groups === "string"
        ? version.info.group === groups
        : groups.includes(version.info.group)
    );
  }

  return (
    <>
      {preview && (
        <SimplePopover
          preview={preview}
          anchorElement={anchorElementReference.current}
        >
          <DialogTitle>
            {preview.name} - v{preview.version}
          </DialogTitle>
          <ConsultConfigurationDialogContent
            author={preview.author}
            creationDatetime={preview.creationDate || ""}
            standalone={preview.standalone}
            testVersion={preview.testVersion}
            description={preview.description}
          />
        </SimplePopover>
      )}
      <FormSelectList label={label} additionalClassName={additionalClassName}>
        {displayedVersions.map((v) => {
          // Sorts versions by creation date if present
          if (
            v.versions.every((version) => version.creationDate !== undefined)
          ) {
            v.versions.sort(
              (a, b) =>
                Date.parse(b.creationDate || "") -
                Date.parse(a.creationDate || "")
            );
          }
          return (
            <VersionSelectListElement
              key={v.info.fullName}
              reference={anchorElementReference}
              selectedVersions={selectedVersions}
              setSelectedVersions={setSelectedVersions}
              v={v}
              setPreview={setPreview}
            />
          );
        })}
      </FormSelectList>
    </>
  );
}

export default VersionSelectList;

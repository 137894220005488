import React, { useContext, useEffect, useState, useCallback } from "react";
import { Paper, Theme, Chip } from "@material-ui/core";
import { Station } from "../../../services/interfaces/referential";
import {
  exportStations,
  fetchStations,
  importStations,
  updateStationEquipped,
} from "../../../services/api/stations";
import Fab from "@material-ui/core/Fab";
import createStyles from "@material-ui/core/styles/createStyles";
import FilterListIcon from "@material-ui/icons/FilterList";
import Tooltip from "@material-ui/core/Tooltip";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Dropzone from "react-dropzone";
import { AppContext } from "../../../app-provider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Switch from "@material-ui/core/Switch";
import { useSnackbar } from "notistack";
import SortableAndSearchableVirtualizedTable from "../../../components/virtualized-table/sortable-and-searchable-virtualized-table";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import useRights from "../../../services/ducks/rights";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
    },
    fab: {
      margin: theme.spacing(1),
    },
    rightFab: {
      margin: theme.spacing(1),
      float: "right",
    },
  })
);

/**
 * GateTab Component
 */
function StationTab() {
  const classes = useStyles();
  const [stations, setStations] = useState<Station[]>([]);
  const [equippedStationsFilter, setEquippedStationsFilter] =
    useState<boolean>(true);
  const [, confirmDialogDispatch] =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useContext(AppContext).reducers.confirmDialog!;
  const [dataInFetching, setDataInFetching] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { hasRights, hasLevel } = useRights();

  const fetchAndSetStations = useCallback(async () => {
    try {
      setDataInFetching(true);
      setStations(await fetchStations(equippedStationsFilter));
      setDataInFetching(false);
    } catch {
      enqueueSnackbar(`Erreur lors de la récupération des gares`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, equippedStationsFilter]);

  useEffect(() => {
    fetchAndSetStations();
  }, [equippedStationsFilter, fetchAndSetStations]);

  /**
   * Renders
   */

  const importData = async (acceptedFiles: File[]) => {
    confirmDialogDispatch({
      type: "open",
      title: "Import de gares",
      msg: "Êtes-vous sûr de vouloir importer ces gares ?",
      submit: async () => {
        // TODO appel API pour importer les gares
        try {
          await importStations(acceptedFiles[0]);
          enqueueSnackbar(`Gares importées avec succès`, {
            variant: "success",
          });
          fetchAndSetStations();
        } catch {
          enqueueSnackbar(`Erreur lors de l'import des gares`, {
            variant: "error",
          });
        }
      },
    });
  };

  const exportData = () => {
    try {
      exportStations();
    } catch {
      enqueueSnackbar(`Erreur lors de l'export des gares`, {
        variant: "error",
      });
    }
  };

  const toggleEquipped = (station: Station) => {
    const cloneStation = { ...station };
    confirmDialogDispatch({
      type: "open",
      title: cloneStation.id + " - " + cloneStation.name,
      msg:
        "Êtes-vous sûr de vouloir " +
        (cloneStation.equipped ? "déséquiper" : "équiper") +
        " cette station ?",
      submit: async () => {
        try {
          cloneStation.equipped = !cloneStation.equipped;
          await updateStationEquipped(cloneStation);
          enqueueSnackbar(`Status "équipé" de la gare modifié avec succès`, {
            variant: "success",
          });
          setStations(
            stations.map((s) => {
              if (s.id === cloneStation.id) {
                s = cloneStation;
              }
              return s;
            })
          );
        } catch {
          enqueueSnackbar(
            `Erreur lors de la modification du status "équipé" de la gare`,
            {
              variant: "error",
            }
          );
        }
      },
    });
  };

  const headerLabelStations = [
    {
      label: "Nom",
      dataKey: "name",
      flexGrow: 2,
      sortable: true,
      searchable: true,
    },
    { label: "Code QLT", dataKey: "id", sortable: true, searchable: true },
    { label: "Id TT0020", dataKey: "sqills", sortable: true, searchable: true },
    { label: "Code UIC", dataKey: "uic", sortable: true, searchable: true },
    {
      label: "Équipé",
      dataKey: "equipped",
      sortable: true,
      width: 100,
      renderer: (station: Station) => {
        if (hasRights("REF_GEO_GARE_WRITE") && hasLevel("NATIONAL")) {
          return (
            <Switch
              checked={station.equipped}
              onChange={() => toggleEquipped(station)}
              color="secondary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          );
        }
        return (
          <Chip
            label={station.equipped ? "Oui" : "Non"}
            icon={station.equipped ? <DoneIcon /> : <ClearIcon />}
            color={station.equipped ? "secondary" : "default"}
          />
        );
      },
    },
  ];

  const filterLabel = () =>
    equippedStationsFilter
      ? "Filtré par stations équipées"
      : "Filtrer par stations équipées";

  return (
    <>
      <Paper className={classes.root}>
        <SortableAndSearchableVirtualizedTable
          renderSelectionCheckboxes={false}
          rows={stations}
          rowCount={stations.length}
          updateRows={setStations}
          columns={headerLabelStations}
          dataInFetching={dataInFetching}
        />
      </Paper>
      <div>
        {hasLevel("NATIONAL") && (
          <Tooltip
            title={filterLabel()}
            aria-label={filterLabel()}
            className={classes.fab}
          >
            <Fab
              color={equippedStationsFilter ? "secondary" : "primary"}
              onClick={() => setEquippedStationsFilter(!equippedStationsFilter)}
            >
              <FilterListIcon />
            </Fab>
          </Tooltip>
        )}
        {hasRights("REF_GEO_GARE_EXPORT") && (
          <Tooltip
            title="Export"
            aria-label="Export"
            className={classes.rightFab}
          >
            <Fab color="secondary" onClick={exportData}>
              <CloudDownloadIcon />
            </Fab>
          </Tooltip>
        )}
        {hasRights("REF_GEO_GARE_IMPORT") && hasLevel("NATIONAL") && (
          <div className={classes.rightFab}>
            <Dropzone
              noDrag
              accept={{
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                  [".xlsx"],
              }}
              multiple={false}
              onDrop={importData}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <Tooltip title="Import" aria-label="Import">
                    <Fab color="primary">
                      <CloudUploadIcon />
                    </Fab>
                  </Tooltip>
                </div>
              )}
            </Dropzone>
          </div>
        )}
      </div>
    </>
  );
}

export default StationTab;

import React from "react";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { Chip, Theme, makeStyles } from "@material-ui/core";

interface StatusChip {
  active: boolean;
}

const useStyles = makeStyles((_theme: Theme) => ({
  statusChipLayout: {
    width: "80px",
    alignSelf: "center",
  },
}));

function StatusChip({ active }: StatusChip) {
  const classes = useStyles();
  if (active) {
    return (
      <Chip
        className={classes.statusChipLayout}
        label="Actif"
        icon={<DoneIcon />}
        color="secondary"
      />
    );
  }
  return (
    <Chip
      className={classes.statusChipLayout}
      label="Inactif"
      icon={<ClearIcon />}
    />
  );
}

export default StatusChip;

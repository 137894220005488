import React, { useContext, useState } from "react";
import {
  addLane,
  deleteLane,
  deleteLanes,
  fetchLanes,
  updateLane,
} from "../../../services/api/lanes";
import {
  Dock,
  Gate,
  Lane,
  Track,
  Zone,
} from "../../../services/interfaces/referential";
import OptionsButton from "../../../components/options-button";
import { Paper, Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { AppContext } from "../../../app-provider";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import LaneFormDialog from "./dialog/lane-form-dialog";
import ChipsRenderer from "../../../components/virtualized-table/renderers/chips-renderer";
import { PriorityHigh } from "@material-ui/icons";
import { fetchGates } from "../../../services/api/gates";
import { UseCrud } from "../../../commons/use-crud";
import { fetchZones } from "../../../services/api/zones";
import { fetchDocks } from "../../../services/api/docks";
import { useSnackbar } from "notistack";
import PopoverWrapperRenderer from "../../../components/virtualized-table/renderers/popover-wrapper-renderer";
import SortableAndSearchableVirtualizedTable from "../../../components/virtualized-table/sortable-and-searchable-virtualized-table";
import useRights from "../../../services/ducks/rights";

interface LaneTabProperties {
  stationId: string;
  gatesState: UseCrud<Gate>;
  lanesState: UseCrud<Lane>;
  tracksState: UseCrud<Track>;
  docksState: UseCrud<Dock>;
  zonesState: UseCrud<Zone>;
  dataInFetching: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
    },
    fab: {
      float: "right",
      margin: theme.spacing(1),
    },
  })
);

export interface LaneFormState {
  open: boolean;
  action?: "add" | "edit";
  data?: Lane;
  submit?: (lane: Lane) => void | Promise<void>;
}

/**
 * LaneTab Component
 */
function LaneTab({
  stationId,
  gatesState,
  lanesState,
  tracksState,
  docksState,
  zonesState,
  dataInFetching,
}: LaneTabProperties) {
  const classes = useStyles();
  const { hasRights } = useRights();

  const gates = gatesState.datas;
  const lanes = lanesState.datas;
  const docks = docksState.datas;
  const zones = zonesState.datas;
  const [selected, setSelected] = useState<Set<string>>(new Set([]));
  const [, confirmDialogDispatch] =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useContext(AppContext).reducers.confirmDialog!;
  const [formDialog, setFormDialog] = useState<LaneFormState>({ open: false });
  const { enqueueSnackbar } = useSnackbar();

  // ----------- ReactVirtualizedTable
  const handleSelected = (lane: Lane, checked: boolean) => {
    const updateSelected = new Set(selected);
    checked ? updateSelected.add(lane.id) : updateSelected.delete(lane.id);
    setSelected(updateSelected);
  };

  const isSelected = (lane: Lane): boolean => {
    return selected.has(lane.id);
  };

  // ----------- api actions

  async function deleteById(rowIndex: number) {
    handleSelected(lanes[rowIndex], false);
    try {
      await deleteLane(lanes[rowIndex].id);
      enqueueSnackbar(`Ligne "${lanes[rowIndex].name}" supprimée avec succès`, {
        variant: "success",
      });
      lanesState.remove(lanes[rowIndex].id);
      if (gates.some((g) => g.laneId === lanes[rowIndex].id)) {
        fetchGates(stationId).then((newGates) => gatesState.set(newGates));
      }
      if (zones.some((z) => z.laneIds.includes(lanes[rowIndex].id))) {
        fetchZones(stationId).then((newZones) => zonesState.set(newZones));
      }
      if (
        docks.some((d) =>
          d.trackLaneDTOList.some((t) => t.laneId === lanes[rowIndex].id)
        )
      ) {
        fetchDocks(stationId).then((newDocks) => docksState.set(newDocks));
      }
    } catch {
      enqueueSnackbar(`Erreur lors de la suppression de la ligne`, {
        variant: "error",
      });
    }
  }

  async function deleteBySelection() {
    try {
      await deleteLanes(selected);
      enqueueSnackbar(
        selected.size > 0
          ? `Lignes supprimées avec succès`
          : `Ligne "${selected.values().next().value}" supprimée avec succès`,
        { variant: "success" }
      );
      lanesState.removeAll(selected);
      if (gates.some((g) => [...selected].includes(g.laneId))) {
        fetchGates(stationId).then((newGates) => gatesState.set(newGates));
      }
      if (zones.some((z) => [...selected].some((r) => z.laneIds.includes(r)))) {
        fetchZones(stationId).then((newZones) => zonesState.set(newZones));
      }
      if (
        docks.some((d) =>
          [...selected].some((r) =>
            d.trackLaneDTOList.some((t) => t.laneId === r)
          )
        )
      ) {
        fetchDocks(stationId).then((newDocks) => docksState.set(newDocks));
      }
      setSelected(new Set([]));
    } catch {
      enqueueSnackbar(
        selected.size > 0
          ? `Erreur lors de la suppression des lignes`
          : `Erreur lors de la suppression de la ligne`,
        {
          variant: "error",
        }
      );
    }
  }

  async function add(lane: Lane) {
    try {
      lanesState.add(await addLane(lane));
      enqueueSnackbar(`Ligne créée avec succès`, { variant: "success" });
      fetchLanes(stationId).then((l) => lanesState.set(l));
      fetchDocks(stationId).then((d) => docksState.set(d));
      if (lane.gateIds.length > 0) {
        fetchGates(stationId).then((g) => gatesState.set(g));
      }
    } catch {
      enqueueSnackbar(`Erreur lors de la création de la ligne`, {
        variant: "error",
      });
    }
  }

  async function edit(lane: Lane) {
    try {
      const oldLane = lanes.find((l) => l.id === lane.id);
      lanesState.update(await updateLane(lane));
      enqueueSnackbar(`Ligne modifiée avec succès`, {
        variant: "success",
      });
      fetchDocks(stationId).then((d) => docksState.set(d));
      if (
        // Si les deux listes de portes sont différentes
        oldLane &&
        (oldLane.gateIds.some((x) => !lane.gateIds.includes(x)) ||
          lane.gateIds.some((x) => !oldLane.gateIds.includes(x)))
      ) {
        fetchGates(stationId).then((g) => gatesState.set(g));
      }
      if (
        // Si les deux listes de trackLanes sont différentes
        oldLane &&
        (oldLane.trackLaneDTOList.some(
          (x) => !lane.trackLaneDTOList.includes(x)
        ) ||
          lane.trackLaneDTOList.some(
            (x) => !oldLane.trackLaneDTOList.includes(x)
          ))
      ) {
        fetchLanes(stationId).then((l) => lanesState.set(l));
      }
    } catch {
      enqueueSnackbar(`Erreur lors de la modification de la ligne`, {
        variant: "error",
      });
    }
  }

  // ----------- renders

  const renderOptionsButton = (rowIndex: number) => {
    const buttonActions = [
      {
        label: "Modifier les informations",
        handler: () => {
          setFormDialog({
            open: true,
            action: "edit",
            data: { ...lanes[rowIndex] },
            submit: (lane: Lane) => edit(lane),
          });
        },
      },
      {
        label: "Supprimer la ligne",
        handler: () =>
          confirmDialogDispatch({
            type: "open",
            title: "Suppression de ligne",
            msg:
              'Êtes-vous sûr de vouloir supprimer la ligne "' +
              lanes[rowIndex].name +
              '" ?',
            submit: () => deleteById(rowIndex),
          }),
      },
    ];

    return <OptionsButton buttonActions={buttonActions} />;
  };

  function hasInactiveGates(lane: Lane) {
    for (const gateId of lane.gateIds) {
      const gate = gates.find((g) => g.id === gateId);
      if (gate !== undefined && !gate.active) {
        return true;
      }
    }
    return false;
  }

  const headerLabelLane = [
    {
      label: "Nom",
      dataKey: "name",
      sortable: true,
      searchable: true,
      renderer: (lane: Lane) => {
        return (
          <>
            <span>{lane.name}</span>
            {hasInactiveGates(lane) && <PriorityHigh color="error" />}
          </>
        );
      },
    },
    {
      label: "Description",
      dataKey: "description",
      sortable: true,
      searchable: true,
    },
    {
      label: "Portes",
      dataKey: "gateIds",
      renderer: (lane: Lane) => (
        <PopoverWrapperRenderer>
          <ChipsRenderer
            elts={dataInFetching ? [] : gatesState.getNames(lane.gateIds)}
          />
        </PopoverWrapperRenderer>
      ),
    },
    {
      label: "Zone étanche",
      dataKey: "dockId",
      sortable: true,
      searchable: true,
      renderButtonActions: hasRights("REF_GARE_WRITE")
        ? renderOptionsButton
        : undefined,
    },
  ];

  return (
    <>
      <LaneFormDialog
        form={formDialog}
        setForm={setFormDialog}
        stationId={stationId}
        gates={gates}
        docks={docks}
        tracksState={tracksState}
        lanesState={lanesState}
      />
      <Paper className={classes.root}>
        <SortableAndSearchableVirtualizedTable
          rows={lanes.map((lane) => ({
            ...lane,
            dockId: docksState.getName(lane.dockId),
          }))}
          rowCount={lanes.length}
          updateRows={lanesState.set}
          columns={headerLabelLane}
          selected={selected}
          setSelected={setSelected}
          isSelected={isSelected}
          dataInFetching={dataInFetching}
          handleSelected={handleSelected}
          renderSelectionCheckboxes={hasRights("REF_GARE_WRITE")}
        />
      </Paper>
      {hasRights("REF_GARE_WRITE") && (
        <div>
          <Tooltip title="Ajouter" aria-label="Ajouter" className={classes.fab}>
            <Fab
              color="secondary"
              onClick={() =>
                setFormDialog({
                  open: true,
                  action: "add",
                  submit: add,
                })
              }
            >
              <AddIcon />
            </Fab>
          </Tooltip>
          <Tooltip
            title="Supprimer"
            aria-label="Supprimer"
            className={classes.fab}
          >
            <div>
              <Fab
                disabled={selected.size === 0}
                onClick={() =>
                  confirmDialogDispatch({
                    type: "open",
                    title: "Suppression de ligne",
                    msg: "Êtes-vous sûr de vouloir supprimer cette sélection ?",
                    submit: deleteBySelection,
                  })
                }
              >
                <DeleteIcon />
              </Fab>
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
}

export default LaneTab;

import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Theme,
} from "@material-ui/core";
import { Group, Station } from "../../../../services/interfaces/referential";
import FormCheckboxList, {
  updateArray,
} from "../../../../components/form/checkbox-list";
import {
  checkFields,
  FieldValidator,
} from "../../../../validators/form-validator";
import { isEmpty } from "../../../../validators/form-validators";
import createStyles from "@material-ui/core/styles/createStyles";
import isEqual = require("lodash/isEqual");
import FormControl from "@material-ui/core/FormControl";
import TextFieldWithSuggest from "../../../../components/form/text-field-with-suggest";
import classNames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { GroupFormState } from "../group-tab";

interface GroupFormDialog {
  form: GroupFormState;
  setForm: (status: GroupFormState) => void;
  stations: Station[];
  types: Set<string>;
}

const groupInitialValue: Group = {
  id: "",
  name: "",
  type: "",
  stationIds: [],
};

interface Errors {
  name: string;
  type: string;
}

const validators: FieldValidator<Group, Errors> = {
  name: [{ error: "Veuillez renseigner un nom", validator: isEmpty }],
  type: [{ error: "Veuillez renseigner un type", validator: isEmpty }],
};

const defaultErrors: Errors = {
  name: "",
  type: "",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginTopField: {
      marginTop: theme.spacing(2),
    },
    dialogPaper: {
      overflowY: "visible",
    },
    buttonsGroupLayout: {
      display: "flex",
      justifyContent: "flex-end",
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(2),
    },
  })
);

function GroupFormDialog({ form, setForm, stations, types }: GroupFormDialog) {
  const classes = useStyles();
  const [data, setData] = useState<Group>({
    ...groupInitialValue,
    stationIds: [...groupInitialValue.stationIds],
  });
  const [errors, setErrors] = useState<Errors>(defaultErrors);

  useEffect(() => {
    if (form.open) {
      setErrors(defaultErrors);
      if (form.data === undefined) {
        setData({
          ...groupInitialValue,
          stationIds: [...groupInitialValue.stationIds],
        });
      } else {
        setData({ ...form.data });
      }
    }
  }, [form.data, form.open]);

  function close() {
    setForm({ ...form, open: false });
  }

  function save() {
    if (validateData()) {
      if (form.open && form.submit !== undefined) {
        form.submit({ ...data });
      }
      close();
    }
  }

  function handleStationIdsChange(event: React.MouseEvent<HTMLDivElement>) {
    const stationIds: string[] = updateArray(
      event.currentTarget.dataset["value"] as string,
      data.stationIds
    );
    setData({ ...data, stationIds });
  }

  const validateData = (): boolean => {
    const errors = checkFields<Group, Errors>(validators, defaultErrors, data);
    setErrors(errors);
    return isEqual(errors, defaultErrors);
  };

  return (
    <Dialog
      open={form.open}
      onEscapeKeyDown={close}
      onBackdropClick={close}
      color={"primary"}
      aria-labelledby="form-dialog-title"
      PaperProps={{ className: classNames(classes.dialogPaper) }}
    >
      <DialogTitle id="form-dialog-title">
        {form.action === "add"
          ? "Créer un groupe de gare"
          : "Modifier un groupe de gare"}
      </DialogTitle>
      <DialogContent>
        <FormControl variant="standard" fullWidth margin="dense">
          <TextField
            id="name"
            label="Nom"
            type="text"
            value={data.name || ""}
            onChange={(event) => setData({ ...data, name: event.target.value })}
            error={errors.name !== ""}
            helperText={errors.name}
          />
        </FormControl>
        <FormControl variant="standard" fullWidth margin="dense">
          <TextFieldWithSuggest
            id="Type"
            label="Type"
            value={data.type || ""}
            onChange={(_event, { newValue }) =>
              setData({ ...data, type: newValue })
            }
            error={errors.type !== ""}
            helperText={errors.type}
            suggestions={types}
          />
        </FormControl>
        <div className={classes.marginTopField}>
          <FormCheckboxList
            label="Stations"
            handleOnChange={handleStationIdsChange}
            availableOptions={stations.map((s) => ({
              id: s.id,
              name: s.id + " - " + s.name,
            }))}
            checkedElements={data.stationIds}
            noFixedHeight
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.buttonsGroupLayout}>
        <Button onClick={close} variant="contained">
          Annuler
        </Button>
        <Button onClick={save} color="secondary" autoFocus variant="contained">
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default GroupFormDialog;

import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import { Button, ClickAwayListener } from "@material-ui/core";
import { AppContext } from "../../../app-provider";
import { AccountCircle } from "@material-ui/icons";
import classNames from "classnames";
import Avatar from "./avatar";
import { SNCF_MAIN_DARK_VIOLET, SNCF_MAIN_VIOLET } from "../../../theme";
import useRights from "../../../services/ducks/rights";
import useVersions from "../../../services/ducks/versions";
import UpdatePasswordDialog from "./update-password-dialog";
import { EUserType } from "../../../services/api/users";

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
  },
  accountButtonIcon: {
    width: "40px",
    height: "40px",
  },
  accountButtonContainer: {
    color: "#ffffff",
    marginLeft: "auto",
    backgroundColor: "rgba(0,0,0,0)",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0)",
    },
    border: "none",
  },
  popup: {
    zIndex: 99_999,
  },
  userInfoContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const useUserInfoStyle = makeStyles((theme) => ({
  userInfoItem: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "15px",
    marginTop: "15px",
  },
  userInfoTitle: {},
  userInfoValue: {
    fontWeight: "bold",
    marginLeft: "10px",
  },
  separator: {
    height: "1px",
    backgroundColor: SNCF_MAIN_DARK_VIOLET,
  },
  userAvatar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "125px",
    height: "125px",
    margin: "auto",
  },
  container: {},
  updatePasswordButtonContainer: {
    padding: "5px 25px 20px 25px",
  },
  updatePasswordButton: {},
  logoutButtonContainer: {
    padding: "20px 25px 12px 25px",
  },
  logoutButton: {
    backgroundColor: SNCF_MAIN_DARK_VIOLET,
    "&:hover": {
      backgroundColor: SNCF_MAIN_VIOLET,
    },
  },
  versionsSeparator: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textAlign: "center",
  },
}));

interface UserInfoProperties {
  updatePassword: boolean;
  setUpdatePassword: (update: boolean) => void;
}

function UserInfo({ updatePassword, setUpdatePassword }: UserInfoProperties) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [, dispatch] = useContext(AppContext).reducers.auth!;
  const classes = useUserInfoStyle();
  const { removeUserInfo, getUserInfo } = useRights();
  const { getVersion } = useVersions();

  const logout = () => {
    removeUserInfo();
    dispatch({
      type: "LOGOUT",
    });
  };
  const currentUserInfo = getUserInfo();

  return (
    <div className={classNames(classes.container)}>
      <div className={classNames(classes.userAvatar)}>
        <Avatar user={currentUserInfo} />
      </div>
      <div className={classNames(classes.separator)} />
      <div className={classNames(classes.userInfoItem)}>
        <div className={classNames(classes.userInfoTitle)}>Utilisateur : </div>
        <div className={classNames(classes.userInfoValue)}>
          {currentUserInfo.name}
        </div>
      </div>
      <div className={classNames(classes.userInfoItem)}>
        <div className={classNames(classes.userInfoTitle)}>Profil : </div>
        <div className={classNames(classes.userInfoValue)}>
          {currentUserInfo.profileName}
        </div>
      </div>
      <div className={classNames(classes.userInfoItem)}>
        <div className={classNames(classes.userInfoTitle)}>Version BOT : </div>
        <div className={classNames(classes.userInfoValue)}>
          {getVersion("bot") || "-"}
        </div>
        <span className={classes.versionsSeparator}> | </span>
        <div className={classNames(classes.userInfoTitle)}>Version IHM : </div>
        <div className={classNames(classes.userInfoValue)}>
          {getVersion("ihm") || "-"}
        </div>
      </div>
      {!currentUserInfo.type.includes("SNCF") && (
        <div className={classNames(classes.updatePasswordButtonContainer)}>
          <Button
            type="submit"
            fullWidth={true}
            variant="contained"
            color="primary"
            className={classNames(classes.updatePasswordButton)}
            onClick={() => setUpdatePassword(true)}
          >
            Changer le mot de passe
          </Button>
        </div>
      )}
      <div className={classNames(classes.separator)} />
      <div className={classNames(classes.logoutButtonContainer)}>
        <Button
          type="submit"
          fullWidth={true}
          variant="contained"
          color="primary"
          className={classNames(classes.logoutButton)}
          onClick={() => logout()}
        >
          Déconnexion
        </Button>
      </div>
      {currentUserInfo.type === EUserType.LOCAL && (
        <UpdatePasswordDialog
          onClose={() => setUpdatePassword(false)}
          open={updatePassword}
        />
      )}
    </div>
  );
}

export default function AccountPopper() {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement>();
  const [updatePassword, setUpdatePassword] = useState<boolean>(false);

  const classes = useStyles();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorElement((previous) =>
      previous ? undefined : event.currentTarget
    );
  };

  const open = Boolean(anchorElement);
  const id = open ? "no-transition-popper" : undefined;

  const handleClickAway = () => {
    if (!updatePassword) {
      setAnchorElement(undefined);
    }
  };
  return (
    <>
      <Button
        aria-describedby={id}
        type="button"
        onClick={handleClick}
        className={classNames(classes.accountButtonContainer)}
      >
        <AccountCircle className={classNames(classes.accountButtonIcon)} />
      </Button>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorElement}
        className={classNames(classes.popup)}
      >
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper className={classNames(classes.userInfoContainer)}>
            <UserInfo
              updatePassword={updatePassword}
              setUpdatePassword={setUpdatePassword}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
}

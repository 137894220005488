import React, { useContext, useEffect, useState } from "react";
import { Paper, Theme } from "@material-ui/core";
import { Group, Station } from "../../../services/interfaces/referential";
import OptionsButton from "../../../components/options-button";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import createStyles from "@material-ui/core/styles/createStyles";
import {
  addGroup,
  deleteGroup,
  deleteGroups,
  fetchGroups,
  fetchGroupTypes,
  updateGroup,
} from "../../../services/api/groups";
import Tooltip from "@material-ui/core/Tooltip";
import GroupFormDialog from "./dialog/group-form-dialog";
import { AppContext } from "../../../app-provider";
import { fetchStations } from "../../../services/api/stations";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useSnackbar } from "notistack";
import ChipsRenderer from "../../../components/virtualized-table/renderers/chips-renderer";
import PopoverWrapperRenderer from "../../../components/virtualized-table/renderers/popover-wrapper-renderer";
import SortableAndSearchableVirtualizedTable from "../../../components/virtualized-table/sortable-and-searchable-virtualized-table";
import useRights from "../../../services/ducks/rights";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
    },
    fab: {
      float: "right",
      margin: theme.spacing(1),
    },
  })
);

export interface GroupFormState {
  open: boolean;
  action?: "add" | "edit";
  data?: Group;
  submit?: (group: Group) => void | Promise<void>;
}

/**
 * GroupTab Component
 */
function GroupTab() {
  const classes = useStyles();
  const [stations, setStations] = useState<Station[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [types, setTypes] = useState<Set<string>>(new Set());
  const [selected, setSelected] = useState<Set<string>>(new Set([]));
  const [, confirmDialogDispatch] =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useContext(AppContext).reducers.confirmDialog!;
  const [formDialog, setFormDialog] = useState<GroupFormState>({
    open: false,
  });
  const [dataInFetching, setDataInFetching] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { hasRights, hasStation, hasLevel } = useRights();

  useEffect(() => {
    try {
      setDataInFetching(true);
      Promise.all([fetchStations(true), fetchGroups(), fetchGroupTypes()]).then(
        (values) => {
          setStations(values[0]);
          setGroups(values[1]);
          setTypes(values[2]);
          setDataInFetching(false);
        }
      );
    } catch {
      enqueueSnackbar(`Erreur lors de la récupération des groupes de gare`, {
        variant: "error",
      });
    }
  }, [enqueueSnackbar]);

  // ----------- ReactVirtualizedTable

  const handleSelected = (group: Group, checked: boolean) => {
    const updateSelected = new Set(selected);
    checked ? updateSelected.add(group.id) : updateSelected.delete(group.id);
    setSelected(updateSelected);
  };

  const isSelected = (group: Group): boolean => {
    return selected.has(group.id);
  };

  // ----------- api actions

  async function deleteById(rowIndex: number) {
    handleSelected(groups[rowIndex], false);
    try {
      await deleteGroup(groups[rowIndex].id);
      enqueueSnackbar(
        `Groupe de gare "${groups[rowIndex].name}" supprimé avec succès`,
        {
          variant: "success",
        }
      );
      setGroups(
        groups.filter((group: Group) => group.id !== groups[rowIndex].id)
      );
      setTypes(await fetchGroupTypes());
    } catch {
      enqueueSnackbar(`Erreur lors de la suppression du groupe de gare`, {
        variant: "error",
      });
    }
  }

  async function deleteBySelection() {
    try {
      await deleteGroups(selected);
      enqueueSnackbar(
        selected.size > 0
          ? `Groupes de gare supprimés avec succès`
          : `Groupe de gare "${
              selected.values().next().value
            }" supprimé avec succès`,
        { variant: "success" }
      );
      setGroups(groups.filter((s) => !selected.has(s.id)));
      setSelected(new Set([]));
      setTypes(await fetchGroupTypes());
    } catch {
      enqueueSnackbar(
        selected.size > 0
          ? `Erreur lors de la suppression des groupes de gare`
          : `Erreur lors de la suppression du groupe de gare`,
        {
          variant: "error",
        }
      );
    }
  }

  async function add(group: Group) {
    try {
      setGroups([...groups, await addGroup(group)]);
      enqueueSnackbar(`Groupe de gare créé avec succès`, {
        variant: "success",
      });
      setTypes(await fetchGroupTypes());
    } catch {
      enqueueSnackbar(`Erreur lors de la création du groupe de gare`, {
        variant: "error",
      });
    }
  }

  async function edit(group: Group, rowIndex: number) {
    const rows = [...groups];
    try {
      rows[rowIndex] = await updateGroup(group);
      setGroups(rows);
      enqueueSnackbar(`Groupe de gare modifié avec succès`, {
        variant: "success",
      });
      setTypes(await fetchGroupTypes());
    } catch {
      enqueueSnackbar(`Erreur lors de la modification du groupe de gare`, {
        variant: "error",
      });
    }
  }

  const renderButtonActions = (rowIndex: number) => {
    if (
      hasLevel("NATIONAL") ||
      groups[rowIndex].stationIds.every((stationId) => hasStation(stationId))
    ) {
      const buttonActions = [];
      buttonActions.push(
        {
          label: "Modifier les informations",
          handler: () => {
            setFormDialog({
              open: true,
              action: "edit",
              data: { ...groups[rowIndex] },
              submit: (group: Group) => edit(group, rowIndex),
            });
          },
        },
        {
          label: "Supprimer le groupe",
          handler: () =>
            confirmDialogDispatch({
              type: "open",
              title: "Suppression de groupe de gare",
              msg:
                'Êtes-vous sûr de vouloir supprimer le groupe "' +
                groups[rowIndex].name +
                '" de type "' +
                groups[rowIndex].type +
                '" ?',
              submit: () => deleteById(rowIndex),
            }),
        }
      );
      return <OptionsButton buttonActions={buttonActions} />;
    }

    return <></>;
  };

  const headerLabelStations = [
    {
      label: "Nom",
      dataKey: "name",
      flexGrow: 1,
      sortable: true,
      searchable: true,
    },
    {
      label: "Type de groupe",
      dataKey: "type",
      flexGrow: 1,
      sortable: true,
      searchable: true,
    },
    {
      label: "Gares associées",
      dataKey: "stationIds",
      flexGrow: 2,
      renderer: (group: Group) => (
        <PopoverWrapperRenderer>
          <ChipsRenderer elts={group.stationIds} />
        </PopoverWrapperRenderer>
      ),
      renderButtonActions: hasRights("REF_GEO_GROUP_WRITE")
        ? renderButtonActions
        : undefined,
    },
  ];

  return (
    <>
      <GroupFormDialog
        form={formDialog}
        setForm={setFormDialog}
        stations={stations}
        types={types}
      />
      <Paper className={classes.root}>
        <SortableAndSearchableVirtualizedTable
          rows={groups}
          rowCount={groups.length}
          updateRows={setGroups}
          columns={headerLabelStations}
          selected={selected}
          setSelected={setSelected}
          handleSelected={handleSelected}
          isSelected={isSelected}
          dataInFetching={dataInFetching}
          renderSelectionCheckboxes={hasRights("REF_GEO_GROUP_WRITE")}
        />
      </Paper>
      {hasRights("REF_GEO_GROUP_WRITE") && (
        <div>
          <Tooltip title="Ajouter" aria-label="Ajouter" className={classes.fab}>
            <Fab
              color="secondary"
              onClick={() =>
                setFormDialog({
                  open: true,
                  action: "add",
                  submit: add,
                })
              }
            >
              <AddIcon />
            </Fab>
          </Tooltip>
          <Tooltip
            title="Supprimer"
            aria-label="Supprimer"
            className={classes.fab}
          >
            <div>
              <Fab
                disabled={selected.size === 0}
                onClick={() =>
                  confirmDialogDispatch({
                    type: "open",
                    title: "Suppression de groupe de gare",
                    msg: "Êtes-vous sûr de vouloir supprimer cette sélection ?",
                    submit: deleteBySelection,
                  })
                }
              >
                <DeleteIcon />
              </Fab>
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
}

export default GroupTab;

import React, { useState } from "react";
import {
  FormHelperText,
  FormLabel,
  List,
  Theme,
  Box,
  Input,
  InputAdornment,
  IconButton,
  Typography,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import FormCheckbox from "./checkbox";
import ClearIcon from "@material-ui/icons/Clear";
import { TopLevelPanel } from "components/expandable-table/top-level-panel";
import { BoardingModesTransporter } from "services/interfaces/referential";

export interface CheckboxListOption {
  name: string;
  id: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    smallMarginBottom: {
      marginBottom: theme.spacing(1),
    },
    listLayout: {
      display: "flex",
      flexDirection: "column",
      overflowX: "hidden",
    },
    optionsContainer: {
      height: "200px",
      borderRadius: "5px",
      border: "1px solid #DCDCDC",
      overflowY: "auto",
      background: "#DCDCDC",
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
        borderRadius: "5px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        backgroundColor: "#B7B7B7",
      },
    },
  })
);

interface Properties<T> {
  handleOnChange: (
    event: React.MouseEvent<HTMLDivElement>,
    parameters: string
  ) => void;
  availableOptions: {
    [key: string]: {
      [key in keyof T]: boolean;
    };
  };
  hide?: boolean;
  label: string;
  optionsLabelMapping?: {
    [key: string]: string;
  };
  checkedElements: T[];
  disabled?: boolean;
  error?: string;
  height?: string;
  noFixedHeight?: boolean;
}

export const updateArray = (value: string, array: string[]): string[] => {
  const array_ = [...array];
  if (array_.includes(value)) {
    array_.splice(array_.indexOf(value), 1);
  } else {
    array_.push(value);
  }
  return array_;
};

/**
 * Accordion checkbox list component for form
 */
function AccordionCheckBoxList<T extends BoardingModesTransporter>({
  handleOnChange,
  availableOptions,
  hide = false,
  optionsLabelMapping,
  label,
  checkedElements,
  disabled,
  error,
}: Properties<T>) {
  const [search, setSearch] = useState<string>("");
  const classes = useStyles();

  if (hide || !availableOptions || Object.keys(availableOptions).length === 0) {
    return <></>;
  }

  const handleSearchChange = (
    event: React.FormEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    setSearch(event.currentTarget.value);
  };

  const handleClearSearch = () => {
    setSearch("");
  };

  const computeElementList = (
    root: [string, { [key in keyof T]: boolean }],
    checkedElements: T[]
  ) => {
    return Object.entries(root[1])
      .filter(
        (option) =>
          option[0] &&
          option[1] == true &&
          option[0].toLowerCase().includes(search.toLowerCase())
      )
      .map((option) => {
        return (
          <FormCheckbox
            handleOnChange={(event) => handleOnChange(event, root[0])}
            value={option[0]}
            key={option[0]}
            label={
              optionsLabelMapping &&
              Object.keys(optionsLabelMapping).includes(option[0])
                ? optionsLabelMapping[option[0]]
                : option[0]
            }
            checked={
              checkedElements.some((checkedElement) => {
                return (
                  (Object.keys(checkedElement).includes(option[0]) &&
                    checkedElement[option[0] as keyof T] == true) ||
                  false
                );
              }) || false
            }
            disabled={disabled}
          />
        );
      });
  };

  const contentRenderer = (root: [string, { [key in keyof T]: boolean }]) => {
    return (
      <>
        <List
          className={classes.listLayout}
          style={{
            height: "auto",
            maxHeight: "215px",
            width: "100%",
            padding: 0,
          }}
        >
          {Object.values(root[1]).some((mode) => mode == true) ? (
            computeElementList(
              root,
              checkedElements.filter(
                (element) => element["transporterName"] == root[0]
              )
            )
          ) : (
            <Typography
              style={{ margin: "auto", alignSelf: "center" }}
              variant="body1"
            >
              AUCUN RESULTAT
            </Typography>
          )}
        </List>
        {error !== undefined && error !== "" && (
          <FormHelperText error={error !== undefined && error !== ""}>
            {error}
          </FormHelperText>
        )}
      </>
    );
  };

  return (
    <>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        display="flex"
        alignItems="center"
        className={classes.smallMarginBottom}
        marginTop={"30px"}
      >
        <FormLabel error={error !== undefined && error !== ""}>
          {label}
        </FormLabel>
        <Input
          type="text"
          value={search}
          onChange={handleSearchChange}
          placeholder="Recherche"
          style={{ width: "40%", marginTop: 0 }}
          endAdornment={
            search !== "" && (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Clear search"
                  onClick={handleClearSearch}
                  size="small"
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            )
          }
        />
      </Box>
      <Box className={classes.optionsContainer}>
        {Object.entries(availableOptions).map((root, index) => {
          return (
            <TopLevelPanel
              key={index}
              root={root}
              titleRenderer={() => <div>{root[0]}</div>}
              contentRenderer={contentRenderer}
              index={index}
            />
          );
        })}
      </Box>
    </>
  );
}

export default AccordionCheckBoxList;

import React from "react";
import { Grid, Box, withStyles, TextField } from "@material-ui/core";
import { PassListFormImport } from "./pass-list-form-import";
import { PassListFormExport } from "./pass-list-form-export";

const BrighterTextField = withStyles({
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
})(TextField);

interface PassListFormProperties {
  listName?: string;
  keyName?: string;
  passList: string[];
  onChange: (passList: string[]) => void;
  canEdit?: boolean;
  error?: boolean;
}

export function PassListForm({
  listName = "list",
  keyName = "key",
  passList,
  onChange,
  canEdit = false,
  error = false,
}: PassListFormProperties) {
  const handleImport = (importedValues: string[]) => {
    onChange(importedValues);
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.value !== undefined) {
      const passListString = event.target.value as string;
      const passListSplit = passListString.split("\n");
      onChange(passListSplit);
    }
  };

  return (
    <>
      <Grid item xs={4}>
        <BrighterTextField
          multiline
          label="Numéros de pass"
          value={passList.join("\n")}
          onChange={handleValueChange}
          rows={13}
          variant="outlined"
          fullWidth
          disabled={!canEdit}
          error={error}
        />
        <Box display={"flex"} justifyContent={"flex-end"} my={1}>
          {canEdit && <PassListFormImport onImport={handleImport} />}
          <PassListFormExport
            listName={listName}
            keyName={keyName}
            passList={passList}
          />
        </Box>
      </Grid>
    </>
  );
}

import React from "react";
import { makeStyles } from "@material-ui/styles";
import { DialogContentText } from "@material-ui/core";
import DateFormatter from "components/date-formatter";

const useStyles = makeStyles({
  row: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    flex: 1,
  },
  info: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  element: {
    margin: "5px",
    color: "#000000",
    fontSize: "0.85rem",
  },
  description: {
    margin: "5px",
    color: "#000000",
    fontSize: "0.85rem",
  },
  elementLabel: {
    margin: "5px",
    fontSize: "0.85rem",
  },
});

interface ConsultConfigurationDialogContentProperties {
  author?: string;
  creationDatetime: string;
  standalone?: boolean;
  testVersion?: boolean;
  description?: string;
}

function ConsultConfigurationDialogContent({
  author,
  creationDatetime,
  standalone,
  testVersion,
  description,
}: ConsultConfigurationDialogContentProperties) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.row}>
        <div className={classes.info}>
          <DialogContentText className={classes.elementLabel}>
            Auteur
          </DialogContentText>
          <DialogContentText className={classes.element}>
            {author}
          </DialogContentText>
        </div>
        <div className={classes.info}>
          <DialogContentText className={classes.elementLabel}>
            Date de création
          </DialogContentText>
          <DialogContentText className={classes.element}>
            <DateFormatter datetime={creationDatetime} />
          </DialogContentText>
        </div>
      </div>
      <div className={classes.row}>
        {standalone != undefined && (
          <div className={classes.info}>
            <DialogContentText className={classes.elementLabel}>
              Mode standalone
            </DialogContentText>
            <DialogContentText className={classes.element}>
              {standalone ? "Oui" : "Non"}
            </DialogContentText>
          </div>
        )}
        <div className={classes.info}>
          <DialogContentText className={classes.elementLabel}>
            Version de test
          </DialogContentText>
          <DialogContentText className={classes.element}>
            {testVersion ? "Oui" : "Non"}
          </DialogContentText>
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.info}>
          <DialogContentText className={classes.elementLabel}>
            Description
          </DialogContentText>
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.info}>
          <DialogContentText
            className={classes.description}
            style={{ whiteSpace: "pre-line", overflowWrap: "anywhere" }}
          >
            {description}
          </DialogContentText>
        </div>
      </div>
    </>
  );
}

export default ConsultConfigurationDialogContent;

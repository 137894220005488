import React, { useState } from "react";
import DateFormatter from "components/date-formatter";
import OptionsButton from "components/options-button";
import { Tab } from "components/tabs-component";
import fileDownload from "js-file-download";
import { WhitelistElementDTO, WhitelistDTO } from "services/api/whitelists";
import useRights from "../../services/ducks/rights";
import { RenderedWhitelist, RenderedWhitelistElement } from ".";
import { DeleteWhitelistElementConfirmationModal } from "./delete-whitelist-element-confirmation-modal";
import { TabKey } from "./hooks/use-tabs";
import { default as useWhitelistStyles } from "./hooks/use-whitelist-styles";

interface WhitelistElementRowProperties {
  whitelist: RenderedWhitelist;
  whitelistElement: RenderedWhitelistElement;
  rowIndex: number;
  selectedTab: Tab;
  onDeletedWhitelistElement: (whitelistElement: WhitelistElementDTO) => void;
  onViewWhitelistElement: (
    whitelist: WhitelistDTO,
    whitelistElement: WhitelistElementDTO
  ) => void;
  onEditWhitelistElement: (
    whitelist: WhitelistDTO,
    whitelistElement: WhitelistElementDTO
  ) => void;
}

export const WhitelistElementRow: React.FC<WhitelistElementRowProperties> = ({
  whitelist,
  whitelistElement,
  rowIndex: _rowIndex,
  selectedTab,
  onDeletedWhitelistElement,
  onViewWhitelistElement,
  onEditWhitelistElement,
}) => {
  const [deletingWhitelistElement, setDeletingWhitelistElement] =
    useState<WhitelistElementDTO>();

  const { hasRights, hasLevel } = useRights();
  const classes = useWhitelistStyles();

  const actions = {
    view: {
      label: "Consulter",
      handler: () => onViewWhitelistElement(whitelist, whitelistElement),
    },
    edit: {
      label: "Modifier",
      props: {
        disabled: whitelist.locked,
      },
      handler: () => onEditWhitelistElement(whitelist, whitelistElement),
    },
    download: {
      label: "Télécharger",
      handler: () => {
        fileDownload(
          whitelistElement.content,
          [...whitelist.name, " - ", whitelistElement.name, ".json"].join("")
        );
      },
    },
    delete: {
      label: "Supprimer",
      props: {
        disabled: whitelist.locked,
      },
      handler: () => {
        setDeletingWhitelistElement(whitelistElement);
      },
    },
  };
  const authorizedActions = [];

  // filter contextual actions
  if (
    (hasRights("WL_UG_VALUE_READ") && TabKey.UG === selectedTab.key) ||
    (hasRights("WL_TRANSPORTER_VALUE_READ") &&
      TabKey.TRANSPORTER === selectedTab.key)
  )
    authorizedActions.push(actions.view);
  if (
    (hasRights("WL_UG_VALUE_UPDATE") && TabKey.UG === selectedTab.key) ||
    (hasRights("WL_TRANSPORTER_VALUE_UPDATE") &&
      TabKey.TRANSPORTER === selectedTab.key)
  )
    authorizedActions.push(actions.edit);
  if (
    hasLevel("NATIONAL") &&
    ((hasRights("WL_UG_VALUE_DOWNLOAD") && TabKey.UG === selectedTab.key) ||
      (hasRights("WL_TRANSPORTER_VALUE_DOWNLOAD") &&
        TabKey.TRANSPORTER === selectedTab.key))
  )
    authorizedActions.push(actions.download);
  if (
    hasLevel("NATIONAL") &&
    ((hasRights("WL_UG_VALUE_DELETE") && TabKey.UG === selectedTab.key) ||
      (hasRights("WL_TRANSPORTER_VALUE_DELETE") &&
        TabKey.TRANSPORTER === selectedTab.key))
  )
    authorizedActions.push(actions.delete);

  const onCloseDeleteWhitelistElementModal = (deleted: boolean) => {
    if (deleted && deletingWhitelistElement) {
      onDeletedWhitelistElement(deletingWhitelistElement);
    }
    setDeletingWhitelistElement(undefined);
  };

  return (
    <>
      <div className={classes.columnName}>{whitelistElement.name}</div>
      <div className={classes.columnFormat}>Editeur</div>
      <div className={classes.columnUpdate}>
        <DateFormatter datetime={whitelistElement.updatedAt} />
      </div>
      {authorizedActions.length > 0 && (
        <div className={classes.columnActions}>
          <OptionsButton
            buttonActions={authorizedActions}
            buttonClassName={classes.buttonAction}
          />
        </div>
      )}

      {deletingWhitelistElement && (
        <DeleteWhitelistElementConfirmationModal
          whitelistElementId={deletingWhitelistElement.id}
          whitelistType={TabKey[whitelist.type]}
          whitelistTransporter={whitelist.transporter || undefined}
          onClose={onCloseDeleteWhitelistElementModal}
        />
      )}
    </>
  );
};

import { useEffect, useState } from "react";
import { Station } from "../../../services/interfaces/referential";
import { fetchEquippedStations } from "../../../services/api/stations";
import useRights from "../../../services/ducks/rights";
import { useSnackbar } from "notistack";

function useStations(): Station[] {
  const [stations, setStations] = useState<Station[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { hasStation } = useRights();

  useEffect(() => {
    (async () => {
      try {
        const result = await fetchEquippedStations();
        const stations = result.filter((station) => hasStation(station.id));
        setStations(stations);
      } catch {
        enqueueSnackbar(`Erreur lors de la récupération des stations`, {
          variant: "error",
        });
      }
    })();
  }, [enqueueSnackbar]);

  return stations;
}

export default useStations;

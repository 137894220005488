import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { GateRow } from "./deployment-tab";
import {
  BundleAvailableVersions,
  createDeployment,
  fetchAvailableVersions,
} from "../../services/api/deployments";
import { TextField, Theme, Box, FormHelperText } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { SNCF_RED } from "../../theme";
import Loader from "../../components/loader";
import { useSnackbar } from "notistack";
import isEmpty = require("lodash/isEmpty");
import ItemElementsList from "../../components/list/item-element-list";
import VersionSelectList from "./version-select-list";
import { default as clsx } from "clsx";
import { format, isBefore, parseISO } from "date-fns";

interface CreateDialogProperties {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void | Promise<void>;
  gates: GateRow[];
  type: string;
}

const mappingNameSortValue: { [key: string]: number } = {
  // Configuration
  "gate-config-technical": 1000,
  "gate-config-business": 900,
  "gate-config-transporter-eurail": 400,
  "gate-config-transporter-ouigo": 600,
  "gate-config-transporter-voyages": 800,
  "gate-config-transporter-tnu": 200,
  "gate-config-transporter-jc": 300,
  "gate-config-transporter-ter": 500,
  "gate-config-transporter-europe": 700,
  "gate-config-transporter-trenitalia": 100,
  // Software
  rfidvalidator: 900,
  gate: 1000,
  "gate-daemon": 950,
  "plugin-ticketprocessor-ter": 860,
  "plugin-ticketprocessor-ryo": 820,
  "plugin-ticketprocessor-tnu": 830,
  "plugin-ticketprocessor-resarail": 890,
  "plugin-ticketprocessor-europe": 880,
  "plugin-ticketprocessor-ouigo": 870,
  "plugin-ticketprocessor-jc": 850,
  "plugin-ticketprocessor-eurail-interrail": 840,
  "plugin-ticketprocessor-trenitalia": 810,
  "plugin-ticketprocessor-trenitalia-mission": 800,
  "plugin-decoder-eurail-interrail": 830,
  "plugin-decoder-ter": 860,
  "plugin-decoder-ryo": 820,
  "plugin-decoder-cards": 850,
  "plugin-decoder-ouigo": 870,
  "plugin-decoder-jc": 840,
  "plugin-decoder-resarail": 890,
  "plugin-decoder-europe": 880,
  "plugin-decoder-trenitalia": 810,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      color: SNCF_RED,
      fontWeight: "bold",
    },
    buttonsGroupLayout: {
      display: "flex",
      justifyContent: "flex-end",
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(2),
    },
    horizontalBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      "&>*": {
        flex: 1,
      },
      flexWrap: "wrap",
    },
    verticalBox: {
      display: "flex",
      flexDirection: "column",
    },
    smallBoxMargin: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    smallMarginBottom: {
      marginBottom: theme.spacing(2),
    },
    formSelectListLayout: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      flex: 1,
    },
  })
);

function defaultDate() {
  return format(new Date(), "yyyy-MM-dd'T'HH:mm");
}

function CreateDialog({
  open,
  onClose,
  onSubmit,
  gates,
  type,
}: CreateDialogProperties) {
  const [versions, setVersions] = useState<BundleAvailableVersions[]>([]);
  const [selectedVersions, setSelectedVersions] = useState<{
    [key: string]: string | undefined;
  }>({});
  const [deployDate, setDeployDate] = useState<string>(defaultDate());
  const [loading, setLoading] = useState<boolean>(true);
  const [dateError, setDateError] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    let canceled = false;

    async function loadConfig() {
      const v = await fetchAvailableVersions(type);
      v.sort((a, b) => {
        return (
          mappingNameSortValue[b.info.fullName] -
          mappingNameSortValue[a.info.fullName]
        );
      });

      if (!canceled) {
        setVersions(v);
        setLoading(false);
      }
    }

    if (open) {
      setLoading(true);
      setDeployDate(defaultDate());
      setDateError("");
      setSelectedVersions({});

      loadConfig();
    }
    return () => {
      canceled = true;
    };
  }, [open, type]);

  const onDialogClose = (_event: unknown, reason: string) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      handleClose();
    }
  };

  function handleClose() {
    onClose();
  }

  async function handleSave() {
    // Get real bundle versions
    const bundles: { [key: string]: string } = {};
    for (const k of Object.keys(selectedVersions)) {
      const v = selectedVersions[k];
      if (!v) return;
      bundles[k] = v;
    }

    if (isEmpty(bundles)) {
      return;
    }

    const ids = gates.map((g) => g.id);
    setLoading(true);

    try {
      await createDeployment(type, {
        gateIds: ids,
        bundleVersions: bundles,
        programmationDate: new Date(deployDate).toISOString(),
      });
      enqueueSnackbar(
        `Déploiement ${
          type === "CONFIG" ? "configuration" : "logiciel"
        } créé avec succès`,
        {
          variant: "success",
        }
      );
    } catch (error) {
      console.log(error);
      enqueueSnackbar(
        `Une erreur est survenue pendant la création du déploiement`,
        { variant: "error" }
      );
      setLoading(false);
      return;
    }

    handleClose();
    onSubmit();
  }

  return (
    <Dialog
      open={open}
      onClose={onDialogClose}
      aria-labelledby="form-create-dialog-title"
      fullWidth={true}
      maxWidth={"xl"}
    >
      <DialogTitle id="form-create-dialog-title">
        Planifier un déploiement de{" "}
        {type === "CONFIG" ? "configuration" : "logiciels"}
      </DialogTitle>
      <DialogContent>
        {loading && (
          <div style={{ textAlign: "center" }}>
            <Loader width={100} height={100} />
          </div>
        )}
        {!loading && (
          <>
            <Box
              className={clsx(classes.horizontalBox, classes.smallMarginBottom)}
            >
              <ItemElementsList
                label="Portes sélectionnées"
                additionalClassName={classes.smallBoxMargin}
                rows={gates
                  .sort((a, b) => a.stationName.localeCompare(b.stationName))
                  .map((g, index) => (
                    <span key={index}>
                      {`${g.stationName} / ${g.dockName} / ${g.laneName} / ${g.name}`}
                    </span>
                  ))}
              />
              <Box
                className={clsx(classes.verticalBox, classes.smallBoxMargin)}
              >
                <FormHelperText>Date du déploiement</FormHelperText>
                <TextField
                  type="datetime-local"
                  value={deployDate}
                  className={classes.smallMarginBottom}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const d = event.target.value;
                    if (isBefore(parseISO(d), Date.now())) {
                      setDateError(
                        "Impossible de définir un déploiement dans le passé"
                      );
                    } else {
                      setDateError("");
                    }
                    setDeployDate(d);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                {dateError !== "" && (
                  <div className={classes.error}>{dateError}</div>
                )}
                <VersionSelectList
                  versions={versions}
                  selectedVersions={selectedVersions}
                  setSelectedVersions={setSelectedVersions}
                  label="Général"
                  groups={
                    type === "CONFIG"
                      ? undefined
                      : ["gate", "gate-daemon", "rfidvalidator"]
                  }
                />
              </Box>
            </Box>
            {type !== "CONFIG" && (
              <Box className={classes.horizontalBox}>
                <VersionSelectList
                  versions={versions}
                  selectedVersions={selectedVersions}
                  setSelectedVersions={setSelectedVersions}
                  label="Décodeurs"
                  groups={"decoder"}
                  additionalClassName={classes.formSelectListLayout}
                />
                <VersionSelectList
                  versions={versions}
                  selectedVersions={selectedVersions}
                  setSelectedVersions={setSelectedVersions}
                  label="Traitements titres"
                  groups={"ticketprocessor"}
                  additionalClassName={classes.formSelectListLayout}
                />
              </Box>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.buttonsGroupLayout}>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={gates.length === 0 || dateError !== "" || loading}
          onClick={handleSave}
        >
          Créer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateDialog;

import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { default as clsx } from "clsx";
import { makeStyles } from "@material-ui/styles";

interface SearchField {
  searchable?: boolean;
  dataKey: string;
  label: string;
  styles: string;
  onSearch: (key: string, value: string) => void;
}

const useStyles = makeStyles({
  focusInput: {
    // sur le TextField
    background: "rgba(255,255,255,0.15)",
  },
  search: {
    marginRight: "15%",
    color: "white",
    "& .MuiInput-input": {
      // sur l'input dans le TextField
      fontWeight: 500,
      fontSize: "0.75rem",
      color: "white",
      borderBottom: "2px solid white",
    },
  },
});

export default function SearchField({
  dataKey,
  label,
  styles,
  onSearch,
}: SearchField) {
  const classes = useStyles();
  const [value, setValue] = useState("");

  function handleOnChange(v: string) {
    onSearch(dataKey, v);
    setValue(v);
  }

  return (
    <TextField
      placeholder={label}
      margin="none"
      InputProps={{ disableUnderline: true }}
      className={clsx(styles, classes.search, {
        [classes.focusInput]: value.length > 0,
      })}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        handleOnChange(event.target.value)
      }
    />
  );
}

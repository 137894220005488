import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  ConfigurationDTO,
  ConfigurationType,
  fetchConfiguration,
} from "../../services/api/configurations";

function useConfiguration(
  configurationType: ConfigurationType,
  transporter: string | undefined,
  configurationId: string | undefined,
  setIsLoading: (s: boolean) => void
): [
  ConfigurationDTO | undefined,
  Dispatch<SetStateAction<ConfigurationDTO | undefined>>
] {
  const [configuration, setConfiguration] = useState<ConfigurationDTO>();

  useEffect(() => {
    if (!configurationId) {
      return;
    }

    const loadConfiguration = async () => {
      const c = await fetchConfiguration(
        configurationType,
        transporter,
        configurationId
      );
      setConfiguration(c);
      setIsLoading(false);
    };

    loadConfiguration();
  }, [configurationType, transporter, configurationId, setIsLoading]);

  return [configuration, setConfiguration];
}

export default useConfiguration;

import axios from "axios";
import { Gate, LedColor } from "../interfaces/referential";
import FileDownload from "js-file-download";

export async function rebootGates(gateIds: string[], type: string) {
  return axios.put(`/gates/${gateIds}/reset/${type}`);
}

export async function fetchGates(stationId: string): Promise<Gate[]> {
  const response = await axios.get(
    "/referential/stations/" + stationId + "/gates"
  );
  return response.data;
}

export async function addGate(gate: Gate) {
  const response = await axios.post("/referential/gates", {
    id: gate.id,
    name: gate.name,
    pmr: gate.pmr,
    gateUrlAddress: gate.gateUrlAddress,
    active: gate.active,
    laneId: gate.laneId === "" ? undefined : gate.laneId,
    stationId: gate.stationId,
    lafBottomScreenEnabled: gate.lafBottomScreenEnabled,
    lafAdditionalGateAddress: gate.lafAdditionalGateAddress,
    baseColor: gate.baseColor || LedColor.OFF,
    customColor: gate.customColor || LedColor.OFF,
    controlTnEnabled: gate.controlTnEnabled || false,
  });

  return response.data;
}

export async function updateGate(gate: Gate) {
  const response = await axios.put("referential/gates/" + gate.id, {
    id: gate.id,
    name: gate.name,
    pmr: gate.pmr,
    gateUrlAddress: gate.gateUrlAddress,
    active: gate.active,
    laneId: gate.laneId === "" ? undefined : gate.laneId,
    stationId: gate.stationId,
    lafBottomScreenEnabled: gate.lafBottomScreenEnabled,
    lafAdditionalGateAddress: gate.lafAdditionalGateAddress,
    baseColor: gate.baseColor || LedColor.OFF,
    customColor: gate.customColor || LedColor.OFF,
    controlTnEnabled: gate.controlTnEnabled || false,
  });
  return response.data;
}

export async function updateGateActive(gate: Gate): Promise<Gate> {
  const response = await axios.patch("/referential/gates/" + gate.id, {
    active: gate.active,
  });
  return response.data;
}

export async function deleteGates(gateIds: Set<string>) {
  return axios.delete("/referential/gates/" + [...gateIds].join(","), {
    responseType: "text",
  });
}

export async function deleteGate(gateId: string) {
  return deleteGates(new Set([gateId]));
}

export async function getLatestLogs(gateId: string, gateName: string) {
  const response = await axios.get(`/gates/${gateId}/logs/_latest`, {
    responseType: "blob",
  });
  const filename = `${gateName}-${Date.now() / 1000}.latest.zip`;
  FileDownload(response.data, filename);
}

export async function getAllLogs(gateId: string, gateName: string) {
  const response = await axios.get(`/gates/${gateId}/logs/_all`, {
    responseType: "blob",
  });
  const filename = `${gateName}-${Date.now() / 1000}.zip`;
  FileDownload(response.data, filename);
}

export async function getGateVersions(gateId: string) {
  const response = await axios.get(`/gates/${gateId}/versions/_current`);
  return response.data;
}

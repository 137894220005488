import React from "react";
import { format } from "date-fns";

const TIME_FORMAT = "dd/MM/yyyy - HH:mm:ss";

interface Properties {
  datetime?: string | number;
}

function DateFormatter({ datetime }: Properties) {
  if (!datetime) return <></>;

  return <span>{format(new Date(datetime), TIME_FORMAT)}</span>;
}

export default DateFormatter;

import React, { useContext, useEffect, useState } from "react";
import { Box, Fab, Paper, Theme, Tooltip } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { Add, CloudDownload, CloudUpload, Delete } from "@material-ui/icons";
import {
  deleteUsers,
  fetchAllUsers,
  User,
  updateUser,
  exportUsers,
  importUsers,
  EUserType,
} from "../../services/api/users";
import StatusChip from "../../components/virtualized-table/renderers/user-status";
import OptionsButton, { ButtonActions } from "../../components/options-button";
import {
  fetchAvailableUserOptions,
  AvailableUserOptions,
} from "../../services/api/available-user-options";
import UserEditModal from "./user-edit-modal";
import UserProfileModal from "./user-profile-modal";
import { AppContext } from "../../app-provider";
import { useSnackbar } from "notistack";
import SortableAndSearchableVirtualizedTable from "../../components/virtualized-table/sortable-and-searchable-virtualized-table";
import Dropzone from "react-dropzone";
import cloneDeep = require("lodash/cloneDeep");
import PopoverWrapperRenderer from "../../components/virtualized-table/renderers/popover-wrapper-renderer";
import ChipsRenderer from "../../components/virtualized-table/renderers/chips-renderer";
import useRights from "../../services/ducks/rights";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleLayout: {
      textAlign: "left",
      marginTop: "0",
    },
    paperLayout: {
      width: "100%",
      height: "520px",
    },
    fabLayout: {
      margin: theme.spacing(1),
    },
  })
);

function UserGestion() {
  const classes = useStyles();
  const [availableUserOptions, setAvailableUserOptions] =
    useState<AvailableUserOptions>();
  const [users, setUsers] = useState<User[]>([]);
  const [selected, setSelected] = useState<Set<string>>(new Set([]));
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openConsult, setOpenConsult] = useState<boolean>(false);
  const [dataInFetching, setDataInFetching] = useState(false);
  const [editedUser, setEditedUser] = useState<User>();
  const [, confirmDialogDispatch] =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useContext(AppContext).reducers.confirmDialog!;
  const { enqueueSnackbar } = useSnackbar();
  const { hasRights, hasLevel, canEditUser } = useRights();

  const fetchUsersAndOptions = async () => {
    setDataInFetching(true);
    try {
      const fetchedAvailableUserOptions: AvailableUserOptions =
        await fetchAvailableUserOptions();
      setAvailableUserOptions(fetchedAvailableUserOptions);
      const fetchedUsers = await fetchAllUsers();
      setUsers(fetchedUsers);
    } catch {
      enqueueSnackbar("Erreur lors du chargement des utilisateurs", {
        variant: "error",
      });
    }
    setDataInFetching(false);
  };

  useEffect(() => {
    fetchUsersAndOptions();
  }, []);

  const handleNewUserClick = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    setOpenEdit(true);
  };

  const handleImportClick = (acceptedFiles: File[]): void => {
    confirmDialogDispatch({
      type: "open",
      title: "Import d'utilisateurs",
      msg: "Êtes-vous sûr de vouloir importer ces utilisateurs ?",
      submit: async () => {
        try {
          await importUsers(acceptedFiles[0]);
          const fetchedUsers = await fetchAllUsers();
          setUsers(cloneDeep(fetchedUsers));
          enqueueSnackbar("Utilisateurs importés avec succès", {
            variant: "success",
          });
        } catch {
          enqueueSnackbar("Erreur lors de l'import des utilisateurs'", {
            variant: "error",
          });
        }
      },
    });
  };

  const handleExportClick = async (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    try {
      await exportUsers();
    } catch {
      enqueueSnackbar("Erreur lors de l'export des utilisateurs'", {
        variant: "error",
      });
    }
  };

  const handleUserSelected = (user: User, checked: boolean) => {
    if (user.id !== undefined) {
      const updateSelected = new Set(selected);

      checked ? updateSelected.add(user.id) : updateSelected.delete(user.id);

      setSelected(updateSelected);
    }
  };

  const isSelected = (user: User): boolean => {
    return user.id !== undefined && selected.has(user.id);
  };

  const handleUserChangeStatusClick = async (rowIndex: number) => {
    const updatedRows = [...users];

    updatedRows[rowIndex].active = !updatedRows[rowIndex].active;

    try {
      const result = await updateUser(updatedRows[rowIndex]);
      if (result) {
        updatedRows[rowIndex] = result;
        setUsers(updatedRows);
      }
    } catch {
      // revert changes (values in array are references)
      updatedRows[rowIndex].active = !updatedRows[rowIndex].active;
      enqueueSnackbar("Erreur lors de l'actualisation de l'utilisateur", {
        variant: "error",
      });
    }
  };

  const handleEditProfileClick = (rowIndex: number) => {
    setEditedUser(users[rowIndex]);
    setOpenEdit(true);
  };

  const handleConsultProfileClick = (rowIndex: number) => {
    setEditedUser(users[rowIndex]);
    setOpenConsult(true);
  };

  async function handleDeleteUsersSubmit() {
    const updatedRows = [...users];
    const selectedRows: User[] = [];
    const unselectedRows: User[] = [];

    for (const row of updatedRows) {
      if (row.id !== undefined && selected.has(row.id)) {
        selectedRows.push(row);
      } else {
        unselectedRows.push(row);
      }
    }

    try {
      const result = await deleteUsers(selectedRows);
      if (result) {
        setUsers(unselectedRows);
        setSelected(new Set([]));
        enqueueSnackbar(
          `Utilisateur ${
            selected.size === 1
              ? "supprimé avec succès"
              : "supprimés avec succès"
          }`,
          { variant: "success" }
        );
      }
    } catch {
      enqueueSnackbar(
        `Erreur lors de la suppression ${
          selected.size === 1 ? "de l'utilisateur(s)" : "des utilisateur(s)"
        }`,
        { variant: "error" }
      );
    }
  }

  async function handleDeleteUserSubmit(rowIndex: number) {
    const updatedRows = [...users];
    try {
      const result = await deleteUsers([updatedRows[rowIndex]]);
      if (result) {
        updatedRows.splice(rowIndex, 1);
        setUsers(updatedRows);
        enqueueSnackbar("Utilisateur supprimé avec succès", {
          variant: "success",
        });
      }
    } catch {
      enqueueSnackbar("Erreur lors de la suppression de l'utilisateur", {
        variant: "error",
      });
    }
  }

  function handleTrashClick() {
    if (selected.size > 0) {
      confirmDialogDispatch({
        type: "open",
        title: "Suppression d'utilisateurs",
        msg: `Êtes-vous sûr de vouloir supprimer ${
          selected.size === 1
            ? "cet utilisateur"
            : `ces ${selected.size} utilisateurs`
        } ?`,
        submit: handleDeleteUsersSubmit,
      });
    }
  }

  async function handleDeleteProfileClick(rowIndex: number) {
    confirmDialogDispatch({
      type: "open",
      title: "Suppression d'utilisateur",
      msg: "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
      submit: () => handleDeleteUserSubmit(rowIndex),
    });
  }

  const renderOptionsButton = (rowIndex: number) => {
    const buttonActions: ButtonActions[] = [];
    const canEditIndex = canEditUser(users[rowIndex]);

    if (canEditIndex && hasRights("USER_TOGGLE_ACTIVE")) {
      buttonActions.push({
        label: users[rowIndex].active ? "Rendre Inactif" : "Rendre Actif",
        handler: () => handleUserChangeStatusClick(rowIndex),
      });
    }

    if (hasRights("USER_READ_PROFILE")) {
      buttonActions.push({
        label: "Consulter le profil",
        handler: () => handleConsultProfileClick(rowIndex),
      });
    }

    if (canEditIndex && hasRights("USER_UPDATE")) {
      buttonActions.push({
        label: "Modifier le profil",
        handler: () => handleEditProfileClick(rowIndex),
      });
    }

    if (canEditIndex && hasRights("USER_DELETE")) {
      buttonActions.push({
        label: "Supprimer le profil",
        handler: () => handleDeleteProfileClick(rowIndex),
      });
    }

    return <OptionsButton buttonActions={buttonActions} />;
  };

  const closeModal = () => {
    setEditedUser(undefined);
    setOpenEdit(false);
    setOpenConsult(false);
  };

  const addNewUser = (user: User) => {
    const updatedRows = [user, ...users];
    setUsers(updatedRows);
  };

  const editUser = (editedUser: User) => {
    if (editedUser) {
      const updatedRows = users.map((user) =>
        user.id === editedUser.id ? editedUser : user
      );
      setUsers(updatedRows);
    }
  };

  const getActivityName = (user: User): string => {
    if (availableUserOptions) {
      const option = availableUserOptions.activities.find(
        (option) => user.activity === option.id
      );
      return option ? option.name : "";
    }
    return "";
  };

  const getProfileName = (user: User): string => {
    if (availableUserOptions) {
      const option = availableUserOptions.profiles.find(
        (option) => user.profile === option.id
      );
      return option ? option.name : "";
    }
    return "";
  };

  const getTransporterName = (user: User): string => {
    if (user.activity === "GC") {
      return "Tous";
    }
    if (user.activity === "GARE") {
      return "";
    }
    if (availableUserOptions) {
      return availableUserOptions.transporters
        .filter((option) => user.transporters.includes(option.id))
        .map((option) => option.name)
        .join(", ");
    }
    return "";
  };

  const getPerimeterName = (user: User): string => {
    if (availableUserOptions) {
      const perimeterOption = availableUserOptions.perimeters.find(
        (option) => user.perimeter === option.id
      );
      const perimeterName = perimeterOption ? perimeterOption.name : "";
      if (user.perimeter === "NATIONAL") {
        return perimeterName;
      }
      const options = [
        ...availableUserOptions.stations,
        ...availableUserOptions.stationGroups,
      ].filter((option) => user.perimeterElements.includes(option.id));
      const optionsNames = options.map((option) => option.name).join(", ");
      return `${perimeterName} : ${optionsNames}`;
    }
    return "";
  };

  const columns = [
    {
      label: "Type",
      dataKey: "type",
      sortable: true,
      flexGrow: 1.25,
      renderer: (user: User) =>
        user.type === EUserType.SNCF ? "SNCF" : "Local",
    },
    {
      label: "Identifiant",
      dataKey: "username",
      sortable: true,
      searchable: true,
      searchableElements: (user: User) => user.username,
      flexGrow: 1.25,
      renderer: (user: User) => (
        <PopoverWrapperRenderer>{user.username}</PopoverWrapperRenderer>
      ),
    },
    {
      label: "Nom",
      dataKey: "lastName",
      sortable: true,
      searchable: true,
      searchableElements: (user: User) => user.lastName,
      flexGrow: 1.25,
      renderer: (user: User) => (
        <PopoverWrapperRenderer>{user.lastName}</PopoverWrapperRenderer>
      ),
    },
    {
      label: "Prénom",
      dataKey: "firstName",
      sortable: true,
      searchable: true,
      searchableElements: (user: User) => user.firstName,
      flexGrow: 1.25,
      renderer: (user: User) => (
        <PopoverWrapperRenderer>{user.firstName}</PopoverWrapperRenderer>
      ),
    },
    {
      label: "Activité",
      dataKey: "activity",
      sortable: true,
      searchable: true,
      searchableElements: (user: User) => getActivityName(user),
      flexGrow: 1.5,
      renderer: (user: User) => {
        if (availableUserOptions) {
          const option = availableUserOptions.activities.find(
            (option) => user.activity === option.id
          );
          return option ? (
            <PopoverWrapperRenderer>{option.name}</PopoverWrapperRenderer>
          ) : (
            ""
          );
        }
      },
    },
    {
      label: "Profil",
      dataKey: "profile",
      sortable: true,
      searchable: true,
      searchableElements: (user: User) => getProfileName(user),
      flexGrow: 1.5,
      renderer: (user: User) => {
        if (availableUserOptions) {
          const option = availableUserOptions.profiles.find(
            (option) => user.profile === option.id
          );
          return option ? (
            <PopoverWrapperRenderer>{option.name}</PopoverWrapperRenderer>
          ) : (
            ""
          );
        }
      },
    },
    {
      label: "Transporteurs",
      dataKey: "transporters",
      sortable: true,
      searchable: true,
      searchableElements: (user: User) => getTransporterName(user),
      flexGrow: 1.5,
      renderer: (user: User) => {
        if (user.activity === "GC") {
          return "Tous";
        }
        if (user.activity === "GARE") {
          return "";
        }
        if (availableUserOptions) {
          const options = availableUserOptions.transporters.filter((option) =>
            user.transporters.includes(option.id)
          );
          const optionNames = options.map((option) => option.name);
          return (
            <PopoverWrapperRenderer>
              <ChipsRenderer elts={optionNames} />
            </PopoverWrapperRenderer>
          );
        }
      },
    },
    {
      label: "Périmètre",
      dataKey: "perimeter",
      sortable: true,
      searchable: true,
      searchableElements: (user: User) => getPerimeterName(user),
      flexGrow: 1.5,
      renderer: (user: User) => {
        if (availableUserOptions) {
          const perimeterOption = availableUserOptions.perimeters.find(
            (option) => user.perimeter === option.id
          );
          const perimeterName = perimeterOption ? perimeterOption.name : "";
          if (user.perimeter === "NATIONAL") {
            return perimeterName;
          }
          const options = [
            ...availableUserOptions.stations,
            ...availableUserOptions.stationGroups,
          ].filter((option) => user.perimeterElements.includes(option.id));
          const optionNames = options.map((option) => option.name);
          return (
            <PopoverWrapperRenderer>
              <ChipsRenderer elts={optionNames} />
            </PopoverWrapperRenderer>
          );
        }
      },
    },
    {
      label: "Statut",
      dataKey: "active",
      flexGrow: 1.25,
      renderer: (user: User) => <StatusChip active={user.active} />,
      renderButtonActions: renderOptionsButton,
    },
  ];

  return (
    <>
      <h1 className={classes.titleLayout}>Gestion des utilisateurs</h1>
      <UserEditModal
        open={openEdit}
        availableUserOptions={availableUserOptions}
        closeModal={closeModal}
        currentUser={editedUser}
        handleUser={editedUser ? editUser : addNewUser}
      />
      <UserProfileModal
        open={openConsult}
        availableUserOptions={availableUserOptions}
        closeModal={closeModal}
        currentUser={editedUser}
        setOpenEdit={setOpenEdit}
        setEditedUser={setEditedUser}
      />
      <Paper className={classes.paperLayout}>
        <SortableAndSearchableVirtualizedTable
          rows={users}
          rowCount={users.length}
          updateRows={setUsers}
          columns={columns}
          selected={selected}
          setSelected={setSelected}
          handleSelected={handleUserSelected}
          isSelected={isSelected}
          dataInFetching={dataInFetching}
          renderSelectionCheckboxes={hasRights("USER_DELETE")}
          enableRowCheckbox={canEditUser}
        />
      </Paper>

      <Box display="flex" justifyContent="flex-end">
        {hasRights("USER_EXPORT") && (
          <Tooltip
            title="Export"
            aria-label="Export"
            className={classes.fabLayout}
          >
            <Fab color="secondary" onClick={handleExportClick}>
              <CloudDownload />
            </Fab>
          </Tooltip>
        )}

        {hasRights("USER_IMPORT") && hasLevel("NATIONAL") && (
          <Dropzone
            noDrag
            accept={{
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".xlsx"],
            }}
            multiple={false}
            onDrop={handleImportClick}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />

                <Tooltip
                  title="Import"
                  aria-label="Import"
                  className={classes.fabLayout}
                >
                  <Fab color="primary">
                    <CloudUpload />
                  </Fab>
                </Tooltip>
              </div>
            )}
          </Dropzone>
        )}

        {hasRights("USER_CREATE") && (
          <Tooltip
            title="Créer un nouvel utilisateur"
            aria-label="Ajouter"
            className={classes.fabLayout}
          >
            <Fab color="secondary" onClick={handleNewUserClick}>
              <Add />
            </Fab>
          </Tooltip>
        )}

        {hasRights("USER_DELETE") && (
          <Tooltip
            title="Supprimer la sélection"
            aria-label="Supprimer"
            className={classes.fabLayout}
          >
            <div>
              <Fab disabled={selected.size === 0} onClick={handleTrashClick}>
                <Delete />
              </Fab>
            </div>
          </Tooltip>
        )}
      </Box>
    </>
  );
}

export default UserGestion;

import { AppContext } from "../../app-provider";
import { useContext } from "react";

// Version context
export interface VersionsContext {
  ihm: string;
  bot: string;
}

// Version actions
export type VersionsAction = {
  type: "SET_VERSION";
  payload: Partial<VersionsContext>;
};

export default function useVersions() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [currentVersions, dispatch] = useContext(AppContext).reducers.versions!;

  const setVersion = (serviceName: string, version: string): void => {
    dispatch({ type: "SET_VERSION", payload: { [serviceName]: version } });
  };

  const getVersion = (serviceName: "ihm" | "bot"): string => {
    return currentVersions[serviceName] || "";
  };

  return { getVersion, setVersion };
}

export const initialState: VersionsContext = {
  ihm: "",
  bot: "",
};

export const reducer = (
  state: VersionsContext,
  action: VersionsAction
): VersionsContext => {
  switch (action.type) {
    case "SET_VERSION": {
      state = {
        ...state,
        ...action.payload,
      };
      break;
    }
  }

  return state;
};

import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Loader from "../../components/loader";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {
  ConfigurationDTO,
  ConfigurationType,
  createConfigurationValue,
  downloadConfigurationFile,
  updateConfigurationValue,
} from "../../services/api/configurations";
import useConfiguration from "./use-configuration";
import useConfigurationValue from "./use-configuration-value";
import { makeStyles } from "@material-ui/styles";
import CustomJsonEditor from "../../components/custom-json-editor";
import InputLabel from "@material-ui/core/InputLabel";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Dropzone from "react-dropzone";
import { useSnackbar } from "notistack";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import { default as clsx } from "clsx";
import { Box } from "@material-ui/core";

const useStyles = makeStyles({
  inputWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  inputName: {
    width: 300,
  },
  editorWrapper: {
    marginTop: 20,
  },
  fileWrapper: {
    display: "flex",
    alignItems: "center",
  },
  jsonEditorLayout: {
    width: "100%",
    border: "1px solid #3883FA",
    padding: "5px 8px 4px",
    "&>div": {
      padding: 0,
    },
  },
});

function readFile(file: File): Promise<string> {
  return new Promise(function (resolve) {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      // Do whatever you want with the file contents
      const binaryString = reader.result as string;
      resolve(btoa(binaryString));
    });
    reader.readAsBinaryString(file);
  });
}

interface EditConfigurationValueDialogProperties {
  configurationId: string | undefined;
  configurationValueId: string | undefined;
  onClose: () => void;
  reloadConfiguration: (configuration: ConfigurationDTO) => void;
  addMode: boolean;
  isTransporter: boolean;
  configurationType: ConfigurationType;
  transporter: string | undefined;
}

function EditConfigurationValueDialog({
  configurationId,
  configurationValueId,
  onClose,
  reloadConfiguration,
  addMode,
  isTransporter,
  configurationType,
  transporter,
}: EditConfigurationValueDialogProperties) {
  const [configurationIsLoading, setConfigurationIsLoading] =
    useState<boolean>(true);
  const [valueIsLoading, setValueIsLoading] = useState<boolean>(true);
  const [configuration, setConfiguration] = useConfiguration(
    configurationType,
    transporter,
    configurationId,
    setConfigurationIsLoading
  );
  const [configurationValue, setConfigurationValue] = useConfigurationValue(
    configurationId,
    configurationValueId,
    addMode,
    setValueIsLoading,
    isTransporter,
    configurationType,
    transporter
  );
  const [data, setData] = useState<object>();
  const [jsonValue, setJsonValue] = useState<string>("");
  const [jsonEditor, setJsonEditor] = useState<boolean>(true);
  const [jsonError, setJsonError] = useState<boolean>(false);
  const [fileData, setFileData] = useState<File>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  // Load the data if json value
  useEffect(() => {
    if (!configurationValue || !configuration) return;

    if (configurationValue.type !== "JSON") return;
    if (data) return; // provide multiple reload
    if (addMode) {
      setData({});
      return;
    }

    const loadData = async () => {
      const result = await downloadConfigurationFile(
        configurationType,
        transporter,
        configuration.id,
        configurationValue.id,
        configurationValue.type,
        undefined
      );

      setData(result);
    };

    loadData();
  }, [
    transporter,
    configurationType,
    addMode,
    configuration,
    configurationValue,
    data,
  ]);

  useEffect(() => {
    try {
      if (data) {
        setJsonValue(JSON.stringify(data, undefined, 4));
      }
    } catch (error) {
      console.log(error);
    }
  }, [jsonEditor, data]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!configurationValue) return;

    setConfigurationValue({ ...configurationValue, name: event.target.value });
  };

  const handlePathChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!configurationValue) return;

    setConfigurationValue({ ...configurationValue, path: event.target.value });
  };

  const onDialogClose = (_event: unknown, reason: string) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      handleClose();
    }
  };

  const handleClose = () => {
    onClose();
    setConfiguration(undefined);
    setConfigurationValue(undefined);
    setData(undefined);
    setFileData(undefined);
    setConfigurationIsLoading(true);
    setValueIsLoading(true);
    setSubmitting(false);
    setJsonEditor(true);
    setJsonError(false);
    setJsonValue("");
  };

  const handleSave = async () => {
    if (!configuration || !configurationValue) return;

    setSubmitting(true);

    let updatedData;
    if (configurationValue.type === "FILE") {
      if (fileData) {
        updatedData = await readFile(fileData);
      }
    } else {
      updatedData = JSON.stringify(data);
    }

    try {
      const update = {
        name: configurationValue.name,
        type: configurationValue.type,
        data: updatedData,
        path: configurationValue.path,
      };

      await (addMode
        ? createConfigurationValue(
            configurationType,
            transporter,
            configuration.id,
            update
          )
        : updateConfigurationValue(
            configurationType,
            transporter,
            configuration.id,
            configurationValue.id,
            update
          ));
      enqueueSnackbar(
        `Valeur de ${configurationValue.name} ${
          addMode ? "ajoutée" : "modifiée"
        } avec succès`,
        {
          variant: "success",
        }
      );
      reloadConfiguration(configuration);
      handleClose();
    } catch (error) {
      console.log(error);
      enqueueSnackbar(
        `Une erreur est survenue lors de la ${
          addMode ? "création" : "modification"
        } de la valeur`,
        { variant: "error" }
      );
    } finally {
      setSubmitting(false);
    }
  };

  // Displays a loader when fetching data or when data is not yet set to an empty object,
  // thus avoiding the delay between the render of the JsonEditor and the rest of the modal
  // when editing a transporter configuration

  const isLoading =
    ((configurationIsLoading || valueIsLoading || submitting) && !addMode) ||
    (configurationValue && configurationValue.type === "JSON" && data === null);

  return (
    <Dialog
      open={
        (Boolean(configurationId) && Boolean(configurationValueId)) || addMode
      }
      onClose={onDialogClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={
        configurationValue && configurationValue.type === "JSON" && !isLoading
          ? "xl"
          : addMode
          ? "md"
          : "sm"
      }
    >
      <DialogTitle id="form-dialog-title">
        {addMode ? "Ajouter une valeur" : "Modifier la valeur"}
      </DialogTitle>
      <DialogContent>
        {isLoading && (
          <div style={{ textAlign: "center" }}>
            <Loader width={100} height={100} />
          </div>
        )}
        {!isLoading && configurationValue && (
          <>
            <div className={classes.inputWrapper}>
              <TextField
                id="name-text-field"
                label="Nom"
                value={configurationValue.name}
                className={classes.inputName}
                onChange={handleNameChange}
              />
              <TextField
                id="path-text-field"
                label="Dossier"
                value={configurationValue.path}
                onChange={handlePathChange}
              />
              {addMode && !isTransporter && (
                <div>
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>Fichier</Grid>
                    <Grid item>
                      <Switch
                        checked={
                          configurationValue.type === "JSON" || isTransporter
                        }
                        onChange={(_event: unknown, checked: boolean) =>
                          setConfigurationValue({
                            ...configurationValue,
                            type: checked ? "JSON" : "FILE",
                          })
                        }
                        value="conf-type"
                      />
                    </Grid>
                    <Grid item>Éditeur</Grid>
                  </Grid>
                </div>
              )}
            </div>
            {configurationValue.type === "JSON" && data && (
              <div className={classes.editorWrapper}>
                <Box display="flex" alignItems="center">
                  <InputLabel style={{ width: "200px" }}>
                    Edition de la valeur
                  </InputLabel>
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>Éditeur texte</Grid>
                    <Grid item>
                      <Switch
                        checked={jsonEditor}
                        onChange={(_event: unknown, checked: boolean) =>
                          setJsonEditor(checked)
                        }
                        value="editor-type"
                        disabled={jsonError}
                      />
                    </Grid>
                    <Grid item>Éditeur visuel</Grid>
                  </Grid>
                </Box>
                {jsonEditor && (
                  <CustomJsonEditor
                    value={data}
                    onChange={(newData) => setData(newData)}
                  />
                )}
                {!jsonEditor && (
                  <>
                    <TextField
                      value={jsonValue}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setJsonValue(event.target.value);
                        try {
                          const parsedData = JSON.parse(event.target.value);
                          setJsonError(false);
                          setData(parsedData);
                        } catch {
                          setJsonError(true);
                        }
                      }}
                      placeholder="Insérez la valeur au format JSON"
                      multiline={true}
                      rows={20}
                      className={classes.jsonEditorLayout}
                      margin="none"
                      InputProps={{ disableUnderline: true }}
                      error={jsonError}
                    />
                    {jsonError && (
                      <InputLabel
                        error={true}
                        style={{ textAlign: "center", marginTop: "12px" }}
                      >
                        Le format de la valeur est incorrect (format attendu :
                        JSON)
                      </InputLabel>
                    )}
                  </>
                )}
              </div>
            )}
            {configurationValue.type === "FILE" && (
              <div className={clsx(classes.editorWrapper, classes.fileWrapper)}>
                <InputLabel>
                  {addMode ? "Ajout" : "Edition"} du fichier
                </InputLabel>
                <Dropzone
                  noDrag
                  multiple={false}
                  onDrop={(acceptedFiles) => setFileData(acceptedFiles[0])}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      style={{ display: "inline-block", margin: "0 20px" }}
                    >
                      <input {...getInputProps()} />
                      <Tooltip
                        title="Sélectionner le fichier"
                        aria-label="Import"
                      >
                        <Fab color="secondary">
                          <CloudUploadIcon />
                        </Fab>
                      </Tooltip>
                    </div>
                  )}
                </Dropzone>
                {fileData && <span>{fileData.name}</span>}
              </div>
            )}
          </>
        )}
      </DialogContent>
      {!isLoading && (
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Annuler
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSave}
            disabled={
              !configurationValue || !configurationValue.name || jsonError
            }
          >
            {addMode ? "Ajouter" : "Enregistrer"}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default EditConfigurationValueDialog;

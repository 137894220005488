import React, { useState } from "react";
import { TopLevelPanel } from "components/expandable-table/top-level-panel";
import { Tab } from "components/tabs-component";
import { WhitelistDTO, WhitelistElementDTO } from "services/api/whitelists";
import { RenderedWhitelist } from ".";
import { WhitelistRowTitle } from "./whitelist-row-title";
import { WhitelistRowContent } from "./whitelist-row-content";
import { CreateWhitelistElementModal } from "./create-whitelist-element-modal";
import { TabKey } from "./hooks/use-tabs";

export interface WhitelistRowProperties {
  whitelist: RenderedWhitelist;
  index: number;
  selectedTab: Tab;
  loadWhitelistElements: (row: RenderedWhitelist) => Promise<void>;
  onDeletedWhitelist: (whitelist: WhitelistDTO) => void;
  onCreatedWhitelistElement: (whitelistElement: WhitelistElementDTO) => void;
  onDeletedWhitelistElement: (whitelistElement: WhitelistElementDTO) => void;
  onViewWhitelistElement: (
    whitelist: WhitelistDTO,
    whitelistElement: WhitelistElementDTO
  ) => void;
  onEditWhitelistElement: (
    whitelist: WhitelistDTO,
    whitelistElement: WhitelistElementDTO
  ) => void;
}

export const WhitelistRow: React.FC<WhitelistRowProperties> = ({
  whitelist,
  index,
  selectedTab,
  loadWhitelistElements,
  onDeletedWhitelist,
  onCreatedWhitelistElement,
  onDeletedWhitelistElement,
  onViewWhitelistElement,
  onEditWhitelistElement,
}) => {
  const [creatingWhitelistElement, setCreatingWhitelistElement] =
    useState<WhitelistDTO>();

  function onCreatingWhitelistElement(whitelist: WhitelistDTO) {
    setCreatingWhitelistElement(whitelist);
  }

  const onCloseCreateWhitelistElementModal = (
    whitelistElement?: WhitelistElementDTO
  ) => {
    if (whitelistElement && creatingWhitelistElement) {
      onCreatedWhitelistElement(whitelistElement);
    }

    setCreatingWhitelistElement(undefined);
  };

  return (
    <>
      <TopLevelPanel
        index={index}
        key={whitelist.id}
        root={whitelist}
        titleRenderer={(root, _index) => (
          <WhitelistRowTitle
            whitelist={root}
            selectedTab={selectedTab}
            onDeletedWhitelist={onDeletedWhitelist}
            onCreatingWhitelistElement={onCreatingWhitelistElement}
          />
        )}
        contentRenderer={(root, index) => (
          <WhitelistRowContent
            whitelist={root}
            index={index}
            selectedTab={selectedTab}
            loadWhitelistElements={loadWhitelistElements}
            onDeletedWhitelistElement={onDeletedWhitelistElement}
            onViewWhitelistElement={onViewWhitelistElement}
            onEditWhitelistElement={onEditWhitelistElement}
          />
        )}
      />

      {creatingWhitelistElement && (
        <CreateWhitelistElementModal
          whitelistId={creatingWhitelistElement.id}
          whitelistType={TabKey[creatingWhitelistElement.type]}
          whitelistTransporter={whitelist.transporter || undefined}
          onClose={onCloseCreateWhitelistElementModal}
        />
      )}
    </>
  );
};

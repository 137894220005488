import * as React from "react";
import FormControl from "@material-ui/core/FormControl";
import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import { ItemName } from "../services/interfaces/referential";
import { useEffect, useState } from "react";
import { default as clsx } from "clsx";
import FormSelect from "./form/select";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

interface Properties {
  elementList: ItemName[];
  defaultValueId?: string;
  noValueLabel?: string;
  handleToUpdate: (data: ItemName | undefined) => void | Promise<void>;
  additionalClassName?: string;
}

function DropDownMenu({
  elementList,
  handleToUpdate,
  defaultValueId,
  noValueLabel = "-",
  additionalClassName,
}: Properties) {
  const classes = useStyles();
  const [element, setElement] = useState<undefined | ItemName>();

  useEffect(() => {
    const defaultValue = elementList.find(
      (element_) => element_.id === defaultValueId
    );
    setElement(defaultValue);
  }, [defaultValueId, elementList]);

  const handleChange = (id: string) => {
    const element_ = elementList.find((element) => element.id === id);

    setElement(element_);
    handleToUpdate(element_);
  };

  const availableElements = [
    { name: noValueLabel, id: "" },
    ...elementList.map((element_) => ({
      name: element_.name,
      id: element_.id,
    })),
  ];

  return (
    <>
      <form
        className={clsx(classes.root, additionalClassName)}
        autoComplete="off"
      >
        <FormControl className={classes.formControl}>
          <FormSelect
            handleOnChange={handleChange}
            availableOptions={availableElements}
            value={element ? element.id : ""}
            displayEmpty
            searchable
            shrink
            label="Gare"
          />
        </FormControl>
      </form>
    </>
  );
}

export default DropDownMenu;

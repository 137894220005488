import axios from "axios";
import { getConfig } from "services/config";

interface BotStatus {
  name: string;
  id: string;
  version: string;
  "build-id": string;
  "build-time": string;
}

export async function fetchBotStatus(): Promise<BotStatus> {
  const config = getConfig();
  const response = await axios.get(`${config.BOT_URL}/status`);
  return response.data;
}

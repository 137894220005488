import React, { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionSummary from "../accordion/accordion-summary";
import AccordionDetails from "../accordion/accordion-details";
import Accordion from "../accordion/accordion";
import { useStyles } from "./styles";

interface TopLevelPanelProperties<RootType> {
  root: RootType;
  titleRenderer: (root: RootType, index: number) => JSX.Element;
  contentRenderer: (root: RootType, index: number) => JSX.Element;
  index: number;
}

export function TopLevelPanel<T>({
  root,
  titleRenderer,
  contentRenderer,
  index,
}: TopLevelPanelProperties<T>) {
  const [open, setOpen] = useState<boolean>(false);
  const classes = useStyles({ open });

  return (
    <Accordion
      expanded={open}
      onChange={() => setOpen(!open)}
      TransitionProps={{ mountOnEnter: true }}
    >
      <AccordionSummary>
        <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
          <ExpandMoreIcon className={classes.icon} />
          {titleRenderer(root, index)}
        </div>
      </AccordionSummary>
      <AccordionDetails>{contentRenderer(root, index)}</AccordionDetails>
    </Accordion>
  );
}

import axios, { AxiosInstance } from "axios";
import { getConfig } from "../config";
import { UserAuth, UserInfo } from "../ducks/auth";

// wrap in a function to prevent instance creation before config is loaded
// otherwise axios.defaults are not set
function getRawInstance(): AxiosInstance {
  // instance sans crédentials et hooks pour l'authent
  const rawAxiosInstance = axios.create();
  rawAxiosInstance.interceptors.request.use((config) => {
    config.headers.Authorization = "";
    return config;
  });
  return rawAxiosInstance;
}

export async function loginWithOpenAM(openamCode: string): Promise<UserAuth> {
  const config = getConfig();
  const response = await getRawInstance().post("/auth/login", {
    grantType: "openam_code",
    openamCode,
    redirectUri: `${window.location.origin}/${config.REDIRECT_PATH}`,
  });
  return response.data;
}

export async function loginWithPassword(
  username: string,
  password: string
): Promise<UserAuth> {
  const response = await getRawInstance().post("/auth/login", {
    grantType: "password",
    username,
    password,
  });
  return response.data;
}

export async function refreshAuthToken(
  refreshToken: string
): Promise<UserAuth> {
  const response = await getRawInstance().post("/auth/login", {
    grantType: "refresh_token",
    refreshToken,
  });
  return response.data;
}

export async function userInfo(token: string): Promise<UserInfo> {
  const response = await axios.get(`/auth/info?access_token=${token}`);
  return response.data;
}

export async function updatePassword(
  currentPassword: string,
  newPassword: string,
  newPasswordConfirmation: string
): Promise<UserAuth> {
  const response = await axios.post("/auth/_password", {
    currentPassword,
    newPassword,
    newPasswordConfirmation,
  });
  return response.data;
}

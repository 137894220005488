import React, { useEffect, useMemo, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Loader from "../../../components/loader";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Box, IconButton } from "@material-ui/core";
import { Create } from "@material-ui/icons";
import useRights from "services/ducks/rights";
import { withStyles } from "@material-ui/core/styles";
import { WhitelistDTO, WhitelistElementDTO } from "services/api/whitelists";
import { PassListForm } from "./pass-list/pass-list-form";
import { ConfirmationModal } from "./confirmation-modal";
import { StationList } from "./station-list/station-list";
import {
  PassListValidation,
  validatePassList,
} from "./pass-list/pass-list-validation";
import { PassListErrors } from "./pass-list/pass-list-errors";

const BrighterTextField = withStyles({
  root: {
    "& .MuiInputBase-root.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.6)",
    },
  },
})(TextField);

interface WhitelistElementDialogProperties {
  whitelist?: WhitelistDTO;
  whitelistElement?: WhitelistElementDTO;
  onClose: () => void;
  canEdit: boolean;
  onEdit: () => void;
  onSave: (update: WhitelistElementDTO) => void;
}

export function WhitelistElementDialog({
  whitelist,
  whitelistElement: whitelistElementProperty,
  onClose,
  canEdit,
  onEdit,
  onSave,
}: WhitelistElementDialogProperties) {
  const [whitelistElement, setWhitelistElement] =
    useState<WhitelistElementDTO>();
  const [data, setData] = useState<Record<string, string[]>>({});
  const [currentData, setCurrentData] = useState<string[]>([]);
  const [selectedKey, setSelectedKey] = useState<string>();
  const [hasError, setHasError] = useState<boolean>(false);
  const [validation, setValidation] = useState<
    Record<string, PassListValidation>
  >({});
  const [editedValues, setEditedValues] = useState(false);
  const [showCancelModifications, setShowCancelModifications] = useState(false);
  const [enableAllStationsKey, setEnableAllStationsKey] =
    useState<boolean>(false);
  const { hasRights, hasLevel } = useRights();

  const onDialogClose = (_event: unknown, reason: string) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      onClose();
    }
  };

  const handleSave = () => {
    if (whitelistElement) {
      onSave({
        ...whitelistElement,
        content: JSON.stringify(data),
      });
    }
  };

  useEffect(() => {
    if (whitelistElementProperty) {
      setWhitelistElement({ ...whitelistElementProperty });
      if (whitelistElementProperty.content) {
        try {
          const parsedContent = JSON.parse(whitelistElementProperty.content);
          if (parsedContent) {
            setData(parsedContent);
          }
        } catch {
          // Ignore
        }
      }
    }
  }, [whitelistElementProperty]);

  useEffect(() => {
    if (
      whitelistElement &&
      whitelistElement.name.startsWith("securite") &&
      hasLevel("NATIONAL")
    ) {
      setEnableAllStationsKey(true);
    }
  }, [whitelistElement, hasLevel]);

  useEffect(() => {
    if (selectedKey && data[selectedKey]) {
      setCurrentData(data[selectedKey]);
    } else {
      setCurrentData([]);
    }
  }, [selectedKey, data]);

  useEffect(() => {
    const errorIndex = Object.values(validation).findIndex((v) => v.hasErrors);
    setHasError(errorIndex !== -1);
  }, [validation]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (whitelistElement) {
      setWhitelistElement({ ...whitelistElement, name: event.target.value });
      setEditedValues(true);
    }
  };

  const createKey = (newKey: string) => {
    if (!Object.keys(data).includes(newKey)) {
      setData((previous) => Object.assign({}, previous, { [newKey]: [] }));
      setEditedValues(true);
    }
    setSelectedKey(newKey);
  };

  const removeSelectedKey = () => {
    if (selectedKey && data[selectedKey]) {
      const updateData = Object.assign({}, data);
      delete updateData[selectedKey];
      setData(updateData);
      const keys = Object.keys(updateData).map((k) => {
        return !hasLevel("NATIONAL") && k === "allStations" ? undefined : k;
      });
      setSelectedKey(keys.pop() || "");
      setEditedValues(true);
    }
  };

  const hasEditRight = useMemo((): boolean => {
    if (!whitelist) return false;

    if (whitelist.locked) return false;

    return (
      (hasRights("WL_UG_VALUE_UPDATE") && "UG" === whitelist.type) ||
      (hasRights("WL_TRANSPORTER_VALUE_UPDATE") &&
        "TRANSPORTER" === whitelist.type)
    );
  }, [whitelist, hasRights]);

  return (
    <Dialog
      open={true}
      onClose={onDialogClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="xl"
    >
      {(!whitelist || !whitelistElement) && (
        <div style={{ textAlign: "center" }}>
          <Loader width={100} height={100} />
        </div>
      )}
      {whitelist && whitelistElement && (
        <>
          <DialogTitle id="form-dialog-title">
            <Box display={"flex"} justifyContent={"space-between"}>
              {canEdit ? "Modifier la valeur" : "Consulter la valeur"}
              {hasEditRight && !canEdit && (
                <IconButton
                  size={"small"}
                  onClick={() => {
                    onEdit();
                  }}
                >
                  <Create fontSize={"small"} color="secondary" />
                </IconButton>
              )}
            </Box>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={3} justifyContent={"space-between"}>
              <Grid item xs={6}>
                <BrighterTextField
                  id="name-text-field"
                  label="Nom"
                  value={whitelistElement.name}
                  onChange={handleNameChange}
                  disabled={!canEdit}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <StationList
                selected={selectedKey}
                stationsList={Object.keys(data)}
                enableAllStationsKey={enableAllStationsKey}
                canEdit={canEdit}
                onSelect={setSelectedKey}
                onAdd={createKey}
                onRemove={removeSelectedKey}
              />
              {selectedKey && (
                <PassListForm
                  listName={whitelistElement.name}
                  keyName={selectedKey}
                  passList={currentData}
                  canEdit={canEdit}
                  error={
                    validation[selectedKey] && validation[selectedKey].hasErrors
                  }
                  onChange={(passList) => {
                    setCurrentData(passList);
                    setValidation((previous) =>
                      Object.assign({}, previous, {
                        [selectedKey]: validatePassList(passList),
                      })
                    );
                    setData((previous) =>
                      Object.assign(previous, {
                        [selectedKey]: passList.filter((v) => v !== ""),
                      })
                    );
                    setEditedValues(true);
                  }}
                />
              )}
              {selectedKey &&
                validation[selectedKey] &&
                validation[selectedKey].hasErrors && (
                  <PassListErrors validation={validation[selectedKey]} />
                )}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() =>
                editedValues ? setShowCancelModifications(true) : onClose()
              }
            >
              Annuler
            </Button>
            {canEdit && (
              <Button
                variant="contained"
                color="secondary"
                onClick={handleSave}
                disabled={
                  !whitelistElement ||
                  !whitelistElement.name ||
                  hasError ||
                  !editedValues
                }
              >
                Enregistrer
              </Button>
            )}
            <ConfirmationModal
              open={showCancelModifications}
              title="Confirmation d'annulation des modifications"
              content={`Êtes-vous sûr de vouloir annuler les modifications apportées à la liste blanche « ${whitelistElement.name} » ?`}
              onCancel={() => setShowCancelModifications(false)}
              onConfirm={() => {
                setShowCancelModifications(false);
                onClose();
              }}
            />
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

import React, { useEffect, useContext } from "react";
import { Paper, Typography, Box, makeStyles, Theme } from "@material-ui/core";
import useRights from "../../../services/ducks/rights";
import ErrorIcon from "@material-ui/icons/Error";
import { SNCF_BCKGND_GRAY, SNCF_MAGENTA } from "../../../theme";
import { AppContext } from "../../../app-provider";

const useStyles = makeStyles((theme: Theme) => ({
  paperLayout: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    paddingBottom: theme.spacing(3),
    width: "60%",
    minHeight: "300px",
    margin: "auto",
  },
  mediumFontSize: {
    fontSize: "16px",
  },
  paperHeaderLayout: {
    alignItems: "center",
    backgroundColor: SNCF_BCKGND_GRAY,
    padding: theme.spacing(3),
  },
  bodyLayout: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  errorIconColor: {
    color: SNCF_MAGENTA,
  },
}));

function ForbiddenAccess() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [_, dispatch] = useContext(AppContext).reducers.auth!;
  const { getUserInfo, removeUserInfo } = useRights();
  const classes = useStyles();

  // Disconnects user after 10 seconds
  useEffect(() => {
    const disconnectTimeout = setTimeout(() => {
      removeUserInfo();
      dispatch({
        type: "LOGOUT",
      });
    }, 10_000);

    return () => clearTimeout(disconnectTimeout);
  }, []);

  const userInfo = getUserInfo();

  return (
    <Paper className={classes.paperLayout}>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        className={classes.paperHeaderLayout}
      >
        <Typography variant="h4">
          Accès à la supervision fixe impossible
        </Typography>
        <ErrorIcon fontSize="large" className={classes.errorIconColor} />
      </Box>
      <Box
        className={classes.mediumFontSize}
        display="flex"
        flex="1"
        flexDirection="column"
        justifyContent="center"
      >
        <Box className={classes.bodyLayout}>
          <Box>
            Votre profil
            <Box component="span" fontWeight="fontWeightBold" m={1}>
              {userInfo && userInfo.profileName}
            </Box>
            ne vous donne pas accès à la supervision fixe.
          </Box>
          <Box>Vous serez déconnecté dans 10 secondes.</Box>
        </Box>
      </Box>
    </Paper>
  );
}

export default ForbiddenAccess;

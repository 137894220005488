import React, { ReactNode } from "react";
import { makeStyles } from "@material-ui/core";
import { SNCF_COMPL_BLUE, SNCF_WHITE } from "../../../theme";
import { default as clsx } from "clsx";
import Paper from "@material-ui/core/Paper";
import Loader from "../../../components/loader";

export interface SectionData {
  label: string;
  value: string;
  root: boolean;
}

interface InfoSectionProperties {
  name: string;
  data: SectionData[];
  loader?: ReactNode;
  classNames?: {
    infoSectionContainer?: string[];
    infoSectionHeader?: string[];
  };
}

const useStyle = makeStyles({
  infoSectionContainer: {
    width: "50%",
  },
  infoSectionHeader: {
    color: SNCF_WHITE,
    padding: 10,
    fontWeight: "bold",
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  highlightedInfo: {
    color: SNCF_COMPL_BLUE,
    fontWeight: "bold",
  },
  infoSectionContent: {
    padding: "5px 20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  infoItemContainer: {
    display: "flex",
    width: "100%",
  },
  infoItemContainerSubLevel: {
    marginLeft: "15%",
  },
  infoItemValue: {},
  infoItemLabel: {
    flex: 1,
  },
});

const DefaultLoader = () => <Loader width={100} />;

export default function InfoSection(properties: InfoSectionProperties) {
  const classes = useStyle();
  const {
    name,
    data = [],
    classNames = {},
    loader = <DefaultLoader />,
  } = properties;

  return (
    <Paper
      className={clsx(
        classes.infoSectionContainer,
        classNames.infoSectionContainer
      )}
    >
      <div
        className={clsx(
          classes.infoSectionHeader,
          classNames.infoSectionHeader
        )}
      >
        {name}
      </div>
      <div className={clsx(classes.infoSectionContent)}>
        {data.length > 0
          ? data.map((item, index) => (
              <div key={index} className={clsx(classes.infoItemContainer)}>
                <div className={clsx(classes.infoItemLabel)}>
                  <div
                    className={clsx({
                      [classes.infoItemContainerSubLevel]: !item.root,
                    })}
                  >
                    {item.label}
                  </div>
                </div>
                <div
                  className={clsx(
                    classes.highlightedInfo,
                    classes.infoItemValue
                  )}
                >
                  {item.value}
                </div>
              </div>
            ))
          : loader}
      </div>
    </Paper>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { Station } from "../../services/interfaces/referential";
import ExpandableTable from "../../components/expandable-table";
import {
  ExpandableColumn,
  TreeNode,
  ValueRow,
} from "../../components/expandable-table/types";
import { TopLevelPanel } from "../../components/expandable-table/top-level-panel";
import { PanelContent } from "../../components/expandable-table/panel-content";
import {
  BundleVersionDTO,
  cancelDeployments,
  DeploymentStatus,
  fetchDeployments,
} from "../../services/api/deployments";
import DateFormatter from "../../components/date-formatter";
import { makeStyles } from "@material-ui/styles";
import { default as clsx } from "clsx";
import BundlesInfo from "./bundles-info";
import StateIcon from "./state-icon";
import Button from "@material-ui/core/Button";
import CreateDialog from "./create-dialog";
import { Checkbox } from "@material-ui/core";
import { AppContext } from "../../app-provider";
import { useSnackbar } from "notistack";
import RebootButton from "./reboot-button";
import ExpandableIcon from "../../components/expandable-table/expandable-icon";
import useRights from "../../services/ducks/rights";

interface ConfigurationTabProperties {
  stations: Station[];
  type: string;
}

export interface GateRow {
  rowType: "value";
  id: string;
  name: string;
  status: DeploymentStatus;
  bundles: BundleVersionDTO[];
  lastDeploymentDate?: string;
  laneName: string;
  stationCode: string;
  stationName: string;
  dockName: string;
  deploymentId: string;
}

interface StationRow {
  id: string;
  name: string;
  rows?: ValueRow<GateRow>[];
  sameVersions?: boolean;
}

enum BundleType {
  CONFIG = "CONFIG",
  SOFTWARE = "SOFTWARE",
}

const useStyles = makeStyles({
  headerMargin: {
    marginLeft: 30,
  },
  expandableTitle: {
    flex: 1,
  },
  columnCheckbox: {
    marginRight: 20,
  },
  columnName: {
    width: 100,
  },
  columnBundle: {
    flex: 1,
    marginLeft: "10px",
    marginRight: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  columnUpdate: {
    textAlign: "center",
    flex: 1,
  },
  columnVersions: {
    display: "flex",
    justifyContent: "center",
    width: 100,
  },
  columnState: {
    textAlign: "center",
    width: 150,
  },
  actions: {
    display: "flex",
    alignItems: "center",
    height: 100,
  },
  actionCancel: {
    marginLeft: 30,
  },
});

async function fetchDeploymentsForStation(station: StationRow, type: string) {
  const result = await fetchDeployments(type, [station.id]);
  const stationDocks = result[station.id];
  const s: ValueRow<GateRow>[] = stationDocks.docks.map((d) => {
    return {
      rowType: "expandable",
      label: d.dockName,
      sameVersions: d.sameVersions,
      values: d.gates.map((g) => ({
        rowType: "value",
        id: g.gateId,
        name: g.gateName,
        status: g.status,
        lane: g.laneName,
        bundles: g.bundles,
        lastDeploymentDate: g.lastDeploymentDate,
        laneName: g.laneName,
        stationCode: g.stationCode,
        stationName: station.name,
        dockName: d.dockName,
        deploymentId: g.id,
      })),
    };
  });

  const docks = s.sort((a, b) => {
    if (a.rowType === "expandable" && b.rowType === "expandable") {
      return a.label.localeCompare(b.label);
    }

    return 0;
  });

  return {
    docks,
    sameVersions: stationDocks.sameVersions,
  };
}

function renderStatus(status: DeploymentStatus) {
  switch (status) {
    case "INIT": {
      return "Initialisé";
    }
    case "CORE_DOWNLOADED": {
      return "Téléchargé sur le BOT";
    }
    case "CORE_DOWNLOAD_ERROR": {
      return "Echec téléchargement BOT";
    }
    case "GATE_DOWNLOAD_WAITING": {
      return "Téléchargement porte en attente";
    }
    case "GATE_DOWNLOADED": {
      return "Téléchargé et en attente d'installation sur la porte";
    }
    case "CANCELLED": {
      return "Annulé";
    }
    case "INSTALL_FAILURE": {
      return "Echec installation porte";
    }
  }
}

function findSelectedGateRows(roots: StationRow[], selectedRows: Set<string>) {
  return roots.flatMap((r) =>
    (r.rows || []).flatMap((row) => {
      if (row.rowType === "expandable") {
        return row.values.filter((g) => selectedRows.has(g.id));
      }
      return [];
    })
  );
}

function useColumns(type: string) {
  const classes = useStyles();
  return type === "CONFIG"
    ? [
        {
          title: "Ligne",
          className: clsx(classes.columnName, classes.headerMargin),
        },
        { title: "UP", className: classes.columnName },
        { title: "Technique", className: classes.columnBundle },
        {
          title: "Transverse",
          className: classes.columnBundle,
        },
        {
          title: "Transporteurs",
          className: classes.columnBundle,
        },
        { title: "Dernier déploiement", className: classes.columnUpdate },
        { title: "Versions", className: classes.columnVersions },
        { title: "Etat", className: classes.columnState },
      ]
    : [
        {
          title: "Ligne",
          className: clsx(classes.columnName, classes.headerMargin),
        },
        { title: "UP", className: classes.columnName },
        { title: "Porte", className: classes.columnBundle },
        { title: "Traitement", className: classes.columnBundle },
        {
          title: "Billetique",
          className: classes.columnBundle,
        },
        {
          title: "Décodeur",
          className: classes.columnBundle,
        },
        {
          title: "Script déploiement",
          className: classes.columnBundle,
        },
        { title: "Dernier déploiement", className: classes.columnUpdate },
        { title: "Versions", className: classes.columnVersions },
        { title: "Etat", className: classes.columnState },
      ];
}

function DeploymentTab({ stations, type }: ConfigurationTabProperties) {
  const [roots, setRoots] = useState<StationRow[]>([]);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set([]));
  const [_, confirmDialogDispatch] =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useContext(AppContext).reducers.confirmDialog!;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const selectedGateRows = findSelectedGateRows(roots, selectedRows);
  const { hasRights } = useRights();

  const hasCreateRight =
    (type === "SOFTWARE" && hasRights("DEPLOY_SOFT_CREATE")) ||
    (type === "CONFIG" && hasRights("DEPLOY_CONF_CREATE"));

  const hasCancelRight = hasRights("DEPLOY_CANCEL");

  const hasRebootRight = hasRights("DEPLOY_REBOOT");

  useEffect(() => {
    setRoots((previousRoots) =>
      stations.map((s) => {
        const previous = previousRoots.find((a) => a.id === s.id);
        return {
          id: s.id,
          name: s.name,
          sameVersions: previous ? previous.sameVersions : undefined,
          rows: (previous || { rows: undefined }).rows,
        };
      })
    );
  }, [stations]);

  useEffect(() => {
    if (roots.length > 0) {
      setRoots(roots.map((r) => ({ ...r, rows: undefined })));
    }
    setSelectedRows(new Set([]));
    setOpenCreate(false);
  }, [type]);

  const columns: ExpandableColumn<StationRow>[] = useColumns(type);

  async function handlerCancel() {
    try {
      await cancelDeployments(selectedGateRows.map((r) => r.id));

      // Refresh des stations
      const stationCodes = new Set(selectedGateRows.map((s) => s.stationCode));
      const updatedRows = roots.filter((r) => stationCodes.has(r.id));
      for (const r of updatedRows) {
        loadCurrentDeployments(r);
      }

      enqueueSnackbar(`Déploiement annulé avec succès`, {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(
        `Une erreur est survenue pendant l'annulation des déploiements`,
        {
          variant: "error",
        }
      );
    }
  }

  function renderTitle(station: StationRow) {
    return (
      <>
        <div className={classes.expandableTitle}>{station.name}</div>
        <div className={classes.columnVersions}>
          {station.sameVersions !== undefined && (
            <StateIcon ok={station.sameVersions} />
          )}
        </div>
        <div className={classes.columnState} />
      </>
    );
  }

  function renderValueRow(station: StationRow, gate: GateRow) {
    function handleCheck(event: React.ChangeEvent<HTMLInputElement>) {
      const r = new Set(selectedRows);
      if (event.target.checked) {
        r.add(gate.id);
      } else {
        r.delete(gate.id);
      }
      setSelectedRows(r);
    }

    function renderBundle(
      gate: GateRow,
      bundleGroup: string,
      type: BundleType
    ) {
      const bundles = gate.bundles.filter((b) => b.id.group === bundleGroup);

      switch (type) {
        case BundleType.CONFIG: {
          if (!hasRights("DEPLOY_CONF_LIST")) return <></>;
          break;
        }
        case BundleType.SOFTWARE: {
          if (!hasRights("DEPLOY_SOFT_LIST")) return <></>;
          break;
        }
      }

      return <BundlesInfo bundles={bundles} />;
    }

    return (
      <>
        <div className={classes.columnCheckbox}>
          <Checkbox
            checked={selectedRows.has(gate.id)}
            onChange={handleCheck}
          />
        </div>
        <div className={classes.columnName}>{gate.laneName}</div>
        <div className={classes.columnName}>{gate.name}</div>
        {type === "CONFIG" && (
          <>
            <div className={classes.columnBundle}>
              {renderBundle(gate, "technical", BundleType.CONFIG)}
            </div>
            <div className={classes.columnBundle}>
              {renderBundle(gate, "business", BundleType.CONFIG)}
            </div>
            <div className={classes.columnBundle}>
              {renderBundle(gate, "transporter", BundleType.CONFIG)}
            </div>
          </>
        )}
        {type === BundleType.SOFTWARE && (
          <>
            <div className={classes.columnBundle}>
              {renderBundle(gate, "gate", BundleType.SOFTWARE)}
            </div>
            <div className={classes.columnBundle}>
              {renderBundle(gate, "ticketprocessor", BundleType.SOFTWARE)}
            </div>
            <div className={classes.columnBundle}>
              {renderBundle(gate, "rfidvalidator", BundleType.SOFTWARE)}
            </div>
            <div className={classes.columnBundle}>
              {renderBundle(gate, "decoder", BundleType.SOFTWARE)}
            </div>
            <div className={classes.columnBundle}>
              {renderBundle(gate, "gate-daemon", BundleType.SOFTWARE)}
            </div>
          </>
        )}
        <div className={classes.columnUpdate}>
          <DateFormatter datetime={gate.lastDeploymentDate} />
        </div>
        <div className={classes.columnVersions} />
        <div className={classes.columnState}>{renderStatus(gate.status)}</div>
      </>
    );
  }

  function renderDock(
    station: StationRow,
    dock: TreeNode<GateRow>,
    index: number,
    open: boolean
  ) {
    function handleCheck(event: React.ChangeEvent<HTMLInputElement>) {
      const r = new Set(selectedRows);
      if (event.target.checked) {
        for (const g of dock.values) r.add(g.id);
      } else {
        for (const g of dock.values) r.delete(g.id);
      }
      setSelectedRows(r);
    }

    return (
      <>
        <Checkbox
          onClick={(event) => event.stopPropagation()}
          onChange={handleCheck}
          checked={dock.values
            .map((g) => g.id)
            .every((id) => selectedRows.has(id))}
        />
        <ExpandableIcon open={open} />
        {dock.label}
      </>
    );
  }

  async function loadCurrentDeployments(row: StationRow) {
    const { docks, sameVersions } = await fetchDeploymentsForStation(row, type);
    setRoots((previous) =>
      previous.map((r) => {
        if (r.id === row.id) {
          return { ...r, rows: docks, sameVersions };
        }
        return r;
      })
    );
  }

  function renderContent(row: StationRow, index: number) {
    return (
      <PanelContent
        root={row}
        index={index}
        onLoadPanel={() => loadCurrentDeployments(row)}
        expandableRowRenderer={renderDock}
        valueRowRenderer={renderValueRow}
        selectable={true}
      />
    );
  }

  function renderRow(station: StationRow, index: number) {
    return (
      <TopLevelPanel
        index={index}
        key={station.id}
        root={station}
        titleRenderer={renderTitle}
        contentRenderer={renderContent}
      />
    );
  }

  return (
    <>
      <ExpandableTable
        roots={roots}
        rowRenderer={renderRow}
        columns={columns}
        bottomHeightMargin={100}
      />
      <div className={classes.actions}>
        {hasCreateRight && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpenCreate(true)}
            disabled={selectedRows.size === 0}
          >
            Prévoir un déploiement
          </Button>
        )}
        {hasCancelRight && (
          <Button
            variant="contained"
            onClick={() => {
              confirmDialogDispatch({
                type: "open",
                title: "Annulation du déploiement",
                msg: `Êtes-vous sûr de vouloir annuler le déploiement sur les portes sélectionnées (${selectedRows.size}) ?`,
                submit: async () => await handlerCancel(),
              });
            }}
            disabled={selectedRows.size === 0}
            className={classes.actionCancel}
          >
            Annuler un déploiement
          </Button>
        )}
        {hasRebootRight && <RebootButton gates={selectedGateRows} />}
      </div>
      <CreateDialog
        open={openCreate}
        onClose={() => setOpenCreate(false)}
        onSubmit={() => {
          const stationCodes = new Set(
            selectedGateRows.map((s) => s.stationCode)
          );
          const updatedRows = roots.filter((r) => stationCodes.has(r.id));
          for (const r of updatedRows) {
            loadCurrentDeployments(r);
          }
        }}
        gates={selectedGateRows}
        type={type}
      />
    </>
  );
}

export default DeploymentTab;

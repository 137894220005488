import React from "react";
import { useSnackbar } from "notistack";
import { Button } from "@material-ui/core";

const SnackbarActions = ({ id }: { id?: string | number }) => {
  const { closeSnackbar } = useSnackbar();
  return (
    <>
      <Button style={{ color: "white" }} onClick={() => closeSnackbar(id)}>
        Fermer
      </Button>
    </>
  );
};

export default SnackbarActions;

import React, { ChangeEvent, createRef } from "react";
import { CloudUpload } from "@material-ui/icons";
import { Fab } from "@material-ui/core";

interface HelpFilesUploadButtonProperties {
  onUpload: (helpFile: File) => void;
}

export function HelpFilesUploadButton({
  onUpload,
}: HelpFilesUploadButtonProperties) {
  const inputReference = createRef<HTMLInputElement>();

  const handleUpload = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files) {
      onUpload(event.target.files[0]);
    }
  };

  const handleUploadClick = () => {
    if (inputReference.current) {
      inputReference.current.click();
    }
  };

  return (
    <div>
      <input
        type="file"
        accept="application/pdf"
        id="upload-help-file"
        onChange={handleUpload}
        style={{ display: "none" }}
        ref={inputReference}
      />
      <label htmlFor="upload-help-file">
        <Fab color="primary" onClick={handleUploadClick}>
          <CloudUpload />
        </Fab>
      </label>
    </div>
  );
}

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import classNames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { StationInfoState } from "../index";
import ItemElement from "components/list/item-element";
import Box from "@material-ui/core/Box";
import useRights from "../../../../services/ducks/rights";
import ChipsRenderer from "../../../../components/virtualized-table/renderers/chips-renderer";
import {
  BoardingModes,
  BoardingModesPlural,
  transporterOptions,
} from "../../../../services/interfaces/referential";

interface StationInfoDialogProperties {
  dialog: StationInfoState;
  setDialog: (status: StationInfoState) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      overflowY: "visible",
    },
    content: {
      padding: theme.spacing(3),
    },
    horizontalBoxes: {
      display: "flex",
    },
    horizontalBox: {
      flex: 1,
    },
    buttonsGroupLayout: {
      display: "flex",
      justifyContent: "flex-end",
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    itemElementLayout: {
      marginTop: theme.spacing(3),
    },
    row: {
      display: "flex",
      justifyContent: "space-around",
      marginTop: theme.spacing(2.5),
    },
  })
);

function StationInfoDialog({ dialog, setDialog }: StationInfoDialogProperties) {
  const classes = useStyles();
  const { hasRights } = useRights();

  function close() {
    setDialog({
      ...dialog,
      open: false,
    });
  }

  function edit() {
    close();
    if (dialog.edit) {
      dialog.edit();
    }
  }

  return (
    <Dialog
      open={dialog.open}
      onBackdropClick={close}
      onEscapeKeyDown={close}
      color={"primary"}
      aria-labelledby="form-dialog-title"
      PaperProps={{ className: classNames(classes.dialogPaper) }}
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        {dialog.data && dialog.data.name}
      </DialogTitle>
      <DialogContent className={classes.content}>
        {dialog.data && (
          <>
            <ItemElement
              label="URL de mise à jour"
              text={dialog.data.updateUrl}
              additionalClassName={classes.itemElementLayout}
            />
            <Box className={classes.horizontalBoxes}>
              <Box className={classes.horizontalBox}>
                <ItemElement
                  label="Code QLT"
                  text={dialog.data.id}
                  additionalClassName={classes.itemElementLayout}
                />
                <ItemElement
                  label="Id TT0020"
                  text={dialog.data.sqills}
                  additionalClassName={classes.itemElementLayout}
                />
                <ItemElement
                  label="Code UIC"
                  text={dialog.data.uic}
                  additionalClassName={classes.itemElementLayout}
                />
              </Box>
              <Box className={classes.horizontalBox}>
                <ItemElement
                  label="Zoom"
                  text={dialog.data.zoom}
                  additionalClassName={classes.itemElementLayout}
                />
                <ItemElement
                  label="Latitude"
                  text={dialog.data.lat}
                  additionalClassName={classes.itemElementLayout}
                />
                <ItemElement
                  label="Longitude"
                  text={dialog.data.lng}
                  additionalClassName={classes.itemElementLayout}
                />
              </Box>
            </Box>
          </>
        )}
        <Box className={classes.horizontalBoxes}>
          <Box className={classes.horizontalBox}>
            {dialog.data &&
              Object.entries(BoardingModes)
                .sort(([, boardingModeTitleA], [, boardingModeTitleB]) =>
                  boardingModeTitleA.localeCompare(boardingModeTitleB)
                )
                .map(([boardingMode]: [string, BoardingModes]) => (
                  <ItemElement
                    label={
                      BoardingModesPlural[
                        boardingMode as keyof typeof BoardingModesPlural
                      ]
                    }
                    key={boardingMode}
                    text={""}
                    style={{ width: "100%" }}
                    additionalClassName={classes.itemElementLayout}
                  >
                    <ChipsRenderer
                      elts={Object.entries(
                        dialog.data?.boardingModesTransporters || {}
                      )
                        .filter(
                          (t) =>
                            t[1][
                              boardingMode as keyof typeof BoardingModesPlural
                            ]
                        )
                        .map(
                          (t) =>
                            (
                              transporterOptions.find(
                                (index) => index.id === t[1].transporterName
                              ) || {
                                name: "",
                              }
                            ).name
                        )}
                    />
                  </ItemElement>
                ))}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions className={classes.buttonsGroupLayout}>
        {hasRights("REF_GARE_WRITE") && (
          <Button onClick={edit} color={"secondary"} variant="contained">
            Modifier
          </Button>
        )}
        <Button onClick={close} variant="contained">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default StationInfoDialog;

import { useContext } from "react";
import { getConfig } from "../config";
import { stringify } from "query-string";
import {
  loginWithOpenAM,
  loginWithPassword,
  refreshAuthToken as callRefreshAuthToken,
  userInfo,
} from "../api/auth";
import axios, { AxiosError, AxiosResponse } from "axios";
import { History } from "history";
import { AppContext } from "../../app-provider";
import debug from "debug";
import useRights from "./rights";
import { EUserType } from "services/api/users";
import { RequestError } from "services/interfaces/common";
import { add, isAfter, parseISO, sub } from "date-fns";

const log = debug("sncf:authenticationService");

// Credentials BOT
export interface UserAuth {
  access_token: string;
  token_type: string;
  expires_in: string;
  refresh_token: string;
}

export type RoleHierarchy = {
  read: Array<string>;
  write: Array<string>;
};

// User info + permissions
export interface UserInfo {
  name: string;
  profileId: string;
  profileName: string;
  activity: string;
  level: string;
  rights: string[];
  transporters: string[];
  stations: string[];
  groups: string[];
  type: EUserType;
  roleHierarchy: RoleHierarchy;
}

// User credentials stored
export interface StoredCredentials {
  credentials?: UserAuth;
  tokenExpirationDate?: Date;
  authError?: string;
}

// User context
export interface UserContext extends StoredCredentials {
  info?: UserInfo;
}

// User actions
export type UserAction =
  | { type: "REFRESH_TOKEN"; payload: UserAuth }
  | { type: "LOGIN"; payload: UserContext }
  | { type: "LOAD_EXISTING"; payload: UserContext }
  | { type: "LOGOUT"; payload?: string };

// récupere l'auth code après redirection de la mire idp
export const getAuthCodeFromRedirect = (): string => {
  const queryDict: { [s: string]: string } = {};
  const locationParameters = window.location.search.slice(1).split("&");
  for (const p of locationParameters) {
    const parameter = p.split("=");
    queryDict[parameter[0]] = parameter[1];
  }
  return queryDict.code;
};

// api d'auth connectée au context d'authent
export function useAuthApi(history: History) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [currentUser, dispatch] = useContext(AppContext).reducers.auth!;
  const { setUserInfo, removeUserInfo } = useRights();

  const logout = () => {
    log("Logging out");
    dispatch({
      type: "LOGOUT",
    });
    removeUserInfo();
    history.push("/");
  };

  const refreshAuthToken = async (user = currentUser) => {
    if (
      user.tokenExpirationDate &&
      isAfter(Date.now(), sub(user.tokenExpirationDate, { seconds: 5 }))
    ) {
      try {
        log("Refreshing access token");
        const newAuth = await callRefreshAuthToken(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          user.credentials!.refresh_token
        );
        dispatch({
          type: "REFRESH_TOKEN",
          payload: newAuth,
        });
        return newAuth;
      } catch {
        removeUserInfo();
        dispatch({
          type: "LOGOUT",
        });
      }
    }
  };

  return {
    tryLoadSavedUser: async () => {
      try {
        const storedUser = getStoredUser();
        if (storedUser && storedUser.credentials) {
          updateAxiosCredentials(storedUser.credentials);
          const userInfoData: UserInfo = await userInfo(
            storedUser.credentials.access_token
          );

          if (userInfoData) {
            const existingUser: UserContext = {
              ...storedUser,
              credentials: storedUser.credentials,
              tokenExpirationDate: parseISO(
                storedUser.tokenExpirationDate as unknown as string
              ),
            };
            log("Loading existing user");
            dispatch({
              type: "LOAD_EXISTING",
              payload: existingUser,
            });

            setUserInfo(userInfoData);
          }
        }
      } catch (error_: unknown) {
        const error = error_ as AxiosError<RequestError>;
        log(error.response);
        history.push("/");
        removeUserInfo();
        dispatch({
          type: "LOGOUT",
          payload: error.response?.data.message || undefined,
        });
      }
    },
    refreshAuthToken,
    loginWithCredentials: async (
      username: string,
      password: string,
      callback: () => void
    ) => {
      try {
        log("Authenticating with user/pass");
        const auth: UserAuth = await loginWithPassword(username, password);
        const userInfoData: UserInfo = await userInfo(auth.access_token);
        dispatch({
          type: "LOGIN",
          payload: {
            credentials: auth,
            tokenExpirationDate: add(Date.now(), {
              seconds: Number.parseInt(auth.expires_in),
            }),
          },
        });

        setUserInfo(userInfoData);
        history.push("/");
        callback();
      } catch (error_: unknown) {
        const error = error_ as AxiosError<RequestError>;
        log(error.response);
        history.push("/");
        removeUserInfo();
        dispatch({
          type: "LOGOUT",
          payload: error.response?.data.message || undefined,
        });
      }
    },
    loginWithOpenAMCode: async (
      code: string,
      andThen: () => void | Promise<void>
    ) => {
      try {
        log("Authenticating with openam auth code");
        const auth: UserAuth = await loginWithOpenAM(code);
        const userInfoData: UserInfo = await userInfo(auth.access_token);
        dispatch({
          type: "LOGIN",
          payload: {
            credentials: auth,
            tokenExpirationDate: add(Date.now(), {
              seconds: Number.parseInt(auth.expires_in),
            }),
          },
        });

        setUserInfo(userInfoData);
        andThen();
        history.push("/");
      } catch (error_: unknown) {
        const error = error_ as AxiosError<RequestError>;
        andThen();
        history.push("/");
        removeUserInfo();
        dispatch({
          type: "LOGOUT",
          payload: error.response?.data.message || undefined,
        });
      }
      history.push("/");
    },
    logout,
  };
}

let axiosInterceptor: number;

export const setAxiosInterceptor = (
  refreshTokenCallback: () => Promise<UserAuth | undefined>,
  logoutCallback: () => void
) => {
  if (axiosInterceptor !== undefined) {
    axios.interceptors.request.eject(axiosInterceptor);
  }
  log("Refreshing axios auth interceptor");
  axiosInterceptor = axios.interceptors.request.use(async (config) => {
    try {
      const newToken = await refreshTokenCallback();
      if (newToken) {
        config.headers.Authorization = `Bearer ${newToken.access_token}`;
      }
    } catch {
      log("Failed to refresh access token!");
    }
    return config;
  });
  axios.interceptors.response.use(
    async (config): Promise<AxiosResponse> => {
      return config;
    },
    async (error) => {
      log("api error", error.response);
      const { response } = error;
      if (response && response.status === 401) {
        logoutCallback();
      }

      throw error;
    }
  );
};

export const redirectOpenAM = () => {
  const config = getConfig();

  const gotoParameters = {
    response_type: "code",
    client_id: "DispositifEmbarquement",
    scope: "openid client_id profile",
    redirect_uri: `${window.location.origin}/${config.REDIRECT_PATH}`,
  };

  const alreadyRequestParameter = config.IDP_LOGIN_URL.includes("?");
  const gotoUrl =
    config.IDP_LOGIN_URL +
    (alreadyRequestParameter ? "&" : "?") +
    stringify(gotoParameters);

  // redirection
  window.location.href = gotoUrl;
};

const CREDENTIAL_STORAGE_KEY = "credentials_stored";

export const getStoredUser = (): StoredCredentials | undefined => {
  const serializedCredentials = localStorage.getItem(CREDENTIAL_STORAGE_KEY);
  if (serializedCredentials) {
    try {
      const storedCredentials: StoredCredentials = JSON.parse(
        serializedCredentials
      );
      return storedCredentials;
    } catch {
      return undefined;
    }
  }
  return undefined;
};

const setStoredCredentials = (credentials: StoredCredentials) => {
  localStorage.setItem(CREDENTIAL_STORAGE_KEY, JSON.stringify(credentials));
};

const updateAxiosCredentials = (credentials: UserAuth | undefined) => {
  log("Updating api credentials");
  if (credentials) {
    axios.defaults.headers.common.Authorization = `Bearer ${credentials.access_token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

export const initialState: UserContext = {};

export const reducer = (
  state: UserContext,
  action: UserAction
): UserContext => {
  let newUserState = state;
  log("New authentication action", action.type);

  switch (action.type) {
    case "LOAD_EXISTING": {
      newUserState = {
        ...state,
        ...action.payload,
      };
      break;
    }
    case "LOGIN": {
      newUserState = {
        ...state,
        ...action.payload,
      };
      break;
    }
    case "REFRESH_TOKEN": {
      newUserState = {
        ...state,
        credentials: action.payload,
        tokenExpirationDate: add(Date.now(), {
          seconds: Number.parseInt(action.payload.expires_in),
        }),
      };
      break;
    }
    case "LOGOUT": {
      newUserState = {
        authError: action.payload,
      };
      axios.interceptors.request.eject(axiosInterceptor);
      break;
    }
    default: {
      break;
    }
  }

  updateAxiosCredentials(newUserState.credentials);

  const credentials = {
    credentials: newUserState.credentials,
    tokenExpirationDate: newUserState.tokenExpirationDate,
    authError: newUserState.authError,
  };

  setStoredCredentials(credentials);

  return newUserState;
};

import React from "react";
import ExpandableIcon from "components/expandable-table/expandable-icon";
import { RenderedWhitelist } from ".";

export interface WhitelistRowProperties {
  root: RenderedWhitelist;
  open: boolean;
}

export const WhitelistExpandableRow: React.FC<WhitelistRowProperties> = ({
  root,
  open,
}) => (
  <>
    <ExpandableIcon open={open} />
    {root.name}
  </>
);

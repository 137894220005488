import React, { useEffect, useState, useMemo } from "react";
import Tabs, { Tab } from "../../components/tabs-component";
import { RouteComponentProps, withRouter } from "react-router";
import { default as TransporterTabs } from "./transporter-tabs";
import { default as useWhitelistStyles } from "./hooks/use-whitelist-styles";
import ExpandableTable from "components/expandable-table";
import {
  ExpandableColumn,
  RootRow,
  TreeNodeValue,
} from "components/expandable-table/types";
import {
  fetchWhitelistElements,
  fetchWhitelists,
  WhitelistDTO,
  WhitelistElementDTO,
} from "services/api/whitelists";
import { useTabs, TabKey } from "./hooks/use-tabs";
import { useSnackbar } from "notistack";
import { WhitelistRow } from "./whitelist-row";
import { WhitelistHelpButton } from "./whitelist-help-button";
import WhitelistCreateButton from "./whitelist-create-button";
import useRights from "services/ducks/rights";
import WhitelistElementModal from "./whitelist-element-modal/whitelist-element-modal";

export interface RouteProperties {
  type: TabKey;
  transporter?: string;
}

export type RenderedWhitelistElement = WhitelistElementDTO & TreeNodeValue;

export type RenderedWhitelist = WhitelistDTO &
  RootRow<RenderedWhitelistElement>;

function Whitelists({ history, match }: RouteComponentProps<RouteProperties>) {
  const tabs = useTabs();
  const [whitelists, setWhitelists] = useState<RenderedWhitelist[]>([]);
  const [transporter, setTransporter] = useState("");
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(() => {
    // select default tab to prevent duplcate fetching if we load
    // another tab by url
    const defaultTabIndex = tabs.findIndex((t) => t.key === match.params.type);

    return defaultTabIndex > -1 ? defaultTabIndex : 0;
  });
  const [selectedTab, setSelectedTab] = useState<Tab>();

  const [openWhitelist, setOpenWhitelist] = useState<WhitelistDTO>();
  const [openWhitelistElement, setOpenWhitelistElement] =
    useState<WhitelistElementDTO>();
  const [openWhitelistElementEdit, setOpenWhitelistElementEdit] =
    useState<boolean>(false);

  const { hasRights } = useRights();
  const classes = useWhitelistStyles();
  const { enqueueSnackbar } = useSnackbar();
  const columns: ExpandableColumn<WhitelistDTO>[] = [
    { title: "Nom", className: classes.columnName },
    { title: "Format", className: classes.columnFormat },
    { title: "Dernière modification", className: classes.columnUpdate },
    { title: "", className: classes.columnActions },
  ];

  const isTransporterSelected = () => match.params.type === TabKey.TRANSPORTER;
  const onChangeIndexTab = (index: number) =>
    history.push(`/whitelists/${tabs[index].key}`);
  const onTransporterTabChange = (tab: Tab) => setTransporter(tab.key);

  const onDeletedWhitelist = (whitelist: WhitelistDTO) => {
    setWhitelists(whitelists.filter((w) => w.id !== whitelist.id));
  };

  const onCreatedWhitelistElement = (whitelistElement: WhitelistElementDTO) => {
    const updatedWhitelists = [...whitelists];
    const whitelistIndex = updatedWhitelists.findIndex(
      (w) => w.id === whitelistElement.whitelistId
    );
    if (whitelistIndex < 0) return;
    updatedWhitelists[whitelistIndex].whitelistElements.push(
      whitelistElement.id
    );
    const rows = updatedWhitelists[whitelistIndex].rows || [];
    rows.push({
      ...whitelistElement,
      rowType: "value",
    });
    setWhitelists(updatedWhitelists);
  };

  const onUpdatedWhitelistElement = (whitelistElement: WhitelistElementDTO) => {
    const updatedWhitelists = [...whitelists];
    const whitelistIndex = updatedWhitelists.findIndex(
      (w) => w.id === whitelistElement.whitelistId
    );
    if (whitelistIndex !== -1) {
      const whitelistElementRows = updatedWhitelists[whitelistIndex].rows || [];
      const whiteListElementIndex = whitelistElementRows.findIndex(
        (w) => (w as WhitelistElementDTO).id === whitelistElement.id
      );
      if (
        whitelistIndex !== -1 &&
        whiteListElementIndex !== -1 &&
        updatedWhitelists[whitelistIndex].rows
      ) {
        const rows = updatedWhitelists[whitelistIndex].rows || [];
        rows[whiteListElementIndex] = {
          ...whitelistElement,
          rowType: "value",
        };
        setWhitelists(updatedWhitelists);
      }
    }
  };

  const onDeletedWhitelistElement = (whitelistElement: WhitelistElementDTO) => {
    const whitelistIndex = whitelists.findIndex(
      (w) => w.id === whitelistElement.whitelistId
    );

    if (whitelistIndex === -1) return;

    const updatedWhitelists = [...whitelists];
    updatedWhitelists[whitelistIndex].whitelistElements = updatedWhitelists[
      whitelistIndex
    ].whitelistElements.filter((element) => element !== whitelistElement.id);
    let rows = updatedWhitelists[whitelistIndex].rows || [];
    rows = rows.filter(
      (element) =>
        (element as RenderedWhitelistElement).id !== whitelistElement.id
    );
    updatedWhitelists[whitelistIndex].rows = rows;
    setWhitelists(updatedWhitelists);
  };

  useEffect(() => {
    if (match.params.type !== TabKey.TRANSPORTER) setTransporter("");

    setSelectedTabIndex(tabs.findIndex((t) => t.key === match.params.type));
    setWhitelists([]);
  }, [match.params.type]);

  useEffect(() => {
    if (tabs && selectedTabIndex < tabs.length) {
      setSelectedTab(tabs[selectedTabIndex]);
    }
  }, [tabs, selectedTabIndex]);

  useEffect(() => {
    async function loadWhitelists() {
      if (!selectedTab) {
        return;
      }

      try {
        const whitelists = await fetchWhitelists({
          whitelistType: selectedTab.key,
          transporter,
        });

        setWhitelists(whitelists);
      } catch {
        enqueueSnackbar(
          "Une erreur est survenue lors de la récupération des listes blanches",
          { variant: "error" }
        );
      }
    }

    // prevent fetching data if we are in transporter tab and no transporter is selected
    if (selectedTab && selectedTab.key === TabKey.TRANSPORTER && !transporter)
      return;

    loadWhitelists();
  }, [selectedTab, transporter]);

  const canViewHelp = useMemo(() => {
    return hasRights("WL_HELP_READ");
  }, [hasRights]);

  const canCreate: boolean = useMemo(() => {
    if (match.params.type == TabKey.UG) {
      return hasRights("WL_UG_CONFIG_CREATE");
    }
    if (match.params.type == TabKey.TRANSPORTER) {
      return hasRights("WL_TRANSPORTER_CONFIG_CREATE");
    }
    return false;
  }, [match.params.type, hasRights]);

  async function loadWhitelistElements(renderedWhitelist: RenderedWhitelist) {
    try {
      if (!selectedTab) {
        return;
      }

      const whitelistElements = await fetchWhitelistElements({
        id: renderedWhitelist.id,
        whitelistType: selectedTab.key,
        transporter,
      });

      setWhitelists(
        whitelists.map((whitelist) => {
          if (whitelist.id === renderedWhitelist.id) {
            return {
              ...whitelist,
              rows: whitelistElements.map((whitelistElement) => {
                return {
                  ...whitelistElement,
                  rowType: "value",
                };
              }),
            };
          }

          return whitelist;
        })
      );
    } catch {
      enqueueSnackbar(
        "Une erreur est survenue lors de la récupération des élements des listes blanches",
        { variant: "error" }
      );
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <h1 className={classes.titleLayout}>Whitelists</h1>
        <div className={classes.actions}>
          {canViewHelp && <WhitelistHelpButton />}
          {canCreate && (
            <WhitelistCreateButton
              refWhitelist={whitelists ? whitelists[0] : undefined}
              onCreate={(wl) => setWhitelists((wls) => [wl, ...wls])}
            />
          )}
        </div>
      </div>

      <div>
        <Tabs
          tabs={tabs}
          indexTab={selectedTabIndex}
          onChangeIndexTab={onChangeIndexTab}
          additionalClassName={
            isTransporterSelected() ? undefined : classes.tabs
          }
        />

        {isTransporterSelected() && (
          <TransporterTabs
            className={classes.tabs}
            onTabChange={onTransporterTabChange}
          />
        )}

        {selectedTab && (
          <ExpandableTable
            roots={whitelists}
            rowRenderer={(row, index) => (
              <WhitelistRow
                whitelist={row}
                index={index}
                key={index}
                selectedTab={selectedTab}
                loadWhitelistElements={loadWhitelistElements}
                onDeletedWhitelist={onDeletedWhitelist}
                onCreatedWhitelistElement={onCreatedWhitelistElement}
                onDeletedWhitelistElement={onDeletedWhitelistElement}
                onViewWhitelistElement={(wl, wle) => {
                  setOpenWhitelist(wl);
                  setOpenWhitelistElement(wle);
                  setOpenWhitelistElementEdit(false);
                }}
                onEditWhitelistElement={(wl, wle) => {
                  setOpenWhitelist(wl);
                  setOpenWhitelistElement(wle);
                  setOpenWhitelistElementEdit(true);
                }}
              />
            )}
            columns={columns}
            bottomHeightMargin={70}
          />
        )}
      </div>
      <div>
        {openWhitelist && openWhitelistElement && (
          <WhitelistElementModal
            whitelistId={openWhitelist.id}
            whitelistElementId={openWhitelistElement.id}
            onClose={() => {
              setOpenWhitelist(undefined);
              setOpenWhitelistElement(undefined);
            }}
            onSave={onUpdatedWhitelistElement}
            editMode={openWhitelistElementEdit}
          />
        )}
      </div>
    </div>
  );
}

export default withRouter(Whitelists);

import { IdNameObject } from "./common";

// TODO : to fix and use configuration from BOT
export const transporterOptions: { id: string; name: string }[] = [
  { id: "VOYAGES", name: "Voyages" },
  { id: "TER", name: "TER" },
  { id: "JC", name: "J&C" },
  { id: "OUIGO", name: "Ouigo" },
  { id: "EUROPE", name: "Europe" },
  { id: "INTERCITES", name: "Intercités" },
  { id: "TRENITALIA", name: "TrenItalia" },
];

export interface StationBoardingStatus {
  id: string;
  name: string;
  activeBoardingCount: number;
  completedBoardings: number;
  liftedBoardings: number;
  passengersCount: number;
  passengerFlow: number;
}

export interface StationMapStatus {
  id: string;
  name: string;
  lat: number;
  lng: number;
  zoom: number;
  percentGateAlive: number;
  activeBoardingCount: number;
}

export interface ItemName extends IdNameObject {
  name: string;
}

export interface BoardingModesTransporter {
  stationId?: string;
  transporterName?: string;
  ttl: boolean;
  unboarding: boolean;
  boarding: boolean;
  mission: boolean;
}

export interface Station extends IdNameObject {
  name: string;
  uic: number;
  sqills: string;
  equipped: boolean;
  lat: number;
  lng: number;
  zoom: number;
  updateUrl: string;
  boardingModesTransporters: BoardingModesTransporter[];
}

export interface StationEquippedOnly {
  patchEquipped?: boolean;
  equipped: boolean;
}

export interface StationUpdate {
  patchUpdate?: boolean;
  updateUrl: string;
  boardingModesTransporters: BoardingModesTransporter[];
}

export interface Group extends IdNameObject {
  name: string;
  type: string;
  stationIds: string[];
}

export interface TrackLane {
  name: string;
  trackId: string;
  laneId: string;
  dockId: string;
  type: "" | "MAIN" | "SECONDARY";
  position: "" | "NONE" | "LEFT" | "RIGHT";
}

export interface Dock extends IdNameObject {
  name: string;
  description: string;
  stationId: string;
  trackIds: string[];
  laneIds: string[];
  trackLaneDTOList: TrackLane[];
  tnBlacklistEnabled: boolean;
}

export interface Lane extends IdNameObject {
  name: string;
  description: string;
  gateIds: string[];
  dockId: string;
  stationId: string;
  trackLaneDTOList: TrackLane[];
}

export enum BoardingModes {
  mission = "Transporteur Utilisateur Embarquement Mission",
  ttl = "Transporteur Non Utilisateur (géré via le TT TNU)",
  unboarding = "Transporteur Utilisateur Débarquement Contrôlé",
  boarding = "Transporteur Utilisateur Embarquement Libre",
}

export enum BoardingModesPlural {
  mission = "Transporteurs Utilisateurs Embarquement Mission",
  ttl = "Transporteurs Non Utilisateurs (gérés via le TT TNU)",
  unboarding = "Transporteurs Utilisateurs Débarquement Contrôlé",
  boarding = "Transporteurs Utilisateurs Embarquement Libre",
}

export enum LedColor {
  OFF,
  RED,
  GREEN,
  YELLOW,
  BLUE,
  MAGENTA,
  CYAN,
  WHITE,
  FLASHING_RED,
  FLASHING_GREEN,
  FLASHING_YELLOW,
  FLASHING_BLUE,
  FLASHING_MAGENTA,
  FLASHING_CYAN,
  FLASHING_WHITE,
  GREEN_SQUARE,
  RED_SQUARE,
  YELLOW_SQUARE,
}

export interface Gate {
  id: string;
  name: string;
  pmr: boolean;
  gateUrlAddress: string;
  active: boolean;
  laneId: string;
  dockId: string;
  stationId: string;
  lafBottomScreenEnabled: boolean;
  lafAdditionalGateAddress?: string;
  baseColor: LedColor;
  customColor: LedColor;
  controlTnEnabled: boolean;
}

export interface Track extends IdNameObject {
  name: string;
  description: string;
  dockId: string;
  stationId: string;
  trackLaneDTOList: TrackLane[];
}

export interface Zone extends IdNameObject {
  name: string;
  description: string;
  laneIds: string[];
  stationId: string;
}

import React, { useContext, useState } from "react";
import {
  addDock,
  deleteDock,
  deleteDocks,
  updateDock,
} from "../../../services/api/docks";
import { Dock, Lane, Track } from "../../../services/interfaces/referential";
import OptionsButton from "../../../components/options-button";
import { Paper, Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { AppContext } from "../../../app-provider";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import DockFormDialog from "./dialog/dock-form-dialog";
import { UseCrud } from "../../../commons/use-crud";
import { fetchTracks } from "../../../services/api/tracks";
import { fetchLanes } from "../../../services/api/lanes";
import DockViewDialog from "./dialog/dock-view-dialog";
import { useSnackbar } from "notistack";
import SortableAndSearchableVirtualizedTable from "../../../components/virtualized-table/sortable-and-searchable-virtualized-table";
import PopoverWrapperRenderer from "../../../components/virtualized-table/renderers/popover-wrapper-renderer";
import ChipsRenderer from "../../../components/virtualized-table/renderers/chips-renderer";
import useRights from "../../../services/ducks/rights";

interface DockTabProperties {
  stationId: string;
  lanesState: UseCrud<Lane>;
  tracksState: UseCrud<Track>;
  docksState: UseCrud<Dock>;
  dataInFetching: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
    },
    fab: {
      float: "right",
      margin: theme.spacing(1),
    },
  })
);

export interface DockFormState {
  open: boolean;
  action?: "add" | "edit";
  data?: Dock;
  submit?: (dock: Dock) => void | Promise<void>;
}

export interface DockViewState {
  open: boolean;
  data?: Dock;
  edit?: () => void | Promise<void>;
}

/**
 * DockTab Component
 */
function DockTab({
  stationId,
  lanesState,
  tracksState,
  docksState,
  dataInFetching,
}: DockTabProperties) {
  const classes = useStyles();
  const { hasRights } = useRights();

  const lanes = lanesState.datas;
  const tracks = tracksState.datas;
  const docks = docksState.datas;
  const [selected, setSelected] = useState<Set<string>>(new Set([]));
  const [, confirmDialogDispatch] =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useContext(AppContext).reducers.confirmDialog!;
  const [formDialog, setFormDialog] = useState<DockFormState>({
    open: false,
  });
  const [viewDialog, setViewDialog] = useState<DockViewState>({
    open: false,
  });
  const { enqueueSnackbar } = useSnackbar();

  // ----------- ReactVirtualizedTable
  const handleSelected = (dock: Dock, checked: boolean) => {
    const updateSelected = new Set(selected);
    checked ? updateSelected.add(dock.id) : updateSelected.delete(dock.id);
    setSelected(updateSelected);
  };

  const isSelected = (dock: Dock): boolean => {
    return selected.has(dock.id);
  };

  // ----------- api actions
  async function deleteById(rowIndex: number) {
    handleSelected(docks[rowIndex], false);
    try {
      await deleteDock(docks[rowIndex].id);
      enqueueSnackbar(
        `Zone étanche "${docks[rowIndex].name}" supprimée avec succès`,
        {
          variant: "success",
        }
      );
      docksState.remove(docks[rowIndex].id);
      if (tracks.some((t) => t.dockId === docks[rowIndex].id)) {
        fetchTracks(stationId).then((newTracks) => tracksState.set(newTracks));
      }
      if (lanes.some((l) => l.dockId === docks[rowIndex].id)) {
        fetchLanes(stationId).then((newLanes) => lanesState.set(newLanes));
      }
    } catch {
      enqueueSnackbar(`Erreur lors de la suppression de la zone étanche`, {
        variant: "error",
      });
    }
  }

  async function deleteBySelection() {
    try {
      await deleteDocks(selected);
      enqueueSnackbar(
        selected.size > 0
          ? `Zones étanches supprimées avec succès`
          : `Zone étanche "${
              selected.values().next().value
            }" supprimée avec succès`,
        { variant: "success" }
      );
      docksState.removeAll(selected);
      if (tracks.some((t) => [...selected].includes(t.dockId))) {
        fetchTracks(stationId).then((newTracks) => tracksState.set(newTracks));
      }
      if (lanes.some((l) => [...selected].includes(l.dockId))) {
        fetchLanes(stationId).then((newLanes) => lanesState.set(newLanes));
      }
      setSelected(new Set([]));
    } catch {
      enqueueSnackbar(
        selected.size > 0
          ? `Erreur lors de la suppression des zones étanches`
          : `Erreur lors de la suppression de la zone étanche`,
        {
          variant: "error",
        }
      );
    }
  }

  async function add(dock: Dock) {
    try {
      docksState.add(await addDock(dock));
      enqueueSnackbar(`Zone étanche créée avec succès`, { variant: "success" });
      if (dock.trackLaneDTOList.length > 0) {
        fetchTracks(stationId).then((t) => tracksState.set(t));
        fetchLanes(stationId).then((l) => lanesState.set(l));
      }
    } catch {
      enqueueSnackbar(`Erreur lors de la création de la zone étanche`, {
        variant: "error",
      });
    }
  }

  async function edit(dock: Dock) {
    try {
      const oldDock = docks.find((d) => d.id === dock.id);
      docksState.update(await updateDock(dock));
      enqueueSnackbar(`Zone étanche modifiée avec succès`, {
        variant: "success",
      });
      // Si les deux listes de trackDocks sont différentes
      if (
        oldDock &&
        (oldDock.trackLaneDTOList.some(
          (l) => !dock.trackLaneDTOList.includes(l)
        ) ||
          dock.trackLaneDTOList.some(
            (l) => !oldDock.trackLaneDTOList.includes(l)
          ))
      ) {
        fetchLanes(stationId).then((l) => lanesState.set(l));
        fetchTracks(stationId).then((d) => tracksState.set(d));
      }
    } catch {
      enqueueSnackbar(`Erreur lors de la modification de la zone étanche`, {
        variant: "error",
      });
    }
  }

  // ----------- renders

  const renderOptionsButton = (rowIndex: number) => {
    function displayFormDialog() {
      setFormDialog({
        open: true,
        action: "edit",
        data: { ...docks[rowIndex] },
        submit: (dock: Dock) => edit(dock),
      });
    }

    function displayViewDialog() {
      setViewDialog({
        open: true,
        data: { ...docks[rowIndex] },
        edit: displayFormDialog,
      });
    }

    const consultAction = {
      label: "Consulter la zone étanche",
      handler: displayViewDialog,
    };

    const buttonActions = hasRights("REF_GARE_WRITE")
      ? [
          consultAction,
          {
            label: "Modifier les informations",
            handler: displayFormDialog,
          },
          {
            label: "Supprimer la zone étanche",
            handler: () =>
              confirmDialogDispatch({
                type: "open",
                title: "Suppression de zone étanche",
                msg:
                  'Êtes-vous sûr de vouloir supprimer la zone étanche ?"' +
                  docks[rowIndex].name +
                  '" ?',
                submit: () => deleteById(rowIndex),
              }),
          },
        ]
      : [consultAction];

    return <OptionsButton buttonActions={buttonActions} />;
  };

  const headerLabelDock = [
    {
      label: "Nom",
      dataKey: "name",
      sortable: true,
      searchable: true,
      flexGrow: 0.5,
    },
    {
      label: "Description",
      dataKey: "description",
      sortable: true,
      searchable: true,
    },
    {
      label: "Lignes",
      dataKey: "laneIds",
      sortable: true,
      searchable: true,
      searchableElements: (dock: Dock) => lanesState.getNames(dock.laneIds),
      renderer: (dock: Dock) => (
        <PopoverWrapperRenderer>
          <ChipsRenderer
            elts={dataInFetching ? [] : lanesState.getNames(dock.laneIds)}
          />
        </PopoverWrapperRenderer>
      ),
    },
    {
      label: "Voies",
      dataKey: "trackIds",
      sortable: true,
      searchable: true,
      searchableElements: (dock: Dock) => tracksState.getNames(dock.trackIds),
      renderer: (dock: Dock) => (
        <PopoverWrapperRenderer>
          <ChipsRenderer
            elts={dataInFetching ? [] : tracksState.getNames(dock.trackIds)}
          />
        </PopoverWrapperRenderer>
      ),
    },
    {
      label: "Diffusion listes noires TN",
      dataKey: "tnBlacklistEnabled",
      sortable: true,
      searchable: false,
      renderer: (dock: Dock) => (
        <PopoverWrapperRenderer>
          {dataInFetching ? "" : dock.tnBlacklistEnabled ? "OUI" : "NON"}
        </PopoverWrapperRenderer>
      ),
      renderButtonActions: renderOptionsButton,
    },
  ];

  return (
    <>
      <DockFormDialog
        form={formDialog}
        setForm={setFormDialog}
        stationId={stationId}
        lanesState={lanesState}
        tracksState={tracksState}
      />
      <DockViewDialog
        dialog={viewDialog}
        setDialog={setViewDialog}
        lanesState={lanesState}
        tracksState={tracksState}
      />
      <Paper className={classes.root}>
        <SortableAndSearchableVirtualizedTable
          rows={docks}
          rowCount={docks.length}
          updateRows={docksState.set}
          columns={headerLabelDock}
          selected={selected}
          setSelected={setSelected}
          handleSelected={handleSelected}
          isSelected={isSelected}
          dataInFetching={dataInFetching}
          renderSelectionCheckboxes={hasRights("REF_GARE_WRITE")}
        />
      </Paper>
      {hasRights("REF_GARE_WRITE") && (
        <div>
          <Tooltip title="Ajouter" aria-label="Ajouter" className={classes.fab}>
            <Fab
              color="secondary"
              onClick={() =>
                setFormDialog({
                  open: true,
                  action: "add",
                  submit: add,
                })
              }
            >
              <AddIcon />
            </Fab>
          </Tooltip>
          <Tooltip
            title="Supprimer"
            aria-label="Supprimer"
            className={classes.fab}
          >
            <div>
              <Fab
                disabled={selected.size === 0}
                onClick={() =>
                  confirmDialogDispatch({
                    type: "open",
                    title: "Suppression de zone étanche",
                    msg: "Êtes-vous sûr de vouloir supprimer cette sélection ?",
                    submit: deleteBySelection,
                  })
                }
              >
                <DeleteIcon />
              </Fab>
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
}

export default DockTab;

import React, { ChangeEvent, PureComponent } from "react";
import { default as clsx } from "clsx";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import {
  AutoSizer,
  Column,
  Table,
  TableCellRenderer,
  TableHeaderProps,
} from "react-virtualized";
import { CircularProgress } from "@material-ui/core";
import Sort, { SortDirectionType } from "./sort";
import SearchField from "./search-field";
import { IndexedRow } from "./sortable-and-searchable-virtualized-table";

const styles = (theme: Theme) =>
  createStyles({
    flexContainer: {
      display: "flex",
      alignItems: "center",
      boxSizing: "border-box",
      borderStyle: "none",
      overflow: "hidden",
    },
    fullSize: {
      position: "relative",
      overflow: "hidden",
      width: "100%",
    },
    table: {
      outline: "none",
    },
    headerRow: {
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
    },
    tableRowHeader: {
      color: "white",
      backgroundColor: theme.palette.secondary.light,
    },
    tableRow: {
      cursor: "pointer",
    },
    tableRowHover: {
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
    tableCellHeader: {
      color: "white",
      fontWeight: "bold",
    },
    tableCell: {
      color: "black",
    },
    noClick: {
      cursor: "initial",
    },
    center: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  });

interface Row {
  index: number;
}

export interface BaseMuiVirtualizedTableProperties {
  setSelected?: (select: Set<string>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSelected?: (row: any, checked: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isSelected?: (row: any) => boolean;
  selected?: Set<string>;
  renderSelectionCheckboxes?: boolean;
  columns: ColumnData[];
  rows: IndexedRow[];
  headerHeight?: number;
  onRowClick?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  updateRows: (any: any) => void;
  rowCount: number;
  rowHeight?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowStyle?: (row: any) => string | undefined;
  dataInFetching?: boolean;
  computeTableHeightUpTo?: number;
  minTableHeight?: number;
  disableHeight?: boolean;
  onSort?: (sortBy: string, sortDirection: SortDirectionType) => void;
  onSearch?: (key: string, value: string) => void;
}

interface MuiVirtualizedTableProperties
  extends BaseMuiVirtualizedTableProperties,
    WithStyles<typeof styles> {
  // utilisation static defaultProps
  headerHeight: number;
  rowHeight: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  enableRowCheckbox?: (row: any) => boolean;
}

export interface ColumnData {
  dataKey: string;
  label: string;
  numeric?: boolean;
  flexGrow?: number;
  flexShrink?: number;
  width?: number;
  renderer?: (
    row: any, // eslint-disable-line @typescript-eslint/no-explicit-any
    index: number
  ) => JSX.Element | Element | string | null | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  searchableElements?: (element: any) => string | string[];
  renderButtonActions?: (index: number) => JSX.Element | Element;
  sortable?: boolean;
  searchable?: boolean;
  sortDirection?: SortDirectionType;
  sortPriority?: number;
}

const WhiteCheckbox = withStyles({
  root: {
    color: "white",
    "&$checked": {
      color: "white",
    },
  },
  checked: {},
})((properties: CheckboxProps) => <Checkbox color="default" {...properties} />);

class MuiVirtualizedTable extends PureComponent<MuiVirtualizedTableProperties> {
  static defaultProps = {
    headerHeight: 52,
    rowHeight: 52,
  };

  getRowClassName = ({ index }: Row) => {
    const { classes, onRowClick } = this.props;

    return clsx(classes.tableRow, classes.flexContainer, {
      [classes.headerRow]: index === -1,
      [classes.tableRowHeader]: index === -1,
      [classes.tableRowHover]: index !== -1 && onRowClick != undefined,
    });
  };

  handleMasterSelected = (
    event: ChangeEvent<HTMLElement>,
    checked: boolean
  ) => {
    const {
      rows,
      handleSelected,
      setSelected,
      enableRowCheckbox = () => true,
    } = this.props;
    if (setSelected && handleSelected) {
      setSelected(
        new Set(
          checked
            ? rows.filter((row) => enableRowCheckbox(row)).map((row) => row.id)
            : []
        )
      );
    }
  };

  cellRenderer: TableCellRenderer = ({ columnIndex, rowIndex, dataKey }) => {
    const {
      renderSelectionCheckboxes = true,
      columns,
      classes,
      rowHeight,
      onRowClick,
      rows,
      isSelected,
      handleSelected,
      rowStyle,
      enableRowCheckbox = () => true,
    } = this.props;

    const column = columns[columnIndex];

    if (!column) return;

    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCell,
          classes.flexContainer,
          rowStyle !== undefined && rowStyle(rows[rowIndex]),
          { [classes.noClick]: onRowClick == undefined }
        )}
        variant="body"
        style={{ height: rowHeight, width: "100%" }}
        align={"justify"}
      >
        {renderSelectionCheckboxes &&
          !columnIndex &&
          isSelected &&
          handleSelected && (
            <Checkbox
              disabled={!enableRowCheckbox(rows[rowIndex])}
              checked={isSelected(rows[rowIndex])}
              onChange={(event: ChangeEvent<HTMLElement>, checked: boolean) => {
                handleSelected(rows[rowIndex], checked);
              }}
            />
          )}
        <span className={classes.fullSize}>
          {column.renderer
            ? column.renderer(rows[rowIndex], rowIndex)
            : rows[rowIndex][dataKey]}
        </span>
        {column.renderButtonActions &&
          column.renderButtonActions(rows[rowIndex].rowIndex || 0)}
      </TableCell>
    );
  };

  headerRenderer = ({
    columnIndex,
    dataKey,
    onSort,
    onSearch,
  }: TableHeaderProps & {
    columnIndex: number;
    onSort?: (sortBy: string, sortDirection: SortDirectionType) => void;
    onSearch?: (key: string, value: string) => void;
  }) => {
    const {
      renderSelectionCheckboxes = true,
      headerHeight,
      classes,
      columns,
      rows,
      isSelected,
      enableRowCheckbox = () => true,
    } = this.props;

    return (
      <TableCell
        component="div"
        className={clsx(
          classes.tableCellHeader,
          classes.flexContainer,
          classes.noClick
        )}
        variant="head"
        style={{
          height: headerHeight,
        }}
      >
        {renderSelectionCheckboxes && !columnIndex && (
          <WhiteCheckbox
            checked={
              rows.length > 0 &&
              rows
                .filter((row) => enableRowCheckbox(row))
                .every(
                  (row) =>
                    row !== undefined &&
                    isSelected !== undefined &&
                    isSelected(row)
                )
            }
            onChange={this.handleMasterSelected}
          />
        )}
        {onSearch && columns[columnIndex].searchable ? (
          <SearchField
            searchable={columns[columnIndex].searchable}
            dataKey={columns[columnIndex].dataKey}
            label={columns[columnIndex].label}
            styles={classes.fullSize}
            onSearch={onSearch}
          />
        ) : (
          <span className={classes.fullSize}>{columns[columnIndex].label}</span>
        )}
        {onSort && columns[columnIndex].sortable && (
          <Sort
            onSortChange={(sortDirection: SortDirectionType) => {
              onSort(dataKey, sortDirection);
            }}
            direction={columns[columnIndex].sortDirection || false}
            priority={columns[columnIndex].sortPriority || 0}
          />
        )}
      </TableCell>
    );
  };

  computeTableHeight = (
    maxHeight: number,
    minHeight: number,
    rowsSize: number,
    rowHeight: number,
    headerHeight: number
  ) => {
    const height = headerHeight + rowsSize * rowHeight;
    const computed = height < maxHeight ? height : maxHeight;
    return computed < minHeight ? minHeight : computed;
  };

  render() {
    const {
      classes,
      columns,
      rows,
      headerHeight,
      rowHeight,
      dataInFetching = false,
      computeTableHeightUpTo,
      minTableHeight = 0,
      disableHeight = false,
      onSort,
      onSearch,
    } = this.props;

    const tableHeight =
      computeTableHeightUpTo &&
      this.computeTableHeight(
        computeTableHeightUpTo,
        minTableHeight,
        rows.length,
        rowHeight,
        headerHeight
      );
    return (
      <AutoSizer
        disableHeight={disableHeight}
        style={{ height: tableHeight || "none", outline: "none" }}
      >
        {(properties) => (
          <Table
            height={properties.height}
            width={properties.width}
            rowGetter={({ index }: { index: number }) => rows[index]}
            rowCount={rows.length}
            rowClassName={this.getRowClassName}
            noRowsRenderer={() => (
              <div className={classes.center}>
                {dataInFetching ? <CircularProgress /> : <p>Pas de données</p>}
              </div>
            )}
            rowHeight={rowHeight}
            headerHeight={headerHeight}
            disableSort
            gridClassName={classes.table}
            className={classes.headerRow}
          >
            {columns.map(
              (
                { dataKey, flexGrow = 1, flexShrink = 0, width = 0, ...other },
                index
              ) => {
                return (
                  <Column
                    key={dataKey}
                    headerRenderer={(headerProperties) =>
                      this.headerRenderer({
                        ...headerProperties,
                        columnIndex: index,
                        onSort,
                        onSearch,
                      })
                    }
                    className={classes.flexContainer}
                    headerClassName={classes.headerRow}
                    cellRenderer={this.cellRenderer}
                    dataKey={dataKey}
                    {...other}
                    width={width}
                    flexGrow={width ? 0 : flexGrow} // désactive flex sur la colonne si width defini
                    flexShrink={width ? 0 : flexShrink}
                  />
                );
              }
            )}
          </Table>
        )}
      </AutoSizer>
    );
  }
}

export default withStyles(styles)(MuiVirtualizedTable);

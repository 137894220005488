import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import fileDownload from "js-file-download";
import { HelpFilesDialog } from "./help-files-dialog";
import {
  fetchHelpFiles,
  uploadHelpFile,
  deleteHelpFile,
  downloadHelpFile,
} from "services/api/help-files";

interface HelpFilesModalProperties {
  open: boolean;
  onClose: () => void;
  helpType: string;
  canDelete?: boolean;
  canUpload?: boolean;
}

export function HelpFilesModal({
  open,
  onClose,
  helpType,
  canDelete = false,
  canUpload = false,
}: HelpFilesModalProperties) {
  const [helpFiles, setHelpFiles] = useState<Array<string>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleFileUpload = async (file: File): Promise<string> => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("filename", file.name);

    return uploadHelpFile(helpType, formData);
  };

  const handleUpload = (file: File) => {
    setUploading(true);
    handleFileUpload(file)
      .then((filename) => setHelpFiles((hfs) => [...hfs, filename]))
      .then(() => enqueueSnackbar("Envoi réussi", { variant: "success" }))
      .catch((error: unknown) =>
        enqueueSnackbar((error as Error).toString(), { variant: "error" })
      )
      .finally(() => setUploading(false));
  };

  const handleDelete = (filename: string) => {
    const deleteFile = async () => {
      try {
        await deleteHelpFile(helpType, filename);
        enqueueSnackbar(`Fichier ${filename} supprimé`, { variant: "success" });
        setHelpFiles((hfs) => hfs.filter((f) => f !== filename));
      } catch (error_: unknown) {
        const error = error_ as Error;
        enqueueSnackbar(error.toString(), { variant: "error" });
      }
    };

    deleteFile();
  };

  const handleDownload = (filename: string) => {
    const downloadFile = async () => {
      try {
        const r = await downloadHelpFile(helpType, filename);
        fileDownload(r, filename);
      } catch (error_: unknown) {
        const error = error_ as Error;
        enqueueSnackbar(error.toString(), { variant: "error" });
      }
    };

    downloadFile();
  };

  useEffect(() => {
    if (open) {
      let unsubscribed = false;
      const fetchFiles = async () => {
        setLoading(true);
        try {
          const fetchedFiles = await fetchHelpFiles(helpType);
          if (!unsubscribed) {
            setHelpFiles(fetchedFiles);
          }
        } catch (error_: unknown) {
          const error = error_ as Error;
          enqueueSnackbar(error.toString(), { variant: "error" });
        } finally {
          setLoading(false);
        }
      };

      fetchFiles();
      return () => {
        unsubscribed = true;
      };
    }
  }, [open, helpType, enqueueSnackbar]);

  return (
    <HelpFilesDialog
      open={open}
      onClose={onClose}
      loading={loading}
      uploading={uploading}
      helpFiles={helpFiles}
      onDownload={handleDownload}
      onUpload={handleUpload}
      canUpload={canUpload}
      onDelete={handleDelete}
      canDelete={canDelete}
    />
  );
}

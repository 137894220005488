import React, { useEffect, createRef } from "react";
import { makeStyles } from "@material-ui/styles";
import { Marker } from "react-leaflet";
import { default as clsx } from "clsx";
import {
  SNCF_COMPL_GREEN,
  SNCF_COMPL_YELLOW,
  SNCF_MAGENTA,
} from "../../../theme";
import { DivIcon } from "leaflet";
import debug from "debug";
import { StationMapStatus } from "../../../services/interfaces/referential";

const log = debug("sncf:StationMarker");

interface StationIconProperties {
  activeBoardingCount: number;
  percentGateAlive: number;
}

interface StationMarkerProperties {
  station: StationMapStatus;
  selectStation: () => void;
}

const useStyle = makeStyles({
  icon: {
    background: "transparent",
    border: "none",
  },
});

const getStationColor = (percentGateAlive: number) => {
  if (percentGateAlive > 90) {
    return SNCF_COMPL_GREEN;
  } else if (percentGateAlive > 50) {
    return SNCF_COMPL_YELLOW;
  } else {
    return SNCF_MAGENTA;
  }
};

export default function StationMarker({
  station,
  selectStation,
}: StationMarkerProperties) {
  const classes = useStyle();
  const markerReference: React.RefObject<Marker> = createRef();

  const getStationIcon = ({
    activeBoardingCount,
    percentGateAlive,
  }: StationIconProperties) =>
    new DivIcon({
      className: clsx(classes.icon),
      iconSize: [40, 40],
      iconAnchor: [7, 28],
      popupAnchor: [0, -40],
      html: `
      <svg viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="40" stroke="${getStationColor(
          percentGateAlive
        )}" stroke-width="3" fill="${getStationColor(percentGateAlive)}">
        </circle>
        <text x="35" y="60" fill="black" font-size="40">${activeBoardingCount}</text>
      </svg>`,
    });

  useEffect(() => {
    if (markerReference.current) {
      markerReference.current.leafletElement.on("click", (event) => {
        log("Station selected", event);
        selectStation();
      });
    }
  }, [markerReference, selectStation]);

  return (
    <Marker
      position={[station.lat || 0, station.lng || 0]}
      key={station.id}
      ref={markerReference}
      icon={getStationIcon(station)}
    />
  );
}

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { VersionInfo } from "services/api/deployments";

interface ConfigOptionProperties {
  versionInfo: VersionInfo;
  handleOpenConsult?: (versionInfo: VersionInfo) => void;
  handleCloseConsult?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  optionWrapperLayout: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  nameLayout: { marginRight: theme.spacing(3) },
}));

function ConfigOption({
  versionInfo,
  handleOpenConsult,
  handleCloseConsult,
}: ConfigOptionProperties) {
  const classes = useStyles();

  useEffect(() => {
    return () => {
      if (handleCloseConsult) {
        handleCloseConsult();
      }
    };
  }, []);

  if (!versionInfo.name) {
    return <span>{versionInfo.version}</span>;
  }

  return (
    <div
      className={classes.optionWrapperLayout}
      onMouseMoveCapture={() => {
        if (handleOpenConsult) {
          handleOpenConsult(versionInfo);
        }
      }}
      onMouseLeave={handleCloseConsult}
    >
      <span className={classes.nameLayout}>
        {versionInfo.name} - v{versionInfo.version}
        {versionInfo.standalone && " - Mode Standalone"}
        {versionInfo.testVersion && " - Version de test"}
      </span>
    </div>
  );
}

export default ConfigOption;

import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import {
  createWhitelistElement,
  WhitelistElementDTO,
} from "services/api/whitelists";
import { useSnackbar } from "notistack";

export interface CreateWhitelistElementModalProperties {
  whitelistId: string;
  whitelistType: string;
  whitelistTransporter?: string;
  onClose: (whitelistElement?: WhitelistElementDTO) => void;
}

export const CreateWhitelistElementModal: React.FC<
  CreateWhitelistElementModalProperties
> = (properties) => {
  const [whitelistElementName, setWhitelistElementName] = useState("");
  const [isSending, setSending] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isSending) {
      (async () => {
        try {
          const data = await createWhitelistElement(whitelistElementName, {
            id: properties.whitelistId,
            whitelistType: properties.whitelistType,
            transporter: properties.whitelistTransporter,
          });

          enqueueSnackbar("Element de liste blanche créé avec succès", {
            variant: "success",
          });
          properties.onClose(data);
        } catch {
          enqueueSnackbar(
            "Une erreur est survenue lors de la création de l'élement de la liste blanche",
            { variant: "error" }
          );
        } finally {
          setSending(false);
        }
      })();
    }

    // prevent memory leaks
    return () => setSending(false);
  }, [isSending]);

  return (
    <Dialog open={true}>
      <DialogTitle>Ajouter une valeur</DialogTitle>
      <DialogContent>
        <TextField
          label="Nom"
          placeholder="operators, pass-embarquement..."
          value={whitelistElementName}
          onChange={(event) => setWhitelistElementName(event.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          disabled={isSending}
          onClick={() => properties.onClose && properties.onClose()}
        >
          Annuler
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setSending(true)}
          disabled={isSending}
        >
          <Grid container spacing={1}>
            <Grid item>Créer</Grid>
            {isSending && (
              <Grid item>
                <CircularProgress size={12} />
              </Grid>
            )}
          </Grid>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

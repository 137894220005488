import React from "react";

export default function palette() {
  return (
    <>
      <h1>Palette</h1>

      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#ef0044",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_MAIN_MAGENTA
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#be006e",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_MAIN_PRUNE
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#6c2c83",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_MAIN_VIOLET
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#541969",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_MAIN_DARK_VIOLET
        </div>

        <div
          style={{
            textAlign: "center",
            backgroundColor: "#c7dd00",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_COMPL_LIGHT_GREEN
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#eeab00",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_COMPL_YELLOW
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#ff5100",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_COMPL_ORANGE
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#0089d1",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_COMPL_BLUE
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#019aa8",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_COMPL_GREEN_BLUE
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#53b900",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_COMPL_GREEN
        </div>

        <div
          style={{
            textAlign: "center",
            backgroundColor: "#9d9087",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_LIGHT_BROWN
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#877970",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_BROW
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#6a5c53",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_DARK_BROWN
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#9b9c9e",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_LIGHT_GRAY
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#eeeeee",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_MODAL_GRAY
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#73777a",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_GRAY
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#4a4d54",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_DARK_GRAY
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#eeeeee",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_BCKGND_GRAY
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#d6d6d8",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_GRAY_DELIMITER
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#737778",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_GRAY_1
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#757678",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_GRAY_2
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#7f8384",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_GRAY_3
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#00062F",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_BLUE_DARK
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#323C69",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_BLUE_LIGHT
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#F0F0F0",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_WHITE
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#D2D4DE",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_GRAY_LIGHT
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#5B6185",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_BLUE_LIGHTER
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#BD0035",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_MAGENTA
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#00CAFF",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_CYAN
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#008CE6",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_CYAN_DARK
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#00E68C",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_GREEN_LIGHT
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#e80000",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_RED_LIGHT
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#A00069",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_PURPLE
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "rgb(254, 251, 0)",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_YELLOW
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "rgb(178, 161, 199)",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_LIGHT_PURPLE
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#ec6625",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_ORANGE
        </div>

        <div
          style={{
            textAlign: "center",
            backgroundColor: "2c2d2f",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_BLACK_TXT
        </div>

        <div
          style={{
            textAlign: "center",
            backgroundColor: "#2d2e30",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_APP_BCKGND
        </div>
        <div
          style={{
            textAlign: "center",
            backgroundColor: "#ed0044",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          SNCF_RED
        </div>

        <div
          style={{
            textAlign: "center",
            backgroundColor: "#f0a700",
            width: "200px",
            height: "200px",
            margin: "20px",
          }}
        >
          PERIL_BOARDING_COLOR
        </div>
      </div>
    </>
  );
}

import React, { createContext, useReducer, Dispatch } from "react";
import {
  ConfirmDialogAction,
  confirmDialogInitialValues,
  confirmDialogReducer,
  ConfirmDialogState,
} from "./confirm-dialog-context";
import * as Auth from "../services/ducks/auth";
import * as Rights from "../services/ducks/rights";
import { initialRightsState } from "../services/ducks/rights";
import * as Versions from "../services/ducks/versions";

interface AppContext {
  reducers: {
    confirmDialog?: [ConfirmDialogState, Dispatch<ConfirmDialogAction>];
    auth?: [Auth.UserContext, Dispatch<Auth.UserAction>];
    rights?: [Auth.UserInfo, Dispatch<Rights.RightsAction>];
    versions?: [Versions.VersionsContext, Dispatch<Versions.VersionsAction>];
  };
}

export const AppContext = createContext<AppContext>({ reducers: {} });

interface AppProviderProperties {
  children: JSX.Element;
}

export default function AppProvider({ children }: AppProviderProperties) {
  const context: AppContext = {
    reducers: {
      confirmDialog: useReducer(confirmDialogReducer, {
        ...confirmDialogInitialValues,
      }),
      auth: useReducer(Auth.reducer, Auth.initialState),
      rights: useReducer(Rights.reducer, initialRightsState),
      versions: useReducer(Versions.reducer, Versions.initialState),
    },
  };

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
}

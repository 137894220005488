import React from "react";
import {
  Dialog,
  DialogContent,
  Button,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";

interface ConfirmationModal {
  title: string;
  content: string;
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export function ConfirmationModal({
  title,
  content,
  open,
  onConfirm,
  onCancel,
}: ConfirmationModal) {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onCancel}>
          Non
        </Button>
        <Button variant="contained" color="secondary" onClick={onConfirm}>
          Oui
        </Button>
      </DialogActions>
    </Dialog>
  );
}

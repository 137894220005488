const isValidValue = (value: string): boolean => {
  const reg = new RegExp(/^\d{10}$/m);
  return reg.test(value);
};

const findDuplicateValues = (array: string[]) => {
  return array.filter((item, index) => array.indexOf(item) !== index);
};

export type PassListValidation = {
  hasErrors: boolean;
  incorrect: string[];
  duplicates: string[];
};

export const validatePassList = (
  contentValues: string[]
): PassListValidation => {
  const incorrect = new Set<string>();
  for (const v of contentValues) {
    if (v && !isValidValue(v)) {
      incorrect.add(v);
    }
  }

  const duplicates = new Set<string>(findDuplicateValues(contentValues));

  return {
    hasErrors: incorrect.size > 0 || duplicates.size > 0,
    incorrect: [...incorrect.values()],
    duplicates: [...duplicates.values()],
  };
};

import { createStyles, makeStyles } from "@material-ui/styles";
import { SECONDARY_LIGHT } from "../../theme";
import { Theme } from "@material-ui/core";

export const useStyles = makeStyles({
  icon: {
    transform: (properties: { open?: boolean }) =>
      `rotate(${properties.open ? 0 : -90}deg)`,
  },
});

export const useRowStyles = makeStyles({
  row: {
    display: "flex",
    alignItems: "center",
    minHeight: "48px",
    padding: "0 24px",
    "&:hover": {
      backgroundColor: SECONDARY_LIGHT,
    },
  },
  expandable: {
    cursor: "pointer",
    fontWeight: "bold",
  },
  value: {
    paddingLeft: 30,
  },
  leveledRow: {
    paddingLeft: 60,
  },
  selectable: {
    paddingLeft: 0,
    "&$leveledRow": {
      paddingLeft: 0,
    },
  },
});

export const useRootStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      padding: "0 24px 0 30px",
      borderTopLeftRadius: 4,
      borderTopRightRadius: 4,
      border: "1px solid rgba(0, 0, 0, .125)",
      borderBottom: "none",
      minHeight: "48px",
      fontWeight: "bold",
      backgroundColor: theme.palette.secondary.light,
      color: "white",
    },
    tabs: {
      marginBottom: 20,
    },
    rowsWrapper: {
      overflow: "auto",
      borderBottom: "1px solid rgba(0, 0, 0, .125)",
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
  })
);

import axios from "axios";
import { TabKey } from "pages/whitelists/hooks/use-tabs";

export type WhitelistType = keyof typeof TabKey;

export interface WhitelistCreateDTO {
  fromWhitelistId?: string;
  name: string;
}

export interface WhitelistDTO {
  id: string;
  name: string;
  type: WhitelistType;
  transporter: string | null;
  createdAt: string;
  updatedAt: string;
  locked: boolean;
  whitelistElements: string[];
}

export interface WhitelistElementDTO {
  id: string;
  name: string;
  whitelistId: string;
  createdAt: string;
  updatedAt: string;
  content: string;
}

export interface WhitelistLockDTO {
  userId: string;
  expiresAt: string;
  lastUpdated: string;
}

export interface FindWhitelistRequestParameters {
  whitelistType: string;
  transporter?: string;
}

export type WhitelistRequestParameters = FindWhitelistRequestParameters & {
  id: string;
};

function getParametrizedPath(
  parameters: FindWhitelistRequestParameters
): string {
  const { whitelistType, transporter = "" } = parameters;

  if (transporter.length > 0) {
    return `/whitelist/${whitelistType}/${transporter}`;
  }

  return `/whitelist/${whitelistType}`;
}

function getWhitelistPath(parameters: WhitelistRequestParameters) {
  return `${getParametrizedPath(parameters)}/${parameters.id}`;
}

function getWhitelistElementPath(parameters: WhitelistRequestParameters) {
  return `${getParametrizedPath(parameters)}/element/${parameters.id}`;
}

export async function fetchWhitelists(
  parameters: FindWhitelistRequestParameters
): Promise<WhitelistDTO[]> {
  const response = await axios.get(getParametrizedPath(parameters));

  return response.data;
}

export async function createWhitelist(
  parameters: FindWhitelistRequestParameters,
  createDto: WhitelistCreateDTO
): Promise<WhitelistDTO> {
  const response = await axios.post(getParametrizedPath(parameters), createDto);

  return response.data;
}

export async function fetchWhitelist(
  parameters: WhitelistRequestParameters
): Promise<WhitelistDTO> {
  const response = await axios.get(getWhitelistPath(parameters));
  return response.data;
}

export async function updateWhitelist(
  parameters: WhitelistRequestParameters,
  whitelist: WhitelistDTO
): Promise<WhitelistDTO> {
  const response = await axios.put(getWhitelistPath(parameters), whitelist);

  return response.data;
}

export async function fetchWhitelistElements(
  parameters: WhitelistRequestParameters
): Promise<WhitelistElementDTO[]> {
  const response = await axios.get(getWhitelistPath(parameters) + "/element");

  return response.data;
}

export async function deleteWhitelist(parameters: WhitelistRequestParameters) {
  await axios.delete(getWhitelistPath(parameters));
}

export async function lockWhitelist(
  parameters: WhitelistRequestParameters
): Promise<WhitelistLockDTO> {
  const response = await axios.post(getWhitelistPath(parameters) + "/lock");
  return response.data;
}

export async function unlockWhitelist(
  parameters: WhitelistRequestParameters
): Promise<void> {
  await axios.post(getWhitelistPath(parameters) + "/unlock");
}

export async function downloadWhitelist(
  parameters: WhitelistRequestParameters
): Promise<Blob> {
  const response = await axios.get(getWhitelistPath(parameters) + "/zip", {
    responseType: "blob",
  });

  return response.data;
}

export async function createWhitelistElement(
  name: string,
  parameters: WhitelistRequestParameters
): Promise<WhitelistElementDTO> {
  const response = await axios.post(getWhitelistPath(parameters) + "/element", {
    name,
  });

  return response.data;
}

export async function fetchWhitelistElement(
  parameters: WhitelistRequestParameters
): Promise<WhitelistElementDTO> {
  const response = await axios.get(getWhitelistElementPath(parameters));
  return response.data;
}

export async function updateWhitelistElement(
  parameters: WhitelistRequestParameters,
  whitelistElement: WhitelistElementDTO
): Promise<WhitelistElementDTO> {
  const response = await axios.put(
    getWhitelistElementPath(parameters),
    whitelistElement
  );

  return response.data;
}

export async function deleteWhitelistElement(
  parameters: WhitelistRequestParameters
) {
  await axios.delete(getWhitelistElementPath(parameters));
}

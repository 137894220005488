import React from "react";
import gcAvatar from "./img/sncf-gares-et-connexions.jpg";
import { UserInfo } from "services/ducks/auth";

interface Properties {
  user: UserInfo;
}

const getAvatar = (_user: UserInfo) => {
  return gcAvatar;
};

export default function avatar(properties: Properties) {
  const avatar = getAvatar(properties.user);
  return (
    <img src={avatar} alt="Avatar" style={{ width: "100%", height: "100%" }} />
  );
}

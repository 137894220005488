import React from "react";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";

interface ClickableEditIconProperties {
  onClick: () => void;
  additionalClassName?: string;
}

function ClickableEditIcon(properties: ClickableEditIconProperties) {
  return (
    <IconButton
      aria-label="Clear search"
      size="small"
      onClick={properties.onClick}
      className={properties.additionalClassName}
    >
      <EditIcon color="secondary" />
    </IconButton>
  );
}

export default ClickableEditIcon;

import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { ConfirmationModal } from "../confirmation-modal";

interface StationListRemoveButtonProperties {
  onRemove: () => void;
  disabled: boolean;
}

export function StationListRemoveButton({
  onRemove,
  disabled,
}: StationListRemoveButtonProperties) {
  const [open, setOpen] = useState<boolean>(false);

  const handleRemoveClick = () => {
    setOpen(true);
  };

  const handleRemoveConfirm = () => {
    onRemove();
    setOpen(false);
  };

  const handleRemoveCancel = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        onClick={handleRemoveClick}
        variant="contained"
        disabled={disabled}
      >
        <Delete />
      </Button>
      <ConfirmationModal
        open={open}
        title="Supprimer une gare"
        content="Êtes-vous sûr de vouloir supprimer cette gare ?"
        onConfirm={handleRemoveConfirm}
        onCancel={handleRemoveCancel}
      />
    </>
  );
}

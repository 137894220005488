import axios from "axios";
import fileDownload from "js-file-download";

export type ConfigurationType = "TECHNICAL" | "BUSINESS" | "TRANSPORTER";

export interface ConfigurationDTO {
  id: string;
  configurationType: ConfigurationType;
  name: string;
  version: string;
  frozen: boolean;
  author: string;
  description: string;
  creationDatetime: string;
  standalone?: boolean;
  testVersion: boolean;
  frozenDatetime: string | undefined;
}

export type ConfigurationValueType = "FILE" | "JSON";

export interface ConfigurationValueDTO {
  id: string;
  type: ConfigurationValueType;
  name: string;
  path: string;
  lastUpdateDatetime: string;
}

export interface ConfigurationCreationDTO {
  name: string;
  author: string;
  version: string;
  duplicated: boolean;
  testVersion: boolean;
  description: string;
  sourceConfigurationId: string;
}

interface ConfigurationUpdateDTO {
  name: string;
  version: string;
  frozen: boolean;
  description: string;
  testVersion: boolean;
}

interface ConfigurationValueUpdateDTO {
  name: string;
  type: ConfigurationValueType;
  path?: string;
  data: string | undefined;
}

export interface TicketProcessor {
  key: string;
  label: string;
}

export async function fetchTransporters(): Promise<TicketProcessor[]> {
  const response = await axios.get(`/configurations/transporter/_transporters`);

  return response.data;
}

export async function fetchConfigurations(
  configurationType: ConfigurationType,
  transporter: string | undefined
): Promise<ConfigurationDTO[]> {
  const response = await axios.get(
    `${getParametrizedPath(configurationType, transporter)}/_search`
  );

  return response.data;
}

export async function createConfiguration(
  configurationType: ConfigurationType,
  transporter: string | undefined,
  request: ConfigurationCreationDTO
): Promise<ConfigurationDTO> {
  const response = await axios.post(
    getParametrizedPath(configurationType, transporter),
    request
  );
  return response.data;
}

export async function fetchConfiguration(
  configurationType: ConfigurationType,
  transporter: string | undefined,
  id: string
): Promise<ConfigurationDTO> {
  const response = await axios.get(
    `${getParametrizedPath(configurationType, transporter)}/${id}`
  );
  return response.data;
}

export async function downloadConfiguration(
  configurationType: ConfigurationType,
  transporter: string | undefined,
  name: string,
  version: string,
  configurationId: string
): Promise<void> {
  const response = await axios.get(
    `${getParametrizedPath(
      configurationType,
      transporter
    )}/${configurationId}/_zip`,
    {
      responseType: "blob",
    }
  );

  fileDownload(response.data, `configuration_${name}_v${version}.zip`);
}

export async function updateConfiguration(
  configurationType: ConfigurationType,
  transporter: string | undefined,
  id: string,
  update: ConfigurationUpdateDTO
): Promise<ConfigurationDTO> {
  const response = await axios.put(
    `${getParametrizedPath(configurationType, transporter)}/${id}`,
    update
  );
  return response.data;
}

export async function fetchConfigurationValues(
  configurationType: ConfigurationType,
  transporter: string | undefined,
  configurationId: string
): Promise<ConfigurationValueDTO[]> {
  const response = await axios.get(
    `${getParametrizedPath(
      configurationType,
      transporter
    )}/${configurationId}/values`
  );

  return response.data;
}

export async function deleteConfiguration(
  configurationType: ConfigurationType,
  transporter: string | undefined,
  configurationId: string
) {
  return axios.delete(
    `${getParametrizedPath(configurationType, transporter)}/${configurationId}`
  );
}

export async function fetchConfigurationValue(
  configurationType: ConfigurationType,
  transporter: string | undefined,
  configurationId: string,
  configurationValueId: string
): Promise<ConfigurationValueDTO> {
  const response = await axios.get(
    `${getParametrizedPath(
      configurationType,
      transporter
    )}/${configurationId}/values/${configurationValueId}`
  );
  return response.data;
}

export async function createConfigurationValue(
  configurationType: ConfigurationType,
  transporter: string | undefined,
  configurationId: string,
  update: ConfigurationValueUpdateDTO
) {
  const response = await axios.put(
    `${getParametrizedPath(
      configurationType,
      transporter
    )}/${configurationId}/values`,
    update
  );
  return response.data;
}

export async function updateConfigurationValue(
  configurationType: ConfigurationType,
  transporter: string | undefined,
  configurationId: string,
  configurationValueId: string,
  update: ConfigurationValueUpdateDTO
) {
  const response = await axios.put(
    `${getParametrizedPath(
      configurationType,
      transporter
    )}/${configurationId}/values/${configurationValueId}`,
    update
  );
  return response.data;
}

export async function deleteConfigurationValue(
  configurationType: ConfigurationType,
  transporter: string | undefined,
  configurationId: string,
  configurationValueId: string
) {
  return axios.delete(
    `${getParametrizedPath(
      configurationType,
      transporter
    )}/${configurationId}/values/${configurationValueId}`
  );
}

export async function downloadConfigurationFile(
  configurationType: ConfigurationType,
  transporter: string | undefined,
  configurationId: string,
  configurationValueId: string,
  configurationValueType: ConfigurationValueType,
  fileName: string | undefined
) {
  const response = await axios.get(
    `${getParametrizedPath(
      configurationType,
      transporter
    )}/${configurationId}/values/${configurationValueId}/_download`,
    { responseType: configurationValueType === "JSON" ? "json" : "blob" }
  );

  if (fileName) {
    fileDownload(
      configurationValueType === "JSON"
        ? JSON.stringify(response.data, undefined, 2)
        : response.data,
      fileName
    );
  }

  return response.data;
}

function getParametrizedPath(
  configurationType: ConfigurationType,
  transporter: string | undefined
) {
  if (transporter == undefined) {
    return `/configurations/${configurationType.toLowerCase()}`;
  }

  return `/configurations/${configurationType.toLowerCase()}/${transporter}`;
}

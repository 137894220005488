import axios from "axios";
import { Group, Station } from "services/interfaces/referential";

export interface AvailableUserOptions {
  activities: {
    name: string;
    id: string;
  }[];
  profiles: {
    name: string;
    id: string;
    activity: "GC" | "GARE" | "TRANSPORTER";
  }[];
  perimeters: {
    name: string;
    id: string;
  }[];
  transporters: {
    id: string;
    name: string;
  }[];
  stations: Station[];
  stationGroups: Group[];
}

/**
 * Fetches available options for user form / provides a mock
 */
export const fetchAvailableUserOptions =
  async (): Promise<AvailableUserOptions> => {
    const response = await axios.get("/users/_availableOptions");
    return response.data;
  };

import { withStyles } from "@material-ui/styles";
import AccordionSummary from "@material-ui/core/AccordionSummary";

export default withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 48,
    "&$expanded": {
      minHeight: 48,
    },
  },
  content: {
    flex: 1,
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(AccordionSummary);

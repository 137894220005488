import { useContext } from "react";
import { AppContext } from "../../app-provider";
import { UserInfo } from "./auth";
import { User, EUserType } from "../api/users";

export type RightsAction = { type: "WRITE_USER"; payload: Partial<UserInfo> };

interface LevelRank {
  STATION: number;
  STATION_GROUP: number;
  NATIONAL: number;
}

const levelRanks: LevelRank = {
  STATION: 1,
  STATION_GROUP: 2,
  NATIONAL: 3,
};

const containsAll = (array1: unknown[] | null, array2: unknown[] | null) =>
  !!(array1 && array2 && array2.every((element) => array1.includes(element)));

const containsAny = (array1: unknown[] | null, array2: unknown[] | null) =>
  !!(array1 && array2 && array2.some((element) => array1.includes(element)));

export default function useRights() {
  const [currentRightsState, dispatch] =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useContext(AppContext).reducers.rights!;

  const hasRights = (rights: string | string[]) => {
    const currentRights = currentRightsState.rights;
    if (!currentRights) {
      return false;
    }
    if (typeof rights === "string") {
      return currentRights.includes(rights);
    }
    return rights.every((right: string) => currentRights.includes(right));
  };

  const hasSomeRights = (rights: string[]) =>
    rights.some((right: string) => currentRightsState.rights.includes(right));

  const hasLevel = (level: string) => currentRightsState.level === level;

  const hasHigherLevel = (level: string) => {
    return (
      levelRanks[level as keyof LevelRank] &&
      levelRanks[currentRightsState.level as keyof LevelRank] &&
      levelRanks[currentRightsState.level as keyof LevelRank] >=
        levelRanks[level as keyof LevelRank]
    );
  };

  const hasProfile = (profile: string) =>
    currentRightsState.profileId === profile;

  const hasOneProfile = (profiles: string[]) =>
    profiles && profiles.includes(currentRightsState.profileId);

  const hasStation = (station: string) =>
    currentRightsState.stations &&
    currentRightsState.stations.includes(station);

  const hasGroup = (group: string) =>
    currentRightsState.groups && currentRightsState.groups.includes(group);

  const hasTransporter = (transporter: string) =>
    currentRightsState.transporters.includes(transporter);

  const hasActivity = (activity: string) =>
    currentRightsState.activity === activity;

  const hasUsername = (username: string) =>
    currentRightsState.name === username;

  const hasRoleHierarchyWrite = (profileId: string) =>
    currentRightsState.roleHierarchy &&
    currentRightsState.roleHierarchy.write &&
    (currentRightsState.roleHierarchy.write.some((writeProfile: string) =>
      profileId.startsWith(writeProfile)
    ) ||
      currentRightsState.roleHierarchy.write.includes("*"));

  const hasRoleHierarchyRead = (profileId: string) =>
    currentRightsState.roleHierarchy &&
    currentRightsState.roleHierarchy.read &&
    (currentRightsState.roleHierarchy.read.some((readProfile: string) =>
      profileId.startsWith(readProfile)
    ) ||
      currentRightsState.roleHierarchy.read.includes("*"));

  const canEditUser = (user: User): boolean => {
    if (currentRightsState.name === user.username) {
      return false; // Cannot self edit
    }
    if (!hasRoleHierarchyWrite(user.profile)) {
      return false; // Profiles roles hierachy prevents edition
    }
    if (
      hasActivity("TRANSPORTER") &&
      !containsAny(currentRightsState.transporters, user.transporters)
    ) {
      return false; // No transporter in commons
    }

    // Stations - National level
    if (currentRightsState.level === "NATIONAL") {
      return true; // NATIONAL has all stations
    }
    // Stations - Group level
    if (user.perimeter === "STATION_GROUP") {
      // Needs at least the same groups to edit a group user
      return containsAll(currentRightsState.groups, user.perimeterElements);
    }
    // Stations - Default unit level
    return containsAll(currentRightsState.stations, user.perimeterElements);
  };

  const setUserInfo = (userInfo: Partial<UserInfo>) => {
    dispatch({
      type: "WRITE_USER",
      payload: userInfo,
    });
  };

  const removeUserInfo = () => {
    dispatch({
      type: "WRITE_USER",
      payload: initialRightsState,
    });
  };

  const getUserInfo = (): UserInfo => currentRightsState;

  return {
    hasRights,
    hasSomeRights,
    hasLevel,
    hasHigherLevel,
    hasProfile,
    hasOneProfile,
    hasStation,
    hasGroup,
    hasTransporter,
    hasActivity,
    hasUsername,
    hasRoleHierarchyWrite,
    hasRoleHierarchyRead,
    canEditUser,
    getUserInfo,
    setUserInfo,
    removeUserInfo,
  };
}

export const initialRightsState: UserInfo = {
  level: "",
  activity: "",
  name: "",
  profileId: "",
  profileName: "",
  transporters: [],
  stations: [],
  groups: [],
  rights: [],
  type: EUserType.SNCF,
  roleHierarchy: {
    read: [],
    write: [],
  },
};

export const reducer = (state: UserInfo, action: RightsAction) => {
  switch (action.type) {
    case "WRITE_USER": {
      return { ...state, ...action.payload };
    }
    default: {
      return state;
    }
  }
};

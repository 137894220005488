import React, { useEffect, useState } from "react";
import { Map as LMap, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import StationMarker from "./station-marker";
import { StationMapStatus } from "../../../services/interfaces/referential";
import { fetchStationsMapStatus } from "services/api/stations";
import { getConfig } from "services/config";

interface MapPosition {
  lat: number;
  lng: number;
  zoom: number;
}

interface MapProperties {
  selected?: string;
  onSelected: (stationId: string | undefined) => void | Promise<void>;
}

const DEFAULT_MAP_POSITION = {
  lat: 47.656_369,
  lng: 2.55,
  zoom: 5,
};

export default function Map({ selected, onSelected }: MapProperties) {
  const [stations, setStations] = useState<Array<StationMapStatus>>([]);
  const [mapPosition, setMapPosition] =
    useState<MapPosition>(DEFAULT_MAP_POSITION);
  const refreshInterval = getConfig().HOMEPAGE_REFRESH_INTERVAL;

  useEffect(() => {
    let subscribed = true;
    const loadStations = async () => {
      const fetchedStations = await fetchStationsMapStatus();
      if (subscribed) {
        setStations(fetchedStations);
      }
    };

    loadStations();
    const mapInterval = setInterval(loadStations, refreshInterval * 1000);
    return () => {
      subscribed = false;
      if (mapInterval) {
        clearInterval(mapInterval);
      }
    };
  }, [refreshInterval]);

  useEffect(() => {
    if (selected) {
      const selectedStation = stations.find(
        (station) => station.id === selected
      );
      if (selectedStation && selectedStation.lat && selectedStation.lng) {
        setMapPosition({
          lat: selectedStation.lat,
          lng: selectedStation.lng,
          zoom: 14,
        });
      }
    } else {
      setMapPosition(DEFAULT_MAP_POSITION);
    }
  }, [selected, stations]);

  return (
    <LMap
      style={{ width: "100%", height: "650px", borderRadius: "4px" }}
      zoom={mapPosition.zoom}
      center={[mapPosition.lat, mapPosition.lng]}
    >
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {stations.map((station) => (
        <StationMarker
          key={station.id}
          station={station}
          selectStation={() => onSelected(station.id)}
        />
      ))}
    </LMap>
  );
}

//

import { withStyles } from "@material-ui/styles";
import { Accordion } from "@material-ui/core";

export default withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: 0,
    },
    "&:not(:last-of-type)": {
      borderBottom: 0,
    },
    "&:last-of-type": {
      "&:not($expanded)": {
        borderBottom: 0,
      },
    },
  },
  expanded: {},
})(Accordion);

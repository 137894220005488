import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Theme } from "@material-ui/core/styles";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import * as React from "react";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { Collapse, Tooltip } from "@material-ui/core";
import List from "@material-ui/core/List";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { default as clsx } from "clsx";
import MenuItem, { MenuItemProperties } from "./menu-item";
import { PRIMARY_COLOR_LIGHTER, SNCF_COMPL_BLUE_DARK } from "../../theme";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: SNCF_COMPL_BLUE_DARK,
    },
  },
  rootOpen: {
    backgroundColor: PRIMARY_COLOR_LIGHTER,
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  subMenu: {
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: "30px",
  },
  text: {
    color: theme.palette.common.white,
  },
}));

interface Properties extends RouteComponentProps {
  text: string;
  Icon: React.ComponentType<SvgIconProps>;
  items: MenuItemProperties[];
  tooltip?: string;
}

function MenuItemDropDown({
  text,
  Icon,
  items,
  location,
  tooltip,
}: Properties) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(items.some((item) => location.pathname.startsWith(item.to)));
  }, [items, location.pathname]);

  function handleClick() {
    setOpen(!open);
  }

  return (
    <div>
      <Tooltip
        title={tooltip || ""}
        enterDelay={500}
        disableFocusListener
        placement="right"
      >
        <ListItem
          className={clsx(classes.root, { [classes.rootOpen]: open })}
          button={true}
          onClick={handleClick}
        >
          <ListItemIcon style={{ marginLeft: "5px" }}>
            <Icon className={classes.icon} />
          </ListItemIcon>
          <ListItemText primary={text} classes={{ primary: classes.text }} />
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
      </Tooltip>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List className={classes.subMenu}>
          {items.map((value, index) => (
            <Tooltip
              title={value.tooltip || ""}
              enterDelay={500}
              disableFocusListener
              placement="right"
              key={index}
            >
              <div>
                <MenuItem {...value} />
              </div>
            </Tooltip>
          ))}
        </List>
      </Collapse>
    </div>
  );
}

export default withRouter(MenuItemDropDown);

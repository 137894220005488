import { PanelContent } from "components/expandable-table/panel-content";
import { Tab } from "components/tabs-component";
import React from "react";
import { WhitelistElementDTO, WhitelistDTO } from "services/api/whitelists";
import { RenderedWhitelist, RenderedWhitelistElement } from ".";
import { WhitelistElementRow } from "./whitelist-element-row";
import { WhitelistExpandableRow } from "./whitelist-expandable-row";

export interface WhitelistRowContentProperties {
  whitelist: RenderedWhitelist;
  index: number;
  selectedTab: Tab;
  loadWhitelistElements: (row: RenderedWhitelist) => Promise<void>;
  onDeletedWhitelistElement: (whitelistElement: WhitelistElementDTO) => void;
  onViewWhitelistElement: (
    whitelist: WhitelistDTO,
    whitelistElement: WhitelistElementDTO
  ) => void;
  onEditWhitelistElement: (
    whitelist: WhitelistDTO,
    whitelistElement: WhitelistElementDTO
  ) => void;
}

export const WhitelistRowContent: React.FC<WhitelistRowContentProperties> = ({
  whitelist,
  index,
  loadWhitelistElements,
  selectedTab,
  onDeletedWhitelistElement,
  onViewWhitelistElement,
  onEditWhitelistElement,
}) => {
  return (
    <PanelContent<RenderedWhitelist, RenderedWhitelistElement>
      root={whitelist}
      index={index}
      onLoadPanel={loadWhitelistElements}
      expandableRowRenderer={(root, value, index_, open) => (
        <WhitelistExpandableRow root={root} open={open} />
      )}
      valueRowRenderer={(r, value, index) => (
        <WhitelistElementRow
          whitelist={whitelist}
          whitelistElement={value}
          rowIndex={index}
          selectedTab={selectedTab}
          onDeletedWhitelistElement={onDeletedWhitelistElement}
          onViewWhitelistElement={onViewWhitelistElement}
          onEditWhitelistElement={onEditWhitelistElement}
        />
      )}
    />
  );
};

import React from "react";
import { makeStyles } from "@material-ui/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export const useStyles = makeStyles({
  icon: {
    transform: ({ open }: ExpandableIconProperties) =>
      `rotate(${open ? 0 : -90}deg)`,
  },
});

interface ExpandableIconProperties {
  open: boolean;
}

function ExpandableIcon(properties: ExpandableIconProperties) {
  const classes = useStyles(properties);
  return <ExpandMoreIcon className={classes.icon} />;
}

export default ExpandableIcon;

import React, { Dispatch, SetStateAction, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  ConfigurationType,
  updateConfiguration,
} from "../../services/api/configurations";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Loader from "../../components/loader";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Configuration } from "./index";
import useConfiguration from "./use-configuration";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import FormCheckbox from "components/form/checkbox";

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textField: {
    flex: 1,
  },
  field: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
  descriptionLabel: {
    color: "grey",
    marginBottom: "5px",
  },
  multiLineTextField: {
    width: "100%",
    "& .MuiInputBase-root": { padding: "10px", borderRadius: "3px" },
  },
}));

interface EditConfigurationDialogProperties {
  configurationId: string | undefined;
  onClose: () => void;
  configurations: Configuration[] | undefined;
  updateConfigurations: Dispatch<SetStateAction<Configuration[] | undefined>>;
  configurationType: ConfigurationType;
  transporter: string | undefined;
}

const emptyHelperText = " ";

function EditConfigurationDialog({
  configurationId,
  onClose,
  configurations,
  updateConfigurations,
  configurationType: type,
  transporter,
}: EditConfigurationDialogProperties) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [configuration, setConfiguration] = useConfiguration(
    type,
    transporter,
    configurationId,
    setIsLoading
  );
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const onDialogClose = (_event: unknown, reason: string) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      handleClose();
    }
  };

  const handleClose = () => {
    onClose();
    setIsLoading(true);
    setConfiguration(undefined);
  };

  const handleEditNom = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!configuration) return;
    setConfiguration({ ...configuration, name: event.target.value });
  };

  const handleEditVersion = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!configuration) return;
    setConfiguration({ ...configuration, version: event.target.value });
  };

  const handleEditDescription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!configuration) return;
    setConfiguration({ ...configuration, description: event.target.value });
  };

  const handleEditTestVersion = () => {
    if (!configuration) return;
    setConfiguration({
      ...configuration,
      testVersion: !configuration.testVersion,
    });
  };

  const handleSave = async () => {
    if (!configuration) return;

    setIsLoading(true);

    try {
      await updateConfiguration(type, transporter, configuration.id, {
        name: configuration.name,
        version: configuration.version,
        frozen: configuration.frozen,
        description: configuration.description,
        testVersion: configuration.testVersion,
      });
      enqueueSnackbar("Configuration figée avec succès", {
        variant: "success",
      });
    } catch (error) {
      console.log(error);
      enqueueSnackbar(
        "Une erreur est survenue lors du figeage de la configuration",
        { variant: "error" }
      );
      return;
    }

    // Update parent state
    updateConfigurations((configurations) => {
      if (!configurations) return;

      return configurations.map((c) => {
        if (c.id === configurationId) {
          c.name = configuration.name;
          c.version = configuration.version;
          c.frozen = configuration.frozen;
          c.testVersion = configuration.testVersion;
          c.description = configuration.description;
        }

        return c;
      });
    });
    handleClose();
  };

  const isVersionUnavailable =
    configurations !== null &&
    configurations !== undefined &&
    configuration !== null &&
    configuration !== undefined &&
    configurations.some(
      (existingConfiguration) =>
        existingConfiguration.version === configuration.version &&
        existingConfiguration.id !== configuration.id
    );

  return (
    <Dialog
      open={Boolean(configurationId)}
      onClose={onDialogClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle id="form-dialog-title">
        Modifier la configuration
      </DialogTitle>
      <DialogContent>
        {isLoading && (
          <div style={{ textAlign: "center" }}>
            <Loader width={100} height={100} />
          </div>
        )}
        {!isLoading && configuration && (
          <>
            <div className={classes.row}>
              <div className={classes.textField}>
                <TextField
                  id="rename-text-field"
                  label="Nom"
                  value={configuration.name}
                  onChange={handleEditNom}
                />
              </div>
              <div className={classes.textField}>
                <TextField
                  id="version-text-field"
                  label="Version"
                  value={configuration.version}
                  onChange={handleEditVersion}
                  error={isVersionUnavailable}
                  helperText={
                    isVersionUnavailable
                      ? `Une version ${configuration.version} existe déjà.`
                      : emptyHelperText
                  }
                />
              </div>
            </div>
            <div className={classes.row}>
              <FormCheckbox
                handleOnChange={handleEditTestVersion}
                label={"Version de test"}
                checked={configuration.testVersion}
                className={classes.field}
              />
            </div>
            <div className={classes.row}>
              <div className={classes.textField}>
                <p className={classes.descriptionLabel}>Description</p>
                <TextField
                  value={configuration.description || ""}
                  onChange={handleEditDescription}
                  multiline={true}
                  className={classes.multiLineTextField}
                  variant="filled"
                  margin="none"
                  minRows={5}
                  maxRows={5}
                  inputProps={{ maxLength: 500 }}
                  InputProps={{ disableUnderline: true }}
                />
              </div>
            </div>
          </>
        )}
      </DialogContent>
      {!isLoading && (
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Annuler
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSave}
            disabled={
              configuration == undefined ||
              !configuration.name ||
              !configuration.version ||
              isVersionUnavailable
            }
          >
            Modifier
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default EditConfigurationDialog;

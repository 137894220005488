import React from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";
import { HelpFilesUploadButton } from "./help-files-upload-button";
import { HelpFiles } from "./help-files";

const useStyles = makeStyles({
  dialogPaper: {
    maxHeight: "50vh",
    minWidth: "30vw",
  },
  dialogActions: {
    padding: "8px 16px",
  },
});

interface HelpFilesDialogProperties {
  open: boolean;
  onClose: () => void;
  helpFiles: Array<string>;
  loading: boolean;
  uploading: boolean;
  onDownload: (file: string) => void;
  onUpload: (file: File) => void;
  canUpload?: boolean;
  onDelete: (file: string) => void;
  canDelete?: boolean;
}

export function HelpFilesDialog({
  open,
  onClose,
  helpFiles,
  loading,
  uploading,
  onDownload,
  onUpload,
  onDelete,
  canUpload = false,
  canDelete = false,
}: HelpFilesDialogProperties) {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      aria-labelledby="wl-help-dialog"
      open={open}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogTitle>Aide</DialogTitle>
      <DialogContent>
        {loading && <CircularProgress />}
        {!loading && (
          <HelpFiles
            helpFiles={helpFiles}
            onDelete={onDelete}
            onDownload={onDownload}
            canDelete={canDelete}
          />
        )}
      </DialogContent>
      <DialogActions>
        {canUpload && (
          <div className={classes.dialogActions}>
            {uploading && <CircularProgress />}
            {!uploading && <HelpFilesUploadButton onUpload={onUpload} />}
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}

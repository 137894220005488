import axios from "axios";
import { Group } from "../interfaces/referential";

export async function fetchGroups(): Promise<Group[]> {
  const response = await axios.get("/referential/groups");
  return response.data;
}

export async function fetchGroupTypes(): Promise<Set<string>> {
  const response = await axios.get("/referential/groups/types");
  return response.data;
}

export async function addGroup(group: Group) {
  const response = await axios.post("/referential/groups", {
    name: group.name,
    type: group.type,
    stationIds: [...group.stationIds],
  });
  return response.data;
}

export async function updateGroup(group: Group) {
  const response = await axios.put("/referential/groups/" + group.id, {
    id: group.id,
    name: group.name,
    type: group.type,
    stationIds: [...group.stationIds],
  });
  return response.data;
}

export async function deleteGroups(groupIds: Set<string>) {
  await axios.delete("/referential/groups/" + [...groupIds].join(","), {
    responseType: "text",
  });
}

export async function deleteGroup(groupId: string) {
  await deleteGroups(new Set([groupId]));
}

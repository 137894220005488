import React, { useEffect, useState } from "react";
import {
  Lane,
  Track,
  TrackLane,
} from "../../../../services/interfaces/referential";
import { UseCrud } from "../../../../commons/use-crud";
import FormSelect from "../../../../components/form/select";
import ReactVirtualizedTable from "../../../../components/virtualized-table";
import {
  checkFields,
  FieldValidator,
} from "../../../../validators/form-validator";
import { isEmpty } from "../../../../validators/form-validators";
import isEqual = require("lodash/isEqual");
import cloneDeep = require("lodash/cloneDeep");
import { findTrackLanesWithMissing } from "../utils/track-lane-utils";

export interface TrackLaneTableProperties {
  dockId: string;
  trackIds: string[];
  laneIds: string[];
  trackLaneDTOList: TrackLane[];
  tracksState: UseCrud<Track>;
  lanesState: UseCrud<Lane>;
  onChange?: (trackLanes: TrackLane[]) => void;
  editMode?: boolean;
  computeTableHeightUpTo?: number;
  onValidateData?: (validatorResult: TrackLaneValidatorResult) => void;
  trackLanesErrors?: TrackLaneErrors[];
}

export interface TrackLaneValidatorResult {
  isValid: boolean;
  errors: TrackLaneErrors[];
}
export interface TrackLaneErrors {
  type: string;
  position: string;
}
const defaultErrors: TrackLaneErrors = {
  type: "",
  position: "",
};
const validators: FieldValidator<TrackLane, TrackLaneErrors> = {
  type: [{ error: "Obligatoire", validator: isEmpty }],
  position: [{ error: "Obligatoire", validator: isEmpty }],
};

/**
 * TrackLaneTab Component
 */
function TrackLaneTable({
  dockId,
  trackIds,
  laneIds,
  trackLaneDTOList,
  tracksState,
  lanesState,
  onChange,
  editMode = true,
  computeTableHeightUpTo = 200,
  onValidateData,
  trackLanesErrors,
}: TrackLaneTableProperties) {
  const [trackLanes, setTrackLanes] = useState(cloneDeep(trackLaneDTOList));

  const handleChange = (trackLanes: TrackLane[]) => {
    if (onChange) {
      onChange(trackLanes);
    }
  };

  useEffect(() => {
    setTrackLanes(cloneDeep(trackLaneDTOList));
    validateData();
  }, [trackLaneDTOList]);

  useEffect(() => {
    const newtrackLanes = findTrackLanesWithMissing(
      trackIds,
      laneIds,
      trackLanes,
      dockId
    );
    const newTrackLanesConcatened = [
      ...newtrackLanes.founded,
      ...newtrackLanes.missing,
    ];
    setTrackLanes(newTrackLanesConcatened);
    handleChange(newTrackLanesConcatened);
  }, [trackIds, laneIds, dockId]);

  // ----------- handler

  function validateData() {
    if (onValidateData) {
      const newTrackLanesErrors = trackLanes.map((value) =>
        checkFields<TrackLane, TrackLaneErrors>(
          validators,
          defaultErrors,
          value
        )
      );
      onValidateData({
        isValid: newTrackLanesErrors
          .map((trackLaneErrors) => isEqual(trackLaneErrors, defaultErrors))
          .every(Boolean),
        errors: newTrackLanesErrors,
      });
    }
  }

  // ----------- api actions

  function changeType(value: "MAIN" | "SECONDARY", rowIndex: number) {
    const arrayTemporary = [...trackLanes];
    arrayTemporary[rowIndex].type = value;
    handleChange(arrayTemporary);
  }

  function changePosition(value: "NONE" | "LEFT" | "RIGHT", rowIndex: number) {
    const arrayTemporary = [...trackLanes];
    arrayTemporary[rowIndex].position = value;
    handleChange(arrayTemporary);
  }

  const headerLabelTrackLane = [
    {
      label: "Voie",
      dataKey: "trackId",
      renderer: (tl: TrackLane) => tracksState.getName(tl.trackId),
    },
    {
      label: "Ligne",
      dataKey: "laneId",
      renderer: (tl: TrackLane) => lanesState.getName(tl.laneId),
    },
    {
      label: "Type",
      dataKey: "type",
      width: 170,
      renderer: (tl: TrackLane, rowIndex: number) =>
        editMode ? (
          <FormSelect
            handleOnChange={(value) =>
              changeType(value as unknown as "MAIN" | "SECONDARY", rowIndex)
            }
            availableOptions={[
              { id: "MAIN", name: "MAIN" },
              { id: "SECONDARY", name: "SECONDARY" },
            ]}
            value={tl.type}
            emptyOption="À définir"
            displayEmpty={true}
            error={
              trackLanesErrors
                ? trackLanesErrors[rowIndex] && trackLanesErrors[rowIndex].type
                : ""
            }
            hideErrorMessage={true}
          />
        ) : (
          <span>{tl.type || "À définir"}</span>
        ),
    },
    {
      label: "Position",
      dataKey: "position",
      width: 150,
      renderer: (tl: TrackLane, rowIndex: number) =>
        editMode ? (
          <FormSelect
            handleOnChange={(value) =>
              changePosition(
                value as unknown as "NONE" | "LEFT" | "RIGHT",
                rowIndex
              )
            }
            availableOptions={[
              { id: "NONE", name: "NONE" },
              { id: "LEFT", name: "LEFT" },
              { id: "RIGHT", name: "RIGHT" },
            ]}
            value={tl.position}
            emptyOption="À définir"
            displayEmpty={true}
            error={
              trackLanesErrors
                ? trackLanesErrors[rowIndex] &&
                  trackLanesErrors[rowIndex].position
                : ""
            }
            hideErrorMessage={true}
          />
        ) : (
          <span>{tl.position || "À définir"}</span>
        ),
    },
  ];

  return (
    <div>
      <ReactVirtualizedTable
        rows={trackLanes}
        rowCount={trackLanes.length}
        updateRows={handleChange}
        columns={headerLabelTrackLane}
        rowHeight={40}
        headerHeight={40}
        computeTableHeightUpTo={computeTableHeightUpTo}
        minTableHeight={80}
        renderSelectionCheckboxes={false}
      />
    </div>
  );
}

export default TrackLaneTable;

import { format } from "date-fns";
import { DeploymentType } from "services/api/deployments";

const DATE_FORMAT = "yyyyMMdd-HHmm";

const typeMap: { [key: string]: string } = {
  CONFIG: "Configurations",
  SOFTWARE: "Logiciels",
};

export const formatDeploymentExportFilename = (
  title: string,
  type: DeploymentType,
  extension: string
) => {
  const date = format(Date.now(), DATE_FORMAT);
  const readableType = typeMap[type];
  return `${title}_${readableType}_${date}.${extension}`;
};

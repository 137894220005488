import React, {
  useState,
  useEffect,
  FormEvent,
  KeyboardEvent,
  MouseEvent,
  forwardRef,
  Ref,
} from "react";
import {
  Select,
  MenuItem,
  Input,
  InputAdornment,
  IconButton,
  FormHelperText,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

export interface SelectOption {
  name: string | JSX.Element;
  id: string;
  searchableElement?: string;
}

const useStyles = makeStyles({
  menuItemChildrenPadding: {
    padding: 0,
    "& > *": { padding: "6px 16px" },
  },
  menuItemParentPadding: { padding: "6px 16px" },
  select: {
    maxHeight: "100%",
  },
});

export interface FormSelectProperties {
  handleOnChange: (event: string) => void;
  handleCloseSelect?: () => void;
  availableOptions: SelectOption[];
  emptyOption?: string;
  value: string;
  disabled?: boolean | undefined;
  label?: string;
  displayEmpty?: boolean;
  error?: string;
  hideErrorMessage?: boolean;
  searchable?: boolean;
  shrink?: boolean;
  setIsListReady?: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Select component for form
 */
const FormSelect = forwardRef(
  (
    {
      handleOnChange,
      handleCloseSelect,
      availableOptions,
      value,
      disabled,
      label,
      emptyOption,
      displayEmpty = false,
      error,
      hideErrorMessage = false,
      searchable = false,
      shrink = false,
      setIsListReady,
    }: FormSelectProperties,
    reference: Ref<HTMLDivElement>
  ) => {
    const classes = useStyles();
    const [search, setSearch] = useState<string>("");
    const [searchInputReference, setSearchInputReference] =
      useState<HTMLInputElement>();
    const [displayedAvailableOptions, setDisplayedAvailableOptions] =
      useState<SelectOption[]>(availableOptions);

    if (searchInputReference) {
      searchInputReference.focus();
    }

    // Returns searchableElements or name if name is a string and searchableElements is null
    const getComparedElement = (option: SelectOption) => {
      if (option.searchableElement) {
        return option.searchableElement;
      }
      if (typeof option.name === "string") {
        return option.name;
      }
      return "";
    };

    useEffect(() => {
      // Filters available options if select is searchable
      if (searchable) {
        const newDisplayedAvailableOptions = availableOptions.filter(
          (option) => {
            const comparedElement = getComparedElement(option);
            return comparedElement.toLowerCase().includes(search.toLowerCase());
          }
        );
        setDisplayedAvailableOptions(newDisplayedAvailableOptions);
      } else {
        setDisplayedAvailableOptions(availableOptions);
      }
    }, [search, availableOptions]);

    // eslint-disable-next-line unicorn/consistent-function-scoping
    const handleSearchClick = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
    };

    const handleSearchChange = (
      event: FormEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >
    ) => {
      setSearch(event.currentTarget.value);
    };

    const clearSearch = () => {
      setSearch("");
    };

    const handleKeyDown = (
      event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
    ) => {
      event.stopPropagation();
    };

    const handleOpenSelect = () => {
      clearSearch();
    };

    const renderValue = () => {
      return (
        availableOptions.find((option) => option.id === value) || { name: "" }
      ).name;
    };

    return (
      <>
        {label && (
          <InputLabel
            shrink={shrink || undefined}
            error={error !== undefined && error !== ""}
          >
            {label}
          </InputLabel>
        )}
        <Select
          value={value}
          className={classes.select}
          onChange={(event) => handleOnChange(event.target.value as string)}
          style={{ minHeight: "32px" }}
          disabled={disabled}
          error={error !== undefined && error !== ""}
          displayEmpty={displayEmpty}
          onOpen={() => {
            setIsListReady && setIsListReady(true);
            handleOpenSelect && handleOpenSelect();
          }}
          onClose={() => {
            setIsListReady && setIsListReady(false);
            handleCloseSelect && handleCloseSelect();
          }}
          renderValue={renderValue}
          MenuProps={{ MenuListProps: { disablePadding: true } }}
        >
          {emptyOption !== undefined && emptyOption !== "" && (
            <MenuItem value="">
              <em>{emptyOption}</em>
            </MenuItem>
          )}
          {searchable && (
            <div onClick={handleSearchClick} ref={reference}>
              <Input
                type="text"
                value={search}
                inputProps={{
                  ref: (node: HTMLInputElement) => {
                    setSearchInputReference(node);
                  },
                }}
                key="search-input"
                onClick={handleSearchClick}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                placeholder="Recherche"
                style={{
                  paddingLeft: "16px",
                  paddingTop: "6px",
                  paddingRight: search === "" ? "42px" : "4px",
                  marginTop: 0,
                  width: "100%",
                  boxShadow: "0 2px 2px -2px black",
                }}
                endAdornment={
                  search !== "" && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Clear search"
                        onClick={clearSearch}
                        size="small"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
                disableUnderline
              />
            </div>
          )}
          {displayedAvailableOptions.length > 0 ? (
            displayedAvailableOptions.map((option, index) => (
              <MenuItem
                value={option.id}
                key={`${option.id}-${index}`}
                className={
                  typeof option.name == "string"
                    ? classes.menuItemParentPadding
                    : classes.menuItemChildrenPadding
                }
              >
                {option.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem
              disabled
              style={{ display: "flex", justifyContent: "center" }}
            >
              AUCUN RESULTAT
            </MenuItem>
          )}
        </Select>
        {!hideErrorMessage && error !== undefined && error !== "" && (
          <FormHelperText error={error !== undefined && error !== ""}>
            {error}
          </FormHelperText>
        )}
      </>
    );
  }
);

FormSelect.displayName = "FormSelect";

export default FormSelect;

import React from "react";
import { Button } from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import fileDownload from "js-file-download";
import { useSnackbar } from "notistack";

interface PassListFormExportProperties {
  listName: string;
  keyName: string;
  passList: string[];
}

export function PassListFormExport({
  listName,
  keyName,
  passList,
}: PassListFormExportProperties) {
  const { enqueueSnackbar } = useSnackbar();

  const handleExport = async (): Promise<void> => {
    try {
      const fileName = `${listName}-${keyName}.txt`;

      fileDownload(
        passList.filter((v) => v !== "").join("\n"),
        fileName,
        "text/plain"
      );
    } catch {
      enqueueSnackbar("Erreur lors de l'export de la liste blanche'", {
        variant: "error",
      });
    }
  };

  return (
    <Button
      color="secondary"
      variant="contained"
      onClick={handleExport}
      startIcon={<CloudDownload />}
    >
      Export
    </Button>
  );
}

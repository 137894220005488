import React, { useContext } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { AppContext } from "../../app-provider";

function ConfirmDialog() {
  const [confirmDialog, dispatch] =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useContext(AppContext).reducers.confirmDialog!;

  function close() {
    dispatch({ type: "close" });
  }

  function submit() {
    if (confirmDialog.submit !== undefined) {
      confirmDialog.submit();
    }
    close();
  }

  return (
    <Dialog
      open={confirmDialog.open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="alert-dialog-title">{confirmDialog.title}</DialogTitle>
      <DialogContent id="alert-dialog-description">
        {typeof confirmDialog.msg === "function" ? (
          confirmDialog.msg()
        ) : (
          <DialogContentText>{confirmDialog.msg}</DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={close}>
          {confirmDialog.closeLabel}
        </Button>
        {confirmDialog.submit !== undefined && (
          <Button
            variant="contained"
            onClick={submit}
            color="secondary"
            autoFocus
          >
            {confirmDialog.submitLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;

import React, { ReactNode } from "react";
import { FormHelperText, List, Theme, Box } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { default as clsx } from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    smallMarginBottom: {
      marginBottom: theme.spacing(1),
    },
    listLayout: {
      display: "flex",
      flexDirection: "column",
      overflowX: "hidden",
      borderRadius: "4px",
      backgroundColor: "#f5f5f5",
      padding: 0,
      "&>div:nth-child(even)": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
        backgroundColor: "#F9F9F9",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        backgroundColor: "#B7B7B7",
      },
      "&::-webkit-scrollbar-track": {
        borderRadius: "10px",
        backgroundColor: "#F9F9F9",
      },
    },
    horizontalBox: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    verticalBox: {
      display: "flex",
      flexDirection: "column",
    },
    rowBoxLayout: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  })
);

interface FormSelectListProperties {
  hide?: boolean;
  label: string;
  disabled?: boolean;
  error?: string;
  height?: string;
  noFixedHeight?: boolean;
  topLabel?: boolean;
  children: ReactNode[];
  additionalClassName?: string;
}

/**
 * Select list component for form
 */
function FormSelectList({
  label,
  error,
  height = "200px",
  noFixedHeight,
  children,
  topLabel,
  additionalClassName,
}: FormSelectListProperties) {
  const classes = useStyles();

  return (
    <Box className={additionalClassName}>
      <FormHelperText
        error={error !== undefined && error !== ""}
        className={classes.smallMarginBottom}
      >
        {label}
      </FormHelperText>
      <List
        className={classes.listLayout}
        style={{
          height: noFixedHeight ? "auto" : height,
          maxHeight: noFixedHeight ? height : "none",
        }}
      >
        {children.map((child, index) => (
          <Box
            className={clsx(classes.rowBoxLayout, {
              [classes.verticalBox]: topLabel,
              [classes.horizontalBox]: !topLabel,
            })}
            key={index}
          >
            {child}
          </Box>
        ))}
      </List>
      {error !== undefined && error !== "" && (
        <FormHelperText error={error !== undefined && error !== ""}>
          {error}
        </FormHelperText>
      )}
    </Box>
  );
}

export default FormSelectList;

import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  DeploymentType,
  fetchDeploymentsFullExportFor,
  fetchRecentDeploymentsExportFor,
  fetchCurrentGateVersionsExportFor,
} from "services/api/deployments";
import useRights from "services/ducks/rights";
import OptionsButton from "./options-button";
import { useSnackbar } from "notistack";
import { CircularProgress, Fab } from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";

const useStyles = makeStyles({
  wrapper: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "1em",
    gap: ".5em",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
});

interface ExportButtonGroupProperties {
  type: DeploymentType;
}

const ERROR_MESSAGE_FULL =
  "La fonctionnalité de génération d'exports est indisponible pour le moment";
const ERROR_MESSAGE_RECENT =
  "Une erreur est survenue durant l'export de l'historique.";
const ERROR_MESSAGE_CURRENT = ERROR_MESSAGE_RECENT;

function ExportButtonGroup({ type }: ExportButtonGroupProperties) {
  const classes = useStyles();
  const { hasRights } = useRights();
  const [loading, setLoading] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const isAuthorized =
    (hasRights(["DEPLOY_SOFT_READ"]) && type === "SOFTWARE") ||
    (hasRights(["DEPLOY_CONF_READ"]) && type === "CONFIG");

  async function handleExport(
    exportFunction: (type: DeploymentType) => Promise<void>,
    type: DeploymentType,
    errorMessage: string,
    errorVariant: "error" | "warning"
  ) {
    setLoading(true);
    try {
      await exportFunction(type);

      enqueueSnackbar(`Export réussi`, {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(errorMessage, { variant: errorVariant });
    }

    setLoading(false);
  }

  const buttonActions = [
    {
      label: "Exporter l'historique complet",
      handler: () =>
        handleExport(
          fetchDeploymentsFullExportFor,
          type,
          ERROR_MESSAGE_FULL,
          "warning"
        ),
    },
    {
      label: "Exporter l’historique des derniers déploiements",
      handler: () =>
        handleExport(
          fetchRecentDeploymentsExportFor,
          type,
          ERROR_MESSAGE_RECENT,
          "error"
        ),
    },
    {
      label: "Exporter les versions actuelles des portes",
      handler: () =>
        handleExport(
          fetchCurrentGateVersionsExportFor,
          type,
          ERROR_MESSAGE_CURRENT,
          "error"
        ),
    },
  ];

  return (
    <>
      {isAuthorized && (
        <div className={classes.wrapper}>
          <OptionsButton
            buttonActions={buttonActions}
            renderButton={(onClick) => (
              <>
                <Fab color="secondary" onClick={onClick}>
                  <CloudDownload />
                </Fab>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </>
            )}
          />
        </div>
      )}
    </>
  );
}

export default ExportButtonGroup;

import React, { useState, Fragment } from "react";
import { ExpandableColumn } from "./types";
import { useRootStyles } from "./styles";
import { AutoSizer } from "react-virtualized";
import SearchField from "./search-field";

interface ExpandableTableProperties<RootType, Filters> {
  columns?: ExpandableColumn<RootType>[];
  roots: RootType[];
  rowRenderer: (row: RootType, index: number) => JSX.Element;
  bottomHeightMargin?: number;
  filterFunction?: (row: RootType, filters: Filters | undefined) => boolean;
}

function ExpandableTable<R, F>({
  roots,
  rowRenderer,
  columns,
  bottomHeightMargin,
  filterFunction,
}: ExpandableTableProperties<R, F>) {
  const classes = useRootStyles();
  const [filters, setFilters] = useState<F>();

  const handleOnChange = (title: keyof R) => (search: string) => {
    setFilters((f: F | undefined) => ({ ...f, [title]: search } as F));
  };

  return (
    <div style={{ flex: 1 }}>
      {columns && (
        <div className={classes.root}>
          {columns.map((c, index) => (
            <Fragment key={c.title + index}>
              {c.filterKey ? (
                <SearchField
                  placeholder={c.title}
                  className={c.className}
                  onChange={handleOnChange(c.filterKey)}
                />
              ) : (
                <div className={c.className}>{c.title}</div>
              )}
            </Fragment>
          ))}
        </div>
      )}
      <AutoSizer disableWidth={true}>
        {({ height }) => (
          <div
            className={classes.rowsWrapper}
            style={{ maxHeight: height - (bottomHeightMargin || 0) }}
          >
            {roots
              .filter((r) =>
                filterFunction ? filterFunction(r, filters) : true
              )
              .map((root, index) => rowRenderer(root, index))}
          </div>
        )}
      </AutoSizer>
    </div>
  );
}

export default ExpandableTable;

import { format } from "date-fns";

const transporterTrigrams: Record<string, string> = {
  EUROPE: "EUR",
  JC: "J&C",
  VOYAGES: "VOY",
  TER: "TER",
  EURAIL: "ERA",
  OUIGO: "OUI",
  TRENITALIA: "TRE",
};

function getTrigram(whitelistType: string, transporter?: string) {
  if (transporter && transporterTrigrams[transporter]) {
    return transporterTrigrams[transporter];
  }
  return whitelistType.toUpperCase();
}

function getSemVersion(previousSemVersion: string) {
  const mmp = previousSemVersion.split(".");
  if (mmp.length != 3) {
    return;
  }
  let major = Number.parseInt(mmp[0]);
  let minor = Number.parseInt(mmp[1]);
  let patch = Number.parseInt(mmp[2]);

  if (Number.isNaN(major) || Number.isNaN(minor) || Number.isNaN(patch)) {
    return;
  }

  patch += 1;
  if (patch > 99) {
    patch = 0;
    minor += 1;
  }
  if (minor > 9) {
    minor = 0;
    major += 1;
  }
  return `${major}.${minor}.${patch.toString().padStart(2, "0")}`;
}

function getDateVersion() {
  return format(new Date(), "dd-MM-yyyy");
}

export function formatWhiteListName(
  whitelistType: string,
  transporter?: string,
  previousName?: string
) {
  // Compute trigam
  const tri = getTrigram(whitelistType, transporter);
  // Compte date DD-MM-YYYY
  const datever = getDateVersion();
  // Compute semantic version
  let semver = "0.0.01";
  if (previousName) {
    const previousVersionIndex = previousName.lastIndexOf("-v");
    if (previousVersionIndex !== -1) {
      const previousSemVersion = previousName.slice(
        Math.max(0, previousVersionIndex + 2)
      );
      const nextSemVersion = getSemVersion(previousSemVersion);
      if (nextSemVersion) {
        semver = nextSemVersion;
      }
    }
  }

  return `WL_${tri}_${datever}-v${semver}`;
}

import axios from "axios";

export interface SncfUser {
  sn: string;
  cn: string;
  givenName: string;
}

/**
 * Fetches sncf user data / provides a mock
 */
export const fetchSncfUser = async (username: string): Promise<SncfUser> => {
  const response = await axios.get(`/users/_sncfUser/${username}`);
  return response.data;
};

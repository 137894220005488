import React from "react";
import { BrowserRouter } from "react-router-dom";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import LoginManager from "../pages/login/login-manager";
import Layout from "./layout";
import { CircularProgress, createTheme } from "@material-ui/core";
import { CheckCircleOutline, HighlightOff, Warning } from "@material-ui/icons";
import {
  PRIMARY_COLOR,
  SECONDARY_COLOR,
  SNCF_COMPL_GREEN,
  SNCF_RED,
  SNCF_ORANGE,
  SNCF_COMPL_BLUE,
} from "../theme";
import AppProvider from "../app-provider";
import ConfirmDialog from "../components/dialog/confirm-dialog";
import { SnackbarProvider } from "notistack";
import SnackbarActions from "../components/snackbar-actions";
import RouteManager from "./route-manager";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: SECONDARY_COLOR,
    },
  },
});

const useStyles = makeStyles({
  success: { backgroundColor: SNCF_COMPL_GREEN },
  error: { backgroundColor: SNCF_RED },
  warning: { backgroundColor: SNCF_ORANGE },
  info: { backgroundColor: SNCF_COMPL_BLUE },
});

export default function App() {
  const classes = useStyles();

  return (
    <div className="App">
      <ThemeProvider theme={defaultTheme}>
        <AppProvider>
          <SnackbarProvider
            maxSnack={5}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            action={(key) => <SnackbarActions id={key} />}
            disableWindowBlurListener={true}
            classes={{
              variantSuccess: classes.success,
              variantError: classes.error,
              variantWarning: classes.warning,
              variantInfo: classes.info,
            }}
            iconVariant={{
              info: (
                <CircularProgress
                  size={24}
                  color="inherit"
                  style={{ marginRight: "12px" }}
                />
              ),
              success: <CheckCircleOutline style={{ marginRight: "12px" }} />,
              error: <HighlightOff style={{ marginRight: "12px" }} />,
              warning: <Warning style={{ marginRight: "12px" }} />,
            }}
          >
            <>
              <ConfirmDialog />
              <BrowserRouter>
                <LoginManager>
                  <Layout>
                    <RouteManager />
                  </Layout>
                </LoginManager>
              </BrowserRouter>
            </>
          </SnackbarProvider>
        </AppProvider>
      </ThemeProvider>
    </div>
  );
}

import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import classNames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { DockViewState } from "../dock-tab";
import TrackLaneTable from "../common/track-lane-table";
import { UseCrud } from "../../../../commons/use-crud";
import { Lane, Track } from "../../../../services/interfaces/referential";
import useRights from "../../../../services/ducks/rights";
import ClickableEditIcon from "../../../../components/form/clickable-edit-icon";

interface DockViewDialogProperties {
  dialog: DockViewState;
  setDialog: (status: DockViewState) => void;
  lanesState: UseCrud<Lane>;
  tracksState: UseCrud<Track>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      overflowY: "visible",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      flexWrap: "nowrap",
      minWidth: "40vw",
    },
    right: {
      float: "right",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& > *": {
        marginLeft: theme.spacing(3),
      },
    },
    buttonsGroupLayout: {
      display: "flex",
      justifyContent: "flex-end",
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(2),
    },
  })
);

function DockViewDialog({
  dialog,
  setDialog,
  lanesState,
  tracksState,
}: DockViewDialogProperties) {
  const classes = useStyles();
  const { hasRights } = useRights();

  function close() {
    setDialog({
      ...dialog,
      open: false,
    });
  }

  function edit() {
    close();
    if (dialog.edit) {
      dialog.edit();
    }
  }

  return (
    <Dialog
      open={dialog.open}
      onBackdropClick={close}
      onEscapeKeyDown={close}
      color={"primary"}
      aria-labelledby="form-dialog-title"
      PaperProps={{ className: classNames(classes.dialogPaper) }}
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Zone étanche{" "}
        {dialog.data && (
          <>
            <span>{dialog.data.name}</span>
            {hasRights("REF_GARE_WRITE") && (
              <div className={classes.right}>
                <ClickableEditIcon onClick={edit} />
              </div>
            )}
          </>
        )}
      </DialogTitle>
      <DialogContent>
        {dialog.data && (
          <>
            <p>{dialog.data.description}</p>
            <TrackLaneTable
              dockId={dialog.data.id}
              trackIds={dialog.data.trackIds}
              laneIds={dialog.data.laneIds}
              trackLaneDTOList={dialog.data.trackLaneDTOList}
              lanesState={lanesState}
              tracksState={tracksState}
              editMode={false}
              computeTableHeightUpTo={360}
            />
          </>
        )}
      </DialogContent>
      <DialogActions className={classes.buttonsGroupLayout}>
        <Button onClick={close} variant="contained">
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DockViewDialog;

import React, { useContext, useState } from "react";
import {
  addZone,
  deleteZone,
  deleteZones,
  updateZone,
} from "../../../services/api/zones";
import { Lane, Zone } from "../../../services/interfaces/referential";
import OptionsButton from "../../../components/options-button";
import { Paper, Theme } from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { AppContext } from "../../../app-provider";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ZoneFormDialog from "./dialog/zone-form-dialog";
import { UseCrud } from "../../../commons/use-crud";
import ChipsRenderer from "../../../components/virtualized-table/renderers/chips-renderer";
import { useSnackbar } from "notistack";
import PopoverWrapperRenderer from "../../../components/virtualized-table/renderers/popover-wrapper-renderer";
import SortableAndSearchableVirtualizedTable from "../../../components/virtualized-table/sortable-and-searchable-virtualized-table";
import useRights from "../../../services/ducks/rights";

interface ZoneTabProperties {
  stationId: string;
  lanesState: UseCrud<Lane>;
  zonesState: UseCrud<Zone>;
  dataInFetching: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
    },
    fab: {
      float: "right",
      margin: theme.spacing(1),
    },
  })
);

export interface ZoneFormState {
  open: boolean;
  action?: "add" | "edit";
  data?: Zone;
  submit?: (zone: Zone) => Promise<void> | void;
}

/**
 * ZoneTab Component
 */
function ZoneTab({
  stationId,
  lanesState,
  zonesState,
  dataInFetching,
}: ZoneTabProperties) {
  const classes = useStyles();
  const { hasRights } = useRights();

  const lanes = lanesState.datas;
  const zones = zonesState.datas;
  const [selected, setSelected] = useState<Set<string>>(new Set([]));
  const [, confirmDialogDispatch] =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    useContext(AppContext).reducers.confirmDialog!;
  const [formDialog, setFormDialog] = useState<ZoneFormState>({
    open: false,
  });
  const { enqueueSnackbar } = useSnackbar();

  // ----------- ReactVirtualizedTable
  const handleSelected = (zone: Zone, checked: boolean) => {
    const updateSelected = new Set(selected);
    checked ? updateSelected.add(zone.id) : updateSelected.delete(zone.id);
    setSelected(updateSelected);
  };

  const isSelected = (zone: Zone): boolean => {
    return selected.has(zone.id);
  };

  // ----------- api actions

  async function deleteById(rowIndex: number) {
    handleSelected(zones[rowIndex], false);
    try {
      await deleteZone(zones[rowIndex].id);
      enqueueSnackbar(
        `Zone étanche "${zones[rowIndex].name}" supprimée avec succès`,
        {
          variant: "success",
        }
      );
      zonesState.remove(zones[rowIndex].id);
    } catch {
      enqueueSnackbar(`Erreur lors de la suppression de la zone`, {
        variant: "error",
      });
    }
  }

  async function deleteBySelection() {
    try {
      await deleteZones(selected);
      enqueueSnackbar(
        selected.size > 0
          ? `Zone supprimées avec succès`
          : `zone "${selected.values().next().value}" supprimée avec succès`,
        { variant: "success" }
      );
      zonesState.removeAll(selected);
      setSelected(new Set([]));
    } catch {
      enqueueSnackbar(
        selected.size > 0
          ? `Erreur lors de la suppression des zones`
          : `Erreur lors de la suppression de la zone`,
        {
          variant: "error",
        }
      );
    }
  }

  async function add(zone: Zone) {
    try {
      zonesState.add(await addZone(zone));
      enqueueSnackbar(`zone créée avec succès`, { variant: "success" });
    } catch {
      enqueueSnackbar(`Erreur lors de la création de la zone`, {
        variant: "error",
      });
    }
  }

  async function edit(zone: Zone) {
    try {
      zonesState.update(await updateZone(zone));
      enqueueSnackbar(`zone modifiée avec succès`, {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(`Erreur lors de la modification de la zone`, {
        variant: "error",
      });
    }
  }

  // ----------- renders

  const renderOptionsButton = (rowIndex: number) => {
    const buttonActions = [
      {
        label: "Modifier les informations",
        handler: () => {
          setFormDialog({
            open: true,
            action: "edit",
            data: { ...zones[rowIndex] },
            submit: (zone: Zone) => edit(zone),
          });
        },
      },
      {
        label: "Supprimer la zone",
        handler: () =>
          confirmDialogDispatch({
            type: "open",
            title: "Suppression de zone",
            msg:
              'Êtes-vous sûr de vouloir supprimer la zone "' +
              zones[rowIndex].name +
              '" ?',
            submit: () => deleteById(rowIndex),
          }),
      },
    ];

    return <OptionsButton buttonActions={buttonActions} />;
  };

  const headerLabelZone = [
    {
      label: "Nom",
      dataKey: "name",
      sortable: true,
      searchable: true,
      flexGrow: 0.5,
    },
    {
      label: "Description",
      dataKey: "description",
      sortable: true,
      searchable: true,
    },
    {
      label: "Lignes associées",
      dataKey: "laneIds",
      renderer: (zone: Zone) => (
        <PopoverWrapperRenderer>
          <ChipsRenderer elts={lanesState.getNames(zone.laneIds)} />
        </PopoverWrapperRenderer>
      ),
      renderButtonActions: hasRights("REF_GARE_WRITE")
        ? renderOptionsButton
        : undefined,
    },
  ];

  return (
    <>
      <ZoneFormDialog
        form={formDialog}
        setForm={setFormDialog}
        stationId={stationId}
        lanes={lanes}
      />
      <Paper className={classes.root}>
        <SortableAndSearchableVirtualizedTable
          rows={zones}
          rowCount={zones.length}
          updateRows={zonesState.set}
          columns={headerLabelZone}
          selected={selected}
          setSelected={setSelected}
          handleSelected={handleSelected}
          isSelected={isSelected}
          dataInFetching={dataInFetching}
          renderSelectionCheckboxes={hasRights("REF_GARE_WRITE")}
        />
      </Paper>
      {hasRights("REF_GARE_WRITE") && (
        <div>
          <Tooltip title="Ajouter" aria-label="Ajouter" className={classes.fab}>
            <Fab
              color="secondary"
              onClick={() =>
                setFormDialog({
                  open: true,
                  action: "add",
                  submit: add,
                })
              }
            >
              <AddIcon />
            </Fab>
          </Tooltip>
          <Tooltip
            title="Supprimer"
            aria-label="Supprimer"
            className={classes.fab}
          >
            <div>
              <Fab
                disabled={selected.size === 0}
                onClick={() =>
                  confirmDialogDispatch({
                    type: "open",
                    title: "Suppression de zone",
                    msg: "Êtes-vous sûr de vouloir supprimer cette sélection ?",
                    submit: deleteBySelection,
                  })
                }
              >
                <DeleteIcon />
              </Fab>
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
}

export default ZoneTab;

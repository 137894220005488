import React, { useEffect, useRef, useState } from "react";
import ReactVirtualizedTable, {
  ColumnData,
  BaseMuiVirtualizedTableProperties,
} from "./index";
import orderBy = require("lodash/orderBy");
import { SortDirectionType } from "./sort";

export interface IndexedRow {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
  rowIndex?: number;
}

export interface Search {
  key: string;
  value: string;
}

interface SortableAndSearchableVirtualizedTableProperties
  extends BaseMuiVirtualizedTableProperties {
  selected?: Set<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  enableRowCheckbox?: (row: any) => boolean;
}

function SortableAndSearchableVirtualizedTable(
  properties: SortableAndSearchableVirtualizedTableProperties
) {
  const [columns, setColumns] = useState<ColumnData[]>([]);
  const [rows, setRows] = useState<IndexedRow[]>([]);
  const sortBysReference = useRef<string[]>([]);
  const sortDirectionsReference = useRef<SortDirectionType[]>([]);
  const searchsReference = useRef<Search[]>([]);

  // ----------- effects

  useEffect(() => {
    setColumns(
      properties.columns.map((c) => {
        const index = sortBysReference.current.indexOf(c.dataKey);
        if (index > -1) {
          c.sortDirection = sortDirectionsReference.current[index];
        }
        return c;
      })
    );
  }, [properties.columns]);

  useEffect(() => {
    const rowsToSave = properties.dataInFetching ? [] : properties.rows;
    for (const [key, r] of rowsToSave.entries()) r.rowIndex = key;
    setRows(
      orderBy(
        search(rowsToSave),
        sortBysReference.current,
        sortDirectionsReference.current
      )
    );
  }, [properties.dataInFetching, properties.rows]);

  // ----------- handlers

  function handleSort(sortBy: string, sortDirection: SortDirectionType) {
    let sortBys = [...sortBysReference.current];
    let sortDirections = [...sortDirectionsReference.current];

    // add or replace sort
    const index = sortBys.indexOf(sortBy);
    if (index > -1) {
      sortBys.splice(index, 1);
      sortDirections.splice(index, 1);
    }
    sortBys = [...sortBys, sortBy];
    sortDirections = [...sortDirections, sortDirection];

    // clear false sort direction
    let indexFalse = 0;
    while (indexFalse > -1) {
      indexFalse = sortDirections.indexOf(false);
      if (indexFalse > -1) {
        sortBys.splice(indexFalse, 1);
        sortDirections.splice(indexFalse, 1);
      }
    }

    sortBysReference.current = sortBys;
    sortDirectionsReference.current = sortDirections;
    setColumns(
      columns.map((c) => {
        const index = sortBys.indexOf(c.dataKey);
        c.sortDirection = index > -1 ? sortDirections[index] : undefined;
        c.sortPriority = index + 1;
        return c;
      })
    );
    setRows(orderBy(properties.rows, sortBys, sortDirections));
  }

  function search(indexedRows: IndexedRow[]) {
    return indexedRows.filter((r) =>
      searchsReference.current.every((s) => {
        const currentColumn = columns.find((c) => c.dataKey === s.key);
        const searchableElements =
          currentColumn && currentColumn.searchableElements
            ? currentColumn.searchableElements(r)
            : r[s.key];
        return (
          (typeof searchableElements === "string" &&
            searchableElements.toLowerCase().includes(s.value)) ||
          (Array.isArray(searchableElements) &&
            searchableElements.join(",").toLowerCase().includes(s.value))
        );
      })
    );
  }

  function handleSearch(key: string, value: string) {
    searchsReference.current = searchsReference.current.filter(
      (s) => s.key !== key
    );
    const string_ = value.trim().toLocaleLowerCase();
    if (string_.length > 0) {
      searchsReference.current.push({ key, value: string_ });
    }
    setRows(
      orderBy(
        search(properties.rows),
        sortBysReference.current,
        sortDirectionsReference.current
      )
    );
  }

  return (
    <ReactVirtualizedTable
      renderSelectionCheckboxes={properties.renderSelectionCheckboxes}
      columns={columns}
      rows={rows}
      headerHeight={properties.headerHeight}
      onRowClick={properties.onRowClick}
      updateRows={properties.updateRows}
      rowCount={properties.rowCount}
      rowHeight={properties.rowHeight}
      rowStyle={properties.rowStyle}
      setSelected={properties.setSelected}
      handleSelected={properties.handleSelected}
      isSelected={properties.isSelected}
      dataInFetching={properties.dataInFetching}
      computeTableHeightUpTo={properties.computeTableHeightUpTo}
      minTableHeight={properties.minTableHeight}
      disableHeight={properties.disableHeight}
      onSort={handleSort}
      onSearch={handleSearch}
      enableRowCheckbox={properties.enableRowCheckbox}
    />
  );
}

export default SortableAndSearchableVirtualizedTable;

import axios from "axios";

export async function fetchHelpFiles(helpType: string): Promise<string[]> {
  const response = await axios.get(`/files/help/${helpType}`);
  return response.data;
}

export async function downloadHelpFile(
  helpType: string,
  filename: string
): Promise<string> {
  const response = await axios.get(`/files/help/${helpType}/${filename}`, {
    responseType: "blob",
  });
  return response.data;
}

export async function uploadHelpFile(
  helpType: string,
  formData: FormData
): Promise<string> {
  const response = await axios.post(`/files/help/${helpType}`, formData);
  return response.data;
}

export async function deleteHelpFile(helpType: string, filename: string) {
  await axios.delete(`/files/help/${helpType}/${filename}`);
}

import * as H from "history";

export const tabsConfigs = [
  {
    key: "gates",
    label: "Portes",
  },
  {
    key: "lanes",
    label: "Lignes",
  },
  {
    key: "tracks",
    label: "Voies",
  },
  {
    key: "docks",
    label: "Zones étanches",
  },
  {
    key: "zones",
    label: "Zones",
  },
];

export const tabsRoutes = {
  gates: (stationId: string) => `/referential/stations/${stationId}/gates`,
  lanes: (stationId: string) => `/referential/stations/${stationId}/lanes`,
  tracks: (stationId: string) => `/referential/stations/${stationId}/tracks`,
  docks: (stationId: string) => `/referential/stations/${stationId}/docks`,
  zones: (stationId: string) => `/referential/stations/${stationId}/zones`,
};

export function historyPush(
  history: H.History,
  indexTab: number,
  stationId: string
) {
  switch (indexTab) {
    case 0: {
      history.push(tabsRoutes.gates(stationId));
      break;
    }
    case 1: {
      history.push(tabsRoutes.lanes(stationId));
      break;
    }
    case 2: {
      history.push(tabsRoutes.tracks(stationId));
      break;
    }
    case 3: {
      history.push(tabsRoutes.docks(stationId));
      break;
    }
    case 4: {
      history.push(tabsRoutes.zones(stationId));
      break;
    }
  }
}

export function retrieveIndexTab(stationId: string, pathname: string) {
  if (tabsRoutes.gates(stationId).startsWith(pathname)) {
    return 0;
  }
  if (tabsRoutes.lanes(stationId).startsWith(pathname)) {
    return 1;
  }
  if (tabsRoutes.tracks(stationId).startsWith(pathname)) {
    return 2;
  }
  if (tabsRoutes.docks(stationId).startsWith(pathname)) {
    return 3;
  }
  if (tabsRoutes.zones(stationId).startsWith(pathname)) {
    return 4;
  }
  return 0;
}

import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Theme,
  DialogContent,
  Button,
  DialogActions,
  DialogTitle,
  Box,
  Dialog,
  makeStyles,
  createStyles,
} from "@material-ui/core";
import { AvailableUserOptions } from "../../services/api/available-user-options";
import { User, EUserType } from "../../services/api/users";
import { defaultUser } from "./user-edit-modal";
import ItemElement from "components/list/item-element";
import ItemElementList from "components/list/item-element-list";
import ClickableEditIcon from "../../components/form/clickable-edit-icon";
import useRights from "../../services/ducks/rights";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loaderWrapperLayout: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "400px",
      height: "200px",
    },
    dialogLayout: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "100%",
      padding: theme.spacing(3),
    },
    buttonsGroupLayout: {
      display: "flex",
      justifyContent: "flex-end",
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(2),
    },
    horizontalBox: {
      display: "flex",
      flexDirection: "row",
      minWidth: "400px",
    },
    titleWrapperLayout: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    listElementLayout: {
      marginTop: theme.spacing(1.5),
    },
    itemElementLayout: {
      marginTop: theme.spacing(1.5),
      maxHeight: "100px",
    },
    marginRight: {
      marginRight: theme.spacing(2),
    },
  })
);

interface UserConsultProperties {
  open: boolean;
  availableUserOptions?: AvailableUserOptions;
  currentUser?: User;
  closeModal: () => void;
  setOpenEdit: (bool: boolean) => void;
  setEditedUser: (id?: User) => void;
}

/**
 * Create New User / Modify User Edit Component
 */
export function UserConsult({
  currentUser,
  closeModal,
  availableUserOptions,
  open,
  setOpenEdit,
  setEditedUser,
}: UserConsultProperties) {
  const [user, setUser] = useState<User>(defaultUser);
  const classes = useStyles();
  const { hasRights, canEditUser } = useRights();

  useEffect(() => {
    if (currentUser) {
      setUser(currentUser);
    }
  }, [user, open]);

  const handleCancelClick = () => {
    closeModal();
  };

  const handleEditIconClick = () => {
    closeModal();
    setEditedUser(user);
    setOpenEdit(true);
  };

  return (
    <Dialog open={open} onEscapeKeyDown={closeModal}>
      {user.id === undefined || !availableUserOptions ? (
        <div className={classes.loaderWrapperLayout}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <Box className={classes.titleWrapperLayout}>
            <DialogTitle>Profil</DialogTitle>
            {hasRights("USER_UPDATE") && canEditUser(user) && (
              <ClickableEditIcon
                onClick={handleEditIconClick}
                additionalClassName={classes.marginRight}
              />
            )}
          </Box>
          <DialogContent className={classes.dialogLayout}>
            <Box className={classes.horizontalBox}>
              <ItemElement
                label="Utilisateur SNCF"
                text={user.type === EUserType.SNCF ? "Oui" : "Non"}
                additionalClassName={classes.itemElementLayout}
              />
              <ItemElement
                label="Utilisateur actif"
                text={user.active ? "Oui" : "Non"}
                additionalClassName={classes.itemElementLayout}
              />
            </Box>
            <Box className={classes.horizontalBox}>
              <ItemElement
                label="Identifiant"
                text={user.username}
                additionalClassName={classes.itemElementLayout}
              />
            </Box>
            <Box className={classes.horizontalBox}>
              <ItemElement
                label="Nom"
                text={user.lastName}
                additionalClassName={classes.itemElementLayout}
              />
              <ItemElement
                label="Prénom"
                text={user.firstName}
                additionalClassName={classes.itemElementLayout}
              />
            </Box>
            <Box className={classes.horizontalBox}>
              <ItemElement
                label="Activité"
                text={
                  (
                    availableUserOptions.activities.find(
                      (option) => user.activity === option.id
                    ) || { name: "" }
                  ).name
                }
                additionalClassName={classes.itemElementLayout}
              />
              <ItemElement
                label="Profil"
                text={
                  (
                    availableUserOptions.profiles.find(
                      (option) => user.profile === option.id
                    ) || { name: "" }
                  ).name
                }
                additionalClassName={classes.itemElementLayout}
              />
            </Box>
            {user.transporters && user.transporters.length > 0 && (
              <Box className={classes.horizontalBox}>
                <ItemElementList
                  label="Transporteurs"
                  fullWidth
                  height="160px"
                  noFixedHeight
                  rows={availableUserOptions.transporters
                    .filter((option) => user.transporters.includes(option.id))
                    .map((transporter, index) => (
                      <span key={index}>{transporter.name}</span>
                    ))}
                />
              </Box>
            )}
            <Box className={classes.horizontalBox}>
              <ItemElement
                label="Périmètre géographique"
                text={
                  (
                    availableUserOptions.perimeters.find(
                      (option) => user.perimeter === option.id
                    ) || { name: "" }
                  ).name
                }
                additionalClassName={classes.itemElementLayout}
              />
            </Box>
            {user.perimeterElements && user.perimeterElements.length > 0 && (
              <Box className={classes.horizontalBox}>
                <ItemElementList
                  label="Périmètre sélectionné"
                  fullWidth
                  height="160px"
                  noFixedHeight
                  additionalClassName={classes.listElementLayout}
                  rows={[
                    ...availableUserOptions.stations,
                    ...availableUserOptions.stationGroups,
                  ]
                    .filter((option) =>
                      user.perimeterElements.includes(option.id)
                    )
                    .map((perimeterElement, index) => (
                      <span key={index}>{perimeterElement.name}</span>
                    ))}
                />
              </Box>
            )}
          </DialogContent>
        </>
      )}
      <DialogActions className={classes.buttonsGroupLayout}>
        <Button variant="contained" onClick={handleCancelClick}>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserConsult;

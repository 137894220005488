import { Tab } from "../../../components/tabs-component";
import useRights from "services/ducks/rights";
import { getConfig } from "services/config";

export enum TabKey {
  UG = "ug",
  TRANSPORTER = "transporter",
}

const UGTab: Tab = {
  key: TabKey.UG,
  label: "WHITELISTS UG",
};
const transporterTab: Tab = {
  key: TabKey.TRANSPORTER,
  label: "WHITELISTS TRANSPORTEURS",
};

export function useTabs(): Tab[] {
  const tabs: Tab[] = [];
  const { hasRights } = useRights();
  const config = getConfig();

  if (!config.LIGHT_MODE && hasRights("WL_UG_CONFIG_READ")) tabs.push(UGTab);

  if (hasRights("WL_TRANSPORTER_CONFIG_READ")) tabs.push(transporterTab);

  return tabs;
}

import React, { useState, useEffect } from "react";
import { Grid, Box } from "@material-ui/core";
import useStations from "pages/referentials/station/use-stations";
import {
  BoardingModesTransporter,
  Station,
} from "services/interfaces/referential";
import { StationListAddButton } from "./station-list-add-button";
import { StationListRemoveButton } from "./station-list-remove-button";
import { StationListSelect } from "./station-list-select";

interface StationListProperties {
  canEdit: boolean;
  selected?: string;
  stationsList: string[];
  enableAllStationsKey: boolean;
  onSelect: (station: string) => void;
  onAdd: (station: string) => void;
  onRemove: (station: string) => void;
}

export function StationList({
  selected,
  stationsList,
  canEdit,
  enableAllStationsKey,
  onSelect,
  onAdd,
  onRemove,
}: StationListProperties) {
  const [availableStations, setAvailableStations] = useState<Station[]>([]);
  const [selectableStations, setSelectableStations] = useState<Station[]>([]);
  const allStations = useStations();

  useEffect(() => {
    let allStationConcat: Station[] = [];
    if (enableAllStationsKey) {
      allStationConcat = [
        {
          id: "allStations",
          name: "Toutes les gares",
          uic: 0,
          sqills: "",
          equipped: false,
          lat: 0,
          lng: 0,
          zoom: 0,
          updateUrl: "",
          boardingModesTransporters: [] as BoardingModesTransporter[],
        },
      ];
    }

    const available = [...allStationConcat, ...allStations].filter(
      (s) => !stationsList.includes(s.id)
    );

    const selectable = [...allStationConcat, ...allStations].filter((s) =>
      stationsList.includes(s.id)
    );

    setSelectableStations(selectable);
    setAvailableStations(available);

    if (selectable.length > 0 && !selected) {
      onSelect(selectable[0].id);
    }
  }, [stationsList, allStations, enableAllStationsKey]);

  return (
    <Grid item xs={4}>
      <StationListSelect
        selectedId={selected}
        stationsList={selectableStations}
        onSelect={onSelect}
      />
      <div>
        {canEdit && (
          <Box>
            <StationListRemoveButton
              onRemove={() => {
                if (selected) {
                  onRemove(selected);
                }
              }}
              disabled={!selected}
            />
            <StationListAddButton
              availableStations={availableStations}
              onAdd={(station) => onAdd(station.id)}
            />
          </Box>
        )}
      </div>
    </Grid>
  );
}

import React from "react";
import { Paper, Box, makeStyles, Theme } from "@material-ui/core";
import { SNCF_BCKGND_GRAY, SNCF_MAGENTA } from "../../theme";

const useStyles = makeStyles((theme: Theme) => ({
  paperLayout: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    padding: theme.spacing(3),
    width: "60%",
    minHeight: "300px",
    margin: "auto",
  },
  paperHeaderLayout: {
    alignItems: "center",
    backgroundColor: SNCF_BCKGND_GRAY,
    padding: theme.spacing(3),
  },
  bodyLayout: {
    fontSize: "32px",
  },
  errorIconColor: {
    color: SNCF_MAGENTA,
  },
}));

function PageNotFound() {
  const classes = useStyles();

  return (
    <Paper className={classes.paperLayout}>
      <Box
        className={classes.bodyLayout}
        display="flex"
        flex="1"
        justifyContent="center"
        alignItems="center"
      >
        Vous n&apos;avez pas accès à cette page
      </Box>
    </Paper>
  );
}

export default PageNotFound;

import React from "react";
import { Button } from "@material-ui/core";
import { RouteComponentProps, withRouter } from "react-router";
import { RouteProperties } from ".";
import {
  WhitelistDTO,
  WhitelistCreateDTO,
  createWhitelist,
} from "services/api/whitelists";
import { useSnackbar } from "notistack";
import { formatWhiteListName } from "./format-whitelist-name";

const WHITELIST_ALREADY_CREATED_MESSAGE =
  "Une configuration ayant déjà été créée aujourd'hui, merci de bien vouloir modifier celle-ci en cas de besoin";
const WHITELIST_CREATE_BUTTON_TEXT =
  "Creer une nouvelle version des whitelists";

type WhitelistCreateButtonProperties = {
  refWhitelist?: WhitelistDTO;
  onCreate: (newWhitelist: WhitelistDTO) => void;
} & RouteComponentProps<RouteProperties>;

function WhitelistCreateButton({
  refWhitelist,
  onCreate,
  match,
}: WhitelistCreateButtonProperties) {
  const { enqueueSnackbar } = useSnackbar();

  const handleCreate = () => {
    if (refWhitelist && refWhitelist.locked === false) {
      enqueueSnackbar(WHITELIST_ALREADY_CREATED_MESSAGE, {
        variant: "warning",
      });
      return;
    }

    const whitelistCreate = async () => {
      try {
        const newName = formatWhiteListName(
          match.params.type,
          match.params.transporter,
          refWhitelist ? refWhitelist.name : undefined
        );
        const newWl: WhitelistCreateDTO = {
          fromWhitelistId: refWhitelist ? refWhitelist.id : undefined,
          name: newName,
        };

        const wl = await createWhitelist(
          {
            whitelistType: match.params.type,
            transporter: match.params.transporter,
          },
          newWl
        );
        enqueueSnackbar(`${wl.name} créée avec succès`, { variant: "success" });
        onCreate(wl);
      } catch (error_: unknown) {
        const error = error_ as Error;
        enqueueSnackbar(`Erreur lors de la création: ${error.toString()}`, {
          variant: "error",
        });
      }
    };
    whitelistCreate();
  };

  return (
    <div>
      <Button color="secondary" variant="contained" onClick={handleCreate}>
        {WHITELIST_CREATE_BUTTON_TEXT}
      </Button>
    </div>
  );
}

export default withRouter(WhitelistCreateButton);

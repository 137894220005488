import { Chip, Theme } from "@material-ui/core";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

interface ChipsRendererProperties {
  elts: string[];
}

function ChipsRenderer({ elts = [] }: ChipsRendererProperties) {
  const classes = useStyles();
  return (
    <>
      {elts.map((value: string, index: number) => (
        <Chip key={index} label={value} className={classes.chip} />
      ))}
    </>
  );
}

export default ChipsRenderer;

import React from "react";
import { HelpFile } from "./help-file";

interface HelpFilesProperties {
  helpFiles: Array<string>;
  onDelete: (helpFile: string) => void;
  onDownload: (helpFile: string) => void;
  canDelete?: boolean;
}

export function HelpFiles({
  helpFiles,
  onDelete,
  onDownload,
  canDelete = false,
}: HelpFilesProperties) {
  return (
    <div>
      {helpFiles.map((helpFile, index) => (
        <HelpFile
          key={index}
          file={helpFile}
          onDelete={onDelete}
          onDownload={onDownload}
          canDelete={canDelete}
        />
      ))}
    </div>
  );
}

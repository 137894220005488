import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Theme,
} from "@material-ui/core";
import {
  checkFields,
  FieldValidator,
} from "../../../../validators/form-validator";
import { isEmpty, max } from "../../../../validators/form-validators";
import createStyles from "@material-ui/core/styles/createStyles";
import isEqual = require("lodash/isEqual");
import FormControl from "@material-ui/core/FormControl";
import classNames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { ZoneFormState } from "../zone-tab";
import { Lane, Zone } from "../../../../services/interfaces/referential";
import FormCheckboxList, {
  updateArray,
} from "../../../../components/form/checkbox-list";

interface ZoneFormDialogProperties {
  form: ZoneFormState;
  setForm: (status: ZoneFormState) => void;
  stationId: string;
  lanes: Lane[];
}

const zoneInitialValue: Zone = {
  id: "",
  name: "",
  description: "",
  laneIds: [],
  stationId: "",
};

interface Errors {
  name: string;
  description: string;
}

const validators: FieldValidator<Zone, Errors> = {
  name: [
    { error: "Veuillez renseigner un nom", validator: isEmpty },
    { error: "Longueur max: 64", validator: (name: string) => max(name, 64) },
  ],
  description: [
    {
      error: "Longueur max: 100",
      validator: (description: string) => max(description, 100),
    },
  ],
};

const defaultErrors: Errors = {
  name: "",
  description: "",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginTopField: {
      marginTop: theme.spacing(2),
    },
    dialogPaper: {
      overflowY: "visible",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      flexWrap: "nowrap",
      minWidth: "40vw",
    },
    buttonsGroupLayout: {
      display: "flex",
      justifyContent: "flex-end",
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(2),
    },
  })
);

function ZoneFormDialog({
  form,
  setForm,
  stationId,
  lanes,
}: ZoneFormDialogProperties) {
  const classes = useStyles();
  const [data, setData] = useState<Zone>({ ...zoneInitialValue, stationId });
  const [errors, setErrors] = useState<Errors>(defaultErrors);

  useEffect(() => {
    if (form.open) {
      setErrors(defaultErrors);
      if (form.data === undefined) {
        setData({ ...zoneInitialValue, stationId });
      } else {
        setData({ ...form.data });
      }
    }
  }, [form.data, form.open, stationId]);

  function close() {
    setForm({
      ...form,
      open: false,
    });
  }

  function save() {
    if (validateData()) {
      if (form.open && form.submit !== undefined) {
        form.submit({ ...data });
      }
      close();
    }
  }

  function handleLaneIdsChange(event: React.MouseEvent<HTMLDivElement>) {
    const laneIds: string[] = updateArray(
      event.currentTarget.dataset["value"] as string,
      data.laneIds
    );
    setData({ ...data, laneIds });
  }

  const validateData = (): boolean => {
    const errors = checkFields<Zone, Errors>(validators, defaultErrors, data);
    setErrors(errors);
    return isEqual(errors, defaultErrors);
  };

  return (
    <Dialog
      open={form.open}
      onBackdropClick={close}
      onEscapeKeyDown={close}
      color={"primary"}
      aria-labelledby="form-dialog-title"
      PaperProps={{ className: classNames(classes.dialogPaper) }}
    >
      <DialogTitle id="form-dialog-title">
        {form.action === "add" ? "Créer une zone" : "Modifier une zone"}
      </DialogTitle>
      <DialogContent>
        <FormControl variant="standard" fullWidth margin="dense">
          <TextField
            id="name"
            label="Nom"
            type="text"
            value={data.name || ""}
            onChange={(event) => setData({ ...data, name: event.target.value })}
            error={errors.name !== ""}
            helperText={errors.name}
          />
        </FormControl>
        <FormControl variant="standard" fullWidth margin="dense">
          <TextField
            id="description"
            label="Description"
            type="text"
            value={data.description || ""}
            onChange={(event) =>
              setData({ ...data, description: event.target.value })
            }
            error={errors.description !== ""}
            helperText={errors.description}
          />
        </FormControl>
        <div className={classes.marginTopField}>
          <FormCheckboxList
            label="Lignes associées"
            handleOnChange={handleLaneIdsChange}
            availableOptions={lanes.map((l) => ({ id: l.id, name: l.name }))}
            checkedElements={data.laneIds}
            noFixedHeight
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.buttonsGroupLayout}>
        <Button onClick={close} variant="contained">
          Annuler
        </Button>
        <Button onClick={save} color="secondary" autoFocus variant="contained">
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ZoneFormDialog;

import { Dispatch, SetStateAction, useState } from "react";
import { IdNameObject } from "../services/interfaces/common";

export interface UseCrud<T extends IdNameObject> {
  datas: T[];
  set: Dispatch<SetStateAction<T[]>>;
  add: (data: T) => void;
  update: (data: T) => void;
  remove: (id: string) => void;
  removeAll: (ids: Set<string>) => void;
  getNames: (ids: string[]) => string[];
  getName: (id: string) => string;
}

function useCrud<T extends IdNameObject>(_name?: string): UseCrud<T> {
  const [datas, set] = useState<T[]>([]);

  function add(data: T) {
    set([...datas, { ...data }]);
  }

  function update(data: T) {
    const datasTemporary = [...datas];
    const index = datasTemporary.findIndex((g) => g.id === data.id);
    datasTemporary[index] = { ...data };
    set(datasTemporary);
  }

  function remove(id: string) {
    set(datas.filter((g) => g.id !== id));
  }

  function removeAll(ids: Set<string>) {
    set(datas.filter((g) => !ids.has(g.id)));
  }

  function getNames(ids: string[]): string[] {
    return ids.map((id) => getName(id));
  }

  function getName(id: string): string {
    if (!id) {
      return "";
    }
    if (datas.length > 0) {
      const data = datas.find((l) => l.id === id);
      if (data && data.name) {
        return data.name;
      }
    }
    return id;
  }

  return { datas, set, add, update, remove, removeAll, getNames, getName };
}

export default useCrud;

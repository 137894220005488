import React, { ChangeEvent } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { default as clsx } from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    "& div": {
      justifyContent: "space-around",
    },
  },
  topLevelWithSecondary: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  secondary: {
    borderTop: "none",
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
}));

export interface Tab {
  key: string;
  label: string;
}

interface TabsComponentProperties {
  tabs: Tab[];
  indexTab: number;
  onChangeIndexTab(indexTab: number): void;
  secondary?: boolean;
  hasSecondaryTab?: string[];
  additionalClassName?: string;
}

function TabsComponent(properties: TabsComponentProperties) {
  const {
    tabs,
    indexTab,
    onChangeIndexTab,
    secondary,
    hasSecondaryTab,
    additionalClassName,
  } = properties;
  const classes = useStyles();
  const handleChange = (_event: ChangeEvent<unknown>, value: number) =>
    onChangeIndexTab(value);
  return (
    <Tabs
      className={clsx(
        classes.root,
        {
          [classes.topLevelWithSecondary]:
            hasSecondaryTab && hasSecondaryTab.includes(tabs[indexTab].key),
          [classes.secondary]: secondary,
        },
        additionalClassName
      )}
      value={indexTab}
      onChange={handleChange}
      indicatorColor="secondary"
      textColor="primary"
    >
      {tabs.map((t) => {
        return <Tab key={t.key} label={t.label} style={{ minWidth: 50 }} />;
      })}
    </Tabs>
  );
}

export default TabsComponent;

import React, { useState, FormEvent } from "react";
import {
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

interface PasswordInputProperties {
  onChange: (
    event: FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => void;
  value: string;
  onBlur?: React.EventHandler<React.SyntheticEvent<unknown, Event>>;
  label?: string;
  disabled?: boolean;
  error?: string;
  inputProps?: { [key: string]: string };
  autoComplete?: string;
  inputRef?: React.MutableRefObject<HTMLInputElement | undefined>;
}

function PasswordInput({
  label,
  value,
  onChange,
  onBlur,
  disabled,
  error,
  inputProps,
  autoComplete,
}: PasswordInputProperties) {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  /* eslint-disable unicorn/consistent-function-scoping */
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  /* eslint-enable unicorn/consistent-function-scoping */

  return (
    <>
      <InputLabel htmlFor="adornment-password" error={!!error}>
        {label || "Mot de passe"}
      </InputLabel>
      <Input
        id="adornment-password"
        inputProps={inputProps}
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        error={!!error}
        autoComplete={autoComplete}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              size="small"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
      {error !== "" && <FormHelperText error={!!error}>{error}</FormHelperText>}
    </>
  );
}

export default PasswordInput;

import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
} from "@material-ui/core";

import createStyles from "@material-ui/core/styles/createStyles";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import PasswordInput from "components/form/password-input";
import { updatePassword } from "../../../services/api/auth";
import { useSnackbar } from "notistack";

interface UpdatePasswordDialogProperties {
  onClose: () => void;
  open: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogPaper: {
      overflowY: "visible",
    },
    buttonsGroupLayout: {
      display: "flex",
      justifyContent: "flex-end",
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(2),
    },
  })
);

function UpdatePasswordDialog({
  open,
  onClose,
}: UpdatePasswordDialogProperties) {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] =
    useState<string>("");
  const [differentPasswords, setDifferentPasswords] = useState<boolean>(false);

  function handleClose() {
    setCurrentPassword("");
    setNewPassword("");
    setNewPasswordConfirmation("");
    onClose();
  }

  const handleSave = async () => {
    try {
      const _c = await updatePassword(
        currentPassword,
        newPassword,
        newPasswordConfirmation
      );
      enqueueSnackbar("Mot de passe modifié avec succès", {
        variant: "success",
      });
    } catch {
      enqueueSnackbar(
        "Une erreur est survenue lors du changement de mot de passe",
        { variant: "error" }
      );
    }
    handleClose();
    return;
  };

  function emptyPasswordError() {
    if (newPassword !== "" && newPassword.trim().length === 0) {
      return "Le mot de passe ne doit pas être vide";
    }
    return "";
  }

  const handleChange = (
    event: React.FormEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    setNewPasswordConfirmation(event.currentTarget.value);
    setDifferentPasswords(false);
  };

  function handleConfirmationBlur() {
    if (
      newPasswordConfirmation !== "" &&
      newPassword !== newPasswordConfirmation
    ) {
      setDifferentPasswords(true);
    }
  }

  function differentPasswordsError() {
    if (differentPasswords) {
      return "Les mots de passe doivent être identiques";
    }
    return "";
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      disableEscapeKeyDown={true}
      disableBackdropClick={true}
      onClick={(event) => event.stopPropagation()}
    >
      <DialogTitle id="form-dialog-title">Changer de mot de passe</DialogTitle>
      <DialogContent>
        <FormControl variant="standard" fullWidth margin="dense">
          <PasswordInput
            autoComplete="new-password"
            onChange={(event) => setCurrentPassword(event.currentTarget.value)}
            label="Mot de passe actuel"
            value={currentPassword}
          />
        </FormControl>

        <FormControl variant="standard" fullWidth margin="dense">
          <PasswordInput
            onChange={(event) => setNewPassword(event.currentTarget.value)}
            label="Nouveau mot de passe"
            value={newPassword}
            error={emptyPasswordError()}
          />
        </FormControl>

        <FormControl variant="standard" fullWidth margin="dense">
          <PasswordInput
            onChange={handleChange}
            label="Confirmer le nouveau mot de passe"
            value={newPasswordConfirmation}
            onBlur={handleConfirmationBlur}
            error={differentPasswordsError()}
          />
        </FormControl>
      </DialogContent>

      <DialogActions className={classes.buttonsGroupLayout}>
        <Button onClick={handleClose} variant="contained">
          Annuler
        </Button>
        <Button
          onClick={handleSave}
          color="secondary"
          autoFocus
          variant="contained"
          disabled={
            newPassword === "" || newPassword !== newPasswordConfirmation
          }
        >
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdatePasswordDialog;

import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  List,
  TextField,
  Theme,
} from "@material-ui/core";
import {
  checkFields,
  FieldValidator,
} from "../../../../validators/form-validator";
import { isEmpty, max } from "../../../../validators/form-validators";
import createStyles from "@material-ui/core/styles/createStyles";
import isEqual = require("lodash/isEqual");
import FormControl from "@material-ui/core/FormControl";
import classNames from "classnames";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { GateFormState, ledColorOptions } from "../gate-tab";
import {
  Gate,
  Lane,
  LedColor,
} from "../../../../services/interfaces/referential";
import FormSelect from "../../../../components/form/select";
import FormCheckbox from "../../../../components/form/checkbox";

interface GateFormDialogProperties {
  stationId: string;
  form: GateFormState;
  setForm: (status: GateFormState) => void;
  lanes: Lane[];
}

const gateInitialValue: Gate = {
  id: "",
  name: "",
  pmr: false,
  lafBottomScreenEnabled: false,
  baseColor: LedColor.OFF,
  customColor: LedColor.OFF,
  controlTnEnabled: false,
  gateUrlAddress: "",
  active: true,
  laneId: "",
  stationId: "",
  dockId: "",
};

interface Errors {
  name: string;
  gateUrlAddress: string;
  lafAdditionalGateAddress?: string;
}

const validators: FieldValidator<Gate, Errors> = {
  name: [
    { error: "Veuillez renseigner un nom", validator: isEmpty },
    { error: "Longueur max: 64", validator: (name: string) => max(name, 64) },
  ],
  gateUrlAddress: [
    { error: "Veuillez renseigner une URL", validator: isEmpty },
    {
      error: "Longueur max: 160",
      validator: (name: string) => max(name, 160),
    },
  ],
  lafAdditionalGateAddress: [
    {
      error: "Longueur max: 160",
      validator: (name?: string) => max(name, 160),
    },
  ],
};

const defaultErrors: Errors = {
  name: "",
  gateUrlAddress: "",
  lafAdditionalGateAddress: "",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginTopField: {
      marginTop: theme.spacing(2),
    },
    dialogPaper: {
      overflowY: "visible",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      flexWrap: "nowrap",
      minWidth: "40vw",
    },
    noPadding: {
      padding: "0",
    },
    spaceBetween: {
      justifyContent: "space-between",
    },
    marginBottom: {
      marginBottom: theme.spacing(2),
    },
    buttonsGroupLayout: {
      display: "flex",
      justifyContent: "flex-end",
      marginRight: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(2),
    },
  })
);

function GateFormDialog({
  form,
  setForm,
  lanes,
  stationId,
}: GateFormDialogProperties) {
  const classes = useStyles();
  const [data, setData] = useState<Gate>({ ...gateInitialValue, stationId });
  const [errors, setErrors] = useState<Errors>(defaultErrors);

  useEffect(() => {
    if (form.open) {
      setErrors(defaultErrors);
      if (form.data === undefined) {
        setData({ ...gateInitialValue, stationId });
      } else {
        setData({ ...form.data });
      }
    }
  }, [form.data, form.open, stationId]);

  function close() {
    setForm({
      ...form,
      open: false,
    });
  }

  function save() {
    if (validateData()) {
      if (form.open && form.submit !== undefined) {
        form.submit({ ...data });
      }
      close();
    }
  }

  const validateData = (): boolean => {
    const errors = checkFields<Gate, Errors>(validators, defaultErrors, data);
    setErrors(errors);
    return isEqual(errors, defaultErrors);
  };

  return (
    <Dialog
      open={form.open}
      onBackdropClick={close}
      onEscapeKeyDown={close}
      color={"primary"}
      aria-labelledby="form-dialog-title"
      PaperProps={{ className: classNames(classes.dialogPaper) }}
    >
      <DialogTitle id="form-dialog-title">
        {form.action === "add" ? "Créer une porte" : "Modifier une porte"}
      </DialogTitle>
      <DialogContent>
        <FormControl variant="standard" fullWidth margin="dense">
          <TextField
            id="name"
            label="Nom"
            type="text"
            value={data.name || ""}
            onChange={(event) => setData({ ...data, name: event.target.value })}
            error={errors.name !== ""}
            helperText={errors.name}
          />
        </FormControl>
        <FormControl
          variant="standard"
          fullWidth
          margin="dense"
          className={classes.marginBottom}
        >
          <TextField
            id="gateUrlAddress"
            label="Url"
            type="text"
            value={data.gateUrlAddress || ""}
            onChange={(event) =>
              setData({ ...data, gateUrlAddress: event.target.value })
            }
            error={errors.gateUrlAddress !== ""}
            helperText={errors.gateUrlAddress}
          />
        </FormControl>
        <FormControl
          variant="standard"
          fullWidth
          margin="dense"
          className={classes.marginBottom}
        >
          <FormSelect
            handleOnChange={(value) => {
              setData({ ...data, laneId: value });
            }}
            availableOptions={lanes}
            emptyOption="Pas de ligne"
            displayEmpty={true}
            value={data.laneId || ""}
            label="Associer à une ligne"
          />
        </FormControl>
        <FormControl
          variant="standard"
          fullWidth
          margin="dense"
          className={classes.marginBottom}
        >
          <FormSelect
            handleOnChange={(value) => {
              const [baseColor, customColor] = value.split("_");
              setData({
                ...data,
                baseColor: baseColor as unknown as LedColor,
                customColor: customColor as unknown as LedColor,
              });
            }}
            availableOptions={ledColorOptions}
            displayEmpty={false}
            value={
              (data.baseColor || LedColor.OFF) +
              "_" +
              (data.customColor || LedColor.OFF)
            }
            label="Couleurs des LED"
          />
        </FormControl>
        <FormGroup row className={classes.spaceBetween}>
          <FormControl className={classes.noPadding}>
            <List>
              <FormCheckbox
                label={
                  data.pmr
                    ? "Dispose d'un accès PMR"
                    : "Ne dispose pas d'accès PMR"
                }
                checked={data.pmr}
                handleOnChange={() => {
                  setData({ ...data, pmr: !data.pmr });
                }}
              />
            </List>
          </FormControl>
          <FormControl className={classes.noPadding}>
            <List>
              <FormCheckbox
                label={"Affichage LAF sur l'écran bas"}
                checked={data.lafBottomScreenEnabled}
                handleOnChange={() => {
                  setData({
                    ...data,
                    lafBottomScreenEnabled: !data.lafBottomScreenEnabled,
                  });
                }}
              />
            </List>
          </FormControl>
          <FormControl>
            <List>
              <FormCheckbox
                label={data.active ? "Porte active" : "Porte inactive"}
                checked={data.active}
                handleOnChange={() => {
                  setData({ ...data, active: !data.active });
                }}
              />
            </List>
          </FormControl>
          <FormControl>
            <List>
              <FormCheckbox
                label="Contrôle Sortie TN autorisé"
                checked={data.controlTnEnabled}
                handleOnChange={() => {
                  setData({
                    ...data,
                    controlTnEnabled: !data.controlTnEnabled,
                  });
                }}
              />
            </List>
          </FormControl>
        </FormGroup>
        <FormControl
          variant="standard"
          fullWidth
          margin="dense"
          className={classes.marginBottom}
        >
          <TextField
            id="lafAdditionalGateAddress"
            label="Double LAF - IP de la seconde porte"
            type="text"
            value={data.lafAdditionalGateAddress || ""}
            onChange={(event) =>
              setData({ ...data, lafAdditionalGateAddress: event.target.value })
            }
            error={errors.lafAdditionalGateAddress !== ""}
            helperText={errors.lafAdditionalGateAddress}
          />
        </FormControl>
      </DialogContent>
      <DialogActions className={classes.buttonsGroupLayout}>
        <Button onClick={close} variant="contained">
          Annuler
        </Button>
        <Button onClick={save} color="secondary" autoFocus variant="contained">
          Valider
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default GateFormDialog;

import React, { useState } from "react";
import StationTab from "./station-tab";
import GroupTab from "./group-tab";
import Tabs from "../../../components/tabs-component";
import tabsConfig from "./utils/tabs-config";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router";
import useRights from "../../../services/ducks/rights";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
    titleLayout: {
      textAlign: "left",
      marginTop: "0",
    },
    tabs: {
      marginBottom: 20,
    },
  })
);

function GeographicalReferential({ history }: RouteComponentProps) {
  const classes = useStyles();
  const [indexTab, setIndexTab] = useState(0);
  const { hasRights } = useRights();

  function changeTab(index: number) {
    switch (index) {
      case 0: {
        history.push("/referential/geo/groups");
        break;
      }
      case 1: {
        history.push("/referential/geo/stations");
        break;
      }
    }
  }

  return (
    <div className={classes.root}>
      <h1 className={classes.titleLayout}>Référentiel géographique</h1>
      {hasRights(["REF_GEO_GROUP_READ", "REF_GEO_GARE_READ"]) && (
        <Tabs
          tabs={tabsConfig}
          indexTab={indexTab}
          onChangeIndexTab={changeTab}
          additionalClassName={classes.tabs}
        />
      )}
      <Switch>
        <Redirect
          from="/referential/geo"
          exact={true}
          to={`/referential/geo/${
            hasRights("REF_GEO_GROUP_READ") ? "groups" : "stations"
          }`}
        />
        {hasRights("REF_GEO_GROUP_READ") && (
          <Route
            path={"/referential/geo/groups"}
            exact={true}
            render={() => (
              <>
                {setIndexTab(0)}
                <GroupTab />
              </>
            )}
          />
        )}
        {hasRights("REF_GEO_GARE_READ") && (
          <Route
            path={"/referential/geo/stations"}
            exact={true}
            render={() => (
              <>
                {setIndexTab(1)}
                <StationTab />
              </>
            )}
          />
        )}
      </Switch>
    </div>
  );
}

export default withRouter(GeographicalReferential);

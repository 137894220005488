import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  ConfigurationType,
  ConfigurationValueDTO,
  fetchConfigurationValue,
} from "../../services/api/configurations";

function useConfigurationValue(
  configurationId: string | undefined,
  configurationValueId: string | undefined,
  addMode: boolean,
  setIsLoading: (s: boolean) => void,
  isTransporter: boolean,
  configurationType: ConfigurationType,
  transporter: string | undefined
): [
  ConfigurationValueDTO | undefined,
  Dispatch<SetStateAction<ConfigurationValueDTO | undefined>>
] {
  const [configurationValue, setConfigurationValue] =
    useState<ConfigurationValueDTO>();

  useEffect(() => {
    if (!configurationId || (!configurationValueId && !addMode)) {
      return;
    }

    if (addMode) {
      setConfigurationValue({
        id: "",
        type: isTransporter ? "JSON" : "FILE",
        name: "",
        path: "",
        lastUpdateDatetime: "",
      });
      setIsLoading(false);
      return;
    }

    const loadConfigurationValue = async () => {
      const c = await fetchConfigurationValue(
        configurationType,
        transporter,
        configurationId,
        configurationValueId || "" // ts fix, should never be empty when it's called
      );
      setConfigurationValue(c);
      setIsLoading(false);
    };

    loadConfigurationValue();
  }, [configurationId, configurationValueId, addMode, setIsLoading]);

  return [configurationValue, setConfigurationValue];
}

export default useConfigurationValue;

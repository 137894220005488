import axios from "axios";
import { Lane } from "../interfaces/referential";

export async function fetchLanes(stationId: string) {
  const response = await axios.get(
    "/referential/stations/" + stationId + "/lanes"
  );
  return response.data;
}

export async function addLane(lane: Lane) {
  const response = await axios.post("/referential/lanes", {
    name: lane.name,
    description: lane.description,
    gateIds: lane.gateIds,
    dockId: lane.dockId,
    stationId: lane.stationId,
    trackLaneDTOList: lane.trackLaneDTOList,
  });
  return response.data;
}

export async function updateLane(lane: Lane) {
  const response = await axios.put("/referential/lanes/" + lane.id, {
    id: lane.id,
    name: lane.name,
    description: lane.description,
    gateIds: lane.gateIds,
    dockId: lane.dockId,
    stationId: lane.stationId,
    trackLaneDTOList: lane.trackLaneDTOList,
  });
  return response.data;
}

export async function deleteLanes(laneIds: Set<string>) {
  await axios.delete("/referential/lanes/" + [...laneIds].join(","), {
    responseType: "text",
  });
}

export async function deleteLane(laneId: string) {
  await deleteLanes(new Set([laneId]));
}

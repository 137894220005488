import React from "react";
import {
  Theme,
  List,
  ListItemText,
  FormHelperText,
  Box,
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { default as clsx } from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listLayout: {
      overflowX: "hidden",
      backgroundColor: "#f5f5f5",
      minWidth: "50px",
      borderRadius: "4px",
      padding: 0,
      "&>div:nth-child(even)": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
      "&::-webkit-scrollbar": {
        width: "8px",
        height: "8px",
        backgroundColor: "#F9F9F9",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        backgroundColor: "#B7B7B7",
      },
      "&::-webkit-scrollbar-track": {
        borderRadius: "10px",
        backgroundColor: "#F9F9F9",
      },
    },
    listItemLayout: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      margin: 0,
    },
    fullWidth: {
      width: "100%",
    },
    smallMarginBottom: {
      marginBottom: theme.spacing(1),
    },
  })
);

interface ItemElementsListProperties {
  label: string;
  rows: JSX.Element[];
  fullWidth?: boolean;
  additionalClassName?: string;
  height?: string;
  noFixedHeight?: boolean;
}

function ItemElementsList({
  label,
  rows,
  fullWidth,
  additionalClassName,
  noFixedHeight,
  height = "200px",
}: ItemElementsListProperties) {
  const classes = useStyles();

  return (
    <Box
      className={clsx(additionalClassName, {
        [classes.fullWidth]: fullWidth,
      })}
    >
      <FormHelperText className={classes.smallMarginBottom}>
        {label}
      </FormHelperText>
      <List
        className={classes.listLayout}
        style={{
          height: noFixedHeight ? "auto" : height,
          maxHeight: noFixedHeight ? height : "none",
        }}
      >
        {rows.map((text, index) => (
          <ListItemText
            key={text.key || index}
            className={classes.listItemLayout}
          >
            {text}
          </ListItemText>
        ))}
      </List>
    </Box>
  );
}

export default ItemElementsList;

import { makeStyles } from "@material-ui/styles";
import {
  SNCF_MAIN_DARK_VIOLET,
  SNCF_MAGENTA,
  SNCF_PURPLE,
  SNCF_WHITE,
  SNCF_GRAY_DELIMITER,
} from "../../../theme";

export const useLoginStyle = makeStyles({
  rootContainer: {
    color: SNCF_MAIN_DARK_VIOLET,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: SNCF_WHITE,
    position: "fixed",
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
  },
  container: {
    width: "1000px",
    padding: "20px",
  },
  coloredInput: {
    color: SNCF_MAIN_DARK_VIOLET,
  },
  columnContainer: {
    display: "flex",
    flexFlow: "column nowrap",
    justifyContent: "space-between",
    flex: "1 1 auto",
    padding: "20px",
  },
  rowContainer: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "space-between",
    flex: 1,
  },
  horizontalSeparator: {
    height: "1px",
    margin: "auto",
    width: "95%",
    backgroundColor: SNCF_GRAY_DELIMITER,
  },
  verticalSeparator: {
    height: "235px",
    width: "1px",
    backgroundColor: SNCF_GRAY_DELIMITER,
  },
  formInput: {
    margin: "10px",
    color: SNCF_MAIN_DARK_VIOLET,
  },
  title: {
    fontSize: "30px",
    textAlign: "center",
    marginBottom: "20px",
  },
  submit: {
    marginTop: "40px",
    backgroundColor: SNCF_MAIN_DARK_VIOLET,
    "&:hover": {
      backgroundColor: `${SNCF_MAIN_DARK_VIOLET}D9`,
    },
  },
  submitSncf: {
    backgroundColor: SNCF_PURPLE,
    "&:hover": {
      backgroundColor: `${SNCF_PURPLE}D9`,
    },
  },
  submitSncfSingle: {
    maxWidth: "496px",
    backgroundColor: SNCF_PURPLE,
    "&:hover": {
      backgroundColor: `${SNCF_PURPLE}D9`,
    },
  },
  loader: {
    position: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  error: {
    color: SNCF_MAGENTA,
    margin: "10px",
    textAlign: "center",
  },
});

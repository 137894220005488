import axios from "axios";
import FileDownload from "js-file-download";
import { formatDeploymentExportFilename } from "services/utils/deployments";

interface BundleInfo {
  group: string;
  name: string;
  fullName: string;
}

export interface BundleVersionDTO {
  actual: string;
  requested: string | null;
  id: BundleInfo;
}

export type DeploymentStatus =
  | "INIT"
  | "CORE_DOWNLOADED"
  | "CORE_DOWNLOAD_ERROR"
  | "GATE_DOWNLOAD_WAITING"
  | "GATE_DOWNLOADED"
  | "CANCELLED"
  | "INSTALL_FAILURE";

export type DeploymentType = "CONFIG" | "SOFTWARE";

interface GateDeploymentStatusDTO {
  gateName: string;
  gateId: string;
  id: string;
  laneName: string;
  lastDeploymentDate?: string;
  stationCode: string;
  status: DeploymentStatus;
  bundles: BundleVersionDTO[];
}

interface DockDeploymentStatusDTO {
  dockName: string;
  gates: GateDeploymentStatusDTO[];
  sameVersions: boolean;
}

export interface StationDeploymentStatusDTO {
  sameVersions: boolean;
  docks: DockDeploymentStatusDTO[];
}

export interface VersionInfo {
  name: string;
  version: string;
  author?: string;
  standalone?: boolean;
  testVersion?: boolean;
  description?: string;
  creationDate: string | null;
}

export interface BundleAvailableVersions {
  info: BundleInfo;
  versions: VersionInfo[];
}

interface DeploymentRequestDTO {
  bundleVersions: { [key: string]: string | null };
  gateIds: string[];
  programmationDate: string;
}

export async function fetchDeployments(
  type: string,
  ids: string[]
): Promise<{ [key: string]: StationDeploymentStatusDTO }> {
  const response = await axios.get(`/deployments/${type}/${ids}`);

  return response.data;
}

export async function fetchAvailableVersions(
  type: string
): Promise<BundleAvailableVersions[]> {
  const response = await axios.get(`/deployments/${type}/_versions`);
  return response.data;
}

export async function createDeployment(
  type: string,
  request: DeploymentRequestDTO
) {
  return axios.post(`/deployments/${type}`, request);
}

export async function cancelDeployments(gateIds: string[]) {
  return axios.post(`/deployments/cancel`, {
    gateIds,
  });
}

export async function fetchDeploymentsFullExportFor(type: DeploymentType) {
  const response = await axios.get(`/deployments/${type}/_export/all`, {
    responseType: "blob",
  });
  const filename = formatDeploymentExportFilename(
    "Export_historique",
    type,
    "xlsx"
  );
  FileDownload(response.data, filename);
}

export async function fetchRecentDeploymentsExportFor(type: DeploymentType) {
  const response = await axios.get(`/deployments/${type}/_export/last`, {
    responseType: "blob",
  });
  const filename = formatDeploymentExportFilename(
    "Export_derniers_deploiements",
    type,
    "xlsx"
  );
  FileDownload(response.data, filename);
}

export async function fetchCurrentGateVersionsExportFor(type: DeploymentType) {
  const response = await axios.get(`/deployments/${type}/_export/current`, {
    responseType: "blob",
  });
  const filename = formatDeploymentExportFilename(
    "Export_versions_actuelles",
    type,
    "xlsx"
  );
  FileDownload(response.data, filename);
}

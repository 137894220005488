import { Theme } from "@material-ui/core";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: "100%",
      whiteSpace: "nowrap",
    },
    popoverContainer: {
      position: "absolute",
      left: 0,
      top: 0,
      height: "100%",
      width: "100%",
      backgroundImage:
        "linear-gradient(to right, transparent, transparent, white)",
    },
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
    typography: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      alignContent: "space-between",
      maxWidth: theme.spacing(70),
      "&>*": {
        margin: theme.spacing(0.5),
      },
    },
  })
);

interface PopoverWrapperRenderer {
  children: ReactNode;
  label?: string;
}

export default function PopoverWrapperRenderer({
  children,
  label,
}: PopoverWrapperRenderer) {
  const classes = useStyles();
  const [anchorElement, setAnchorElement] = useState<HTMLElement>();
  const open = Boolean(anchorElement);
  const reference = useRef<HTMLDivElement | null>(null);
  const showPopover = reference.current
    ? reference.current.scrollWidth > reference.current.clientWidth
    : false;
  const [showPopoverState, setShowPopoverState] = useState(showPopover);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        !showPopover &&
        !showPopoverState &&
        reference.current &&
        reference.current.scrollWidth > reference.current.clientWidth
      ) {
        setShowPopoverState(true);
      }
    }, 0);
    return () => clearTimeout(timeout);
  }, [showPopover, showPopoverState]);

  function handlePopoverOpen(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    setAnchorElement(event.currentTarget);
  }

  function handlePopoverClose() {
    setAnchorElement(undefined);
  }

  return (
    <>
      <div className={classes.content} ref={reference}>
        {label && `${label} : `}
        {children}
      </div>
      {(showPopover || showPopoverState) && (
        <>
          <Typography
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            className={classes.popoverContainer}
          />
          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            classes={{ paper: classes.paper }}
            open={open}
            anchorEl={anchorElement}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography className={classes.typography} component="div">
              {children}
            </Typography>
          </Popover>
        </>
      )}
    </>
  );
}

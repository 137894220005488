import React, { CSSProperties, ReactNode } from "react";
import { Theme, ListItemText, FormHelperText, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { default as clsx } from "clsx";

const useStyles = makeStyles((_theme: Theme) => ({
  elementLayout: {
    flex: 1,
    minWidth: "50px",
  },
}));

interface ItemElementProperties {
  label: string;
  text: string | number;
  children?: ReactNode;
  style?: CSSProperties;
  additionalClassName?: string;
}

function ItemElement({
  label,
  text,
  children,
  style,
  additionalClassName,
}: ItemElementProperties) {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.elementLayout, additionalClassName)}>
      <ListItemText>
        <FormHelperText
          style={{ display: "inline-block", marginRight: 10, ...style }}
        >
          {label}
        </FormHelperText>
        {text}
        {children}
      </ListItemText>
    </Box>
  );
}

export default ItemElement;

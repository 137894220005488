import axios from "axios";
import debug from "debug";

const log = debug("sncf:configurationSerice");

interface Config {
  BOT_URL: string;
  IDP_LOGIN_URL: string;
  REDIRECT_PATH: string;
  OPPE_URL: string;
  PAGECHECKUP_URL: string;
  HOMEPAGE_REFRESH_INTERVAL: number;
  REACT_APP_VERSION: string;
  LIGHT_MODE: boolean;
}

let config: Config = {
  BOT_URL: "",
  IDP_LOGIN_URL: "",
  REDIRECT_PATH: "",
  OPPE_URL: "",
  PAGECHECKUP_URL: "",
  HOMEPAGE_REFRESH_INTERVAL: 5,
  REACT_APP_VERSION: "",
  LIGHT_MODE: false,
};

export const getConfig = (): Config => config;

export const initConfig = async (
  callback: () => void | Promise<void>
): Promise<void> => {
  try {
    const response = await axios.get("/config.json", { baseURL: "/" });
    config = {
      ...config,
      ...response.data,
    };
  } catch {
    log("Failed to retrieve user config. Will use default env config.");
  }
  log("loaded config is ", config);

  // configuration de l'url de bot
  axios.defaults.baseURL = config.LIGHT_MODE
    ? config.BOT_URL + "/api/v2/light"
    : config.BOT_URL + "/api/v2";

  callback();
};

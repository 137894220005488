import List from "@material-ui/core/List";
import HomeIcon from "@material-ui/icons/Home";
import ConfigIcon from "@material-ui/icons/Settings";
import LayersIcon from "@material-ui/icons/Layers";
import WidgetsIcon from "@material-ui/icons/Widgets";
import PlaceIcon from "@material-ui/icons/Place";
import SupervisedUserCircle from "@material-ui/icons/SupervisedUserCircle";
import TrainIcon from "@material-ui/icons/Train";
import React from "react";
import MenuItem from "./menu-item";
import MenuItemDropDown from "./menu-item-drop-down";
import useRights from "../../services/ducks/rights";
import { getConfig } from "services/config";
import { Description } from "@material-ui/icons";
import { TabKey } from "pages/whitelists/hooks/use-tabs";

interface MenuProperties {
  open?: boolean;
}

function Menu({ open }: MenuProperties) {
  const { hasRights, hasSomeRights } = useRights();
  const config = getConfig();

  function renderUsersMenuItem() {
    if (hasRights("USER_READ")) {
      return (
        <MenuItem
          Icon={SupervisedUserCircle}
          text="Utilisateurs"
          to="/users"
          tooltip={open ? "" : "Utilisateurs"}
        />
      );
    }
    return <></>;
  }

  function renderReferentialMenuItem() {
    const referentialItems = [];
    if (hasRights("SUPERVISION_REF_GARE_READ")) {
      referentialItems.push({
        Icon: TrainIcon,
        text: "Gare",
        to: "/referential/stations",
        tooltip: open ? "" : "Référentiel de gare",
      });
    }
    if (hasSomeRights(["REF_GEO_GARE_READ", "REF_GEO_GROUP_READ"])) {
      referentialItems.push({
        Icon: PlaceIcon,
        text: "Géographique",
        to: "/referential/geo",
        tooltip: open ? "" : "Référentiel géographique",
      });
    }
    if (referentialItems.length > 0) {
      return (
        <MenuItemDropDown
          Icon={LayersIcon}
          text="Référentiels"
          items={referentialItems}
          tooltip={open ? "" : "Référentiels"}
        />
      );
    }
    return <></>;
  }

  function renderConfigMenuItem() {
    const lightWithRights =
      config.LIGHT_MODE && hasRights(["CONF_TRANSP_READ"]);
    const fullWithSomeRights =
      !config.LIGHT_MODE &&
      hasSomeRights(["CONF_TECH_READ", "CONF_TRANSV_READ", "CONF_TRANSP_READ"]);
    if (lightWithRights || fullWithSomeRights) {
      const navUrl = lightWithRights ? "transporters" : "";
      return (
        <MenuItem
          Icon={ConfigIcon}
          text="Configurations"
          tooltip={open ? "" : "Configurations"}
          to={`/configurations/${navUrl}`}
        />
      );
    }
    return <></>;
  }

  function renderDeployMenuItem() {
    if (hasSomeRights(["DEPLOY_SOFT_READ", "DEPLOY_CONF_READ"])) {
      return (
        <MenuItem
          Icon={WidgetsIcon}
          text="Déploiements"
          to="/deploy"
          tooltip={open ? "" : "Déploiements"}
        />
      );
    }
    return <></>;
  }

  function renderWhitelistsMenuItem() {
    const regularAccess =
      !config.LIGHT_MODE &&
      hasSomeRights(["WL_UG_CONFIG_READ", "WL_TRANSPORTER_CONFIG_READ"]);
    const lightAccess =
      config.LIGHT_MODE && hasRights("WL_TRANSPORTER_CONFIG_READ");

    if (regularAccess || lightAccess) {
      const defaultTab = config.LIGHT_MODE
        ? TabKey.TRANSPORTER
        : hasSomeRights(["WL_UG_CONFIG_READ"])
        ? TabKey.UG
        : TabKey.TRANSPORTER;
      return (
        <MenuItem
          Icon={Description}
          text="Whitelists"
          to={"/whitelists/" + defaultTab}
          tooltip={open ? "" : "Whitelists"}
        />
      );
    }
    return <></>;
  }

  return (
    <List>
      <MenuItem
        Icon={HomeIcon}
        text="Accueil"
        to="/"
        exact={true}
        tooltip={open ? "" : "Accueil"}
      />
      {renderUsersMenuItem()}
      {!config.LIGHT_MODE && renderReferentialMenuItem()}
      {renderConfigMenuItem()}
      {!config.LIGHT_MODE && renderDeployMenuItem()}
      {renderWhitelistsMenuItem()}
    </List>
  );
}

export default Menu;

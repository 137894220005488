import React, { useState } from "react";
import { TextField, makeStyles, createStyles } from "@material-ui/core";
import { default as clsx } from "clsx";

interface SearchFieldProperties {
  placeholder: string;
  className?: string;
  onChange: (search: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    focusInput: {
      background: "rgba(255,255,255,0.15)",
    },
    search: {
      color: "white",
      "& .MuiInput-input": {
        fontWeight: 500,
        fontSize: "0.75rem",
        color: "white",
        borderBottom: "2px solid white",
        width: "95%",
      },
    },
  })
);

function SearchField({
  placeholder,
  className,
  onChange,
}: SearchFieldProperties) {
  const classes = useStyles();
  const [value, setValue] = useState<string>("");

  return (
    <TextField
      value={value}
      placeholder={placeholder}
      margin="none"
      InputProps={{ disableUnderline: true }}
      className={clsx(classes.search, className, {
        [classes.focusInput]: value.length > 0,
      })}
      onChange={(
        event: React.ChangeEvent<
          HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
        >
      ) => {
        onChange(event.target.value);
        setValue(event.target.value);
      }}
    />
  );
}
export default SearchField;

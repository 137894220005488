import React, { ChangeEvent } from "react";
import { Box, Button } from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";

interface PassListFormProperties {
  onImport: (passValues: string[]) => void;
}

function readFile(file: File): Promise<string> {
  return new Promise(function (resolve) {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const content = reader.result as string;
      resolve(content);
    });
    reader.readAsText(file);
  });
}

export function PassListFormImport({ onImport }: PassListFormProperties) {
  const handleImport = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      readFile(event.target.files[0]).then((f) => {
        const importedValues = f.replace(/(\r|\\r|\t|\\t)/gm, "").split("\n");
        onImport(importedValues);
      });
    }
  };

  return (
    <Box mx={1}>
      <input
        type="file"
        accept="text/plain"
        id="pass-list-import-input"
        style={{ display: "none" }}
        onChange={handleImport}
      />
      <label htmlFor="pass-list-import-input">
        <Button
          color="primary"
          variant="contained"
          component="span"
          startIcon={<CloudUpload />}
        >
          Import
        </Button>
      </label>
    </Box>
  );
}
